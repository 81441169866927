import Vue from 'vue'

export default Vue.extend({
  name: 'IconError',
  functional: true,
  render(h) {
    return h(
      'svg',
      {
        staticClass: 'app-alert__icon',
        attrs: { viewBox: '0 -960 960 960' },
      },
      [
        h('path', {
          attrs: {
            d: 'M440-280h80v-240h-80v240Zm40-320q17 0 29-11t11-29q0-17-11-28t-29-12q-17 0-28 12t-12 28q0 17 12 29t28 11Zm0 520q-83 0-156-31t-127-86q-54-54-85-127T80-480q0-83 32-156t85-127q54-54 127-85t156-32q83 0 156 32t127 85q54 54 86 127t31 156q0 83-31 156t-86 127q-54 54-127 86T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z',
          },
        }),
      ],
    )
  },
})
