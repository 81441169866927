import { computed } from 'vue'

import { useGetWorkspaceWithConsumablesQuery } from '@/use/graphql'

import useAuthenticated from '../useAuthenticated'
import useWorkspaceId from '../useWorkspaceId'

export default function useAvailableCredits() {
  const { authenticated } = useAuthenticated()
  const { workspaceId } = useWorkspaceId()
  const { result, loading } = useGetWorkspaceWithConsumablesQuery(
    () => ({
      id: workspaceId.value,
    }),
    () => ({ enabled: authenticated.value && workspaceId.value !== undefined }),
  )
  const consumables = computed(
    () => result.value?.workspace?.consumables ?? { credits: 0, openWeves: 0 },
  )

  const credits = computed(() => consumables.value.credits)
  const openWeveRooms = computed(() => consumables.value.openWeves)

  return {
    loading,
    credits,
    openWeveRooms,
  }
}
