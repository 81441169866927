import { ref, onBeforeUnmount } from 'vue'

import type { Nullable, ExperienceId } from '@/types'
import type Request from '@/services/Request'
import ScheduleSchema from '@/modules/schedule/ScheduleSchema'
import SessionAvailabilitySchema from '@/modules/schedule/SessionAvailabilitySchema'
import type ExperienceScheduleSchema from '@/modules/schedule/ExperienceScheduleSchema'

import { useSchedulerService } from '../services'

export default function useAvailableDates() {
  const { service } = useSchedulerService()

  let request: Nullable<Request<Date[]>> = null
  const loading = ref(false)
  const dates = ref<Date[]>([])

  onBeforeUnmount(() => request?.cancel())

  function cancel() {
    request?.cancel()
  }

  async function fetch(r: Request<Date[]>) {
    cancel()
    dates.value = []
    request = r
    loading.value = true
    try {
      dates.value = await request.promise
    } finally {
      request = null
      loading.value = false
    }
    return request
  }

  async function getForSchema(schema: ScheduleSchema) {
    const r = service.availableDatesForSchema(schema)
    await fetch(r)
  }

  async function getForSession(schema: SessionAvailabilitySchema) {
    const r = service.availableDatesForSession(schema)
    await fetch(r)
  }

  async function forExperience(
    id: ExperienceId,
    options: ExperienceScheduleSchema,
  ) {
    await fetch(service.availableDatesForExperience(id, options))
  }

  return {
    loading,
    dates,
    getForSchema,
    getForSession,
    forExperience,
  }
}
