import type { UnwrapSet } from '@/types'

import type { Props as IdentifiableMixinProps } from '../../mixins/identifiable'
import type { TextInputMixinProps } from '../../mixins/text-input'

export const TEXTAREA_SIZES = new Set(['md'] as const)
export type TextareaSize = UnwrapSet<typeof TEXTAREA_SIZES>

export interface TextareaProps
  extends IdentifiableMixinProps,
    TextInputMixinProps {
  hideLabel?: boolean
  autoheight?: boolean
}

export type TextareaRefs = {
  field: HTMLTextAreaElement
}
