
import type { PropType } from 'vue'
import Vue from 'vue'
import type {
  TooltipProp,
  Position,
} from 'vue-slider-component/typings/typings'
import VueSlider from 'vue-slider-component'

import type { VueComponentListeners } from '@/types'

export default Vue.extend({
  name: 'WeveSlider',
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    height: {
      type: Number,
      default: 4,
    },
    dotSize: {
      type: Number,
      default: 16,
    },
    tooltip: {
      type: String as PropType<TooltipProp>,
      default: 'always',
    },
    tooltipPlacement: {
      type: String as PropType<Position>,
      default: 'top',
    },
  },
  computed: {
    listeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        change: (val: number) => this.$emit('input', val),
      }
    },
  },
  render(h) {
    return h(VueSlider, {
      props: {
        ...this.$attrs,
        value: this.value,
        height: this.height,
        dotSize: this.dotSize,
        tooltip: this.tooltip,
        tooltipPlacement: this.tooltipPlacement,
      },
      staticClass: 'weve-slider',
      on: this.listeners,
    })
  },
})
