
import type { PropType } from 'vue'

import { SlotsMixin } from '../../mixins'
import { uid } from '../../utils'
import Icon from '../Icon/Icon.vue'
import { SelectionCardType, SELECTION_CARD_MODEL } from './SelectionCard.types'

/** @private */
export default SlotsMixin.extend({
  name: 'WeveSelectionCard',
  inheritAttrs: false,
  model: SELECTION_CARD_MODEL,
  props: {
    id: {
      type: String,
      default: uid,
    },
    type: {
      type: String as PropType<SelectionCardType>,
      required: true,
      validator: (value: SelectionCardType) =>
        Object.values(SelectionCardType).includes(value),
    },
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Boolean] as PropType<unknown>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  render(h) {
    return h(
      'div',
      {
        staticClass: 'weve-selection-card',
        class: {
          'weve-selection-card--checked': this.checked,
          [`weve-selection-card--type--${this.type}`]: true,
        },
      },
      [
        h(
          'label',
          {
            attrs: { for: this.id },
            staticClass: 'weve-selection-card__label',
          },
          [
            h('input', {
              attrs: {
                ...this.$attrs,
                id: this.id,
                name: this.name,
                value: this.value,
                type:
                  this.type === SelectionCardType.RADIO ? 'radio' : 'checkbox',
              },
              domProps: {
                checked: this.checked,
              },
              staticClass: 'sr-only',
              on: this.$listeners,
            }),
            h('span', { staticClass: 'sr-only' }, this.label),
          ],
        ),
        h(
          'div',
          { staticClass: 'weve-selection-card__imitator' },
          this.type === SelectionCardType.RADIO
            ? null
            : [
                h(Icon, {
                  props: { name: 'check' },
                  staticClass: 'weve-selection-card__icon',
                }),
              ],
        ),
        this.normalizeSlot('default'),
      ],
    )
  },
})
