const GetterTypes = {
  AUTHENTICATED: 'AUTHENTICATED',
  IS_WORKSPACE_SELECTED: 'IS_WORKSPACE_SELECTED',
  USER_ID: 'USER_ID',
  ROLE: 'ROLE',
  GROUPS: 'GROUPS',
  HAS_ACCESS: 'HAS_ACCESS',
  IS_ADMIN: 'IS_ADMIN',
  IS_EDITOR: 'IS_EDITOR',
  IS_STAFF: 'IS_STAFF',
  HAS_APPROPRIATE_ROLE: 'HAS_APPROPRIATE_ROLE',
  IS_EVENT_PRODUCER: 'IS_EVENT_PRODUCER',
  IS_HOST: 'IS_HOST',
  IS_SALESPERSON: 'IS_SALESPERSON',
  IS_BOOKING_USER: 'IS_BOOKING_USER',
  PERMISSIONS: 'PERMISSIONS',
  HAS_STAFF_PERMISSIONS: 'HAS_STAFF_PERMISSIONS',
}

export default GetterTypes
