
import type { VNode, PropType } from 'vue'
import mixins from 'vue-typed-mixins'
import type { WithEvents } from 'vue-typed-emit'
import type { WithRefs } from 'vue-typed-refs'

import type { ClassDefinition, VueComponentListeners } from '../../types'
import {
  SlotsMixin,
  IdentifiableMixin,
  TextInputMixin,
  ThemeMixin,
} from '../../mixins'
import WeveFormControlLabel from '../FormControlLabel/FormControlLabel.vue'
import WeveFormControlHint from '../FormControlHint/FormControlHint.vue'
import WeveStatusIndicator from '../StatusIndicator/StatusIndicator.vue'
import WeveFormControlMessage from '../FormControlMessage/FormControlMessage.vue'
import WeveIcon from '../Icon/Icon.vue'
import WeveInlineHelp from '../InlineHelp/InlineHelp.vue'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import { Events } from './types'

export type Refs = {
  input: HTMLInputElement
}

const Extendable = mixins(
  SlotsMixin,
  IdentifiableMixin,
  TextInputMixin,
  ThemeMixin,
)

export default (
  Extendable as WithEvents<Events, WithRefs<Refs, typeof Extendable>>
).extend({
  name: 'WeveTextField',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'text',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String as PropType<string>,
      default: undefined,
    },
    appendIcon: {
      type: String as PropType<string>,
      default: undefined,
    },
    mask: {
      type: String,
      default: undefined,
    },
    inputClass: {
      type: [String, Array, Object] as PropType<ClassDefinition>,
      default: undefined,
    },
  },
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    localListeners(): VueComponentListeners {
      return {
        ...this.listeners,
        focus: (e: FocusEvent) => {
          this.focused = true
          this.$emit('focus', e)
        },
        blur: (e: FocusEvent) => {
          this.focused = false
          this.$emit('blur', e)
        },
        change: (event: InputEvent) => {
          const value = (event.target as HTMLInputElement).value
          this.$emit('change', value)
        },
      }
    },
    withAppendSlot(): boolean {
      return (
        this.$slots.append !== undefined ||
        this.$scopedSlots.append !== undefined
      )
    },
    bottomVisible(): boolean {
      return (
        this.hint !== undefined ||
        this.message !== undefined ||
        this.hasSlot('message') ||
        this.hasSlot('hint')
      )
    },
    computedPlaceholder(): string | undefined {
      const { label, placeholder } = this
      if (placeholder !== undefined) return placeholder
      if (label === undefined) return undefined
      return `Enter ${label}`
    },
    $removable(): boolean {
      return (
        this.removable === true &&
        this.value !== null &&
        this.value !== undefined &&
        this.value !== ''
      )
    },
  },
  mounted() {
    if (this.autofocus === true) {
      this.focus()
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
  },
  render(h): VNode {
    return h(
      'div',
      {
        class: {
          'app-text-input--disabled': this.disabled,
          'app-text-input--readonly': this.readonly,
          'app-text-input--invalid': this.invalid,
          'app-text-input--with-prepend-icon': this.prependIcon !== undefined,
          'app-text-input--with-append-icon': this.appendIcon !== undefined,
          'app-text-input--focused': this.focused,
          'app-text-input--with-append-slot': this.withAppendSlot,
          'app-text-input--removable': this.$removable,
          'app-form-control': true,
          'w-theme--light': this.light,
          'w-theme--dark': this.dark,
        },
        staticClass: 'app-text-input',
      },
      [
        h(
          'div',
          {
            class: { 'sr-only': this.hideLabel },
            staticClass: 'app-text-input__top',
          },
          [
            h(
              WeveFormControlLabel,
              {
                attrs: { for: this.id },
                staticClass: 'app-text-input__label',
              },
              this.label,
            ),
            this.help !== undefined
              ? h(
                  WeveInlineHelp,
                  { staticClass: 'app-text-input__help' },
                  this.help,
                )
              : undefined,
          ],
        ),
        h('div', { staticClass: 'app-text-input__inner' }, [
          h('div', { staticClass: 'app-text-input__holder' }, [
            this.prependIcon !== undefined
              ? h(WeveIcon, {
                  props: { name: this.prependIcon },
                  staticClass: 'app-text-input__prepend-icon',
                })
              : undefined,
            h('input', {
              ref: 'input',
              attrs: {
                ...this.$attrs,
                id: this.id,
                type: this.type,
                disabled: this.disabled,
                readonly: this.readonly,
                placeholder: this.computedPlaceholder,
                'aria-invalid': this.invalid === true ? 'true' : undefined,
              },
              domProps: {
                value: this.value,
              },
              class: this.inputClass,
              staticClass: 'app-text-input__field',
              on: this.localListeners,
            }),
            this.appendIcon !== undefined
              ? h(WeveIcon, {
                  props: { name: this.appendIcon },
                  staticClass: 'app-text-input__append-icon',
                })
              : undefined,
            this.$_status !== undefined
              ? h(WeveStatusIndicator, {
                  props: { status: this.$_status },
                  staticClass: 'app-text-input__status-indicator',
                })
              : undefined,
            this.$removable
              ? h(ButtonIcon, {
                  props: { icon: 'close', label: 'Remove', size: 'sm' },
                  staticClass: 'app-text-input__remove',
                  on: {
                    click: () => {
                      this.$emit('remove')
                    },
                  },
                })
              : undefined,
          ]),
          this.hasSlot('append')
            ? h(
                'div',
                { staticClass: 'app-text-input__append' },
                this.normalizeSlot('append'),
              )
            : undefined,
          this.normalizeSlot('inner'),
        ]),
        this.bottomVisible === true
          ? h(
              'div',
              {
                staticClass: 'weve-form-control__bottom app-text-input__bottom',
              },
              [
                this.hasSlot('hint')
                  ? this.normalizeSlot('hint')
                  : this.hint !== undefined
                  ? h(WeveFormControlHint, undefined, [this.hint])
                  : undefined,
                this.hasSlot('message')
                  ? this.normalizeSlot('message')
                  : this.message !== undefined &&
                    this.$_message_variant !== undefined
                  ? h(
                      WeveFormControlMessage,
                      {
                        props: { variant: this.$_message_variant },
                        staticClass: 'app-text-input__message',
                      },
                      [this.message],
                    )
                  : undefined,
              ],
            )
          : undefined,
        this.hasSlot('bottom') ? this.normalizeSlot('bottom') : undefined,
      ],
    )
  },
})
