import { ref, onMounted } from 'vue'

export default function useParentMessage(origin: string) {
  const hasParent = ref(false)

  onMounted(() => {
    hasParent.value = window.self !== window.top
  })

  function send(data: unknown) {
    if (hasParent.value === false) return
    try {
      const parent = new URL(document.referrer)
      try {
        const targer = new URL(origin)
        if (parent.host !== targer.host) return
        window.parent.postMessage(data, origin)
      } catch (e) {
        console.error(e)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return { hasParent, send }
}
