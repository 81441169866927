import Vue from 'vue'

import type { PageInfo } from '@/graphql/types'

export default Vue.extend({
  name: 'PaginationMixin',
  data() {
    return {
      pageInfo: null as PageInfo | null,
      limit: 25,
      cursor: undefined as any | undefined,
    }
  },
  computed: {
    hasPreviousPage(): boolean {
      return this.pageInfo?.hasPreviousPage ?? false
    },
    hasNextPage(): boolean {
      return this.pageInfo?.hasNextPage ?? false
    },
    pagination(): { hasPreviousPage: boolean; hasNextPage: boolean } {
      const { hasPreviousPage, hasNextPage } = this
      return { hasPreviousPage, hasNextPage }
    },
  },
  watch: {
    limit() {
      this.paginationReset()
    },
  },
  methods: {
    paginationPrev() {
      if (this.cursor === null) return
      const cursor = this.pageInfo?.startCursor ?? undefined
      if (cursor === undefined) return
      this.cursor = cursor
    },
    paginationNext() {
      if (this.cursor === null) return
      const cursor = this.pageInfo?.endCursor ?? undefined
      if (cursor === undefined) return
      this.cursor = cursor
    },
    paginationReset() {
      this.cursor = null
    },
    paginationHandleResponse(pageInfo: PageInfo) {
      this.pageInfo = pageInfo
    },
  },
})
