import type { UserId } from '@/types'

import Request from './Request'
import BaseService from './BaseService'
import type {
  Password,
  RegisterDto,
  RegisterWithCodeDto,
  LoginResult,
  CompletePasswordDto,
  CompleteUserPasswordResult,
  ForgotPasswordDto,
  ForgotPasswordResult,
  ForgotPasswordConfirmDto,
  SetTemporaryPasswordResult,
  PasswordStrengthResponse,
} from './AuthService.types'
import { PasswordResetRequiredException } from './AuthService.exceptions'

class AuthService extends BaseService {
  async register(registerDto: RegisterDto): Promise<void> {
    await this.client.post('/auth/register', registerDto)
  }

  async registerWithCode(registerWithCode: RegisterWithCodeDto): Promise<void> {
    await this.client.post('/auth/register/code', registerWithCode)
  }

  async login(email: string, password: string): Promise<LoginResult> {
    try {
      const { data } = await this.client.post<LoginResult>('/auth/login', {
        email,
        password,
      })
      return data
    } catch (e) {
      if (
        BaseService.isServerErrorWithMetadata<{ reset: boolean }>(e) &&
        e.response.data.metadata.reset === true
      ) {
        throw new PasswordResetRequiredException(e.message)
      }
      throw e
    }
  }

  async completePassword(
    completePasswordDto: CompletePasswordDto,
  ): Promise<CompleteUserPasswordResult> {
    const { data } = await this.client.post<CompleteUserPasswordResult>(
      '/auth/password/complete',
      completePasswordDto,
    )
    return data
  }

  async forgotPassword(
    forgotPasswordDto: ForgotPasswordDto,
  ): Promise<ForgotPasswordResult> {
    const { data } = await this.client.post<ForgotPasswordResult>(
      '/auth/password/forgot',
      forgotPasswordDto,
    )
    return data
  }

  async forgotPasswordConfirm(
    forgotPasswordConfirmDto: ForgotPasswordConfirmDto,
  ): Promise<ForgotPasswordResult> {
    const { data } = await this.client.post<ForgotPasswordResult>(
      '/auth/password/forgot/confirm',
      forgotPasswordConfirmDto,
    )
    return data
  }

  async resetPassword(id: UserId): Promise<void> {
    await this.client.post('/auth/password/reset', { id })
  }

  async setTemporaryPassword(id: UserId): Promise<SetTemporaryPasswordResult> {
    const { data } = await this.client.post<SetTemporaryPasswordResult>(
      '/auth/password/temporary',
      {
        id,
      },
    )
    return data
  }

  async updateEmail(id: UserId, email: string): Promise<void> {
    await this.client.put('/auth/email', { id, email })
  }

  passwordStrength(password: Password): Request<PasswordStrengthResponse> {
    const ac = new AbortController()
    return new Request(
      this.client
        .post<PasswordStrengthResponse>('/auth/password/strength', { password })
        .then(({ data }) => data),
      ac,
    )
  }
}

export default AuthService
