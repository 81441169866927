import type { UnwrapSet } from '@/types'

export const CHIP_VARIANTS = new Set(['default', 'dark'] as const)

export type ChipVariant = UnwrapSet<typeof CHIP_VARIANTS>

export type ChipProps = {
  tag?: string
  variant?: ChipVariant
}
