import Vue from 'vue'
import type { WithProperties } from 'vue-typed-properties'

import type { Nullable } from '@/types'
import { generateId } from '@/util'

function getScrollParent(node: Nullable<Element>): Nullable<HTMLElement> {
  if (node === null) {
    return null
  }
  const overflowY = window.getComputedStyle(node).overflowY
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden'
  if (
    isScrollable &&
    node instanceof HTMLElement &&
    node.scrollHeight > node.clientHeight
  ) {
    return node
  }
  return getScrollParent(node.parentElement)
}

export default (
  Vue as WithProperties<{
    scrollable: Nullable<HTMLElement>
    __scrollable_uid: string
  }>
).extend({
  name: 'ScrollLockMixin',
  created() {
    this.scrollable = null
    this.__scrollable_uid = generateId()
  },
  beforeDestroy() {
    this.unlockScroll()
    this.scrollable = null
  },
  methods: {
    lockScroll() {
      const scrollable = getScrollParent(this.$el.parentElement)
      if (scrollable !== null) {
        this.scrollable = scrollable
        const scrollBarWidth = scrollable.offsetWidth - scrollable.clientWidth
        scrollable.style.overflow = 'hidden'
        scrollable.style.paddingRight = `${scrollBarWidth}px`
        scrollable.dataset.locked = this.__scrollable_uid
      }
    },
    unlockScroll() {
      if (this.scrollable === null) return
      if (this.scrollable.dataset.locked !== this.__scrollable_uid) return
      this.scrollable.style.overflow = ''
      this.scrollable.style.paddingRight = ''
      this.scrollable.dataset.locked = ''
      this.scrollable = null
    },
  },
})
