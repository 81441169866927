import { ref, watch } from 'vue'

export default function useOffsetPagination(l = 25) {
  const offset = ref(0)
  const limit = ref(l)

  function prev() {
    offset.value = Math.max(offset.value - limit.value, 0)
  }

  function next() {
    offset.value += limit.value
  }

  function reset() {
    offset.value = 0
  }

  watch(limit, reset)

  return {
    offset,
    limit,
    prev,
    next,
    reset,
  }
}
