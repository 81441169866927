
import type { PropType, VNode } from 'vue'
import type { WithEvents } from 'vue-typed-emit'
import mixins from 'vue-typed-mixins'

import type { ClassDefinition } from '../../types'
import { SlotsMixin, ThemeMixin } from '../../mixins'
import WeveIcon from '../Icon/Icon.vue'
import WeveButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import WeveTextField from '../TextField/TextField.vue'
import WeveCheckbox from '../Checkbox/Checkbox.vue'
import WevePagination from '../Pagination/Pagination.vue'
import type { Events, TableHeader, TableItem, Pagination } from './Table.types'
import Tr from './Tr.vue'
import Td from './Td.vue'
import Text from './Text.vue'

const CHECKBOX_CELL_WIDTH = '40px'

const Extendable = mixins(SlotsMixin, ThemeMixin)

export default (Extendable as WithEvents<Events, typeof Extendable>).extend({
  name: 'WeveTable',
  components: {
    WeveButtonIcon,
    WeveTextField,
    WeveCheckbox,
    WevePagination,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeader[]>,
      required: true,
    },
    items: {
      type: Array as PropType<TableItem[]>,
      required: true,
    },
    identity: {
      type: String,
      default: 'id',
    },
    selected: {
      type: Array,
      default() {
        return []
      },
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    interactable: {
      type: Boolean,
      default: false,
    },
    active: {
      type: [String, Number],
      default: undefined,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: [Object, null] as PropType<Pagination>,
      default: null,
    },
    limit: {
      type: Number,
      default: undefined,
    },
    sortBy: {
      type: String,
      default: undefined,
    },
    sortDesc: {
      type: Boolean,
      default: undefined,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    tableClass: {
      type: [String, Array, Object] as PropType<ClassDefinition>,
      default: undefined,
    },
  },
  computed: {
    isAllSelected(): boolean {
      if (this.items.length === 0 || this.selected.length === 0) {
        return false
      }
      return this.items.every(this.isItemSelected)
    },
    isAnySelected(): boolean {
      return this.items.some(this.isItemSelected)
    },
  },
  methods: {
    isActive(item: TableItem) {
      return this.active !== undefined && item[this.identity] === this.active
    },
    selectAll() {
      this.$emit(
        'select',
        this.items.map((item) => item[this.identity]),
      )
    },
    unselectAll() {
      this.$emit('select', [])
    },
    onItemSelectionStateChange(state: boolean, item: TableItem) {
      const { selected } = this
      const identity = item[this.identity]
      if (state) {
        this.$emit('select', [...selected, identity])
      } else {
        this.$emit(
          'select',
          selected.filter((item) => item !== identity),
        )
      }
    },
    isItemSelected(item: TableItem): boolean {
      return this.selected.indexOf(item[this.identity]) !== -1
    },
  },
  render(h): VNode {
    const headers = this.headers.map((cell, index) => {
      const slotName = `cell:header:${cell.key}`
      const hasSlot = this.hasSlot(slotName)

      return h(
        'th',
        {
          attrs: {
            colspan: cell.colspan,
            scope: 'col',
            'aria-colindex': index + 1,
            'aria-sort': cell.sortable
              ? cell.key === this.sortBy
                ? this.sortDesc
                  ? 'descending'
                  : 'ascending'
                : 'none'
              : undefined,
            tabindex: cell.sortable ? '0' : undefined,
          },
          style: [
            {
              width: cell.width !== undefined ? `${cell.width}px` : undefined,
            },
            cell.style,
          ],
          class: [
            cell.class,
            cell.headerClass,
            {
              'weve-table__th--sortable': cell.sortable,
              'weve-table__th--sorted': this.sortBy === cell.key,
            },
          ],
          staticClass: 'weve-table__th',
          on: {
            click: () => {
              if (cell.sortable) {
                if (this.sortBy !== cell.key) {
                  this.$emit('update:sortBy', cell.key)
                  this.$emit('update:sortDesc', false)
                } else if (
                  this.sortBy === cell.key &&
                  this.sortDesc === false
                ) {
                  this.$emit('update:sortDesc', true)
                } else if (this.sortBy === cell.key && this.sortDesc === true) {
                  this.$emit('update:sortBy', undefined)
                }
              }
            },
          },
        },
        [
          hasSlot
            ? this.normalizeSlot(slotName)
            : h(
                'span',
                { class: { 'sr-only': cell.noLabel === true } },
                cell.label,
              ),
          cell.sortable
            ? h(WeveIcon, {
                props: { name: 'arrow-down-regular' },
                class: {
                  'weve-table__sort-icon--hidden': this.sortBy !== cell.key,
                  'weve-table__sort-icon--rotated':
                    this.sortBy === cell.key && this.sortDesc === false,
                },
                staticClass: 'weve-table__sort-icon',
              })
            : undefined,
        ],
      )
    })

    if (this.selectable) {
      headers.unshift(
        h(
          'th',
          {
            style: {
              width: CHECKBOX_CELL_WIDTH,
            },
            staticClass: 'weve-table__th',
          },
          [
            h(WeveCheckbox, {
              props: {
                checked: this.isAllSelected,
                indeterminate: this.isAnySelected,
                label: 'Check all',
                noLabel: true,
              },
              on: {
                change: (value: boolean) => {
                  if (value) {
                    this.selectAll()
                    return
                  }
                  this.unselectAll()
                },
              },
            }),
          ],
        ),
      )
    }

    const rows = this.hasSlot('body')
      ? this.normalizeSlot('body', { items: this.items })
      : this.items.map((item, index) => {
          const active = this.isActive(item)
          return h(
            Tr,
            {
              props: { active: this.isActive(item) },
              on: {
                click: () => {
                  this.$emit('row:click', { item, index })
                },
              },
            },
            [
              this.selectable
                ? h(Td, undefined, [
                    h(WeveCheckbox, {
                      props: {
                        label: 'Check item',
                        noLabel: true,
                        checked: this.isItemSelected(item),
                      },
                      staticClass: 'z-2',
                      on: {
                        change: (state: boolean) => {
                          this.onItemSelectionStateChange(state, item)
                        },
                      },
                    }),
                  ])
                : null,
              ...this.headers.map((cell) => {
                const slotName = `cell:${cell.key}`
                const hasSlot = this.hasSlot(slotName)
                return h(
                  Td,
                  {
                    class: cell.class,
                    style: [
                      {
                        width:
                          cell.width !== undefined
                            ? `${cell.width}px`
                            : undefined,
                      },
                      cell.style,
                    ],
                  },
                  [
                    hasSlot
                      ? this.normalizeSlot(slotName, { item, index, active })
                      : h(Text, {
                          props: {
                            text:
                              cell.formatter !== undefined
                                ? cell.formatter(item[cell.key])
                                : item[cell.key],
                            truncate: cell.truncate,
                          },
                        }),
                  ],
                )
              }),
            ],
          )
        })

    return h('div', { staticClass: 'weve-table__root' }, [
      h('div', { staticClass: 'weve-table__container' }, [
        h(
          'table',
          {
            staticClass: 'weve-table',
            class: [
              this.tableClass,
              {
                'weve-table--selectable': this.selectable,
                'weve-table--interactable': this.interactable,
                'weve-table--narrow': this.narrow,
                'weve-table--bordered': this.bordered,
                'w-theme--light': this.light,
              },
            ],
          },
          [
            h('thead', { staticClass: 'weve-table__head' }, [h('tr', headers)]),
            h('tbody', { staticClass: 'weve-table__body' }, rows),
          ],
        ),
      ]),

      this.pagination !== null
        ? h(WevePagination, {
            props: {
              limit: this.limit,
              pagination: this.pagination,
            },
            staticClass: 'weve-table__pagination',
            on: {
              'update:limit': (value: number) =>
                this.$emit('update:limit', value),
              prev: () => this.$emit('pagination:prev'),
              next: () => this.$emit('pagination:next'),
            },
            scopedSlots: {
              prepend: () => {
                return h(WeveButtonIcon, {
                  props: { icon: 'redo-regular', label: 'Refresh' },
                  staticClass: 'mr-3',
                  on: {
                    click: () => {
                      this.$emit('refresh')
                    },
                  },
                })
              },
            },
          })
        : undefined,
    ])
  },
})
