
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveTextFieldSkeleton',
  functional: true,
  render(h, { data }) {
    return h(
      'div',
      mergeData(data, {
        staticClass: 'weve-text-field-skeleton app-form-element-skeleton',
      }),
      [
        h('div', {
          staticClass: 'skeleton-element weve-text-field-skeleton__label',
        }),
        h('div', {
          staticClass: 'skeleton-element weve-text-field-skeleton__container',
        }),
      ],
    )
  },
})
