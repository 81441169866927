import { ref, onMounted } from 'vue'

import type { Nullable, Undefined } from '@/types'

import type {
  StoredExperience,
  StoredExperienceSerialized,
} from './useExperienceSetup.types'

const LOCAL_STORAGE_KEY = 'cart'

export default function useExperienceSetup() {
  const data = ref<Nullable<StoredExperience>>(null)
  onMounted(() => {
    const string = localStorage.getItem(LOCAL_STORAGE_KEY) ?? undefined
    if (string === undefined) return
    try {
      const serialized: StoredExperienceSerialized = JSON.parse(string)
      data.value = {
        id: serialized.id,
        title: serialized.title,
        format: serialized.format,
        hostType: serialized.hostType,
        attendees: serialized.attendees,
        timezone: serialized.timezone,
        duration: serialized.duration,
        upgrade: serialized.upgrade,
        date: serialized.date !== undefined ? new Date(serialized.date) : null,
        location:
          serialized.location !== undefined
            ? JSON.parse(serialized.location)
            : null,
        customizations:
          serialized.customizations !== undefined
            ? new Set(JSON.parse(serialized.customizations))
            : null,
      }
    } catch (e) {
      console.error(e)
    }
  })
  function store(data: StoredExperience) {
    const normalized: StoredExperienceSerialized = {
      id: data.id,
      title: data.title,
      format: data.format,
      hostType: data.hostType,
      attendees: data.attendees,
      timezone: data.timezone,
      duration: data.duration,
      upgrade: data.upgrade,
    }
    if (data.date !== null) {
      normalized.date = data.date.toISOString()
    }
    if (data.location !== null) {
      normalized.location = JSON.stringify(data.location)
    }
    if (data.customizations !== null) {
      normalized.customizations = JSON.stringify([...data.customizations])
    }
    let string: Undefined<string> = undefined
    try {
      string = JSON.stringify(normalized)
    } catch (e) {
      console.error(e)
    }
    if (string !== undefined) {
      localStorage.setItem(LOCAL_STORAGE_KEY, string)
    }
  }
  function clear() {
    data.value = null
    localStorage.removeItem(LOCAL_STORAGE_KEY)
  }
  return {
    data,
    store,
    clear,
  }
}
