
import type { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import WeveAvatar from '../Avatar/Avatar.vue'

export default ThemeMixin.extend({
  name: 'WevePerson',
  functional: true,
  props: {
    displayName: {
      type: String,
      required: true,
    },
    avatar: {
      type: String as PropType<string | undefined | null>,
      default: undefined,
    },
  },
  render(h, { props, data, slots, injections }) {
    const { default: defaultSlot, append } = slots()

    return h(
      'div',
      mergeData(data, {
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
        staticClass: 'weve-person',
      }),
      [
        h('div', { staticClass: 'weve-person__inner' }, [
          h('div', { staticClass: 'weve-person__avatar-holder' }, [
            defaultSlot ??
              h(WeveAvatar, {
                props: {
                  src: props.avatar,
                  displayName: props.displayName,
                  alt: `${props.displayName}' avatar`,
                },
              }),
          ]),
          h('div', { staticClass: 'weve-person__content' }, [
            h(
              'span',
              { staticClass: 'weve-person__display-name' },
              props.displayName,
            ),
            append !== undefined ? append : null,
          ]),
        ]),
      ],
    )
  },
})
