
import Vue, { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import { Grid, GRIDS, Gap, GAPS } from './types'

export default Vue.extend({
  name: 'WeveRow',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    grid: {
      type: String as PropType<Grid>,
      default: '12' as const,
      validator: (val: Grid) => GRIDS.includes(val),
    },
    gap: {
      type: String as PropType<Gap>,
      default: 'default' as const,
      validator: (val: Gap) => GAPS.includes(val),
    },
    wrap: {
      type: Boolean,
      default: true,
    },
  },
  render(h, { props, children, data }) {
    return h(
      props.tag,
      mergeData(data, {
        class: {
          [`app-row--grid--${props.grid}`]: true,
          [`app-row--gap--${props.gap}`]: props.gap,
          'app-row--wrap': props.wrap,
        },
        staticClass: 'app-row',
      }),
      children,
    )
  },
})
