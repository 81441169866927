import { computed } from 'vue'

import { getCurrentInstance } from '@/util'

export default function useRoute() {
  const instance = getCurrentInstance()
  const route = computed(() => instance.proxy.$route)

  return {
    route,
  }
}
