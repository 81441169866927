type Listener = (...args: any[]) => void

export default function executeListener(
  argument: Listener | Listener[],
  ...args: unknown[]
): void {
  if (typeof argument === 'function') {
    argument(...args)
  } else {
    argument.forEach((fn) => fn(...args))
  }
}
