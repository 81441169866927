import type { DirectiveOptions } from 'vue'
import Clipboard from 'clipboard'

interface ExtendedHTMLElement extends HTMLElement {
  __clipboard?: Clipboard | null
}

const ClipboardDirective: DirectiveOptions = {
  bind(el: ExtendedHTMLElement, { value }, node) {
    el.dataset.clipboard = value

    setTimeout(() => {
      const clipboard = new Clipboard(el, {
        text() {
          return el.dataset.clipboard ?? ''
        },
      })

      clipboard.on('success', () => {
        node.context?.$root.$toast('Copied')
      })

      el.__clipboard = clipboard
    }, 0)
  },
  update(el: ExtendedHTMLElement, { value }) {
    el.dataset.clipboard = value
  },
  unbind(el: ExtendedHTMLElement) {
    el.__clipboard?.destroy()
    delete el.__clipboard
  },
}

export default ClipboardDirective
