
import type { PropType } from 'vue'
import type { RawLocation } from 'vue-router'
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import { Color, COLORS } from './types'

export default ThemeMixin.extend({
  name: 'WeveDropdownItem',
  functional: true,
  props: {
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    color: {
      type: String as PropType<Color>,
      default: 'default' as const,
      validator: (val: Color) => COLORS.includes(val),
    },
    href: {
      type: String,
      default: '#',
    },
  },
  render(h, { props, children, data, injections }) {
    const attrs: Record<string, string> = {
      role: 'menuitem',
      target: '_self',
      title: props.title,
    }

    if (props.to === undefined) {
      attrs.href = props.href
    }

    return h('li', { attrs: { role: 'presentation' } }, [
      h(
        props.to !== undefined ? 'router-link' : 'a',
        mergeData(data, {
          props: props.to !== undefined ? { to: props.to } : undefined,
          attrs,
          staticClass: 'weve-dropdown-item',
          class: {
            [`weve-dropdown-item--color--${props.color}`]:
              props.color !== 'default',
            'w-theme--light': injections.theme === 'light',
            'w-theme--dark': injections.theme === 'dark',
          },
          on:
            attrs.href === '#'
              ? {
                  click: (e: Event) => {
                    e.preventDefault()
                  },
                }
              : {},
        }),
        children,
      ),
    ])
  },
})
