
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'

export default ThemeMixin.extend({
  name: 'SkeletonElement',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
  render(h, { props, data, injections }) {
    return h(
      props.tag,
      mergeData(data, {
        staticClass: 'skeleton-element',
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme === 'dark',
        },
      }),
    )
  },
})
