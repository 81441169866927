import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type ReactiveFunction<TParam> = () => TParam;
export const WorkspaceForEventsSearchDropdownFragmentDoc = gql`
    fragment workspaceForEventsSearchDropdown on Workspace {
  id
  sessions {
    totalCount
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment file on File {
  id
  src
  name
  size
  mime
  type
}
    `;
export const UserWithAvatarAndImageFragmentDoc = gql`
    fragment userWithAvatarAndImage on User {
  id
  avatar
  image {
    ...file
  }
}
    ${FileFragmentDoc}`;
export const OrderForEditFragmentDoc = gql`
    fragment orderForEdit on Order {
  id
  consumables {
    used
    available
    name
    orderItemId
    ordered
  }
}
    `;
export const SessionWithFilesFragmentDoc = gql`
    fragment sessionWithFiles on Session {
  id
  files {
    ...file
  }
}
    ${FileFragmentDoc}`;
export const ExperienceForExperienceMultiselectFragmentDoc = gql`
    fragment experienceForExperienceMultiselect on Experience {
  id
  title
}
    `;
export const ExperienceForExperienceSelectFragmentDoc = gql`
    fragment experienceForExperienceSelect on Experience {
  id
  title
}
    `;
export const GameCityForGameCitiesSelectFragmentDoc = gql`
    fragment gameCityForGameCitiesSelect on GameCity {
  id
  label
}
    `;
export const GameZoneForGameZoneSelectFragmentDoc = gql`
    fragment gameZoneForGameZoneSelect on GameZone {
  id
  name
}
    `;
export const MissionSetForMissionSetSelectFragmentDoc = gql`
    fragment missionSetForMissionSetSelect on MissionSet {
  id
  name
}
    `;
export const SessionForMissionSetSessionMultiselectFragmentDoc = gql`
    fragment sessionForMissionSetSessionMultiselect on Session {
  id
  name
  shortName
  startsAt
  startDate @client
  missionSet {
    id
  }
}
    `;
export const ModifierForModifierMultiselectFragmentDoc = gql`
    fragment modifierForModifierMultiselect on Modifier {
  id
  label
}
    `;
export const SessionWithPackageLevelFragmentDoc = gql`
    fragment sessionWithPackageLevel on Session {
  id
  packageLevel
}
    `;
export const PermissionForPermissionsAssignerFragmentDoc = gql`
    fragment permissionForPermissionsAssigner on Permission {
  id
  workspaceId
  userId
  role
}
    `;
export const PermissionWithPrimaryFragmentDoc = gql`
    fragment permissionWithPrimary on Permission {
  id
  primary
}
    `;
export const SessionForScheduleDialogFragmentDoc = gql`
    fragment sessionForScheduleDialog on Session {
  id
  startDate
  timezone
  duration
  packageLevel
  language
  size
  hostType
  modifications @client
}
    `;
export const UserWithFullNameAndAvatarFragmentDoc = gql`
    fragment userWithFullNameAndAvatar on User {
  id
  fullName
  avatar
}
    `;
export const SessionAssignmentForSessionAssignmentsSectionFragmentDoc = gql`
    fragment sessionAssignmentForSessionAssignmentsSection on SessionAssignment {
  id
  role
  user {
    ...userWithFullNameAndAvatar
  }
  travel
  travelConfirmed
}
    ${UserWithFullNameAndAvatarFragmentDoc}`;
export const SessionForSessionAssignmentsSectionFragmentDoc = gql`
    fragment sessionForSessionAssignmentsSection on Session {
  id
  diy
  size
  type
  remote @client
  packageLevel
  hasUpgrade @client
  assignments {
    ...sessionAssignmentForSessionAssignmentsSection
  }
  hosts @client {
    ...sessionAssignmentForSessionAssignmentsSection
  }
  locateable
}
    ${SessionAssignmentForSessionAssignmentsSectionFragmentDoc}`;
export const SessionForVirtualSessionAttendeesInputFragmentDoc = gql`
    fragment sessionForVirtualSessionAttendeesInput on Session {
  id
  size
  diy
  restrictions {
    attendees
  }
}
    `;
export const SessionForNonVirtualSessionAttendeesInputFragmentDoc = gql`
    fragment sessionForNonVirtualSessionAttendeesInput on Session {
  id
  credits
  restrictions {
    attendees
  }
}
    `;
export const SessionForSessionAttendeesInputFragmentDoc = gql`
    fragment sessionForSessionAttendeesInput on Session {
  id
  type
  ...sessionForVirtualSessionAttendeesInput
  ...sessionForNonVirtualSessionAttendeesInput
}
    ${SessionForVirtualSessionAttendeesInputFragmentDoc}
${SessionForNonVirtualSessionAttendeesInputFragmentDoc}`;
export const SessionForSessionCheckoutDialogFragmentDoc = gql`
    fragment sessionForSessionCheckoutDialog on Session {
  id
  type
  timezone
  duration
  packageLevel
  size
  startDate @client
}
    `;
export const SessionGroupForSessionGroupHighlightCardFragmentDoc = gql`
    fragment sessionGroupForSessionGroupHighlightCard on SessionGroup {
  id
  name
  image {
    id
    src
  }
  attendees @client
  experience {
    id
    title
  }
}
    `;
export const SessionForSessionHighlightCardFragmentDoc = gql`
    fragment sessionForSessionHighlightCard on Session {
  id
  name
  shortName
  preview
  type
  attendees
  teams
  size
  status
  experience {
    id
    title
  }
  supportsSlots {
    customer
    staff
  }
}
    `;
export const SuggestedVenueFragmentDoc = gql`
    fragment suggestedVenue on Venue {
  id
  location {
    id
    name
    address
    placeId
  }
  name
  rating
}
    `;
export const SessionForSessionSelectFragmentDoc = gql`
    fragment sessionForSessionSelect on Session {
  id
  name
}
    `;
export const TemplateForTemplateSelectFragmentDoc = gql`
    fragment templateForTemplateSelect on Template {
  id
  name
  url
}
    `;
export const UserForUserMultiselectFragmentDoc = gql`
    fragment userForUserMultiselect on User {
  id
  fullName
}
    `;
export const UserForUserSelectFragmentDoc = gql`
    fragment userForUserSelect on User {
  id
  fullName
}
    `;
export const PermissionForWorkspaceAssignerFragmentDoc = gql`
    fragment permissionForWorkspaceAssigner on Permission {
  id
  workspaceId
}
    `;
export const WorkspaceForWorkspaceMultiselectFragmentDoc = gql`
    fragment workspaceForWorkspaceMultiselect on Workspace {
  id
  name
}
    `;
export const WorkspaceForWorkspaceSelectFragmentDoc = gql`
    fragment workspaceForWorkspaceSelect on Workspace {
  id
  name
}
    `;
export const ZoneAreaForSelectFragmentDoc = gql`
    fragment zoneAreaForSelect on ZoneArea {
  id
  name
  price
}
    `;
export const AvailableConsumableFragmentDoc = gql`
    fragment availableConsumable on AvailableConsumable {
  name
  ordered
  available
  used
}
    `;
export const MissionFragmentDoc = gql`
    fragment mission on Mission {
  id
  name
  type
  options {
    title
    correct
  }
  instructions
  position
  notes
  createdAt
  createdAtDate @client
  updatedAt
}
    `;
export const MissionSetFragmentDoc = gql`
    fragment missionSet on MissionSet {
  id
  name
}
    `;
export const OffsetPageInfoFragmentDoc = gql`
    fragment offsetPageInfo on OffsetPageInfo {
  hasNextPage
  hasPreviousPage
}
    `;
export const OrderFragmentDoc = gql`
    fragment order on Order {
  id
  workspaceId
  source
  paidAt
}
    `;
export const OrderItemFragmentDoc = gql`
    fragment orderItem on OrderItem {
  id
  consumable {
    id
    name
  }
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment pageInfo on PageInfo {
  startCursor
  endCursor
  hasPreviousPage
  hasNextPage
}
    `;
export const SessionForSessionGroupDetailsSectionFragmentDoc = gql`
    fragment sessionForSessionGroupDetailsSection on Session {
  id
  hasUpgrade @client
}
    `;
export const SessionGroupWithSessionsFragmentDoc = gql`
    fragment sessionGroupWithSessions on SessionGroup {
  id
  sessions {
    id
  }
}
    `;
export const SystemFragmentDoc = gql`
    fragment system on System {
  key
  value
}
    `;
export const WorkspaceWithConsumablesFragmentDoc = gql`
    fragment workspaceWithConsumables on Workspace {
  id
  consumables {
    credits
    openWeves
  }
}
    `;
export const ExperienceFormatForExperienceDurationInputFragmentDoc = gql`
    fragment experienceFormatForExperienceDurationInput on ExperienceFormat {
  id
  type
  hostType
  minDuration
  maxDuration
}
    `;
export const ExperienceFormatForExperienceFormatSelectFragmentDoc = gql`
    fragment experienceFormatForExperienceFormatSelect on ExperienceFormat {
  id
  title
  type
  hostType
  experience {
    id
    title
  }
}
    `;
export const ExperienceFormatWithModifiersFragmentDoc = gql`
    fragment experienceFormatWithModifiers on ExperienceFormat {
  id
  modifiers {
    id
  }
}
    `;
export const ModifierForSearchFragmentDoc = gql`
    fragment modifierForSearch on Modifier {
  id
  label
}
    `;
export const SessionAssignmentForSessionAssignmentStatusFragmentDoc = gql`
    fragment sessionAssignmentForSessionAssignmentStatus on SessionAssignment {
  id
  travel
  travelConfirmed
}
    `;
export const SessionForSessionAssignmentStatusFragmentDoc = gql`
    fragment sessionForSessionAssignmentStatus on Session {
  id
  locateable
}
    `;
export const SessionAssignmentForTravelEditButtonFragmentDoc = gql`
    fragment sessionAssignmentForTravelEditButton on SessionAssignment {
  id
  user {
    id
  }
  travel
  travelConfirmed
}
    `;
export const UserForSessionTemplateSelectFragmentDoc = gql`
    fragment userForSessionTemplateSelect on User {
  id
  workspace {
    id
    name
  }
}
    `;
export const SessionAssignmentWithTravelFragmentDoc = gql`
    fragment sessionAssignmentWithTravel on SessionAssignment {
  id
  travel
  travelConfirmed
}
    `;
export const SessionAssignmentWithTravelAndCityFragmentDoc = gql`
    fragment sessionAssignmentWithTravelAndCity on SessionAssignment {
  ...sessionAssignmentWithTravel
  session {
    id
    city
  }
}
    ${SessionAssignmentWithTravelFragmentDoc}`;
export const SessionWithCreditsFragmentDoc = gql`
    fragment sessionWithCredits on Session {
  id
  credits
}
    `;
export const SessionWithHasBreadcrumbIntegrationFragmentDoc = gql`
    fragment sessionWithHasBreadcrumbIntegration on Session {
  id
  syncType
  integrations {
    id
    type
  }
  hasBreadcrumbIntegration @client
}
    `;
export const SessionWithStatusFragmentDoc = gql`
    fragment sessionWithStatus on Session {
  id
  status
}
    `;
export const UserAudioFragmentDoc = gql`
    fragment userAudio on UserAudio {
  id
  file {
    ...file
  }
  overlay
}
    ${FileFragmentDoc}`;
export const WorkspaceWithCreditsFragmentDoc = gql`
    fragment workspaceWithCredits on Workspace {
  id
  consumables {
    credits
  }
}
    `;
export const WorkspaceForStoreFragmentDoc = gql`
    fragment workspaceForStore on Workspace {
  id
  name
  content
  subscriber
  notifications
}
    `;
export const PermissionsForStoreFragmentDoc = gql`
    fragment permissionsForStore on Permission {
  id
  workspaceId
  userId
  role
  primary
}
    `;
export const UserForStoreFragmentDoc = gql`
    fragment userForStore on User {
  id
  firstName
  lastName
  fullName
  email
  role
  permission
  phone
  meetingLink
  avatar
  permissions {
    ...permissionsForStore
  }
  canHaveAvailability
}
    ${PermissionsForStoreFragmentDoc}`;
export const UserWithEmailFragmentDoc = gql`
    fragment userWithEmail on User {
  id
  email
}
    `;
export const SessionForSessionRescheduleFragmentDoc = gql`
    fragment sessionForSessionReschedule on Session {
  id
  type
  size
  diy
  freeReschedule
  startDate @client
  endDate @client
  createdDate @client
  scheduled @client
  complete @client
}
    `;
export const SessionIntegrationFragmentDoc = gql`
    fragment sessionIntegration on SessionIntegration {
  id
  integrationId
  type
  url
  syncedAt
  active
}
    `;
export const SessionForSessionCardFragmentDoc = gql`
    fragment sessionForSessionCard on Session {
  id
  name
  shortName
  preview
  type
  remote @client
  startsAt
  startDate @client
  attendees
  teams
  size
  packageLevel
  status
  integrations {
    ...sessionIntegration
  }
  group {
    id
  }
  links @client
}
    ${SessionIntegrationFragmentDoc}`;
export const SessionForShiftPlanningSessionFragmentDoc = gql`
    fragment sessionForShiftPlanningSession on Session {
  ...sessionForSessionCard
}
    ${SessionForSessionCardFragmentDoc}`;
export const ExperienceForAvailabilityEventFragmentDoc = gql`
    fragment experienceForAvailabilityEvent on Experience {
  id
  title
  formats {
    id
    type
    hostType
    modifiers {
      id
      label
    }
  }
}
    `;
export const GlobalUnavailabilityFragmentDoc = gql`
    fragment globalUnavailability on GlobalUnavailability {
  id
  startsAt
  endsAt
  startDate @client
  endDate @client
}
    `;
export const SessionForShiftPlanningFragmentDoc = gql`
    fragment sessionForShiftPlanning on Session {
  id
  type
  hostType
  startsAt
  endsAt
  scheduleStartsAt
  assignments(filter: {userId: {isNot: null}}) {
    userId
  }
  startDate @client
  endDate @client
  scheduleStartDate @client
}
    `;
export const AvailabilityFragmentDoc = gql`
    fragment availability on Availability {
  id
  from
  to
  enabled
  day
  date
  available
  datetime @client
  createdAt
}
    `;
export const UserForAvailabilityFragmentDoc = gql`
    fragment userForAvailability on User {
  id
  fullName
  timezone
  availabilities {
    ...availability
  }
}
    ${AvailabilityFragmentDoc}`;
export const UserForShiftPlanningFragmentDoc = gql`
    fragment userForShiftPlanning on User {
  id
  fullName
  timezone
  avatar
  location {
    id
    name
  }
  availabilities(filter: {enabled: {is: true}}) {
    ...availability
  }
}
    ${AvailabilityFragmentDoc}`;
export const MissionForFormFragmentDoc = gql`
    fragment missionForForm on Mission {
  name
  type
  options {
    title
    correct
  }
  instructions
  notes
}
    `;
export const WorkspaceForContentLibraryFragmentDoc = gql`
    fragment workspaceForContentLibrary on Workspace {
  id
  settings {
    missions
  }
}
    `;
export const CustomizationMediaFragmentDoc = gql`
    fragment customizationMedia on CustomizationMedia {
  id
  type
  file {
    ...file
  }
}
    ${FileFragmentDoc}`;
export const CustomizationWithMediaFragmentDoc = gql`
    fragment customizationWithMedia on Customization {
  id
  media {
    ...customizationMedia
  }
}
    ${CustomizationMediaFragmentDoc}`;
export const CustomizationFragmentDoc = gql`
    fragment customization on Customization {
  id
  colors {
    primary
    secondary
    tertiary
  }
  media {
    ...customizationMedia
  }
}
    ${CustomizationMediaFragmentDoc}`;
export const WorkspaceWithCustomizationFragmentDoc = gql`
    fragment workspaceWithCustomization on Workspace {
  id
  customization {
    ...customization
  }
}
    ${CustomizationFragmentDoc}`;
export const MissionListForGameMissionListFragmentDoc = gql`
    fragment missionListForGameMissionList on BreadcrumbMissionList {
  id
  name
}
    `;
export const BreadcrumbMissionForSelectFragmentDoc = gql`
    fragment breadcrumbMissionForSelect on BreadcrumbMission {
  id
  name
}
    `;
export const SessionForSearchFragmentDoc = gql`
    fragment sessionForSearch on Session {
  id
  name
  shortName
  preview
  type
  remote @client
  startsAt
  startDate @client
  attendees
  teams
  status
  size
  integrations(filter: {type: {in: [HUBSPOT, WEVE]}}) {
    id
    integrationId
    type
    url
  }
  group {
    id
  }
  links @client
}
    `;
export const UserWithContactsFragmentDoc = gql`
    fragment userWithContacts on User {
  id
  fullName
  email
  phone
  avatar
}
    `;
export const SessionWithContactFragmentDoc = gql`
    fragment sessionWithContact on Session {
  id
  contact {
    ...userWithContacts
  }
}
    ${UserWithContactsFragmentDoc}`;
export const SessionForSessionSlotsInputFragmentDoc = gql`
    fragment sessionForSessionSlotsInput on Session {
  id
  size
  startsAt
  startDate @client
  supportsSlots {
    customer
    staff
  }
}
    `;
export const PlaythroughForGameControlFragmentDoc = gql`
    fragment playthroughForGameControl on Playthrough {
  id
  status
  startedAt
  endedAt
}
    `;
export const SessionForRelationsDialogFragmentDoc = gql`
    fragment sessionForRelationsDialog on Session {
  id
  workspaceId
  relations {
    id
    type
    right {
      id
    }
  }
  pastEventsDetails
  futureEventsDetails
}
    `;
export const TimelineItemFragmentDoc = gql`
    fragment timelineItem on TimelineItem {
  id
  time
  description
}
    `;
export const TimelineFragmentDoc = gql`
    fragment timeline on Timeline {
  outdated
  items {
    ...timelineItem
  }
}
    ${TimelineItemFragmentDoc}`;
export const SessionForTimelineFragmentDoc = gql`
    fragment sessionForTimeline on Session {
  id
  startsAt
  startDate @client
  timeline {
    ...timeline
  }
}
    ${TimelineFragmentDoc}`;
export const UserWithFullNameFragmentDoc = gql`
    fragment userWithFullName on User {
  id
  fullName
}
    `;
export const SessionAssignmentForStaffAssignmentDialogFragmentDoc = gql`
    fragment sessionAssignmentForStaffAssignmentDialog on SessionAssignment {
  id
  role
  user {
    ...userWithContacts
  }
  type
  updatedBy {
    ...userWithFullName
  }
  order
  rating
  travel
  approval {
    id
    state
  }
}
    ${UserWithContactsFragmentDoc}
${UserWithFullNameFragmentDoc}`;
export const SessionForStaffAssignmentDialogFragmentDoc = gql`
    fragment sessionForStaffAssignmentDialog on Session {
  id
  name
  type
  irl @client
  hybrid @client
  scheduled @client
  city
  assignability
  isTravelable
  isApprovable
  modifiers {
    id
    label
  }
  assignments {
    ...sessionAssignmentForStaffAssignmentDialog
  }
  hosts @client {
    ...sessionAssignmentForStaffAssignmentDialog
  }
}
    ${SessionAssignmentForStaffAssignmentDialogFragmentDoc}`;
export const SessionAssignmentForStaffAssignmentFragmentDoc = gql`
    fragment sessionAssignmentForStaffAssignment on SessionAssignment {
  id
  role
  type
  userId
  order
  user {
    ...userWithContacts
  }
  updatedBy {
    ...userWithFullName
  }
  travel
  travelConfirmed
  rating
  approval {
    id
    state
  }
}
    ${UserWithContactsFragmentDoc}
${UserWithFullNameFragmentDoc}`;
export const SessionWithAssignmentsForStaffAssignmentFragmentDoc = gql`
    fragment sessionWithAssignmentsForStaffAssignment on Session {
  id
  assignments {
    ...sessionAssignmentForStaffAssignment
  }
}
    ${SessionAssignmentForStaffAssignmentFragmentDoc}`;
export const SessionWithIntegrationsFragmentDoc = gql`
    fragment sessionWithIntegrations on Session {
  id
  integrations {
    id
    integrationId
    type
    url
    syncedAt
  }
}
    `;
export const SessionFragmentDoc = gql`
    fragment session on Session {
  id
  integrations {
    ...sessionIntegration
  }
  workspaceId
  templateId
  experience {
    id
  }
  name
  shortName
  startsAt
  endsAt
  duration
  attendees
  status
  timezone
  type
  remote @client
  irl @client
  hybrid @client
  isEscapeRoom
  isMysteryExpress
  modifiers {
    id
  }
  packageLevel
  lobby
  playType
  language
  startDate @client
  endDate @client
  creationType
  createdBy {
    id
  }
  files {
    id
  }
  missionSet {
    id
  }
  freeReschedule
  contact {
    id
    fullName
  }
  pastEventsDetails
  futureEventsDetails
  internalNotes
  activityNotes
}
    ${SessionIntegrationFragmentDoc}`;
export const SessionForSessionInfoFragmentDoc = gql`
    fragment sessionForSessionInfo on Session {
  id
  syncType
  integrations {
    ...sessionIntegration
  }
  internalNotes
  contact {
    ...userWithContacts
  }
  bookingContact {
    ...userWithContacts
  }
  additionalBookingContacts {
    ...userWithContacts
  }
  createdBy {
    ...userWithFullName
  }
  creationType
  createdAt
  createdDate @client
}
    ${SessionIntegrationFragmentDoc}
${UserWithContactsFragmentDoc}
${UserWithFullNameFragmentDoc}`;
export const SessionSupportsUpgradeFragmentDoc = gql`
    fragment sessionSupportsUpgrade on SessionSupportsUpgrade {
  customer {
    value
    reason
  }
  staff {
    value
    reason
  }
}
    `;
export const SessionForSessionDetailsFragmentDoc = gql`
    fragment sessionForSessionDetails on Session {
  id
  workspaceId
  name
  shortName
  type
  remote @client
  irl @client
  hybrid @client
  hostType
  syncType
  isEscapeRoom
  isMysteryExpress
  pdfType
  startsAt
  startDate @client
  timezone
  duration
  packageLevel
  credits
  templateId
  sourceSession {
    id
  }
  modifiers {
    id
    label
  }
  meta {
    template
    actors
    vas
    lodging
    permissions
    useEventProducerPhone
  }
  isLobbyEligible
  lobby
  playType
  supportsSlots {
    customer
    staff
  }
  supportsUpgrade {
    ...sessionSupportsUpgrade
  }
  size
  diy
  vas
  language
  attendees
  teams
  freeReschedule
  restrictions {
    attendees
  }
  createdAt
  createdDate @client
  scheduled @client
  complete @client
  modifications @client
}
    ${SessionSupportsUpgradeFragmentDoc}`;
export const SessionForSessionContactDetailsFragmentDoc = gql`
    fragment sessionForSessionContactDetails on Session {
  id
  contact {
    ...userWithContacts
  }
  bookingContact {
    ...userWithContacts
  }
}
    ${UserWithContactsFragmentDoc}`;
export const SessionForSessionActivityDetailsFragmentDoc = gql`
    fragment sessionForSessionActivityDetails on Session {
  id
  type
  irl @client
  activityNotes
  missionSet {
    id
  }
  isCustomizable
}
    `;
export const SessionForSessionDetailsSectionFragmentDoc = gql`
    fragment sessionForSessionDetailsSection on Session {
  ...sessionForSessionInfo
  ...sessionForSessionDetails
  ...sessionForSessionContactDetails
  ...sessionForSessionActivityDetails
  complete @client
}
    ${SessionForSessionInfoFragmentDoc}
${SessionForSessionDetailsFragmentDoc}
${SessionForSessionContactDetailsFragmentDoc}
${SessionForSessionActivityDetailsFragmentDoc}`;
export const LocationFragmentDoc = gql`
    fragment location on Location {
  id
  placeId
  name
  address
  point
}
    `;
export const SessionVenueContactsFragmentDoc = gql`
    fragment sessionVenueContacts on SessionVenueContacts {
  name
  email
  phone
  details
}
    `;
export const SessionForSessionLocationSectionFragmentDoc = gql`
    fragment sessionForSessionLocationSection on Session {
  id
  irl @client
  city
  priceZone
  gameZoneId
  gameZone {
    id
    point
    hull
  }
  locations {
    id
    type
    location {
      ...location
      venueId
    }
  }
  venue {
    ...sessionVenueContacts
  }
  locationDetails
  supportsGameZone
}
    ${LocationFragmentDoc}
${SessionVenueContactsFragmentDoc}`;
export const SessionForSessionScheduleSectionFragmentDoc = gql`
    fragment sessionForSessionScheduleSection on Session {
  id
  name
  type
  hostType
  startsAt
  startDate @client
  timezone
  duration
  scheduleOffset
  packageLevel
  size
  teams
  diy
  vas
  timeline {
    ...timeline
  }
  scheduled @client
  complete @client
  upcoming @client
}
    ${TimelineFragmentDoc}`;
export const GroupDetailsFragmentDoc = gql`
    fragment groupDetails on SessionGroupDetails {
  playedPreviously
  multiSession
  multicultural
  young
  familiar
  sameLocation
  hasPreviousEvent
  previousEventDetails
  hasNextEvent
  nextEventUrl
  departments
  attendeesDetails
  eventDetails
  teamsOrigin
  teamsDetails
  prizesOrigin
  prizesShippingAddress
  food
  alcohol
  tv
  avDetails
  age
  rating
  bar
  description
  mobility
}
    `;
export const SessionForSessionGroupDetailsFragmentDoc = gql`
    fragment sessionForSessionGroupDetails on Session {
  id
  type
  hostType
  irl @client
  hybrid @client
  experience {
    id
    category
    title
  }
  size
  relations {
    id
    type
  }
  pastEventsDetails
  futureEventsDetails
  meta {
    group {
      ...groupDetails
    }
  }
  modifiers {
    id
    label
  }
  supportsPrizes
  hasUpgrade @client
}
    ${GroupDetailsFragmentDoc}`;
export const IntegrationForMediaAndDownloadFragmentDoc = gql`
    fragment integrationForMediaAndDownload on SessionIntegration {
  id
  type
  url
  attributes {
    password
  }
}
    `;
export const SessionForEventMediaAndDownloadsFragmentDoc = gql`
    fragment sessionForEventMediaAndDownloads on Session {
  id
  type
  remote @client
  irl @client
  onScreen @client
  startsAt
  startDate @client
  modifiers {
    id
    label
  }
  integrations {
    ...integrationForMediaAndDownload
  }
  files {
    ...file
  }
  experience {
    id
    title
  }
  meta {
    attendees
  }
  complete @client
}
    ${IntegrationForMediaAndDownloadFragmentDoc}
${FileFragmentDoc}`;
export const SessionForGameControlFragmentDoc = gql`
    fragment sessionForGameControl on Session {
  id
  duration
  startsAt
  meta {
    gameControl
  }
}
    `;
export const SessionLocationFragmentDoc = gql`
    fragment sessionLocation on SessionLocation {
  id
  type
  location {
    ...location
  }
}
    ${LocationFragmentDoc}`;
export const SessionForInviteLinksFragmentDoc = gql`
    fragment sessionForInviteLinks on Session {
  id
  name
  shortName
  type
  irl @client
  hybrid @client
  hostType
  syncType
  vas
  isEscapeRoom
  isMysteryExpress
  pdfType
  startsAt
  startDate @client
  endsAt
  endDate @client
  timezone
  modifiers {
    id
    label
  }
  experience {
    id
    title
  }
  integrations {
    id
    type
    url
  }
  locations {
    ...sessionLocation
  }
  scheduled @client
  pdfType
  hasBreadcrumbIntegration @client
  links @client
  language
}
    ${SessionLocationFragmentDoc}`;
export const SessionForInstructionsFragmentDoc = gql`
    fragment sessionForInstructions on Session {
  id
  instructions {
    id
    type
    documentType
    title
    description
  }
}
    `;
export const SessionForShowFragmentDoc = gql`
    fragment sessionForShow on Session {
  ...session
  ...sessionForSessionDetailsSection
  ...sessionForSessionLocationSection
  ...sessionForSessionScheduleSection
  ...sessionForSessionGroupDetails
  ...sessionForEventMediaAndDownloads
  ...sessionForGameControl
  ...sessionForInviteLinks
  ...sessionForInstructions
  preview
  modifiers {
    id
    label
  }
  city
  gameZoneId
  scheduled @client
  upcoming @client
  complete @client
  assignability
  assignments {
    ...sessionAssignmentForStaffAssignment
  }
  hosts @client {
    ...sessionAssignmentForStaffAssignment
  }
  experience {
    id
    title
  }
  files {
    ...file
  }
  contact {
    ...userWithContacts
  }
  bookingContact {
    ...userWithContacts
  }
  additionalBookingContacts {
    ...userWithContacts
  }
  size
  diy
  hasGroupDetails
  vas
  group {
    id
    name
  }
  hasGroup
  hasBreadcrumbIntegration @client
  priceZone
  credits
  createdDate @client
  hasUpgrade @client
  producer @client {
    id
  }
  producers @client {
    id
  }
  links @client
  locateable
  isTravelable
  isApprovable
}
    ${SessionFragmentDoc}
${SessionForSessionDetailsSectionFragmentDoc}
${SessionForSessionLocationSectionFragmentDoc}
${SessionForSessionScheduleSectionFragmentDoc}
${SessionForSessionGroupDetailsFragmentDoc}
${SessionForEventMediaAndDownloadsFragmentDoc}
${SessionForGameControlFragmentDoc}
${SessionForInviteLinksFragmentDoc}
${SessionForInstructionsFragmentDoc}
${SessionAssignmentForStaffAssignmentFragmentDoc}
${FileFragmentDoc}
${UserWithContactsFragmentDoc}`;
export const SessionWithGroupFragmentDoc = gql`
    fragment sessionWithGroup on Session {
  id
  group {
    id
    name
  }
}
    `;
export const LawnScheduleForShowFragmentDoc = gql`
    fragment lawnScheduleForShow on LawnSchedule {
  id
  break
  breakDuration
  roundBreakDuration
  startOffset
  endOffset
  list
  missions {
    id
    name
  }
  tournament {
    break {
      afterRound
      endsAt
      startsAt
    }
    rounds {
      endsAt
      round
      startsAt
      teams
    }
  }
}
    `;
export const SessionWithSchedulingDataFragmentDoc = gql`
    fragment sessionWithSchedulingData on Session {
  id
  startsAt
  startDate @client
  endsAt
  endDate @client
  duration
  scheduleOffset
  timezone
  lobby
  size
  timeline {
    ...timeline
  }
  lawnSchedule {
    ...lawnScheduleForShow
  }
  assignability
  assignments {
    id
    role
    type
    user {
      ...userWithFullName
    }
    travel
  }
  restrictions {
    attendees
  }
  supportsUpgrade {
    ...sessionSupportsUpgrade
  }
}
    ${TimelineFragmentDoc}
${LawnScheduleForShowFragmentDoc}
${UserWithFullNameFragmentDoc}
${SessionSupportsUpgradeFragmentDoc}`;
export const ExperienceOverviewItemFragmentDoc = gql`
    fragment experienceOverviewItem on ExperienceOverviewItem {
  title
  subtitle
  body
  image
  imageId
}
    `;
export const ExperienceCustomizationFragmentDoc = gql`
    fragment experienceCustomization on ExperienceCustomization {
  title
  description
  notes
}
    `;
export const ModifierWithLabelFragmentDoc = gql`
    fragment modifierWithLabel on Modifier {
  id
  label
}
    `;
export const ExperienceFormatForExperienceCardFragmentDoc = gql`
    fragment experienceFormatForExperienceCard on ExperienceFormat {
  id
  type
  hostType
  pdfType
  title
  subtitle
  description
  formatDescription
  hostDescription
  modifiers {
    ...modifierWithLabel
  }
  minDuration
  duration
  maxDuration
  lobby
  syncType
  playType
  locatable
  templateId
  template
  youtube
  poster {
    ...file
  }
  media {
    ...file
  }
  settings {
    customization {
      type
      description
    }
  }
}
    ${ModifierWithLabelFragmentDoc}
${FileFragmentDoc}`;
export const ExperienceForExperienceCardFragmentDoc = gql`
    fragment experienceForExperienceCard on Experience {
  id
  title
  subtitle
  description
  visible
  bookable
  subscribers
  position
  type
  category
  status
  tier
  featured {
    ...file
  }
  thumbnail {
    ...file
  }
  poster {
    ...file
  }
  banner {
    ...file
  }
  image {
    ...file
  }
  tags
  url
  overview {
    titleTag
    description
    cost
    path
    attendees
    label
    youtube
    features {
      ...experienceOverviewItem
    }
    feedbacks {
      ...experienceOverviewItem
    }
    studies {
      ...experienceOverviewItem
    }
    customizations {
      ...experienceCustomization
    }
    keywords
    url
  }
  formats {
    ...experienceFormatForExperienceCard
  }
  attributes {
    gCalPrefix
    hubSpotDisabled
    webSiteDisabled
    quoteDisabled
  }
  types
  updatedAt
  updatedDate @client
}
    ${FileFragmentDoc}
${ExperienceOverviewItemFragmentDoc}
${ExperienceCustomizationFragmentDoc}
${ExperienceFormatForExperienceCardFragmentDoc}`;
export const ExperienceFormatForTileFragmentDoc = gql`
    fragment experienceFormatForTile on ExperienceFormat {
  id
  title
  type
  hostType
}
    `;
export const ExperienceForExperienceDurationInputFragmentDoc = gql`
    fragment experienceForExperienceDurationInput on Experience {
  id
  title
  bookable
}
    `;
export const ExperienceForEditExperienceFormatFragmentDoc = gql`
    fragment experienceForEditExperienceFormat on Experience {
  ...experienceForExperienceDurationInput
}
    ${ExperienceForExperienceDurationInputFragmentDoc}`;
export const ExperienceFormatForEditFragmentDoc = gql`
    fragment experienceFormatForEdit on ExperienceFormat {
  id
  type
  hostType
  title
  subtitle
  description
  formatDescription
  hostDescription
  minDuration
  duration
  maxDuration
  lobby
  syncType
  playType
  pdfType
  templateId
  template
  youtube
  settings {
    customization {
      type
      description
    }
  }
  media {
    ...file
  }
  poster {
    ...file
  }
  modifiers {
    ...modifierWithLabel
  }
}
    ${FileFragmentDoc}
${ModifierWithLabelFragmentDoc}`;
export const ExperienceFormatWithMediaFragmentDoc = gql`
    fragment experienceFormatWithMedia on ExperienceFormat {
  id
  poster {
    ...file
  }
  media {
    ...file
  }
}
    ${FileFragmentDoc}`;
export const ExperienceWithMediaFragmentDoc = gql`
    fragment experienceWithMedia on Experience {
  id
  image {
    ...file
  }
  featured {
    ...file
  }
  thumbnail {
    ...file
  }
  poster {
    ...file
  }
  banner {
    ...file
  }
}
    ${FileFragmentDoc}`;
export const ExperienceWithCustomizationFragmentDoc = gql`
    fragment experienceWithCustomization on Experience {
  id
  overview {
    customizations {
      ...experienceCustomization
    }
  }
}
    ${ExperienceCustomizationFragmentDoc}`;
export const ExperienceForLegacyExperienceViewCardFragmentDoc = gql`
    fragment experienceForLegacyExperienceViewCard on Experience {
  id
  type
  title
  subtitle
  description
  bookable
  formats {
    id
    type
    hostType
    duration
  }
  overview {
    url
  }
}
    `;
export const ExperienceForListFragmentDoc = gql`
    fragment experienceForList on Experience {
  id
  title
  subtitle
  description
  position
  status
  category
  tags
  types
  image {
    ...file
  }
}
    ${FileFragmentDoc}`;
export const ExperienceForHeroFragmentDoc = gql`
    fragment experienceForHero on Experience {
  id
  title
  description
  image {
    ...file
  }
  position
}
    ${FileFragmentDoc}`;
export const OrderItemForOrderListFragmentDoc = gql`
    fragment orderItemForOrderList on OrderItem {
  id
  quantity
  unitPrice
  discount
  consumable {
    id
    name
  }
}
    `;
export const OrderForOrderListItemFragmentDoc = gql`
    fragment orderForOrderListItem on Order {
  id
  dealId
  url
  items {
    ...orderItemForOrderList
  }
  subscription
  paidAt
  createdBy {
    ...userWithFullName
  }
  createdAt
  paidDate @client
  createdDate @client
}
    ${OrderItemForOrderListFragmentDoc}
${UserWithFullNameFragmentDoc}`;
export const SessionForWorkspaceFragmentDoc = gql`
    fragment sessionForWorkspace on Session {
  id
  workspaceId
}
    `;
export const OpenWeveForViewFragmentDoc = gql`
    fragment openWeveForView on OpenWeve {
  id
  title
  subtitle
  description
  url
}
    `;
export const OpenWeveForEditFragmentDoc = gql`
    fragment openWeveForEdit on OpenWeve {
  id
  title
  subtitle
  description
}
    `;
export const OpenWeveForOpenWeveCardFragmentDoc = gql`
    fragment openWeveForOpenWeveCard on OpenWeve {
  ...openWeveForView
  ...openWeveForEdit
}
    ${OpenWeveForViewFragmentDoc}
${OpenWeveForEditFragmentDoc}`;
export const OpenWeveForListFragmentDoc = gql`
    fragment openWeveForList on OpenWeve {
  id
  title
}
    `;
export const SessionForSessionGroupPageFragmentDoc = gql`
    fragment sessionForSessionGroupPage on Session {
  id
  name
  shortName
  type
  hostType
  attendees
  size
  status
  startsAt
  startDate @client
  endsAt
  endDate @client
  timezone
  modifiers {
    id
    label
  }
  status
  experience {
    id
    title
  }
  assignments {
    id
    role
    user {
      ...userWithFullNameAndAvatar
    }
  }
  hosts @client {
    id
    user {
      ...userWithFullNameAndAvatar
    }
  }
  locateable
  locations {
    ...sessionLocation
  }
}
    ${UserWithFullNameAndAvatarFragmentDoc}
${SessionLocationFragmentDoc}`;
export const SessionGroupForSessionGroupPageFragmentDoc = gql`
    fragment sessionGroupForSessionGroupPage on SessionGroup {
  id
  name
  image {
    id
    src
  }
  sessions {
    ...sessionForSessionGroupPage
  }
  experience {
    id
    title
  }
}
    ${SessionForSessionGroupPageFragmentDoc}`;
export const AiPromptForAdminSettingsFragmentDoc = gql`
    fragment aiPromptForAdminSettings on AiPrompt {
  id
  name
  model
  prompt
  webhookUrl
}
    `;
export const AiPromptForListForAdminSettingsFragmentDoc = gql`
    fragment aiPromptForListForAdminSettings on AiPrompt {
  id
  name
}
    `;
export const ModifierForAdminSettingsFragmentDoc = gql`
    fragment modifierForAdminSettings on Modifier {
  id
  label
  description
}
    `;
export const PriceModifierForAdminSettingsFragmentDoc = gql`
    fragment priceModifierForAdminSettings on PriceModifier {
  id
  dateRange
  types
  percent
}
    `;
export const SessionPhoneForSessionPhoneFormFragmentDoc = gql`
    fragment sessionPhoneForSessionPhoneForm on SessionPhone {
  id
  phone
  sessionType
  hostType
  packageLevel
}
    `;
export const SessionPhoneForAdminSettingsFragmentDoc = gql`
    fragment sessionPhoneForAdminSettings on SessionPhone {
  id
  phone
  sessionType
  hostType
  packageLevel
}
    `;
export const ZoneAreaForZoneAreaFormFragmentDoc = gql`
    fragment zoneAreaForZoneAreaForm on ZoneArea {
  id
  name
  price
  area
}
    `;
export const ZoneAreaForAdminSettingsFragmentDoc = gql`
    fragment zoneAreaForAdminSettings on ZoneArea {
  id
  name
}
    `;
export const UserForProfileSettingsFragmentDoc = gql`
    fragment userForProfileSettings on User {
  id
  email
  firstName
  lastName
  fullName
  phone
  meetingLink
  attributes {
    email
  }
  image {
    ...file
  }
}
    ${FileFragmentDoc}`;
export const WorkspaceForSettingsFragmentDoc = gql`
    fragment workspaceForSettings on Workspace {
  id
  name
  company
  notes
  content
  preferredEventProducerId
  preferredSalesId
  notifications
  settings {
    missions
  }
}
    `;
export const UserForUserManagementFragmentDoc = gql`
    fragment userForUserManagement on User {
  id
  fullName
  email
  role
  permission
  avatar
}
    `;
export const UserForUserManagementFormFragmentDoc = gql`
    fragment userForUserManagementForm on User {
  id
  firstName
  lastName
  fullName
  email
  phone
  role
  permission
  active
  modifiers {
    id
  }
  timezone
  languages
  notes
  level
  upgrades
  load
  lastLoggedInAt
  attributes {
    email
  }
  location {
    ...location
  }
  zoneAreaId
  birthYear
  meetingLink
}
    ${LocationFragmentDoc}`;
export const VenueForVenueManagementFragmentDoc = gql`
    fragment venueForVenueManagement on Venue {
  id
  location {
    id
    name
    address
    point
  }
  name
  rating
}
    `;
export const VenueForVenuePageFragmentDoc = gql`
    fragment venueForVenuePage on Venue {
  description
  id
  location {
    id
    name
    address
    placeId
    point
  }
  gameZone {
    id
    name
  }
  gameZoneId
  participantsMin
  participantsMax
  name
  city
  rating
  nOfReviews
  nOfEvents
  private
}
    `;
export const VenueReviewForCreateVenueReviewFormFragmentDoc = gql`
    fragment venueReviewForCreateVenueReviewForm on VenueReview {
  id
  rate
  review
  venueId
  sessionId
  createdById
  media {
    id
  }
}
    `;
export const VenueReviewForUpdateVenueReviewFormFragmentDoc = gql`
    fragment venueReviewForUpdateVenueReviewForm on VenueReview {
  id
  rate
  review
  sessionId
  media {
    id
  }
}
    `;
export const VenueReviewForVenuePageFragmentDoc = gql`
    fragment venueReviewForVenuePage on VenueReview {
  id
  rate
  review
  venueId
  createdAt
  session {
    id
    shortName
  }
  createdBy {
    id
    fullName
    avatar
  }
  media {
    id
    src
    type
  }
}
    `;
export const GetWorkspaceForEventsSearchDropdownDocument = gql`
    query GetWorkspaceForEventsSearchDropdown($id: ID!) {
  workspace(id: $id) {
    ...workspaceForEventsSearchDropdown
  }
}
    ${WorkspaceForEventsSearchDropdownFragmentDoc}`;
export function useGetWorkspaceForEventsSearchDropdownQuery(variables: import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQuery, import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQuery, import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQuery, import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQuery, import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables>(GetWorkspaceForEventsSearchDropdownDocument, variables, options);
}
export function useGetWorkspaceForEventsSearchDropdownLazyQuery(variables: import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQuery, import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQuery, import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQuery, import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQuery, import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables>(GetWorkspaceForEventsSearchDropdownDocument, variables, options);
}
export type GetWorkspaceForEventsSearchDropdownQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQuery, import("@/graphql/types").GetWorkspaceForEventsSearchDropdownQueryVariables>;
export const DeleteOrderDocument = gql`
    mutation DeleteOrder($input: DeleteOrderInput!) {
  deleteOrder(input: $input) {
    id
  }
}
    `;
export function useDeleteOrderMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteOrderMutation, import("@/graphql/types").DeleteOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteOrderMutation, import("@/graphql/types").DeleteOrderMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteOrderMutation, import("@/graphql/types").DeleteOrderMutationVariables>(DeleteOrderDocument, options);
}
export type DeleteOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteOrderMutation, import("@/graphql/types").DeleteOrderMutationVariables>;
export const DeleteOrderItemsDocument = gql`
    mutation DeleteOrderItems($input: DeleteOrderItemsInput!) {
  deleteOrderItems(input: $input) {
    deletedCount
  }
}
    `;
export function useDeleteOrderItemsMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteOrderItemsMutation, import("@/graphql/types").DeleteOrderItemsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteOrderItemsMutation, import("@/graphql/types").DeleteOrderItemsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteOrderItemsMutation, import("@/graphql/types").DeleteOrderItemsMutationVariables>(DeleteOrderItemsDocument, options);
}
export type DeleteOrderItemsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteOrderItemsMutation, import("@/graphql/types").DeleteOrderItemsMutationVariables>;
export const GetOrderForEditDocument = gql`
    query GetOrderForEdit($id: ID!) {
  order(id: $id) {
    ...orderForEdit
  }
}
    ${OrderForEditFragmentDoc}`;
export function useGetOrderForEditQuery(variables: import("@/graphql/types").GetOrderForEditQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetOrderForEditQueryVariables> | ReactiveFunction<import("@/graphql/types").GetOrderForEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOrderForEditQuery, import("@/graphql/types").GetOrderForEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOrderForEditQuery, import("@/graphql/types").GetOrderForEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOrderForEditQuery, import("@/graphql/types").GetOrderForEditQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetOrderForEditQuery, import("@/graphql/types").GetOrderForEditQueryVariables>(GetOrderForEditDocument, variables, options);
}
export function useGetOrderForEditLazyQuery(variables: import("@/graphql/types").GetOrderForEditQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetOrderForEditQueryVariables> | ReactiveFunction<import("@/graphql/types").GetOrderForEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOrderForEditQuery, import("@/graphql/types").GetOrderForEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOrderForEditQuery, import("@/graphql/types").GetOrderForEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOrderForEditQuery, import("@/graphql/types").GetOrderForEditQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetOrderForEditQuery, import("@/graphql/types").GetOrderForEditQueryVariables>(GetOrderForEditDocument, variables, options);
}
export type GetOrderForEditQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetOrderForEditQuery, import("@/graphql/types").GetOrderForEditQueryVariables>;
export const UpdateOrderItemDocument = gql`
    mutation UpdateOrderItem($id: ID!, $input: UpdateOrderItemInput!) {
  updateOrderItem(id: $id, input: $input) {
    ...orderItemForOrderList
  }
}
    ${OrderItemForOrderListFragmentDoc}`;
export function useUpdateOrderItemMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateOrderItemMutation, import("@/graphql/types").UpdateOrderItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateOrderItemMutation, import("@/graphql/types").UpdateOrderItemMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateOrderItemMutation, import("@/graphql/types").UpdateOrderItemMutationVariables>(UpdateOrderItemDocument, options);
}
export type UpdateOrderItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateOrderItemMutation, import("@/graphql/types").UpdateOrderItemMutationVariables>;
export const ListExperiencesForExperienceMultiselectDocument = gql`
    query ListExperiencesForExperienceMultiselect($filter: ExperienceFilter, $paging: OffsetPaging, $sorting: [ExperienceSort!]) {
  experiences(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...experienceForExperienceMultiselect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${ExperienceForExperienceMultiselectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListExperiencesForExperienceMultiselectQuery(variables: import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceMultiselectQuery, import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceMultiselectQuery, import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceMultiselectQuery, import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListExperiencesForExperienceMultiselectQuery, import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables>(ListExperiencesForExperienceMultiselectDocument, variables, options);
}
export function useListExperiencesForExperienceMultiselectLazyQuery(variables: import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceMultiselectQuery, import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceMultiselectQuery, import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceMultiselectQuery, import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListExperiencesForExperienceMultiselectQuery, import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables>(ListExperiencesForExperienceMultiselectDocument, variables, options);
}
export type ListExperiencesForExperienceMultiselectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListExperiencesForExperienceMultiselectQuery, import("@/graphql/types").ListExperiencesForExperienceMultiselectQueryVariables>;
export const GetExperienceForExperienceSelectDocument = gql`
    query GetExperienceForExperienceSelect($id: ID!) {
  experience(id: $id) {
    ...experienceForExperienceSelect
  }
}
    ${ExperienceForExperienceSelectFragmentDoc}`;
export function useGetExperienceForExperienceSelectQuery(variables: import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceSelectQuery, import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceSelectQuery, import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceSelectQuery, import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetExperienceForExperienceSelectQuery, import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables>(GetExperienceForExperienceSelectDocument, variables, options);
}
export function useGetExperienceForExperienceSelectLazyQuery(variables: import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceSelectQuery, import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceSelectQuery, import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceSelectQuery, import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetExperienceForExperienceSelectQuery, import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables>(GetExperienceForExperienceSelectDocument, variables, options);
}
export type GetExperienceForExperienceSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetExperienceForExperienceSelectQuery, import("@/graphql/types").GetExperienceForExperienceSelectQueryVariables>;
export const ListExperiencesForExperienceSelectDocument = gql`
    query ListExperiencesForExperienceSelect($filter: ExperienceFilter, $paging: OffsetPaging) {
  experiences(
    filter: $filter
    paging: $paging
    sorting: [{field: title, direction: ASC}]
  ) {
    nodes {
      ...experienceForExperienceSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${ExperienceForExperienceSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListExperiencesForExperienceSelectQuery(variables: import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceSelectQuery, import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceSelectQuery, import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceSelectQuery, import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListExperiencesForExperienceSelectQuery, import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables>(ListExperiencesForExperienceSelectDocument, variables, options);
}
export function useListExperiencesForExperienceSelectLazyQuery(variables: import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceSelectQuery, import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceSelectQuery, import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForExperienceSelectQuery, import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListExperiencesForExperienceSelectQuery, import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables>(ListExperiencesForExperienceSelectDocument, variables, options);
}
export type ListExperiencesForExperienceSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListExperiencesForExperienceSelectQuery, import("@/graphql/types").ListExperiencesForExperienceSelectQueryVariables>;
export const UpdateGalleryPasswordDocument = gql`
    mutation UpdateGalleryPassword($input: UpdateGalleryPasswordInput!) {
  updateGalleryPassword(input: $input) {
    ...integrationForMediaAndDownload
  }
}
    ${IntegrationForMediaAndDownloadFragmentDoc}`;
export function useUpdateGalleryPasswordMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateGalleryPasswordMutation, import("@/graphql/types").UpdateGalleryPasswordMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateGalleryPasswordMutation, import("@/graphql/types").UpdateGalleryPasswordMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateGalleryPasswordMutation, import("@/graphql/types").UpdateGalleryPasswordMutationVariables>(UpdateGalleryPasswordDocument, options);
}
export type UpdateGalleryPasswordMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateGalleryPasswordMutation, import("@/graphql/types").UpdateGalleryPasswordMutationVariables>;
export const ListGameCitiesForGameCitySelectDocument = gql`
    query ListGameCitiesForGameCitySelect($filter: GameCityFilter!, $paging: OffsetPaging) {
  gameCities(filter: $filter, paging: $paging) {
    nodes {
      ...gameCityForGameCitiesSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${GameCityForGameCitiesSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListGameCitiesForGameCitySelectQuery(variables: import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameCitiesForGameCitySelectQuery, import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameCitiesForGameCitySelectQuery, import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameCitiesForGameCitySelectQuery, import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListGameCitiesForGameCitySelectQuery, import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables>(ListGameCitiesForGameCitySelectDocument, variables, options);
}
export function useListGameCitiesForGameCitySelectLazyQuery(variables: import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameCitiesForGameCitySelectQuery, import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameCitiesForGameCitySelectQuery, import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameCitiesForGameCitySelectQuery, import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListGameCitiesForGameCitySelectQuery, import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables>(ListGameCitiesForGameCitySelectDocument, variables, options);
}
export type ListGameCitiesForGameCitySelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListGameCitiesForGameCitySelectQuery, import("@/graphql/types").ListGameCitiesForGameCitySelectQueryVariables>;
export const ListGameZonesForGameZoneSelectDocument = gql`
    query ListGameZonesForGameZoneSelect($filter: GameZoneFilter!, $paging: OffsetPaging) {
  gameZones(filter: $filter, paging: $paging, sorting: {field: "name"}) {
    nodes {
      ...gameZoneForGameZoneSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${GameZoneForGameZoneSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListGameZonesForGameZoneSelectQuery(variables: import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameZonesForGameZoneSelectQuery, import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameZonesForGameZoneSelectQuery, import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameZonesForGameZoneSelectQuery, import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListGameZonesForGameZoneSelectQuery, import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables>(ListGameZonesForGameZoneSelectDocument, variables, options);
}
export function useListGameZonesForGameZoneSelectLazyQuery(variables: import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameZonesForGameZoneSelectQuery, import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameZonesForGameZoneSelectQuery, import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGameZonesForGameZoneSelectQuery, import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListGameZonesForGameZoneSelectQuery, import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables>(ListGameZonesForGameZoneSelectDocument, variables, options);
}
export type ListGameZonesForGameZoneSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListGameZonesForGameZoneSelectQuery, import("@/graphql/types").ListGameZonesForGameZoneSelectQueryVariables>;
export const CreateManualOrderDocument = gql`
    mutation CreateManualOrder($input: CreateManualOrderInput!) {
  createManualOrder(input: $input) {
    id
  }
}
    `;
export function useCreateManualOrderMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateManualOrderMutation, import("@/graphql/types").CreateManualOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateManualOrderMutation, import("@/graphql/types").CreateManualOrderMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateManualOrderMutation, import("@/graphql/types").CreateManualOrderMutationVariables>(CreateManualOrderDocument, options);
}
export type CreateManualOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateManualOrderMutation, import("@/graphql/types").CreateManualOrderMutationVariables>;
export const ListMissionSetsForMissionSetSelectDocument = gql`
    query ListMissionSetsForMissionSetSelect($filter: MissionSetFilter, $paging: OffsetPaging) {
  missionSets(filter: $filter, paging: $paging) {
    nodes {
      ...missionSetForMissionSetSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${MissionSetForMissionSetSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListMissionSetsForMissionSetSelectQuery(variables: import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForMissionSetSelectQuery, import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForMissionSetSelectQuery, import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForMissionSetSelectQuery, import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListMissionSetsForMissionSetSelectQuery, import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables>(ListMissionSetsForMissionSetSelectDocument, variables, options);
}
export function useListMissionSetsForMissionSetSelectLazyQuery(variables: import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForMissionSetSelectQuery, import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForMissionSetSelectQuery, import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForMissionSetSelectQuery, import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListMissionSetsForMissionSetSelectQuery, import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables>(ListMissionSetsForMissionSetSelectDocument, variables, options);
}
export type ListMissionSetsForMissionSetSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListMissionSetsForMissionSetSelectQuery, import("@/graphql/types").ListMissionSetsForMissionSetSelectQueryVariables>;
export const ListSessionsForMissionSetMultiselectDocument = gql`
    query ListSessionsForMissionSetMultiselect($filter: SessionFilter, $paging: OffsetPaging, $sorting: [SessionSort!]) {
  sessions(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...sessionForMissionSetSessionMultiselect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${SessionForMissionSetSessionMultiselectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListSessionsForMissionSetMultiselectQuery(variables: import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForMissionSetMultiselectQuery, import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForMissionSetMultiselectQuery, import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForMissionSetMultiselectQuery, import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListSessionsForMissionSetMultiselectQuery, import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables>(ListSessionsForMissionSetMultiselectDocument, variables, options);
}
export function useListSessionsForMissionSetMultiselectLazyQuery(variables: import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForMissionSetMultiselectQuery, import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForMissionSetMultiselectQuery, import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForMissionSetMultiselectQuery, import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListSessionsForMissionSetMultiselectQuery, import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables>(ListSessionsForMissionSetMultiselectDocument, variables, options);
}
export type ListSessionsForMissionSetMultiselectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListSessionsForMissionSetMultiselectQuery, import("@/graphql/types").ListSessionsForMissionSetMultiselectQueryVariables>;
export const ListModifiersForModifierMultiselectDocument = gql`
    query ListModifiersForModifierMultiselect($filter: ModifierFilter, $paging: OffsetPaging, $sorting: [ModifierSort!]) {
  modifiers(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...modifierForModifierMultiselect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${ModifierForModifierMultiselectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListModifiersForModifierMultiselectQuery(variables: import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersForModifierMultiselectQuery, import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersForModifierMultiselectQuery, import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersForModifierMultiselectQuery, import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListModifiersForModifierMultiselectQuery, import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables>(ListModifiersForModifierMultiselectDocument, variables, options);
}
export function useListModifiersForModifierMultiselectLazyQuery(variables: import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersForModifierMultiselectQuery, import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersForModifierMultiselectQuery, import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersForModifierMultiselectQuery, import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListModifiersForModifierMultiselectQuery, import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables>(ListModifiersForModifierMultiselectDocument, variables, options);
}
export type ListModifiersForModifierMultiselectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListModifiersForModifierMultiselectQuery, import("@/graphql/types").ListModifiersForModifierMultiselectQueryVariables>;
export const CreateGameZoneDocument = gql`
    mutation CreateGameZone($input: CreateGameZoneInput!) {
  createGameZone(input: $input) {
    ...gameZoneForGameZoneSelect
  }
}
    ${GameZoneForGameZoneSelectFragmentDoc}`;
export function useCreateGameZoneMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateGameZoneMutation, import("@/graphql/types").CreateGameZoneMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateGameZoneMutation, import("@/graphql/types").CreateGameZoneMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateGameZoneMutation, import("@/graphql/types").CreateGameZoneMutationVariables>(CreateGameZoneDocument, options);
}
export type CreateGameZoneMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateGameZoneMutation, import("@/graphql/types").CreateGameZoneMutationVariables>;
export const CreatePermissionForPermissionAssignerDocument = gql`
    mutation CreatePermissionForPermissionAssigner($input: CreatePermissionInput!) {
  createPermission(input: $input) {
    ...permissionForPermissionsAssigner
  }
}
    ${PermissionForPermissionsAssignerFragmentDoc}`;
export function useCreatePermissionForPermissionAssignerMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreatePermissionForPermissionAssignerMutation, import("@/graphql/types").CreatePermissionForPermissionAssignerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreatePermissionForPermissionAssignerMutation, import("@/graphql/types").CreatePermissionForPermissionAssignerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreatePermissionForPermissionAssignerMutation, import("@/graphql/types").CreatePermissionForPermissionAssignerMutationVariables>(CreatePermissionForPermissionAssignerDocument, options);
}
export type CreatePermissionForPermissionAssignerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreatePermissionForPermissionAssignerMutation, import("@/graphql/types").CreatePermissionForPermissionAssignerMutationVariables>;
export const DeletePermissionForPermissionAssignerDocument = gql`
    mutation DeletePermissionForPermissionAssigner($input: DeletePermissionInput!) {
  deletePermission(input: $input) {
    ...permissionForPermissionsAssigner
  }
}
    ${PermissionForPermissionsAssignerFragmentDoc}`;
export function useDeletePermissionForPermissionAssignerMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeletePermissionForPermissionAssignerMutation, import("@/graphql/types").DeletePermissionForPermissionAssignerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeletePermissionForPermissionAssignerMutation, import("@/graphql/types").DeletePermissionForPermissionAssignerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeletePermissionForPermissionAssignerMutation, import("@/graphql/types").DeletePermissionForPermissionAssignerMutationVariables>(DeletePermissionForPermissionAssignerDocument, options);
}
export type DeletePermissionForPermissionAssignerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeletePermissionForPermissionAssignerMutation, import("@/graphql/types").DeletePermissionForPermissionAssignerMutationVariables>;
export const ListPermissionsForPermissionsAssignerDocument = gql`
    query ListPermissionsForPermissionsAssigner($workspaceId: ID!, $role: PermissionRole!) {
  permissions(
    filter: {workspaceId: {eq: $workspaceId}, role: {eq: $role}}
    paging: {limit: 1024}
  ) {
    nodes {
      ...permissionForPermissionsAssigner
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${PermissionForPermissionsAssignerFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListPermissionsForPermissionsAssignerQuery(variables: import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables> | ReactiveFunction<import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForPermissionsAssignerQuery, import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForPermissionsAssignerQuery, import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForPermissionsAssignerQuery, import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListPermissionsForPermissionsAssignerQuery, import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables>(ListPermissionsForPermissionsAssignerDocument, variables, options);
}
export function useListPermissionsForPermissionsAssignerLazyQuery(variables: import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables> | ReactiveFunction<import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForPermissionsAssignerQuery, import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForPermissionsAssignerQuery, import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForPermissionsAssignerQuery, import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListPermissionsForPermissionsAssignerQuery, import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables>(ListPermissionsForPermissionsAssignerDocument, variables, options);
}
export type ListPermissionsForPermissionsAssignerQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListPermissionsForPermissionsAssignerQuery, import("@/graphql/types").ListPermissionsForPermissionsAssignerQueryVariables>;
export const ListUsersForPermissionsAssignerDocument = gql`
    query ListUsersForPermissionsAssigner($filter: UserFilter, $paging: OffsetPaging) {
  users(filter: $filter, paging: $paging) {
    nodes {
      ...userWithFullName
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${UserWithFullNameFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListUsersForPermissionsAssignerQuery(variables: import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPermissionsAssignerQuery, import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPermissionsAssignerQuery, import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPermissionsAssignerQuery, import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListUsersForPermissionsAssignerQuery, import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables>(ListUsersForPermissionsAssignerDocument, variables, options);
}
export function useListUsersForPermissionsAssignerLazyQuery(variables: import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPermissionsAssignerQuery, import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPermissionsAssignerQuery, import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPermissionsAssignerQuery, import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListUsersForPermissionsAssignerQuery, import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables>(ListUsersForPermissionsAssignerDocument, variables, options);
}
export type ListUsersForPermissionsAssignerQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListUsersForPermissionsAssignerQuery, import("@/graphql/types").ListUsersForPermissionsAssignerQueryVariables>;
export const ListUserPermissionsForPrimaryWorkspaceSelectDocument = gql`
    query ListUserPermissionsForPrimaryWorkspaceSelect($userId: ID!) {
  permissions(filter: {userId: {eq: $userId}}) {
    nodes {
      id
      primary
      workspace {
        id
        name
      }
    }
  }
}
    `;
export function useListUserPermissionsForPrimaryWorkspaceSelectQuery(variables: import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQuery, import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQuery, import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQuery, import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQuery, import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables>(ListUserPermissionsForPrimaryWorkspaceSelectDocument, variables, options);
}
export function useListUserPermissionsForPrimaryWorkspaceSelectLazyQuery(variables: import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQuery, import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQuery, import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQuery, import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQuery, import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables>(ListUserPermissionsForPrimaryWorkspaceSelectDocument, variables, options);
}
export type ListUserPermissionsForPrimaryWorkspaceSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQuery, import("@/graphql/types").ListUserPermissionsForPrimaryWorkspaceSelectQueryVariables>;
export const UpdatePrimaryPermissionDocument = gql`
    mutation UpdatePrimaryPermission($id: ID!) {
  updatePermission(input: {id: $id, update: {primary: true}}) {
    ...permissionWithPrimary
  }
}
    ${PermissionWithPrimaryFragmentDoc}`;
export function useUpdatePrimaryPermissionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdatePrimaryPermissionMutation, import("@/graphql/types").UpdatePrimaryPermissionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdatePrimaryPermissionMutation, import("@/graphql/types").UpdatePrimaryPermissionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdatePrimaryPermissionMutation, import("@/graphql/types").UpdatePrimaryPermissionMutationVariables>(UpdatePrimaryPermissionDocument, options);
}
export type UpdatePrimaryPermissionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdatePrimaryPermissionMutation, import("@/graphql/types").UpdatePrimaryPermissionMutationVariables>;
export const UpdateSessionHostTypeDocument = gql`
    mutation UpdateSessionHostType($id: ID!, $hostType: HostType!) {
  updateSessionHostType(input: {id: $id, hostType: $hostType}) {
    id
    hostType
    name
    assignments {
      id
      userId
      type
      user {
        id
      }
      updatedBy {
        id
        fullName
      }
    }
    supportsSlots {
      customer
      staff
    }
    isLobbyEligible
    isApprovable
  }
}
    `;
export function useUpdateSessionHostTypeMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionHostTypeMutation, import("@/graphql/types").UpdateSessionHostTypeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionHostTypeMutation, import("@/graphql/types").UpdateSessionHostTypeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionHostTypeMutation, import("@/graphql/types").UpdateSessionHostTypeMutationVariables>(UpdateSessionHostTypeDocument, options);
}
export type UpdateSessionHostTypeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionHostTypeMutation, import("@/graphql/types").UpdateSessionHostTypeMutationVariables>;
export const ListSuggestedVenuesDocument = gql`
    query listSuggestedVenues($filter: VenueFilter, $sorting: [VenueSort!], $paging: OffsetPaging) {
  venues(filter: $filter, sorting: $sorting, paging: $paging) {
    nodes {
      ...suggestedVenue
    }
  }
}
    ${SuggestedVenueFragmentDoc}`;
export function useListSuggestedVenuesQuery(variables: import("@/graphql/types").ListSuggestedVenuesQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSuggestedVenuesQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSuggestedVenuesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSuggestedVenuesQuery, import("@/graphql/types").ListSuggestedVenuesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSuggestedVenuesQuery, import("@/graphql/types").ListSuggestedVenuesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSuggestedVenuesQuery, import("@/graphql/types").ListSuggestedVenuesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListSuggestedVenuesQuery, import("@/graphql/types").ListSuggestedVenuesQueryVariables>(ListSuggestedVenuesDocument, variables, options);
}
export function useListSuggestedVenuesLazyQuery(variables: import("@/graphql/types").ListSuggestedVenuesQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSuggestedVenuesQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSuggestedVenuesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSuggestedVenuesQuery, import("@/graphql/types").ListSuggestedVenuesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSuggestedVenuesQuery, import("@/graphql/types").ListSuggestedVenuesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSuggestedVenuesQuery, import("@/graphql/types").ListSuggestedVenuesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListSuggestedVenuesQuery, import("@/graphql/types").ListSuggestedVenuesQueryVariables>(ListSuggestedVenuesDocument, variables, options);
}
export type ListSuggestedVenuesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListSuggestedVenuesQuery, import("@/graphql/types").ListSuggestedVenuesQueryVariables>;
export const ListSessionsForSessionSelectDocument = gql`
    query ListSessionsForSessionSelect($filter: SessionFilter, $paging: OffsetPaging) {
  sessions(
    filter: $filter
    paging: $paging
    sorting: [{field: name, direction: ASC}]
  ) {
    nodes {
      ...sessionForSessionSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${SessionForSessionSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListSessionsForSessionSelectQuery(variables: import("@/graphql/types").ListSessionsForSessionSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionsForSessionSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionsForSessionSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSessionSelectQuery, import("@/graphql/types").ListSessionsForSessionSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSessionSelectQuery, import("@/graphql/types").ListSessionsForSessionSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSessionSelectQuery, import("@/graphql/types").ListSessionsForSessionSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListSessionsForSessionSelectQuery, import("@/graphql/types").ListSessionsForSessionSelectQueryVariables>(ListSessionsForSessionSelectDocument, variables, options);
}
export function useListSessionsForSessionSelectLazyQuery(variables: import("@/graphql/types").ListSessionsForSessionSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionsForSessionSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionsForSessionSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSessionSelectQuery, import("@/graphql/types").ListSessionsForSessionSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSessionSelectQuery, import("@/graphql/types").ListSessionsForSessionSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSessionSelectQuery, import("@/graphql/types").ListSessionsForSessionSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListSessionsForSessionSelectQuery, import("@/graphql/types").ListSessionsForSessionSelectQueryVariables>(ListSessionsForSessionSelectDocument, variables, options);
}
export type ListSessionsForSessionSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListSessionsForSessionSelectQuery, import("@/graphql/types").ListSessionsForSessionSelectQueryVariables>;
export const UpdateSessionTypeDocument = gql`
    mutation UpdateSessionType($id: ID!, $type: SessionType!) {
  updateSessionType(input: {id: $id, type: $type}) {
    id
    type
    name
    remote @client
    irl @client
    hybrid @client
    assignability
    assignments {
      id
      userId
      user {
        id
      }
      type
      updatedBy {
        id
        fullName
      }
      travel
      travelConfirmed
    }
    supportsSlots {
      customer
      staff
    }
    isLobbyEligible
    isApprovable
    locateable
    hasGroupDetails
    meta {
      gameControl
    }
    irl @client
  }
}
    `;
export function useUpdateSessionTypeMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionTypeMutation, import("@/graphql/types").UpdateSessionTypeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionTypeMutation, import("@/graphql/types").UpdateSessionTypeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionTypeMutation, import("@/graphql/types").UpdateSessionTypeMutationVariables>(UpdateSessionTypeDocument, options);
}
export type UpdateSessionTypeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionTypeMutation, import("@/graphql/types").UpdateSessionTypeMutationVariables>;
export const UpdateSessionSyncTypeDocument = gql`
    mutation UpdateSessionSyncType($id: ID!, $value: SyncType!) {
  updateSessionSyncType(input: {id: $id, value: $value}) {
    id
    templateId
    syncType
    pdfType
    vas
    assignability
    hasGroupDetails
    supportsSlots {
      customer
      staff
    }
    isLobbyEligible
    isCustomizable
    locateable
    meta {
      template
      gameControl
    }
    integrations {
      id
      active
    }
  }
}
    `;
export function useUpdateSessionSyncTypeMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionSyncTypeMutation, import("@/graphql/types").UpdateSessionSyncTypeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionSyncTypeMutation, import("@/graphql/types").UpdateSessionSyncTypeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionSyncTypeMutation, import("@/graphql/types").UpdateSessionSyncTypeMutationVariables>(UpdateSessionSyncTypeDocument, options);
}
export type UpdateSessionSyncTypeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionSyncTypeMutation, import("@/graphql/types").UpdateSessionSyncTypeMutationVariables>;
export const GetTemplateForTemplateSelectDocument = gql`
    query GetTemplateForTemplateSelect($id: ID!, $sync: SyncType!, $type: SessionTemplateType) {
  template(id: $id, sync: $sync, type: $type) {
    ...templateForTemplateSelect
  }
}
    ${TemplateForTemplateSelectFragmentDoc}`;
export function useGetTemplateForTemplateSelectQuery(variables: import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetTemplateForTemplateSelectQuery, import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetTemplateForTemplateSelectQuery, import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetTemplateForTemplateSelectQuery, import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetTemplateForTemplateSelectQuery, import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables>(GetTemplateForTemplateSelectDocument, variables, options);
}
export function useGetTemplateForTemplateSelectLazyQuery(variables: import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetTemplateForTemplateSelectQuery, import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetTemplateForTemplateSelectQuery, import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetTemplateForTemplateSelectQuery, import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetTemplateForTemplateSelectQuery, import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables>(GetTemplateForTemplateSelectDocument, variables, options);
}
export type GetTemplateForTemplateSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetTemplateForTemplateSelectQuery, import("@/graphql/types").GetTemplateForTemplateSelectQueryVariables>;
export const ListTemplatesForTemplateSelectDocument = gql`
    query ListTemplatesForTemplateSelect($sync: SyncType!, $type: SessionTemplateType, $search: String, $paging: OffsetPaging, $exclude: String, $lobby: Boolean, $mine: Boolean) {
  templates(
    sync: $sync
    type: $type
    filter: {search: $search, exclude: $exclude, lobby: $lobby, mine: $mine}
    paging: $paging
  ) {
    nodes {
      ...templateForTemplateSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${TemplateForTemplateSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListTemplatesForTemplateSelectQuery(variables: import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListTemplatesForTemplateSelectQuery, import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListTemplatesForTemplateSelectQuery, import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListTemplatesForTemplateSelectQuery, import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListTemplatesForTemplateSelectQuery, import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables>(ListTemplatesForTemplateSelectDocument, variables, options);
}
export function useListTemplatesForTemplateSelectLazyQuery(variables: import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListTemplatesForTemplateSelectQuery, import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListTemplatesForTemplateSelectQuery, import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListTemplatesForTemplateSelectQuery, import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListTemplatesForTemplateSelectQuery, import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables>(ListTemplatesForTemplateSelectDocument, variables, options);
}
export type ListTemplatesForTemplateSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListTemplatesForTemplateSelectQuery, import("@/graphql/types").ListTemplatesForTemplateSelectQueryVariables>;
export const ListUsersForUserMultiselectDocument = gql`
    query ListUsersForUserMultiselect($filter: UserFilter, $paging: OffsetPaging, $sorting: [UserSort!]) {
  users(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...userForUserMultiselect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${UserForUserMultiselectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListUsersForUserMultiselectQuery(variables: import("@/graphql/types").ListUsersForUserMultiselectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForUserMultiselectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForUserMultiselectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserMultiselectQuery, import("@/graphql/types").ListUsersForUserMultiselectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserMultiselectQuery, import("@/graphql/types").ListUsersForUserMultiselectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserMultiselectQuery, import("@/graphql/types").ListUsersForUserMultiselectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListUsersForUserMultiselectQuery, import("@/graphql/types").ListUsersForUserMultiselectQueryVariables>(ListUsersForUserMultiselectDocument, variables, options);
}
export function useListUsersForUserMultiselectLazyQuery(variables: import("@/graphql/types").ListUsersForUserMultiselectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForUserMultiselectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForUserMultiselectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserMultiselectQuery, import("@/graphql/types").ListUsersForUserMultiselectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserMultiselectQuery, import("@/graphql/types").ListUsersForUserMultiselectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserMultiselectQuery, import("@/graphql/types").ListUsersForUserMultiselectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListUsersForUserMultiselectQuery, import("@/graphql/types").ListUsersForUserMultiselectQueryVariables>(ListUsersForUserMultiselectDocument, variables, options);
}
export type ListUsersForUserMultiselectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListUsersForUserMultiselectQuery, import("@/graphql/types").ListUsersForUserMultiselectQueryVariables>;
export const GetUserForUserSelectDocument = gql`
    query GetUserForUserSelect($id: ID!) {
  user(id: $id) {
    ...userForUserSelect
  }
}
    ${UserForUserSelectFragmentDoc}`;
export function useGetUserForUserSelectQuery(variables: import("@/graphql/types").GetUserForUserSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetUserForUserSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetUserForUserSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserSelectQuery, import("@/graphql/types").GetUserForUserSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserSelectQuery, import("@/graphql/types").GetUserForUserSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserSelectQuery, import("@/graphql/types").GetUserForUserSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetUserForUserSelectQuery, import("@/graphql/types").GetUserForUserSelectQueryVariables>(GetUserForUserSelectDocument, variables, options);
}
export function useGetUserForUserSelectLazyQuery(variables: import("@/graphql/types").GetUserForUserSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetUserForUserSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetUserForUserSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserSelectQuery, import("@/graphql/types").GetUserForUserSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserSelectQuery, import("@/graphql/types").GetUserForUserSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserSelectQuery, import("@/graphql/types").GetUserForUserSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetUserForUserSelectQuery, import("@/graphql/types").GetUserForUserSelectQueryVariables>(GetUserForUserSelectDocument, variables, options);
}
export type GetUserForUserSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetUserForUserSelectQuery, import("@/graphql/types").GetUserForUserSelectQueryVariables>;
export const ListUsersForUserSelectDocument = gql`
    query ListUsersForUserSelect($filter: UserFilter, $paging: OffsetPaging) {
  users(
    filter: $filter
    paging: $paging
    sorting: [{field: fullName, direction: ASC}]
  ) {
    nodes {
      ...userForUserSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${UserForUserSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListUsersForUserSelectQuery(variables: import("@/graphql/types").ListUsersForUserSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForUserSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForUserSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserSelectQuery, import("@/graphql/types").ListUsersForUserSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserSelectQuery, import("@/graphql/types").ListUsersForUserSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserSelectQuery, import("@/graphql/types").ListUsersForUserSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListUsersForUserSelectQuery, import("@/graphql/types").ListUsersForUserSelectQueryVariables>(ListUsersForUserSelectDocument, variables, options);
}
export function useListUsersForUserSelectLazyQuery(variables: import("@/graphql/types").ListUsersForUserSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForUserSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForUserSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserSelectQuery, import("@/graphql/types").ListUsersForUserSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserSelectQuery, import("@/graphql/types").ListUsersForUserSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserSelectQuery, import("@/graphql/types").ListUsersForUserSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListUsersForUserSelectQuery, import("@/graphql/types").ListUsersForUserSelectQueryVariables>(ListUsersForUserSelectDocument, variables, options);
}
export type ListUsersForUserSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListUsersForUserSelectQuery, import("@/graphql/types").ListUsersForUserSelectQueryVariables>;
export const CreatePermissionForWorkspaceAssignerDocument = gql`
    mutation CreatePermissionForWorkspaceAssigner($input: CreatePermissionInput!) {
  createPermission(input: $input) {
    ...permissionForWorkspaceAssigner
  }
}
    ${PermissionForWorkspaceAssignerFragmentDoc}`;
export function useCreatePermissionForWorkspaceAssignerMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreatePermissionForWorkspaceAssignerMutation, import("@/graphql/types").CreatePermissionForWorkspaceAssignerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreatePermissionForWorkspaceAssignerMutation, import("@/graphql/types").CreatePermissionForWorkspaceAssignerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreatePermissionForWorkspaceAssignerMutation, import("@/graphql/types").CreatePermissionForWorkspaceAssignerMutationVariables>(CreatePermissionForWorkspaceAssignerDocument, options);
}
export type CreatePermissionForWorkspaceAssignerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreatePermissionForWorkspaceAssignerMutation, import("@/graphql/types").CreatePermissionForWorkspaceAssignerMutationVariables>;
export const DeletePermissionForWorkspaceAssignerDocument = gql`
    mutation DeletePermissionForWorkspaceAssigner($input: DeletePermissionInput!) {
  deletePermission(input: $input) {
    id
  }
}
    `;
export function useDeletePermissionForWorkspaceAssignerMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeletePermissionForWorkspaceAssignerMutation, import("@/graphql/types").DeletePermissionForWorkspaceAssignerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeletePermissionForWorkspaceAssignerMutation, import("@/graphql/types").DeletePermissionForWorkspaceAssignerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeletePermissionForWorkspaceAssignerMutation, import("@/graphql/types").DeletePermissionForWorkspaceAssignerMutationVariables>(DeletePermissionForWorkspaceAssignerDocument, options);
}
export type DeletePermissionForWorkspaceAssignerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeletePermissionForWorkspaceAssignerMutation, import("@/graphql/types").DeletePermissionForWorkspaceAssignerMutationVariables>;
export const ListPermissionsForWorkspaceAssignerDocument = gql`
    query ListPermissionsForWorkspaceAssigner($role: PermissionRole, $user: ID!) {
  permissions(filter: {role: {eq: $role}, userId: {eq: $user}}) {
    nodes {
      id
      workspaceId
    }
  }
}
    `;
export function useListPermissionsForWorkspaceAssignerQuery(variables: import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables> | ReactiveFunction<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQuery, import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQuery, import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQuery, import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQuery, import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables>(ListPermissionsForWorkspaceAssignerDocument, variables, options);
}
export function useListPermissionsForWorkspaceAssignerLazyQuery(variables: import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables> | ReactiveFunction<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQuery, import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQuery, import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQuery, import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQuery, import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables>(ListPermissionsForWorkspaceAssignerDocument, variables, options);
}
export type ListPermissionsForWorkspaceAssignerQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListPermissionsForWorkspaceAssignerQuery, import("@/graphql/types").ListPermissionsForWorkspaceAssignerQueryVariables>;
export const ListWorkspacesForWorkspaceMultiselectDocument = gql`
    query ListWorkspacesForWorkspaceMultiselect($filter: WorkspaceFilter, $paging: OffsetPaging, $sorting: [WorkspaceSort!]) {
  workspaces(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...workspaceForWorkspaceSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${WorkspaceForWorkspaceSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListWorkspacesForWorkspaceMultiselectQuery(variables: import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables>(ListWorkspacesForWorkspaceMultiselectDocument, variables, options);
}
export function useListWorkspacesForWorkspaceMultiselectLazyQuery(variables: import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables>(ListWorkspacesForWorkspaceMultiselectDocument, variables, options);
}
export type ListWorkspacesForWorkspaceMultiselectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceMultiselectQueryVariables>;
export const ListWorkspacesForWorkspaceSelectDocument = gql`
    query ListWorkspacesForWorkspaceSelect($filter: WorkspaceFilter, $paging: OffsetPaging, $sorting: [WorkspaceSort!]) {
  workspaces(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...workspaceForWorkspaceSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${WorkspaceForWorkspaceSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListWorkspacesForWorkspaceSelectQuery(variables: import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables>(ListWorkspacesForWorkspaceSelectDocument, variables, options);
}
export function useListWorkspacesForWorkspaceSelectLazyQuery(variables: import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables>(ListWorkspacesForWorkspaceSelectDocument, variables, options);
}
export type ListWorkspacesForWorkspaceSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListWorkspacesForWorkspaceSelectQuery, import("@/graphql/types").ListWorkspacesForWorkspaceSelectQueryVariables>;
export const GetZoneAreaForSelectDocument = gql`
    query getZoneAreaForSelect($id: ID!) {
  zoneArea(id: $id) {
    ...zoneAreaForSelect
  }
}
    ${ZoneAreaForSelectFragmentDoc}`;
export function useGetZoneAreaForSelectQuery(variables: import("@/graphql/types").GetZoneAreaForSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetZoneAreaForSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetZoneAreaForSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForSelectQuery, import("@/graphql/types").GetZoneAreaForSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForSelectQuery, import("@/graphql/types").GetZoneAreaForSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForSelectQuery, import("@/graphql/types").GetZoneAreaForSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetZoneAreaForSelectQuery, import("@/graphql/types").GetZoneAreaForSelectQueryVariables>(GetZoneAreaForSelectDocument, variables, options);
}
export function useGetZoneAreaForSelectLazyQuery(variables: import("@/graphql/types").GetZoneAreaForSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetZoneAreaForSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetZoneAreaForSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForSelectQuery, import("@/graphql/types").GetZoneAreaForSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForSelectQuery, import("@/graphql/types").GetZoneAreaForSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForSelectQuery, import("@/graphql/types").GetZoneAreaForSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetZoneAreaForSelectQuery, import("@/graphql/types").GetZoneAreaForSelectQueryVariables>(GetZoneAreaForSelectDocument, variables, options);
}
export type GetZoneAreaForSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetZoneAreaForSelectQuery, import("@/graphql/types").GetZoneAreaForSelectQueryVariables>;
export const ListZoneAreasForSelectDocument = gql`
    query listZoneAreasForSelect($paging: OffsetPaging) {
  zoneAreas(paging: $paging) {
    nodes {
      ...zoneAreaForSelect
    }
    pageInfo {
      hasNextPage
    }
  }
}
    ${ZoneAreaForSelectFragmentDoc}`;
export function useListZoneAreasForSelectQuery(variables: import("@/graphql/types").ListZoneAreasForSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListZoneAreasForSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListZoneAreasForSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasForSelectQuery, import("@/graphql/types").ListZoneAreasForSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasForSelectQuery, import("@/graphql/types").ListZoneAreasForSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasForSelectQuery, import("@/graphql/types").ListZoneAreasForSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListZoneAreasForSelectQuery, import("@/graphql/types").ListZoneAreasForSelectQueryVariables>(ListZoneAreasForSelectDocument, variables, options);
}
export function useListZoneAreasForSelectLazyQuery(variables: import("@/graphql/types").ListZoneAreasForSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListZoneAreasForSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListZoneAreasForSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasForSelectQuery, import("@/graphql/types").ListZoneAreasForSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasForSelectQuery, import("@/graphql/types").ListZoneAreasForSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasForSelectQuery, import("@/graphql/types").ListZoneAreasForSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListZoneAreasForSelectQuery, import("@/graphql/types").ListZoneAreasForSelectQueryVariables>(ListZoneAreasForSelectDocument, variables, options);
}
export type ListZoneAreasForSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListZoneAreasForSelectQuery, import("@/graphql/types").ListZoneAreasForSelectQueryVariables>;
export const CreateMissionDocument = gql`
    mutation CreateMission($input: CreateMissionInput!) {
  createMission(input: $input) {
    ...mission
  }
}
    ${MissionFragmentDoc}`;
export function useCreateMissionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateMissionMutation, import("@/graphql/types").CreateMissionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateMissionMutation, import("@/graphql/types").CreateMissionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateMissionMutation, import("@/graphql/types").CreateMissionMutationVariables>(CreateMissionDocument, options);
}
export type CreateMissionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateMissionMutation, import("@/graphql/types").CreateMissionMutationVariables>;
export const CreateMissionSetDocument = gql`
    mutation CreateMissionSet($input: CreateOneMissionSetInput!) {
  createMissionSet(input: $input) {
    ...missionSet
  }
}
    ${MissionSetFragmentDoc}`;
export function useCreateMissionSetMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateMissionSetMutation, import("@/graphql/types").CreateMissionSetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateMissionSetMutation, import("@/graphql/types").CreateMissionSetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateMissionSetMutation, import("@/graphql/types").CreateMissionSetMutationVariables>(CreateMissionSetDocument, options);
}
export type CreateMissionSetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateMissionSetMutation, import("@/graphql/types").CreateMissionSetMutationVariables>;
export const DeleteMissionDocument = gql`
    mutation DeleteMission($input: DeleteOneMissionInput!) {
  deleteMission(input: $input) {
    id
  }
}
    `;
export function useDeleteMissionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteMissionMutation, import("@/graphql/types").DeleteMissionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteMissionMutation, import("@/graphql/types").DeleteMissionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteMissionMutation, import("@/graphql/types").DeleteMissionMutationVariables>(DeleteMissionDocument, options);
}
export type DeleteMissionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteMissionMutation, import("@/graphql/types").DeleteMissionMutationVariables>;
export const DeleteMissionSetDocument = gql`
    mutation DeleteMissionSet($input: DeleteOneMissionSetInput!) {
  deleteMissionSet(input: $input) {
    id
  }
}
    `;
export function useDeleteMissionSetMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteMissionSetMutation, import("@/graphql/types").DeleteMissionSetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteMissionSetMutation, import("@/graphql/types").DeleteMissionSetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteMissionSetMutation, import("@/graphql/types").DeleteMissionSetMutationVariables>(DeleteMissionSetDocument, options);
}
export type DeleteMissionSetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteMissionSetMutation, import("@/graphql/types").DeleteMissionSetMutationVariables>;
export const DeleteSessionDocument = gql`
    mutation DeleteSession($input: DeleteOneSessionInput!) {
  deleteSession(input: $input) {
    id
  }
}
    `;
export function useDeleteSessionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteSessionMutation, import("@/graphql/types").DeleteSessionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteSessionMutation, import("@/graphql/types").DeleteSessionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteSessionMutation, import("@/graphql/types").DeleteSessionMutationVariables>(DeleteSessionDocument, options);
}
export type DeleteSessionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteSessionMutation, import("@/graphql/types").DeleteSessionMutationVariables>;
export const DeleteSystemDocument = gql`
    mutation DeleteSystem($input: DeleteSystemInput!) {
  deleteSystem(input: $input) {
    key
  }
}
    `;
export function useDeleteSystemMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteSystemMutation, import("@/graphql/types").DeleteSystemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteSystemMutation, import("@/graphql/types").DeleteSystemMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteSystemMutation, import("@/graphql/types").DeleteSystemMutationVariables>(DeleteSystemDocument, options);
}
export type DeleteSystemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteSystemMutation, import("@/graphql/types").DeleteSystemMutationVariables>;
export const SetSystemDocument = gql`
    mutation SetSystem($input: SetSystemInput!) {
  setSystem(input: $input) {
    ...system
  }
}
    ${SystemFragmentDoc}`;
export function useSetSystemMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetSystemMutation, import("@/graphql/types").SetSystemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetSystemMutation, import("@/graphql/types").SetSystemMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").SetSystemMutation, import("@/graphql/types").SetSystemMutationVariables>(SetSystemDocument, options);
}
export type SetSystemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").SetSystemMutation, import("@/graphql/types").SetSystemMutationVariables>;
export const UpdateMissionDocument = gql`
    mutation UpdateMission($input: UpdateOneMissionInput!) {
  updateMission(input: $input) {
    ...mission
  }
}
    ${MissionFragmentDoc}`;
export function useUpdateMissionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateMissionMutation, import("@/graphql/types").UpdateMissionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateMissionMutation, import("@/graphql/types").UpdateMissionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateMissionMutation, import("@/graphql/types").UpdateMissionMutationVariables>(UpdateMissionDocument, options);
}
export type UpdateMissionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateMissionMutation, import("@/graphql/types").UpdateMissionMutationVariables>;
export const UpdateMissionPositionDocument = gql`
    mutation UpdateMissionPosition($id: ID!, $position: Float!) {
  updateMission(input: {id: $id, update: {position: $position}}) {
    id
    position
  }
}
    `;
export function useUpdateMissionPositionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateMissionPositionMutation, import("@/graphql/types").UpdateMissionPositionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateMissionPositionMutation, import("@/graphql/types").UpdateMissionPositionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateMissionPositionMutation, import("@/graphql/types").UpdateMissionPositionMutationVariables>(UpdateMissionPositionDocument, options);
}
export type UpdateMissionPositionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateMissionPositionMutation, import("@/graphql/types").UpdateMissionPositionMutationVariables>;
export const UpdateSessionDocument = gql`
    mutation UpdateSession($input: UpdateSessionInput!) {
  updateSession(input: $input) {
    ...session
  }
}
    ${SessionFragmentDoc}`;
export function useUpdateSessionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionMutation, import("@/graphql/types").UpdateSessionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionMutation, import("@/graphql/types").UpdateSessionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionMutation, import("@/graphql/types").UpdateSessionMutationVariables>(UpdateSessionDocument, options);
}
export type UpdateSessionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionMutation, import("@/graphql/types").UpdateSessionMutationVariables>;
export const GetWorkspaceWithConsumablesDocument = gql`
    query GetWorkspaceWithConsumables($id: ID!) {
  workspace(id: $id) {
    ...workspaceWithConsumables
  }
}
    ${WorkspaceWithConsumablesFragmentDoc}`;
export function useGetWorkspaceWithConsumablesQuery(variables: import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceWithConsumablesQuery, import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceWithConsumablesQuery, import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceWithConsumablesQuery, import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetWorkspaceWithConsumablesQuery, import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables>(GetWorkspaceWithConsumablesDocument, variables, options);
}
export function useGetWorkspaceWithConsumablesLazyQuery(variables: import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceWithConsumablesQuery, import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceWithConsumablesQuery, import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceWithConsumablesQuery, import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetWorkspaceWithConsumablesQuery, import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables>(GetWorkspaceWithConsumablesDocument, variables, options);
}
export type GetWorkspaceWithConsumablesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetWorkspaceWithConsumablesQuery, import("@/graphql/types").GetWorkspaceWithConsumablesQueryVariables>;
export const ListSessionsForSearchDocument = gql`
    query ListSessionsForSearch($filter: SessionFilter, $paging: OffsetPaging, $sorting: [SessionSort!] = [{field: startsAt, direction: ASC}]) {
  sessions(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...sessionForSearch
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${SessionForSearchFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListSessionsForSearchQuery(variables: import("@/graphql/types").ListSessionsForSearchQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionsForSearchQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionsForSearchQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSearchQuery, import("@/graphql/types").ListSessionsForSearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSearchQuery, import("@/graphql/types").ListSessionsForSearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSearchQuery, import("@/graphql/types").ListSessionsForSearchQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListSessionsForSearchQuery, import("@/graphql/types").ListSessionsForSearchQueryVariables>(ListSessionsForSearchDocument, variables, options);
}
export function useListSessionsForSearchLazyQuery(variables: import("@/graphql/types").ListSessionsForSearchQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionsForSearchQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionsForSearchQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSearchQuery, import("@/graphql/types").ListSessionsForSearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSearchQuery, import("@/graphql/types").ListSessionsForSearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForSearchQuery, import("@/graphql/types").ListSessionsForSearchQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListSessionsForSearchQuery, import("@/graphql/types").ListSessionsForSearchQueryVariables>(ListSessionsForSearchDocument, variables, options);
}
export type ListSessionsForSearchQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListSessionsForSearchQuery, import("@/graphql/types").ListSessionsForSearchQueryVariables>;
export const GetExperienceFormatForExperienceFormatSelectDocument = gql`
    query GetExperienceFormatForExperienceFormatSelect($id: ID!) {
  experienceFormat(id: $id) {
    ...experienceFormatForExperienceFormatSelect
  }
}
    ${ExperienceFormatForExperienceFormatSelectFragmentDoc}`;
export function useGetExperienceFormatForExperienceFormatSelectQuery(variables: import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQuery, import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQuery, import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQuery, import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQuery, import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables>(GetExperienceFormatForExperienceFormatSelectDocument, variables, options);
}
export function useGetExperienceFormatForExperienceFormatSelectLazyQuery(variables: import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQuery, import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQuery, import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQuery, import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQuery, import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables>(GetExperienceFormatForExperienceFormatSelectDocument, variables, options);
}
export type GetExperienceFormatForExperienceFormatSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQuery, import("@/graphql/types").GetExperienceFormatForExperienceFormatSelectQueryVariables>;
export const ListExperienceFormatsForExperienceFormatSelectDocument = gql`
    query ListExperienceFormatsForExperienceFormatSelect($filter: ExperienceFormatFilter, $paging: OffsetPaging) {
  experienceFormats(filter: $filter, paging: $paging) {
    nodes {
      ...experienceFormatForExperienceFormatSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${ExperienceFormatForExperienceFormatSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListExperienceFormatsForExperienceFormatSelectQuery(variables: import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQuery, import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQuery, import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQuery, import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQuery, import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables>(ListExperienceFormatsForExperienceFormatSelectDocument, variables, options);
}
export function useListExperienceFormatsForExperienceFormatSelectLazyQuery(variables: import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQuery, import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQuery, import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQuery, import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQuery, import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables>(ListExperienceFormatsForExperienceFormatSelectDocument, variables, options);
}
export type ListExperienceFormatsForExperienceFormatSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQuery, import("@/graphql/types").ListExperienceFormatsForExperienceFormatSelectQueryVariables>;
export const GetExperienceFormatWithModifiersDocument = gql`
    query GetExperienceFormatWithModifiers($id: ID!) {
  experienceFormat(id: $id) {
    ...experienceFormatWithModifiers
  }
}
    ${ExperienceFormatWithModifiersFragmentDoc}`;
export function useGetExperienceFormatWithModifiersQuery(variables: import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables> | ReactiveFunction<import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatWithModifiersQuery, import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatWithModifiersQuery, import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatWithModifiersQuery, import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetExperienceFormatWithModifiersQuery, import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables>(GetExperienceFormatWithModifiersDocument, variables, options);
}
export function useGetExperienceFormatWithModifiersLazyQuery(variables: import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables> | ReactiveFunction<import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatWithModifiersQuery, import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatWithModifiersQuery, import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceFormatWithModifiersQuery, import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetExperienceFormatWithModifiersQuery, import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables>(GetExperienceFormatWithModifiersDocument, variables, options);
}
export type GetExperienceFormatWithModifiersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetExperienceFormatWithModifiersQuery, import("@/graphql/types").GetExperienceFormatWithModifiersQueryVariables>;
export const SearchModifiersDocument = gql`
    query SearchModifiers($lables: [String!]!) {
  modifiers(filter: {label: {in: $lables}}) {
    nodes {
      ...modifierForSearch
    }
  }
}
    ${ModifierForSearchFragmentDoc}`;
export function useSearchModifiersQuery(variables: import("@/graphql/types").SearchModifiersQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").SearchModifiersQueryVariables> | ReactiveFunction<import("@/graphql/types").SearchModifiersQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").SearchModifiersQuery, import("@/graphql/types").SearchModifiersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").SearchModifiersQuery, import("@/graphql/types").SearchModifiersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").SearchModifiersQuery, import("@/graphql/types").SearchModifiersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").SearchModifiersQuery, import("@/graphql/types").SearchModifiersQueryVariables>(SearchModifiersDocument, variables, options);
}
export function useSearchModifiersLazyQuery(variables: import("@/graphql/types").SearchModifiersQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").SearchModifiersQueryVariables> | ReactiveFunction<import("@/graphql/types").SearchModifiersQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").SearchModifiersQuery, import("@/graphql/types").SearchModifiersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").SearchModifiersQuery, import("@/graphql/types").SearchModifiersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").SearchModifiersQuery, import("@/graphql/types").SearchModifiersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").SearchModifiersQuery, import("@/graphql/types").SearchModifiersQueryVariables>(SearchModifiersDocument, variables, options);
}
export type SearchModifiersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").SearchModifiersQuery, import("@/graphql/types").SearchModifiersQueryVariables>;
export const UpdateSessionAssignmentWithTravelDocument = gql`
    mutation UpdateSessionAssignmentWithTravel($input: UpdateSessionAssignmentInput!) {
  updateSessionAssignment(input: $input) {
    ...sessionAssignmentWithTravel
  }
}
    ${SessionAssignmentWithTravelFragmentDoc}`;
export function useUpdateSessionAssignmentWithTravelMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionAssignmentWithTravelMutation, import("@/graphql/types").UpdateSessionAssignmentWithTravelMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionAssignmentWithTravelMutation, import("@/graphql/types").UpdateSessionAssignmentWithTravelMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionAssignmentWithTravelMutation, import("@/graphql/types").UpdateSessionAssignmentWithTravelMutationVariables>(UpdateSessionAssignmentWithTravelDocument, options);
}
export type UpdateSessionAssignmentWithTravelMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionAssignmentWithTravelMutation, import("@/graphql/types").UpdateSessionAssignmentWithTravelMutationVariables>;
export const UpdateSessionAssignmentForStaffDocument = gql`
    mutation UpdateSessionAssignmentForStaff($input: UpdateSessionAssignmentInput!) {
  updateSessionAssignment(input: $input) {
    ...sessionAssignmentForStaffAssignment
  }
}
    ${SessionAssignmentForStaffAssignmentFragmentDoc}`;
export function useUpdateSessionAssignmentForStaffMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionAssignmentForStaffMutation, import("@/graphql/types").UpdateSessionAssignmentForStaffMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionAssignmentForStaffMutation, import("@/graphql/types").UpdateSessionAssignmentForStaffMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionAssignmentForStaffMutation, import("@/graphql/types").UpdateSessionAssignmentForStaffMutationVariables>(UpdateSessionAssignmentForStaffDocument, options);
}
export type UpdateSessionAssignmentForStaffMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionAssignmentForStaffMutation, import("@/graphql/types").UpdateSessionAssignmentForStaffMutationVariables>;
export const UpdateSessionAssignmentTravelConfirmationStatusDocument = gql`
    mutation UpdateSessionAssignmentTravelConfirmationStatus($input: UpdateSessionAssignmentTravelConfirmationStatusInput!) {
  updateSessionAssignmentTravelConfirmationStatus(input: $input) {
    id
    travelConfirmed
  }
}
    `;
export function useUpdateSessionAssignmentTravelConfirmationStatusMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionAssignmentTravelConfirmationStatusMutation, import("@/graphql/types").UpdateSessionAssignmentTravelConfirmationStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionAssignmentTravelConfirmationStatusMutation, import("@/graphql/types").UpdateSessionAssignmentTravelConfirmationStatusMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionAssignmentTravelConfirmationStatusMutation, import("@/graphql/types").UpdateSessionAssignmentTravelConfirmationStatusMutationVariables>(UpdateSessionAssignmentTravelConfirmationStatusDocument, options);
}
export type UpdateSessionAssignmentTravelConfirmationStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionAssignmentTravelConfirmationStatusMutation, import("@/graphql/types").UpdateSessionAssignmentTravelConfirmationStatusMutationVariables>;
export const GetUserForSessionTemplateSelectDocument = gql`
    query GetUserForSessionTemplateSelect {
  me {
    ...userForSessionTemplateSelect
  }
}
    ${UserForSessionTemplateSelectFragmentDoc}`;
export function useGetUserForSessionTemplateSelectQuery(options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionTemplateSelectQuery, import("@/graphql/types").GetUserForSessionTemplateSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionTemplateSelectQuery, import("@/graphql/types").GetUserForSessionTemplateSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionTemplateSelectQuery, import("@/graphql/types").GetUserForSessionTemplateSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetUserForSessionTemplateSelectQuery, import("@/graphql/types").GetUserForSessionTemplateSelectQueryVariables>(GetUserForSessionTemplateSelectDocument, {}, options);
}
export function useGetUserForSessionTemplateSelectLazyQuery(options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionTemplateSelectQuery, import("@/graphql/types").GetUserForSessionTemplateSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionTemplateSelectQuery, import("@/graphql/types").GetUserForSessionTemplateSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionTemplateSelectQuery, import("@/graphql/types").GetUserForSessionTemplateSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetUserForSessionTemplateSelectQuery, import("@/graphql/types").GetUserForSessionTemplateSelectQueryVariables>(GetUserForSessionTemplateSelectDocument, {}, options);
}
export type GetUserForSessionTemplateSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetUserForSessionTemplateSelectQuery, import("@/graphql/types").GetUserForSessionTemplateSelectQueryVariables>;
export const GetSessionWithAssignmentsDocument = gql`
    query GetSessionWithAssignments($id: ID!) {
  session(id: $id) {
    id
    assignments {
      ...sessionAssignmentForStaffAssignment
    }
  }
}
    ${SessionAssignmentForStaffAssignmentFragmentDoc}`;
export function useGetSessionWithAssignmentsQuery(variables: import("@/graphql/types").GetSessionWithAssignmentsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionWithAssignmentsQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionWithAssignmentsQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithAssignmentsQuery, import("@/graphql/types").GetSessionWithAssignmentsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithAssignmentsQuery, import("@/graphql/types").GetSessionWithAssignmentsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithAssignmentsQuery, import("@/graphql/types").GetSessionWithAssignmentsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionWithAssignmentsQuery, import("@/graphql/types").GetSessionWithAssignmentsQueryVariables>(GetSessionWithAssignmentsDocument, variables, options);
}
export function useGetSessionWithAssignmentsLazyQuery(variables: import("@/graphql/types").GetSessionWithAssignmentsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionWithAssignmentsQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionWithAssignmentsQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithAssignmentsQuery, import("@/graphql/types").GetSessionWithAssignmentsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithAssignmentsQuery, import("@/graphql/types").GetSessionWithAssignmentsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithAssignmentsQuery, import("@/graphql/types").GetSessionWithAssignmentsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionWithAssignmentsQuery, import("@/graphql/types").GetSessionWithAssignmentsQueryVariables>(GetSessionWithAssignmentsDocument, variables, options);
}
export type GetSessionWithAssignmentsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionWithAssignmentsQuery, import("@/graphql/types").GetSessionWithAssignmentsQueryVariables>;
export const GetSessionWithIntegrationsDocument = gql`
    query GetSessionWithIntegrations($id: ID!) {
  session(id: $id) {
    id
    integrations {
      ...sessionIntegration
    }
  }
}
    ${SessionIntegrationFragmentDoc}`;
export function useGetSessionWithIntegrationsQuery(variables: import("@/graphql/types").GetSessionWithIntegrationsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionWithIntegrationsQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionWithIntegrationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithIntegrationsQuery, import("@/graphql/types").GetSessionWithIntegrationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithIntegrationsQuery, import("@/graphql/types").GetSessionWithIntegrationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithIntegrationsQuery, import("@/graphql/types").GetSessionWithIntegrationsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionWithIntegrationsQuery, import("@/graphql/types").GetSessionWithIntegrationsQueryVariables>(GetSessionWithIntegrationsDocument, variables, options);
}
export function useGetSessionWithIntegrationsLazyQuery(variables: import("@/graphql/types").GetSessionWithIntegrationsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionWithIntegrationsQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionWithIntegrationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithIntegrationsQuery, import("@/graphql/types").GetSessionWithIntegrationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithIntegrationsQuery, import("@/graphql/types").GetSessionWithIntegrationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithIntegrationsQuery, import("@/graphql/types").GetSessionWithIntegrationsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionWithIntegrationsQuery, import("@/graphql/types").GetSessionWithIntegrationsQueryVariables>(GetSessionWithIntegrationsDocument, variables, options);
}
export type GetSessionWithIntegrationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionWithIntegrationsQuery, import("@/graphql/types").GetSessionWithIntegrationsQueryVariables>;
export const UpdateSessionImageDocument = gql`
    mutation UpdateSessionImage($id: ID!, $imageId: ID!) {
  updateSession(input: {id: $id, update: {imageId: $imageId}}) {
    id
    preview
  }
}
    `;
export function useUpdateSessionImageMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionImageMutation, import("@/graphql/types").UpdateSessionImageMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionImageMutation, import("@/graphql/types").UpdateSessionImageMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionImageMutation, import("@/graphql/types").UpdateSessionImageMutationVariables>(UpdateSessionImageDocument, options);
}
export type UpdateSessionImageMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionImageMutation, import("@/graphql/types").UpdateSessionImageMutationVariables>;
export const CreateUserAudioDocument = gql`
    mutation CreateUserAudio($input: CreateUserAudioInput!) {
  createUserAudio(input: $input) {
    ...userAudio
  }
}
    ${UserAudioFragmentDoc}`;
export function useCreateUserAudioMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateUserAudioMutation, import("@/graphql/types").CreateUserAudioMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateUserAudioMutation, import("@/graphql/types").CreateUserAudioMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateUserAudioMutation, import("@/graphql/types").CreateUserAudioMutationVariables>(CreateUserAudioDocument, options);
}
export type CreateUserAudioMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateUserAudioMutation, import("@/graphql/types").CreateUserAudioMutationVariables>;
export const DeleteUserAudioDocument = gql`
    mutation DeleteUserAudio($id: ID!) {
  deleteUserAudio(id: $id)
}
    `;
export function useDeleteUserAudioMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteUserAudioMutation, import("@/graphql/types").DeleteUserAudioMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteUserAudioMutation, import("@/graphql/types").DeleteUserAudioMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteUserAudioMutation, import("@/graphql/types").DeleteUserAudioMutationVariables>(DeleteUserAudioDocument, options);
}
export type DeleteUserAudioMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteUserAudioMutation, import("@/graphql/types").DeleteUserAudioMutationVariables>;
export const ListUserAudioDocument = gql`
    query ListUserAudio($paging: OffsetPaging) {
  userAudios(paging: $paging) {
    nodes {
      ...userAudio
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${UserAudioFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListUserAudioQuery(variables: import("@/graphql/types").ListUserAudioQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUserAudioQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUserAudioQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserAudioQuery, import("@/graphql/types").ListUserAudioQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserAudioQuery, import("@/graphql/types").ListUserAudioQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserAudioQuery, import("@/graphql/types").ListUserAudioQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListUserAudioQuery, import("@/graphql/types").ListUserAudioQueryVariables>(ListUserAudioDocument, variables, options);
}
export function useListUserAudioLazyQuery(variables: import("@/graphql/types").ListUserAudioQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUserAudioQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUserAudioQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserAudioQuery, import("@/graphql/types").ListUserAudioQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserAudioQuery, import("@/graphql/types").ListUserAudioQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUserAudioQuery, import("@/graphql/types").ListUserAudioQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListUserAudioQuery, import("@/graphql/types").ListUserAudioQueryVariables>(ListUserAudioDocument, variables, options);
}
export type ListUserAudioQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListUserAudioQuery, import("@/graphql/types").ListUserAudioQueryVariables>;
export const UpdateUserAudioDocument = gql`
    mutation UpdateUserAudio($id: ID!, $input: UpdateUserAudioInput!) {
  updateUserAudio(id: $id, input: $input) {
    ...userAudio
  }
}
    ${UserAudioFragmentDoc}`;
export function useUpdateUserAudioMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateUserAudioMutation, import("@/graphql/types").UpdateUserAudioMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateUserAudioMutation, import("@/graphql/types").UpdateUserAudioMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateUserAudioMutation, import("@/graphql/types").UpdateUserAudioMutationVariables>(UpdateUserAudioDocument, options);
}
export type UpdateUserAudioMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateUserAudioMutation, import("@/graphql/types").UpdateUserAudioMutationVariables>;
export const GetWorkspaceForStoreDocument = gql`
    query GetWorkspaceForStore($id: ID!) {
  workspace(id: $id) {
    ...workspaceForStore
  }
}
    ${WorkspaceForStoreFragmentDoc}`;
export function useGetWorkspaceForStoreQuery(variables: import("@/graphql/types").GetWorkspaceForStoreQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceForStoreQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceForStoreQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForStoreQuery, import("@/graphql/types").GetWorkspaceForStoreQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForStoreQuery, import("@/graphql/types").GetWorkspaceForStoreQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForStoreQuery, import("@/graphql/types").GetWorkspaceForStoreQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetWorkspaceForStoreQuery, import("@/graphql/types").GetWorkspaceForStoreQueryVariables>(GetWorkspaceForStoreDocument, variables, options);
}
export function useGetWorkspaceForStoreLazyQuery(variables: import("@/graphql/types").GetWorkspaceForStoreQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceForStoreQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceForStoreQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForStoreQuery, import("@/graphql/types").GetWorkspaceForStoreQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForStoreQuery, import("@/graphql/types").GetWorkspaceForStoreQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForStoreQuery, import("@/graphql/types").GetWorkspaceForStoreQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetWorkspaceForStoreQuery, import("@/graphql/types").GetWorkspaceForStoreQueryVariables>(GetWorkspaceForStoreDocument, variables, options);
}
export type GetWorkspaceForStoreQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetWorkspaceForStoreQuery, import("@/graphql/types").GetWorkspaceForStoreQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...userForStore
  }
}
    ${UserForStoreFragmentDoc}`;
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeQuery, import("@/graphql/types").MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeQuery, import("@/graphql/types").MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeQuery, import("@/graphql/types").MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").MeQuery, import("@/graphql/types").MeQueryVariables>(MeDocument, {}, options);
}
export function useMeLazyQuery(options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeQuery, import("@/graphql/types").MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeQuery, import("@/graphql/types").MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeQuery, import("@/graphql/types").MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").MeQuery, import("@/graphql/types").MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").MeQuery, import("@/graphql/types").MeQueryVariables>;
export const UpdateUserForStoreDocument = gql`
    mutation UpdateUserForStore($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    firstName
    lastName
    fullName
    phone
    meetingLink
    attributes {
      email
    }
  }
}
    `;
export function useUpdateUserForStoreMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateUserForStoreMutation, import("@/graphql/types").UpdateUserForStoreMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateUserForStoreMutation, import("@/graphql/types").UpdateUserForStoreMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateUserForStoreMutation, import("@/graphql/types").UpdateUserForStoreMutationVariables>(UpdateUserForStoreDocument, options);
}
export type UpdateUserForStoreMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateUserForStoreMutation, import("@/graphql/types").UpdateUserForStoreMutationVariables>;
export const GetSystemDocument = gql`
    query GetSystem($key: SystemKey!) {
  system(key: $key) {
    ...system
  }
}
    ${SystemFragmentDoc}`;
export function useGetSystemQuery(variables: import("@/graphql/types").GetSystemQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSystemQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSystemQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSystemQuery, import("@/graphql/types").GetSystemQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSystemQuery, import("@/graphql/types").GetSystemQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSystemQuery, import("@/graphql/types").GetSystemQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSystemQuery, import("@/graphql/types").GetSystemQueryVariables>(GetSystemDocument, variables, options);
}
export function useGetSystemLazyQuery(variables: import("@/graphql/types").GetSystemQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSystemQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSystemQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSystemQuery, import("@/graphql/types").GetSystemQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSystemQuery, import("@/graphql/types").GetSystemQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSystemQuery, import("@/graphql/types").GetSystemQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSystemQuery, import("@/graphql/types").GetSystemQueryVariables>(GetSystemDocument, variables, options);
}
export type GetSystemQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSystemQuery, import("@/graphql/types").GetSystemQueryVariables>;
export const GetSessionForShiftPlanningSessionDocument = gql`
    query GetSessionForShiftPlanningSession($id: ID!) {
  session(id: $id) {
    ...sessionForShiftPlanningSession
  }
}
    ${SessionForShiftPlanningSessionFragmentDoc}`;
export function useGetSessionForShiftPlanningSessionQuery(variables: import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShiftPlanningSessionQuery, import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShiftPlanningSessionQuery, import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShiftPlanningSessionQuery, import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionForShiftPlanningSessionQuery, import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables>(GetSessionForShiftPlanningSessionDocument, variables, options);
}
export function useGetSessionForShiftPlanningSessionLazyQuery(variables: import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShiftPlanningSessionQuery, import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShiftPlanningSessionQuery, import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShiftPlanningSessionQuery, import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionForShiftPlanningSessionQuery, import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables>(GetSessionForShiftPlanningSessionDocument, variables, options);
}
export type GetSessionForShiftPlanningSessionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionForShiftPlanningSessionQuery, import("@/graphql/types").GetSessionForShiftPlanningSessionQueryVariables>;
export const CreateAvailabilitiesDocument = gql`
    mutation CreateAvailabilities($input: [CreateAvailabilityInput!]!) {
  createAvailabilities(input: $input) {
    ...availability
  }
}
    ${AvailabilityFragmentDoc}`;
export function useCreateAvailabilitiesMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateAvailabilitiesMutation, import("@/graphql/types").CreateAvailabilitiesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateAvailabilitiesMutation, import("@/graphql/types").CreateAvailabilitiesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateAvailabilitiesMutation, import("@/graphql/types").CreateAvailabilitiesMutationVariables>(CreateAvailabilitiesDocument, options);
}
export type CreateAvailabilitiesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateAvailabilitiesMutation, import("@/graphql/types").CreateAvailabilitiesMutationVariables>;
export const CreateGlobalUnavailabilitiesDocument = gql`
    mutation CreateGlobalUnavailabilities($input: CreateManyGlobalUnavailabilitiesInput!) {
  createGlobalUnavailabilities(input: $input) {
    ...globalUnavailability
  }
}
    ${GlobalUnavailabilityFragmentDoc}`;
export function useCreateGlobalUnavailabilitiesMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateGlobalUnavailabilitiesMutation, import("@/graphql/types").CreateGlobalUnavailabilitiesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateGlobalUnavailabilitiesMutation, import("@/graphql/types").CreateGlobalUnavailabilitiesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateGlobalUnavailabilitiesMutation, import("@/graphql/types").CreateGlobalUnavailabilitiesMutationVariables>(CreateGlobalUnavailabilitiesDocument, options);
}
export type CreateGlobalUnavailabilitiesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateGlobalUnavailabilitiesMutation, import("@/graphql/types").CreateGlobalUnavailabilitiesMutationVariables>;
export const DeleteAvailabilitiesDocument = gql`
    mutation DeleteAvailabilities($ids: [ID!]!) {
  deleteAvailabilities(ids: $ids) {
    id
    success
  }
}
    `;
export function useDeleteAvailabilitiesMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteAvailabilitiesMutation, import("@/graphql/types").DeleteAvailabilitiesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteAvailabilitiesMutation, import("@/graphql/types").DeleteAvailabilitiesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteAvailabilitiesMutation, import("@/graphql/types").DeleteAvailabilitiesMutationVariables>(DeleteAvailabilitiesDocument, options);
}
export type DeleteAvailabilitiesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteAvailabilitiesMutation, import("@/graphql/types").DeleteAvailabilitiesMutationVariables>;
export const DeleteGlobalUnavailabilityDocument = gql`
    mutation DeleteGlobalUnavailability($input: DeleteOneGlobalUnavailabilityInput!) {
  deleteGlobalUnavailability(input: $input) {
    id
  }
}
    `;
export function useDeleteGlobalUnavailabilityMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteGlobalUnavailabilityMutation, import("@/graphql/types").DeleteGlobalUnavailabilityMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteGlobalUnavailabilityMutation, import("@/graphql/types").DeleteGlobalUnavailabilityMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteGlobalUnavailabilityMutation, import("@/graphql/types").DeleteGlobalUnavailabilityMutationVariables>(DeleteGlobalUnavailabilityDocument, options);
}
export type DeleteGlobalUnavailabilityMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteGlobalUnavailabilityMutation, import("@/graphql/types").DeleteGlobalUnavailabilityMutationVariables>;
export const GetExperienceForAvailabilityEventDocument = gql`
    query GetExperienceForAvailabilityEvent($id: ID!) {
  experience(id: $id) {
    ...experienceForAvailabilityEvent
  }
}
    ${ExperienceForAvailabilityEventFragmentDoc}`;
export function useGetExperienceForAvailabilityEventQuery(variables: import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables> | ReactiveFunction<import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForAvailabilityEventQuery, import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForAvailabilityEventQuery, import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForAvailabilityEventQuery, import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetExperienceForAvailabilityEventQuery, import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables>(GetExperienceForAvailabilityEventDocument, variables, options);
}
export function useGetExperienceForAvailabilityEventLazyQuery(variables: import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables> | ReactiveFunction<import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForAvailabilityEventQuery, import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForAvailabilityEventQuery, import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForAvailabilityEventQuery, import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetExperienceForAvailabilityEventQuery, import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables>(GetExperienceForAvailabilityEventDocument, variables, options);
}
export type GetExperienceForAvailabilityEventQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetExperienceForAvailabilityEventQuery, import("@/graphql/types").GetExperienceForAvailabilityEventQueryVariables>;
export const GetUserForAvailabilityDocument = gql`
    query GetUserForAvailability($id: ID!) {
  user(id: $id) {
    ...userForAvailability
  }
}
    ${UserForAvailabilityFragmentDoc}`;
export function useGetUserForAvailabilityQuery(variables: import("@/graphql/types").GetUserForAvailabilityQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetUserForAvailabilityQueryVariables> | ReactiveFunction<import("@/graphql/types").GetUserForAvailabilityQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForAvailabilityQuery, import("@/graphql/types").GetUserForAvailabilityQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForAvailabilityQuery, import("@/graphql/types").GetUserForAvailabilityQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForAvailabilityQuery, import("@/graphql/types").GetUserForAvailabilityQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetUserForAvailabilityQuery, import("@/graphql/types").GetUserForAvailabilityQueryVariables>(GetUserForAvailabilityDocument, variables, options);
}
export function useGetUserForAvailabilityLazyQuery(variables: import("@/graphql/types").GetUserForAvailabilityQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetUserForAvailabilityQueryVariables> | ReactiveFunction<import("@/graphql/types").GetUserForAvailabilityQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForAvailabilityQuery, import("@/graphql/types").GetUserForAvailabilityQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForAvailabilityQuery, import("@/graphql/types").GetUserForAvailabilityQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForAvailabilityQuery, import("@/graphql/types").GetUserForAvailabilityQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetUserForAvailabilityQuery, import("@/graphql/types").GetUserForAvailabilityQueryVariables>(GetUserForAvailabilityDocument, variables, options);
}
export type GetUserForAvailabilityQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetUserForAvailabilityQuery, import("@/graphql/types").GetUserForAvailabilityQueryVariables>;
export const ListGlobalUnavailabilitiesDocument = gql`
    query ListGlobalUnavailabilities {
  globalUnavailabilities {
    nodes {
      ...globalUnavailability
    }
  }
}
    ${GlobalUnavailabilityFragmentDoc}`;
export function useListGlobalUnavailabilitiesQuery(options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesQuery, import("@/graphql/types").ListGlobalUnavailabilitiesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesQuery, import("@/graphql/types").ListGlobalUnavailabilitiesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesQuery, import("@/graphql/types").ListGlobalUnavailabilitiesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListGlobalUnavailabilitiesQuery, import("@/graphql/types").ListGlobalUnavailabilitiesQueryVariables>(ListGlobalUnavailabilitiesDocument, {}, options);
}
export function useListGlobalUnavailabilitiesLazyQuery(options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesQuery, import("@/graphql/types").ListGlobalUnavailabilitiesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesQuery, import("@/graphql/types").ListGlobalUnavailabilitiesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesQuery, import("@/graphql/types").ListGlobalUnavailabilitiesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListGlobalUnavailabilitiesQuery, import("@/graphql/types").ListGlobalUnavailabilitiesQueryVariables>(ListGlobalUnavailabilitiesDocument, {}, options);
}
export type ListGlobalUnavailabilitiesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListGlobalUnavailabilitiesQuery, import("@/graphql/types").ListGlobalUnavailabilitiesQueryVariables>;
export const ListGlobalUnavailabilitiesForShiftPlanningDocument = gql`
    query ListGlobalUnavailabilitiesForShiftPlanning($from: DateTime!, $to: DateTime!) {
  globalUnavailabilities(
    filter: {or: [{startsAt: {between: {lower: $from, upper: $to}}}, {endsAt: {between: {lower: $from, upper: $to}}}]}
  ) {
    nodes {
      ...globalUnavailability
    }
  }
}
    ${GlobalUnavailabilityFragmentDoc}`;
export function useListGlobalUnavailabilitiesForShiftPlanningQuery(variables: import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables> | ReactiveFunction<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQuery, import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQuery, import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQuery, import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQuery, import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables>(ListGlobalUnavailabilitiesForShiftPlanningDocument, variables, options);
}
export function useListGlobalUnavailabilitiesForShiftPlanningLazyQuery(variables: import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables> | ReactiveFunction<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQuery, import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQuery, import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQuery, import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQuery, import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables>(ListGlobalUnavailabilitiesForShiftPlanningDocument, variables, options);
}
export type ListGlobalUnavailabilitiesForShiftPlanningQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQuery, import("@/graphql/types").ListGlobalUnavailabilitiesForShiftPlanningQueryVariables>;
export const ListSessionAssignmentForShiftPlanningDocument = gql`
    query ListSessionAssignmentForShiftPlanning($overlaps: DateTimeRange!, $type: SessionType) {
  sessionAssignments(
    filter: {travel: {overlaps: $overlaps}, session: {type: {eq: $type}}}
  ) {
    nodes {
      ...sessionAssignmentWithTravelAndCity
      user {
        id
      }
      session {
        type
      }
    }
  }
}
    ${SessionAssignmentWithTravelAndCityFragmentDoc}`;
export function useListSessionAssignmentForShiftPlanningQuery(variables: import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQuery, import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQuery, import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQuery, import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQuery, import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables>(ListSessionAssignmentForShiftPlanningDocument, variables, options);
}
export function useListSessionAssignmentForShiftPlanningLazyQuery(variables: import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQuery, import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQuery, import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQuery, import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQuery, import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables>(ListSessionAssignmentForShiftPlanningDocument, variables, options);
}
export type ListSessionAssignmentForShiftPlanningQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListSessionAssignmentForShiftPlanningQuery, import("@/graphql/types").ListSessionAssignmentForShiftPlanningQueryVariables>;
export const ListSessionsForShiftPlanningDocument = gql`
    query ListSessionsForShiftPlanning($from: DateTime!, $to: DateTime!, $type: SessionType) {
  sessions(
    filter: {type: {eq: $type}, or: [{scheduleStartsAt: {between: {lower: $from, upper: $to}}}, {endsAt: {between: {lower: $from, upper: $to}}}]}
    paging: {limit: 1024}
  ) {
    nodes {
      ...sessionForShiftPlanning
    }
  }
}
    ${SessionForShiftPlanningFragmentDoc}`;
export function useListSessionsForShiftPlanningQuery(variables: import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForShiftPlanningQuery, import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForShiftPlanningQuery, import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForShiftPlanningQuery, import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListSessionsForShiftPlanningQuery, import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables>(ListSessionsForShiftPlanningDocument, variables, options);
}
export function useListSessionsForShiftPlanningLazyQuery(variables: import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForShiftPlanningQuery, import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForShiftPlanningQuery, import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForShiftPlanningQuery, import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListSessionsForShiftPlanningQuery, import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables>(ListSessionsForShiftPlanningDocument, variables, options);
}
export type ListSessionsForShiftPlanningQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListSessionsForShiftPlanningQuery, import("@/graphql/types").ListSessionsForShiftPlanningQueryVariables>;
export const ListUsersForShiftPlanningDocument = gql`
    query ListUsersForShiftPlanning($filter: UserFilter!, $paging: OffsetPaging) {
  users(filter: $filter, paging: $paging) {
    nodes {
      ...userForShiftPlanning
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${UserForShiftPlanningFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListUsersForShiftPlanningQuery(variables: import("@/graphql/types").ListUsersForShiftPlanningQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForShiftPlanningQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForShiftPlanningQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForShiftPlanningQuery, import("@/graphql/types").ListUsersForShiftPlanningQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForShiftPlanningQuery, import("@/graphql/types").ListUsersForShiftPlanningQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForShiftPlanningQuery, import("@/graphql/types").ListUsersForShiftPlanningQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListUsersForShiftPlanningQuery, import("@/graphql/types").ListUsersForShiftPlanningQueryVariables>(ListUsersForShiftPlanningDocument, variables, options);
}
export function useListUsersForShiftPlanningLazyQuery(variables: import("@/graphql/types").ListUsersForShiftPlanningQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForShiftPlanningQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForShiftPlanningQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForShiftPlanningQuery, import("@/graphql/types").ListUsersForShiftPlanningQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForShiftPlanningQuery, import("@/graphql/types").ListUsersForShiftPlanningQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForShiftPlanningQuery, import("@/graphql/types").ListUsersForShiftPlanningQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListUsersForShiftPlanningQuery, import("@/graphql/types").ListUsersForShiftPlanningQueryVariables>(ListUsersForShiftPlanningDocument, variables, options);
}
export type ListUsersForShiftPlanningQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListUsersForShiftPlanningQuery, import("@/graphql/types").ListUsersForShiftPlanningQueryVariables>;
export const UpdateAvailabilityDocument = gql`
    mutation UpdateAvailability($input: UpdateAvailabilityInput!) {
  updateAvailability(input: $input) {
    ...availability
  }
}
    ${AvailabilityFragmentDoc}`;
export function useUpdateAvailabilityMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateAvailabilityMutation, import("@/graphql/types").UpdateAvailabilityMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateAvailabilityMutation, import("@/graphql/types").UpdateAvailabilityMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateAvailabilityMutation, import("@/graphql/types").UpdateAvailabilityMutationVariables>(UpdateAvailabilityDocument, options);
}
export type UpdateAvailabilityMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateAvailabilityMutation, import("@/graphql/types").UpdateAvailabilityMutationVariables>;
export const UpdateUserTimezoneDocument = gql`
    mutation UpdateUserTimezone($id: ID!, $timezone: String!) {
  updateUser(input: {id: $id, update: {timezone: $timezone}}) {
    id
    timezone
  }
}
    `;
export function useUpdateUserTimezoneMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateUserTimezoneMutation, import("@/graphql/types").UpdateUserTimezoneMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateUserTimezoneMutation, import("@/graphql/types").UpdateUserTimezoneMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateUserTimezoneMutation, import("@/graphql/types").UpdateUserTimezoneMutationVariables>(UpdateUserTimezoneDocument, options);
}
export type UpdateUserTimezoneMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateUserTimezoneMutation, import("@/graphql/types").UpdateUserTimezoneMutationVariables>;
export const GetWorkspaceForContentLibraryDocument = gql`
    query GetWorkspaceForContentLibrary($id: ID!) {
  workspace(id: $id) {
    ...workspaceForContentLibrary
  }
}
    ${WorkspaceForContentLibraryFragmentDoc}`;
export function useGetWorkspaceForContentLibraryQuery(variables: import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForContentLibraryQuery, import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForContentLibraryQuery, import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForContentLibraryQuery, import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetWorkspaceForContentLibraryQuery, import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables>(GetWorkspaceForContentLibraryDocument, variables, options);
}
export function useGetWorkspaceForContentLibraryLazyQuery(variables: import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForContentLibraryQuery, import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForContentLibraryQuery, import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForContentLibraryQuery, import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetWorkspaceForContentLibraryQuery, import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables>(GetWorkspaceForContentLibraryDocument, variables, options);
}
export type GetWorkspaceForContentLibraryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetWorkspaceForContentLibraryQuery, import("@/graphql/types").GetWorkspaceForContentLibraryQueryVariables>;
export const ListMissionSetsForContentLibraryDocument = gql`
    query listMissionSetsForContentLibrary($filter: MissionSetFilter, $paging: OffsetPaging) {
  missionSets(filter: $filter, paging: $paging) {
    nodes {
      ...missionSet
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${MissionSetFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListMissionSetsForContentLibraryQuery(variables: import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables> | ReactiveFunction<import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForContentLibraryQuery, import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForContentLibraryQuery, import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForContentLibraryQuery, import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListMissionSetsForContentLibraryQuery, import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables>(ListMissionSetsForContentLibraryDocument, variables, options);
}
export function useListMissionSetsForContentLibraryLazyQuery(variables: import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables> | ReactiveFunction<import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForContentLibraryQuery, import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForContentLibraryQuery, import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionSetsForContentLibraryQuery, import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListMissionSetsForContentLibraryQuery, import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables>(ListMissionSetsForContentLibraryDocument, variables, options);
}
export type ListMissionSetsForContentLibraryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListMissionSetsForContentLibraryQuery, import("@/graphql/types").ListMissionSetsForContentLibraryQueryVariables>;
export const ListMissionsByMissionSetDocument = gql`
    query listMissionsByMissionSet($missionSetId: ID!) {
  missions(filter: {missionSet: {id: {eq: $missionSetId}}}) {
    nodes {
      ...mission
    }
  }
}
    ${MissionFragmentDoc}`;
export function useListMissionsByMissionSetQuery(variables: import("@/graphql/types").ListMissionsByMissionSetQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListMissionsByMissionSetQueryVariables> | ReactiveFunction<import("@/graphql/types").ListMissionsByMissionSetQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionsByMissionSetQuery, import("@/graphql/types").ListMissionsByMissionSetQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionsByMissionSetQuery, import("@/graphql/types").ListMissionsByMissionSetQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionsByMissionSetQuery, import("@/graphql/types").ListMissionsByMissionSetQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListMissionsByMissionSetQuery, import("@/graphql/types").ListMissionsByMissionSetQueryVariables>(ListMissionsByMissionSetDocument, variables, options);
}
export function useListMissionsByMissionSetLazyQuery(variables: import("@/graphql/types").ListMissionsByMissionSetQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListMissionsByMissionSetQueryVariables> | ReactiveFunction<import("@/graphql/types").ListMissionsByMissionSetQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionsByMissionSetQuery, import("@/graphql/types").ListMissionsByMissionSetQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionsByMissionSetQuery, import("@/graphql/types").ListMissionsByMissionSetQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionsByMissionSetQuery, import("@/graphql/types").ListMissionsByMissionSetQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListMissionsByMissionSetQuery, import("@/graphql/types").ListMissionsByMissionSetQueryVariables>(ListMissionsByMissionSetDocument, variables, options);
}
export type ListMissionsByMissionSetQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListMissionsByMissionSetQuery, import("@/graphql/types").ListMissionsByMissionSetQueryVariables>;
export const UpdateMissionSetDocument = gql`
    mutation UpdateMissionSet($id: ID!, $name: String!) {
  updateMissionSet(input: {id: $id, update: {name: $name}}) {
    id
    name
  }
}
    `;
export function useUpdateMissionSetMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateMissionSetMutation, import("@/graphql/types").UpdateMissionSetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateMissionSetMutation, import("@/graphql/types").UpdateMissionSetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateMissionSetMutation, import("@/graphql/types").UpdateMissionSetMutationVariables>(UpdateMissionSetDocument, options);
}
export type UpdateMissionSetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateMissionSetMutation, import("@/graphql/types").UpdateMissionSetMutationVariables>;
export const GetWorkspaceForCustomizationDocument = gql`
    query GetWorkspaceForCustomization($id: ID!) {
  workspace(id: $id) {
    ...workspaceWithCustomization
  }
}
    ${WorkspaceWithCustomizationFragmentDoc}`;
export function useGetWorkspaceForCustomizationQuery(variables: import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForCustomizationQuery, import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForCustomizationQuery, import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForCustomizationQuery, import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetWorkspaceForCustomizationQuery, import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables>(GetWorkspaceForCustomizationDocument, variables, options);
}
export function useGetWorkspaceForCustomizationLazyQuery(variables: import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForCustomizationQuery, import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForCustomizationQuery, import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForCustomizationQuery, import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetWorkspaceForCustomizationQuery, import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables>(GetWorkspaceForCustomizationDocument, variables, options);
}
export type GetWorkspaceForCustomizationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetWorkspaceForCustomizationQuery, import("@/graphql/types").GetWorkspaceForCustomizationQueryVariables>;
export const ResetCustomizationDocument = gql`
    mutation ResetCustomization($id: ID!) {
  resetCustomization(id: $id) {
    id
    colors {
      primary
      secondary
      tertiary
    }
    media {
      id
    }
  }
}
    `;
export function useResetCustomizationMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").ResetCustomizationMutation, import("@/graphql/types").ResetCustomizationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").ResetCustomizationMutation, import("@/graphql/types").ResetCustomizationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").ResetCustomizationMutation, import("@/graphql/types").ResetCustomizationMutationVariables>(ResetCustomizationDocument, options);
}
export type ResetCustomizationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").ResetCustomizationMutation, import("@/graphql/types").ResetCustomizationMutationVariables>;
export const UpdateCustomizationDocument = gql`
    mutation UpdateCustomization($input: UpdateOneCustomizationInput!) {
  updateCustomization(input: $input) {
    id
    colors {
      primary
      secondary
      tertiary
    }
  }
}
    `;
export function useUpdateCustomizationMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateCustomizationMutation, import("@/graphql/types").UpdateCustomizationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateCustomizationMutation, import("@/graphql/types").UpdateCustomizationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateCustomizationMutation, import("@/graphql/types").UpdateCustomizationMutationVariables>(UpdateCustomizationDocument, options);
}
export type UpdateCustomizationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateCustomizationMutation, import("@/graphql/types").UpdateCustomizationMutationVariables>;
export const UpdateSessionForEventMediaAndDownloadsDocument = gql`
    mutation UpdateSessionForEventMediaAndDownloads($input: UpdateSessionInput!) {
  updateSession(input: $input) {
    id
    meta {
      attendees
    }
  }
}
    `;
export function useUpdateSessionForEventMediaAndDownloadsMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionForEventMediaAndDownloadsMutation, import("@/graphql/types").UpdateSessionForEventMediaAndDownloadsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionForEventMediaAndDownloadsMutation, import("@/graphql/types").UpdateSessionForEventMediaAndDownloadsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionForEventMediaAndDownloadsMutation, import("@/graphql/types").UpdateSessionForEventMediaAndDownloadsMutationVariables>(UpdateSessionForEventMediaAndDownloadsDocument, options);
}
export type UpdateSessionForEventMediaAndDownloadsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionForEventMediaAndDownloadsMutation, import("@/graphql/types").UpdateSessionForEventMediaAndDownloadsMutationVariables>;
export const ListMissionListsForGameMissionListDocument = gql`
    query ListMissionListsForGameMissionList($filter: MissionListFilter!, $paging: OffsetPaging) {
  breadcrumbMissionLists(
    filter: $filter
    paging: $paging
    sorting: {field: "name"}
  ) {
    nodes {
      ...missionListForGameMissionList
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${MissionListForGameMissionListFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListMissionListsForGameMissionListQuery(variables: import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables> | ReactiveFunction<import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionListsForGameMissionListQuery, import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionListsForGameMissionListQuery, import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionListsForGameMissionListQuery, import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListMissionListsForGameMissionListQuery, import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables>(ListMissionListsForGameMissionListDocument, variables, options);
}
export function useListMissionListsForGameMissionListLazyQuery(variables: import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables> | ReactiveFunction<import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionListsForGameMissionListQuery, import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionListsForGameMissionListQuery, import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListMissionListsForGameMissionListQuery, import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListMissionListsForGameMissionListQuery, import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables>(ListMissionListsForGameMissionListDocument, variables, options);
}
export type ListMissionListsForGameMissionListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListMissionListsForGameMissionListQuery, import("@/graphql/types").ListMissionListsForGameMissionListQueryVariables>;
export const ListBreadcrumbMissionsForSelectDocument = gql`
    query ListBreadcrumbMissionsForSelect($filter: BreadcrumbMissionFilter!, $paging: OffsetPaging) {
  breadcrumbMissions(filter: $filter, paging: $paging, sorting: {field: "name"}) {
    nodes {
      ...breadcrumbMissionForSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${BreadcrumbMissionForSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListBreadcrumbMissionsForSelectQuery(variables: import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListBreadcrumbMissionsForSelectQuery, import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListBreadcrumbMissionsForSelectQuery, import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListBreadcrumbMissionsForSelectQuery, import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListBreadcrumbMissionsForSelectQuery, import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables>(ListBreadcrumbMissionsForSelectDocument, variables, options);
}
export function useListBreadcrumbMissionsForSelectLazyQuery(variables: import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListBreadcrumbMissionsForSelectQuery, import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListBreadcrumbMissionsForSelectQuery, import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListBreadcrumbMissionsForSelectQuery, import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListBreadcrumbMissionsForSelectQuery, import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables>(ListBreadcrumbMissionsForSelectDocument, variables, options);
}
export type ListBreadcrumbMissionsForSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListBreadcrumbMissionsForSelectQuery, import("@/graphql/types").ListBreadcrumbMissionsForSelectQueryVariables>;
export const GetLawnScheduleForShowDocument = gql`
    query GetLawnScheduleForShow($sessionId: ID!) {
  lawnSchedule(sessionId: $sessionId) {
    ...lawnScheduleForShow
  }
}
    ${LawnScheduleForShowFragmentDoc}`;
export function useGetLawnScheduleForShowQuery(variables: import("@/graphql/types").GetLawnScheduleForShowQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetLawnScheduleForShowQueryVariables> | ReactiveFunction<import("@/graphql/types").GetLawnScheduleForShowQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetLawnScheduleForShowQuery, import("@/graphql/types").GetLawnScheduleForShowQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetLawnScheduleForShowQuery, import("@/graphql/types").GetLawnScheduleForShowQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetLawnScheduleForShowQuery, import("@/graphql/types").GetLawnScheduleForShowQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetLawnScheduleForShowQuery, import("@/graphql/types").GetLawnScheduleForShowQueryVariables>(GetLawnScheduleForShowDocument, variables, options);
}
export function useGetLawnScheduleForShowLazyQuery(variables: import("@/graphql/types").GetLawnScheduleForShowQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetLawnScheduleForShowQueryVariables> | ReactiveFunction<import("@/graphql/types").GetLawnScheduleForShowQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetLawnScheduleForShowQuery, import("@/graphql/types").GetLawnScheduleForShowQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetLawnScheduleForShowQuery, import("@/graphql/types").GetLawnScheduleForShowQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetLawnScheduleForShowQuery, import("@/graphql/types").GetLawnScheduleForShowQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetLawnScheduleForShowQuery, import("@/graphql/types").GetLawnScheduleForShowQueryVariables>(GetLawnScheduleForShowDocument, variables, options);
}
export type GetLawnScheduleForShowQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetLawnScheduleForShowQuery, import("@/graphql/types").GetLawnScheduleForShowQueryVariables>;
export const UpdateLawnScheduleDocument = gql`
    mutation UpdateLawnSchedule($sessionId: ID!, $input: UpdateLawnScheduleInput!) {
  updateLawnSchedule(sessionId: $sessionId, input: $input) {
    ...lawnScheduleForShow
  }
}
    ${LawnScheduleForShowFragmentDoc}`;
export function useUpdateLawnScheduleMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateLawnScheduleMutation, import("@/graphql/types").UpdateLawnScheduleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateLawnScheduleMutation, import("@/graphql/types").UpdateLawnScheduleMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateLawnScheduleMutation, import("@/graphql/types").UpdateLawnScheduleMutationVariables>(UpdateLawnScheduleDocument, options);
}
export type UpdateLawnScheduleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateLawnScheduleMutation, import("@/graphql/types").UpdateLawnScheduleMutationVariables>;
export const CreateUserForCreateNewPlantDialogDocument = gql`
    mutation CreateUserForCreateNewPlantDialog($input: CreatePlantInput!) {
  createPlant(input: $input) {
    ...userForUserSelect
  }
}
    ${UserForUserSelectFragmentDoc}`;
export function useCreateUserForCreateNewPlantDialogMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateUserForCreateNewPlantDialogMutation, import("@/graphql/types").CreateUserForCreateNewPlantDialogMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateUserForCreateNewPlantDialogMutation, import("@/graphql/types").CreateUserForCreateNewPlantDialogMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateUserForCreateNewPlantDialogMutation, import("@/graphql/types").CreateUserForCreateNewPlantDialogMutationVariables>(CreateUserForCreateNewPlantDialogDocument, options);
}
export type CreateUserForCreateNewPlantDialogMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateUserForCreateNewPlantDialogMutation, import("@/graphql/types").CreateUserForCreateNewPlantDialogMutationVariables>;
export const ListUsersForPlantSelectDocument = gql`
    query ListUsersForPlantSelect($filter: UserFilter, $paging: OffsetPaging) {
  users(
    filter: $filter
    paging: $paging
    sorting: [{field: fullName, direction: ASC}]
  ) {
    nodes {
      ...userForUserSelect
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${UserForUserSelectFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListUsersForPlantSelectQuery(variables: import("@/graphql/types").ListUsersForPlantSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForPlantSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForPlantSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPlantSelectQuery, import("@/graphql/types").ListUsersForPlantSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPlantSelectQuery, import("@/graphql/types").ListUsersForPlantSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPlantSelectQuery, import("@/graphql/types").ListUsersForPlantSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListUsersForPlantSelectQuery, import("@/graphql/types").ListUsersForPlantSelectQueryVariables>(ListUsersForPlantSelectDocument, variables, options);
}
export function useListUsersForPlantSelectLazyQuery(variables: import("@/graphql/types").ListUsersForPlantSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForPlantSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForPlantSelectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPlantSelectQuery, import("@/graphql/types").ListUsersForPlantSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPlantSelectQuery, import("@/graphql/types").ListUsersForPlantSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForPlantSelectQuery, import("@/graphql/types").ListUsersForPlantSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListUsersForPlantSelectQuery, import("@/graphql/types").ListUsersForPlantSelectQueryVariables>(ListUsersForPlantSelectDocument, variables, options);
}
export type ListUsersForPlantSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListUsersForPlantSelectQuery, import("@/graphql/types").ListUsersForPlantSelectQueryVariables>;
export const GetSessionAssignmentForSessionAssignmentSelectDocument = gql`
    query GetSessionAssignmentForSessionAssignmentSelect($id: ID!) {
  sessionAssignment(id: $id) {
    ...sessionAssignmentForStaffAssignment
  }
}
    ${SessionAssignmentForStaffAssignmentFragmentDoc}`;
export function useGetSessionAssignmentForSessionAssignmentSelectQuery(variables: import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQuery, import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQuery, import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQuery, import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQuery, import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables>(GetSessionAssignmentForSessionAssignmentSelectDocument, variables, options);
}
export function useGetSessionAssignmentForSessionAssignmentSelectLazyQuery(variables: import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQuery, import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQuery, import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQuery, import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQuery, import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables>(GetSessionAssignmentForSessionAssignmentSelectDocument, variables, options);
}
export type GetSessionAssignmentForSessionAssignmentSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQuery, import("@/graphql/types").GetSessionAssignmentForSessionAssignmentSelectQueryVariables>;
export const CreateMissionSetForSessionActivityDetailsDocument = gql`
    mutation CreateMissionSetForSessionActivityDetails($input: CreateOneMissionSetInput!) {
  createMissionSet(input: $input) {
    id
  }
}
    `;
export function useCreateMissionSetForSessionActivityDetailsMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateMissionSetForSessionActivityDetailsMutation, import("@/graphql/types").CreateMissionSetForSessionActivityDetailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateMissionSetForSessionActivityDetailsMutation, import("@/graphql/types").CreateMissionSetForSessionActivityDetailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateMissionSetForSessionActivityDetailsMutation, import("@/graphql/types").CreateMissionSetForSessionActivityDetailsMutationVariables>(CreateMissionSetForSessionActivityDetailsDocument, options);
}
export type CreateMissionSetForSessionActivityDetailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateMissionSetForSessionActivityDetailsMutation, import("@/graphql/types").CreateMissionSetForSessionActivityDetailsMutationVariables>;
export const RemoveMissionSetFromSessionDocument = gql`
    mutation RemoveMissionSetFromSession($input: RemoveMissionSetFromSessionInput!) {
  removeMissionSetFromSession(input: $input) {
    id
    missionSet {
      id
    }
  }
}
    `;
export function useRemoveMissionSetFromSessionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveMissionSetFromSessionMutation, import("@/graphql/types").RemoveMissionSetFromSessionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveMissionSetFromSessionMutation, import("@/graphql/types").RemoveMissionSetFromSessionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").RemoveMissionSetFromSessionMutation, import("@/graphql/types").RemoveMissionSetFromSessionMutationVariables>(RemoveMissionSetFromSessionDocument, options);
}
export type RemoveMissionSetFromSessionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").RemoveMissionSetFromSessionMutation, import("@/graphql/types").RemoveMissionSetFromSessionMutationVariables>;
export const SetMissionSetOnSessionDocument = gql`
    mutation SetMissionSetOnSession($input: SetMissionSetOnSessionInput!) {
  setMissionSetOnSession(input: $input) {
    id
    missionSet {
      id
    }
  }
}
    `;
export function useSetMissionSetOnSessionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetMissionSetOnSessionMutation, import("@/graphql/types").SetMissionSetOnSessionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetMissionSetOnSessionMutation, import("@/graphql/types").SetMissionSetOnSessionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").SetMissionSetOnSessionMutation, import("@/graphql/types").SetMissionSetOnSessionMutationVariables>(SetMissionSetOnSessionDocument, options);
}
export type SetMissionSetOnSessionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").SetMissionSetOnSessionMutation, import("@/graphql/types").SetMissionSetOnSessionMutationVariables>;
export const CreateUserForCreateNewUserDialogDocument = gql`
    mutation CreateUserForCreateNewUserDialog($input: CreateBookingUserInput!) {
  createBookingUser(input: $input) {
    ...userWithContacts
  }
}
    ${UserWithContactsFragmentDoc}`;
export function useCreateUserForCreateNewUserDialogMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateUserForCreateNewUserDialogMutation, import("@/graphql/types").CreateUserForCreateNewUserDialogMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateUserForCreateNewUserDialogMutation, import("@/graphql/types").CreateUserForCreateNewUserDialogMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateUserForCreateNewUserDialogMutation, import("@/graphql/types").CreateUserForCreateNewUserDialogMutationVariables>(CreateUserForCreateNewUserDialogDocument, options);
}
export type CreateUserForCreateNewUserDialogMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateUserForCreateNewUserDialogMutation, import("@/graphql/types").CreateUserForCreateNewUserDialogMutationVariables>;
export const SetSessionContactDocument = gql`
    mutation SetSessionContact($input: SetContactOnSessionInput!) {
  setContactOnSession(input: $input) {
    id
    contact {
      ...userWithContacts
    }
  }
}
    ${UserWithContactsFragmentDoc}`;
export function useSetSessionContactMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetSessionContactMutation, import("@/graphql/types").SetSessionContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetSessionContactMutation, import("@/graphql/types").SetSessionContactMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").SetSessionContactMutation, import("@/graphql/types").SetSessionContactMutationVariables>(SetSessionContactDocument, options);
}
export type SetSessionContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").SetSessionContactMutation, import("@/graphql/types").SetSessionContactMutationVariables>;
export const AddSessionModifiersDocument = gql`
    mutation AddSessionModifiers($input: AddSessionModifiersInput!) {
  addSessionModifiers(input: $input) {
    id
    isEscapeRoom
    isMysteryExpress
    hasGroupDetails
    modifiers {
      id
    }
    supportsUpgrade {
      ...sessionSupportsUpgrade
    }
  }
}
    ${SessionSupportsUpgradeFragmentDoc}`;
export function useAddSessionModifiersMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").AddSessionModifiersMutation, import("@/graphql/types").AddSessionModifiersMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").AddSessionModifiersMutation, import("@/graphql/types").AddSessionModifiersMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").AddSessionModifiersMutation, import("@/graphql/types").AddSessionModifiersMutationVariables>(AddSessionModifiersDocument, options);
}
export type AddSessionModifiersMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").AddSessionModifiersMutation, import("@/graphql/types").AddSessionModifiersMutationVariables>;
export const RemoveSessionModifiersDocument = gql`
    mutation RemoveSessionModifiers($input: RemoveSessionModifiersInput!) {
  removeSessionModifiers(input: $input) {
    id
    isEscapeRoom
    isMysteryExpress
    hasGroupDetails
    modifiers {
      id
    }
    supportsUpgrade {
      ...sessionSupportsUpgrade
    }
  }
}
    ${SessionSupportsUpgradeFragmentDoc}`;
export function useRemoveSessionModifiersMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveSessionModifiersMutation, import("@/graphql/types").RemoveSessionModifiersMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveSessionModifiersMutation, import("@/graphql/types").RemoveSessionModifiersMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").RemoveSessionModifiersMutation, import("@/graphql/types").RemoveSessionModifiersMutationVariables>(RemoveSessionModifiersDocument, options);
}
export type RemoveSessionModifiersMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").RemoveSessionModifiersMutation, import("@/graphql/types").RemoveSessionModifiersMutationVariables>;
export const RemoveSourceSessionFromSessionDocument = gql`
    mutation RemoveSourceSessionFromSession($input: RemoveSourceSessionFromSessionInput!) {
  removeSourceSessionFromSession(input: $input) {
    id
    sourceSession {
      id
      name
    }
  }
}
    `;
export function useRemoveSourceSessionFromSessionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveSourceSessionFromSessionMutation, import("@/graphql/types").RemoveSourceSessionFromSessionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveSourceSessionFromSessionMutation, import("@/graphql/types").RemoveSourceSessionFromSessionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").RemoveSourceSessionFromSessionMutation, import("@/graphql/types").RemoveSourceSessionFromSessionMutationVariables>(RemoveSourceSessionFromSessionDocument, options);
}
export type RemoveSourceSessionFromSessionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").RemoveSourceSessionFromSessionMutation, import("@/graphql/types").RemoveSourceSessionFromSessionMutationVariables>;
export const SetSourceSessionOnSessionDocument = gql`
    mutation SetSourceSessionOnSession($input: SetSourceSessionOnSessionInput!) {
  setSourceSessionOnSession(input: $input) {
    id
    sourceSession {
      id
      name
    }
  }
}
    `;
export function useSetSourceSessionOnSessionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetSourceSessionOnSessionMutation, import("@/graphql/types").SetSourceSessionOnSessionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetSourceSessionOnSessionMutation, import("@/graphql/types").SetSourceSessionOnSessionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").SetSourceSessionOnSessionMutation, import("@/graphql/types").SetSourceSessionOnSessionMutationVariables>(SetSourceSessionOnSessionDocument, options);
}
export type SetSourceSessionOnSessionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").SetSourceSessionOnSessionMutation, import("@/graphql/types").SetSourceSessionOnSessionMutationVariables>;
export const UpdateSessionForSessionDetailsDocument = gql`
    mutation UpdateSessionForSessionDetails($input: UpdateSessionInput!) {
  updateSession(input: $input) {
    ...sessionForInstructions
    id
    name
    shortName
    credits
    lobby
    templateId
    internalNotes
    activityNotes
    language
    teams
    attendees
    freeReschedule
    pdfType
    meta {
      template
    }
    restrictions {
      attendees
    }
  }
}
    ${SessionForInstructionsFragmentDoc}`;
export function useUpdateSessionForSessionDetailsMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionForSessionDetailsMutation, import("@/graphql/types").UpdateSessionForSessionDetailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionForSessionDetailsMutation, import("@/graphql/types").UpdateSessionForSessionDetailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionForSessionDetailsMutation, import("@/graphql/types").UpdateSessionForSessionDetailsMutationVariables>(UpdateSessionForSessionDetailsDocument, options);
}
export type UpdateSessionForSessionDetailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionForSessionDetailsMutation, import("@/graphql/types").UpdateSessionForSessionDetailsMutationVariables>;
export const ActionEndRealPlaythroughDocument = gql`
    mutation ActionEndRealPlaythrough($sessionId: ID!) {
  actionEndRealPlaythrough(sessionId: $sessionId) {
    ...playthroughForGameControl
  }
}
    ${PlaythroughForGameControlFragmentDoc}`;
export function useActionEndRealPlaythroughMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").ActionEndRealPlaythroughMutation, import("@/graphql/types").ActionEndRealPlaythroughMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").ActionEndRealPlaythroughMutation, import("@/graphql/types").ActionEndRealPlaythroughMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").ActionEndRealPlaythroughMutation, import("@/graphql/types").ActionEndRealPlaythroughMutationVariables>(ActionEndRealPlaythroughDocument, options);
}
export type ActionEndRealPlaythroughMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").ActionEndRealPlaythroughMutation, import("@/graphql/types").ActionEndRealPlaythroughMutationVariables>;
export const ActionMessageRealPlaythroughDocument = gql`
    mutation ActionMessageRealPlaythrough($sessionId: ID!, $message: String!) {
  actionMessageRealPlaythrough(sessionId: $sessionId, message: $message) {
    id
  }
}
    `;
export function useActionMessageRealPlaythroughMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").ActionMessageRealPlaythroughMutation, import("@/graphql/types").ActionMessageRealPlaythroughMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").ActionMessageRealPlaythroughMutation, import("@/graphql/types").ActionMessageRealPlaythroughMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").ActionMessageRealPlaythroughMutation, import("@/graphql/types").ActionMessageRealPlaythroughMutationVariables>(ActionMessageRealPlaythroughDocument, options);
}
export type ActionMessageRealPlaythroughMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").ActionMessageRealPlaythroughMutation, import("@/graphql/types").ActionMessageRealPlaythroughMutationVariables>;
export const ActionStartRealPlaythroughDocument = gql`
    mutation ActionStartRealPlaythrough($sessionId: ID!) {
  actionStartRealPlaythrough(sessionId: $sessionId) {
    ...playthroughForGameControl
  }
}
    ${PlaythroughForGameControlFragmentDoc}`;
export function useActionStartRealPlaythroughMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").ActionStartRealPlaythroughMutation, import("@/graphql/types").ActionStartRealPlaythroughMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").ActionStartRealPlaythroughMutation, import("@/graphql/types").ActionStartRealPlaythroughMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").ActionStartRealPlaythroughMutation, import("@/graphql/types").ActionStartRealPlaythroughMutationVariables>(ActionStartRealPlaythroughDocument, options);
}
export type ActionStartRealPlaythroughMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").ActionStartRealPlaythroughMutation, import("@/graphql/types").ActionStartRealPlaythroughMutationVariables>;
export const GetBreadcrumbRealPlaythroughDocument = gql`
    query GetBreadcrumbRealPlaythrough($sessionId: ID!) {
  breadcrumbRealPlaythrough(sessionId: $sessionId) {
    ...playthroughForGameControl
  }
}
    ${PlaythroughForGameControlFragmentDoc}`;
export function useGetBreadcrumbRealPlaythroughQuery(variables: import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables> | ReactiveFunction<import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetBreadcrumbRealPlaythroughQuery, import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetBreadcrumbRealPlaythroughQuery, import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetBreadcrumbRealPlaythroughQuery, import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetBreadcrumbRealPlaythroughQuery, import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables>(GetBreadcrumbRealPlaythroughDocument, variables, options);
}
export function useGetBreadcrumbRealPlaythroughLazyQuery(variables: import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables> | ReactiveFunction<import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetBreadcrumbRealPlaythroughQuery, import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetBreadcrumbRealPlaythroughQuery, import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetBreadcrumbRealPlaythroughQuery, import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetBreadcrumbRealPlaythroughQuery, import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables>(GetBreadcrumbRealPlaythroughDocument, variables, options);
}
export type GetBreadcrumbRealPlaythroughQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetBreadcrumbRealPlaythroughQuery, import("@/graphql/types").GetBreadcrumbRealPlaythroughQueryVariables>;
export const UpdateSessionGameControlDocument = gql`
    mutation updateSessionGameControl($input: UpdateGameControl!) {
  updateSessionGameControl(input: $input) {
    id
    meta {
      gameControl
    }
  }
}
    `;
export function useUpdateSessionGameControlMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionGameControlMutation, import("@/graphql/types").UpdateSessionGameControlMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionGameControlMutation, import("@/graphql/types").UpdateSessionGameControlMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionGameControlMutation, import("@/graphql/types").UpdateSessionGameControlMutationVariables>(UpdateSessionGameControlDocument, options);
}
export type UpdateSessionGameControlMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionGameControlMutation, import("@/graphql/types").UpdateSessionGameControlMutationVariables>;
export const ClearGroupDetailsDocument = gql`
    mutation ClearGroupDetails($id: ID!) {
  clearGroupDetails(input: {id: $id}) {
    id
    meta {
      group {
        ...groupDetails
      }
    }
    pastEventsDetails
    futureEventsDetails
    relations {
      id
      type
    }
  }
}
    ${GroupDetailsFragmentDoc}`;
export function useClearGroupDetailsMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").ClearGroupDetailsMutation, import("@/graphql/types").ClearGroupDetailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").ClearGroupDetailsMutation, import("@/graphql/types").ClearGroupDetailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").ClearGroupDetailsMutation, import("@/graphql/types").ClearGroupDetailsMutationVariables>(ClearGroupDetailsDocument, options);
}
export type ClearGroupDetailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").ClearGroupDetailsMutation, import("@/graphql/types").ClearGroupDetailsMutationVariables>;
export const CopyGroupDetailsDocument = gql`
    mutation CopyGroupDetails($input: CopyGroupDetailsInput!) {
  copyGroupDetails(input: $input) {
    id
    meta {
      group {
        ...groupDetails
      }
    }
    pastEventsDetails
    futureEventsDetails
  }
}
    ${GroupDetailsFragmentDoc}`;
export function useCopyGroupDetailsMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CopyGroupDetailsMutation, import("@/graphql/types").CopyGroupDetailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CopyGroupDetailsMutation, import("@/graphql/types").CopyGroupDetailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CopyGroupDetailsMutation, import("@/graphql/types").CopyGroupDetailsMutationVariables>(CopyGroupDetailsDocument, options);
}
export type CopyGroupDetailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CopyGroupDetailsMutation, import("@/graphql/types").CopyGroupDetailsMutationVariables>;
export const AddSessionRelationsDocument = gql`
    mutation AddSessionRelations($input: AddSessionRelationsInput!) {
  addSessionRelations(input: $input) {
    id
    relations {
      id
      right {
        id
      }
    }
  }
}
    `;
export function useAddSessionRelationsMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").AddSessionRelationsMutation, import("@/graphql/types").AddSessionRelationsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").AddSessionRelationsMutation, import("@/graphql/types").AddSessionRelationsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").AddSessionRelationsMutation, import("@/graphql/types").AddSessionRelationsMutationVariables>(AddSessionRelationsDocument, options);
}
export type AddSessionRelationsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").AddSessionRelationsMutation, import("@/graphql/types").AddSessionRelationsMutationVariables>;
export const GetSessionForRelationsDialogDocument = gql`
    query GetSessionForRelationsDialog($id: ID!) {
  session(id: $id) {
    ...sessionForRelationsDialog
  }
}
    ${SessionForRelationsDialogFragmentDoc}`;
export function useGetSessionForRelationsDialogQuery(variables: import("@/graphql/types").GetSessionForRelationsDialogQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForRelationsDialogQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForRelationsDialogQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForRelationsDialogQuery, import("@/graphql/types").GetSessionForRelationsDialogQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForRelationsDialogQuery, import("@/graphql/types").GetSessionForRelationsDialogQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForRelationsDialogQuery, import("@/graphql/types").GetSessionForRelationsDialogQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionForRelationsDialogQuery, import("@/graphql/types").GetSessionForRelationsDialogQueryVariables>(GetSessionForRelationsDialogDocument, variables, options);
}
export function useGetSessionForRelationsDialogLazyQuery(variables: import("@/graphql/types").GetSessionForRelationsDialogQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForRelationsDialogQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForRelationsDialogQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForRelationsDialogQuery, import("@/graphql/types").GetSessionForRelationsDialogQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForRelationsDialogQuery, import("@/graphql/types").GetSessionForRelationsDialogQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForRelationsDialogQuery, import("@/graphql/types").GetSessionForRelationsDialogQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionForRelationsDialogQuery, import("@/graphql/types").GetSessionForRelationsDialogQueryVariables>(GetSessionForRelationsDialogDocument, variables, options);
}
export type GetSessionForRelationsDialogQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionForRelationsDialogQuery, import("@/graphql/types").GetSessionForRelationsDialogQueryVariables>;
export const RemoveSessionRelationsDocument = gql`
    mutation RemoveSessionRelations($input: RemoveSessionRelationsInput!) {
  removeSessionRelations(input: $input) {
    id
    relations {
      id
    }
  }
}
    `;
export function useRemoveSessionRelationsMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveSessionRelationsMutation, import("@/graphql/types").RemoveSessionRelationsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveSessionRelationsMutation, import("@/graphql/types").RemoveSessionRelationsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").RemoveSessionRelationsMutation, import("@/graphql/types").RemoveSessionRelationsMutationVariables>(RemoveSessionRelationsDocument, options);
}
export type RemoveSessionRelationsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").RemoveSessionRelationsMutation, import("@/graphql/types").RemoveSessionRelationsMutationVariables>;
export const UpdateSessionForPickPastSessionsDialogDocument = gql`
    mutation UpdateSessionForPickPastSessionsDialog($id: ID!, $pastEventsDetails: String, $futureEventsDetails: String) {
  updateSession(
    input: {id: $id, update: {pastEventsDetails: $pastEventsDetails, futureEventsDetails: $futureEventsDetails}}
  ) {
    id
    pastEventsDetails
    futureEventsDetails
  }
}
    `;
export function useUpdateSessionForPickPastSessionsDialogMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionForPickPastSessionsDialogMutation, import("@/graphql/types").UpdateSessionForPickPastSessionsDialogMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionForPickPastSessionsDialogMutation, import("@/graphql/types").UpdateSessionForPickPastSessionsDialogMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionForPickPastSessionsDialogMutation, import("@/graphql/types").UpdateSessionForPickPastSessionsDialogMutationVariables>(UpdateSessionForPickPastSessionsDialogDocument, options);
}
export type UpdateSessionForPickPastSessionsDialogMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionForPickPastSessionsDialogMutation, import("@/graphql/types").UpdateSessionForPickPastSessionsDialogMutationVariables>;
export const UpdateSessionForGroupDetailsDocument = gql`
    mutation UpdateSessionForGroupDetails($id: ID!, $meta: SessionMetaInput) {
  updateSession(input: {id: $id, update: {meta: $meta}}) {
    id
    meta {
      group {
        ...groupDetails
      }
    }
  }
}
    ${GroupDetailsFragmentDoc}`;
export function useUpdateSessionForGroupDetailsMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionForGroupDetailsMutation, import("@/graphql/types").UpdateSessionForGroupDetailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionForGroupDetailsMutation, import("@/graphql/types").UpdateSessionForGroupDetailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionForGroupDetailsMutation, import("@/graphql/types").UpdateSessionForGroupDetailsMutationVariables>(UpdateSessionForGroupDetailsDocument, options);
}
export type UpdateSessionForGroupDetailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionForGroupDetailsMutation, import("@/graphql/types").UpdateSessionForGroupDetailsMutationVariables>;
export const GetUserForSessionHostSelectDocument = gql`
    query GetUserForSessionHostSelect($id: ID!) {
  user(id: $id) {
    ...userWithFullName
  }
}
    ${UserWithFullNameFragmentDoc}`;
export function useGetUserForSessionHostSelectQuery(variables: import("@/graphql/types").GetUserForSessionHostSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetUserForSessionHostSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetUserForSessionHostSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionHostSelectQuery, import("@/graphql/types").GetUserForSessionHostSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionHostSelectQuery, import("@/graphql/types").GetUserForSessionHostSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionHostSelectQuery, import("@/graphql/types").GetUserForSessionHostSelectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetUserForSessionHostSelectQuery, import("@/graphql/types").GetUserForSessionHostSelectQueryVariables>(GetUserForSessionHostSelectDocument, variables, options);
}
export function useGetUserForSessionHostSelectLazyQuery(variables: import("@/graphql/types").GetUserForSessionHostSelectQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetUserForSessionHostSelectQueryVariables> | ReactiveFunction<import("@/graphql/types").GetUserForSessionHostSelectQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionHostSelectQuery, import("@/graphql/types").GetUserForSessionHostSelectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionHostSelectQuery, import("@/graphql/types").GetUserForSessionHostSelectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForSessionHostSelectQuery, import("@/graphql/types").GetUserForSessionHostSelectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetUserForSessionHostSelectQuery, import("@/graphql/types").GetUserForSessionHostSelectQueryVariables>(GetUserForSessionHostSelectDocument, variables, options);
}
export type GetUserForSessionHostSelectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetUserForSessionHostSelectQuery, import("@/graphql/types").GetUserForSessionHostSelectQueryVariables>;
export const AddLocationToSessionDocument = gql`
    mutation AddLocationToSession($input: AddLocationToSessionInput!) {
  addLocationToSession(input: $input) {
    id
    locations {
      id
      type
      location {
        ...location
        venueId
      }
    }
  }
}
    ${LocationFragmentDoc}`;
export function useAddLocationToSessionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").AddLocationToSessionMutation, import("@/graphql/types").AddLocationToSessionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").AddLocationToSessionMutation, import("@/graphql/types").AddLocationToSessionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").AddLocationToSessionMutation, import("@/graphql/types").AddLocationToSessionMutationVariables>(AddLocationToSessionDocument, options);
}
export type AddLocationToSessionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").AddLocationToSessionMutation, import("@/graphql/types").AddLocationToSessionMutationVariables>;
export const RemoveLocationFromSessionDocument = gql`
    mutation RemoveLocationFromSession($input: RemoveLocationFromSessionInput!) {
  removeLocationFromSession(input: $input) {
    id
    locations {
      id
      location {
        ...location
      }
    }
  }
}
    ${LocationFragmentDoc}`;
export function useRemoveLocationFromSessionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveLocationFromSessionMutation, import("@/graphql/types").RemoveLocationFromSessionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveLocationFromSessionMutation, import("@/graphql/types").RemoveLocationFromSessionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").RemoveLocationFromSessionMutation, import("@/graphql/types").RemoveLocationFromSessionMutationVariables>(RemoveLocationFromSessionDocument, options);
}
export type RemoveLocationFromSessionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").RemoveLocationFromSessionMutation, import("@/graphql/types").RemoveLocationFromSessionMutationVariables>;
export const UpdateSessionForSessionLocationSectionDocument = gql`
    mutation UpdateSessionForSessionLocationSection($input: UpdateSessionInput!) {
  updateSession(input: $input) {
    id
    city
    gameZoneId
    credits
    priceZone
    gameZone {
      id
      point
    }
    venue {
      ...sessionVenueContacts
    }
    locationDetails
  }
}
    ${SessionVenueContactsFragmentDoc}`;
export function useUpdateSessionForSessionLocationSectionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionForSessionLocationSectionMutation, import("@/graphql/types").UpdateSessionForSessionLocationSectionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionForSessionLocationSectionMutation, import("@/graphql/types").UpdateSessionForSessionLocationSectionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionForSessionLocationSectionMutation, import("@/graphql/types").UpdateSessionForSessionLocationSectionMutationVariables>(UpdateSessionForSessionLocationSectionDocument, options);
}
export type UpdateSessionForSessionLocationSectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionForSessionLocationSectionMutation, import("@/graphql/types").UpdateSessionForSessionLocationSectionMutationVariables>;
export const AddTimelineItemDocument = gql`
    mutation AddTimelineItem($input: AddTimelineItem!) {
  addTimelineItem(input: $input) {
    id
    time
    description
  }
}
    `;
export function useAddTimelineItemMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").AddTimelineItemMutation, import("@/graphql/types").AddTimelineItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").AddTimelineItemMutation, import("@/graphql/types").AddTimelineItemMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").AddTimelineItemMutation, import("@/graphql/types").AddTimelineItemMutationVariables>(AddTimelineItemDocument, options);
}
export type AddTimelineItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").AddTimelineItemMutation, import("@/graphql/types").AddTimelineItemMutationVariables>;
export const RemoveTimelineItemDocument = gql`
    mutation RemoveTimelineItem($input: RemoveTimelineItem!) {
  removeTimelineItem(input: $input) {
    id
  }
}
    `;
export function useRemoveTimelineItemMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveTimelineItemMutation, import("@/graphql/types").RemoveTimelineItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveTimelineItemMutation, import("@/graphql/types").RemoveTimelineItemMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").RemoveTimelineItemMutation, import("@/graphql/types").RemoveTimelineItemMutationVariables>(RemoveTimelineItemDocument, options);
}
export type RemoveTimelineItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").RemoveTimelineItemMutation, import("@/graphql/types").RemoveTimelineItemMutationVariables>;
export const SetTimelineDocument = gql`
    mutation SetTimeline($input: SetTimelineInput!) {
  setTimeline(input: $input) {
    id
    timeline {
      ...timeline
    }
  }
}
    ${TimelineFragmentDoc}`;
export function useSetTimelineMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetTimelineMutation, import("@/graphql/types").SetTimelineMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetTimelineMutation, import("@/graphql/types").SetTimelineMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").SetTimelineMutation, import("@/graphql/types").SetTimelineMutationVariables>(SetTimelineDocument, options);
}
export type SetTimelineMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").SetTimelineMutation, import("@/graphql/types").SetTimelineMutationVariables>;
export const GetSessionForShuffleDocument = gql`
    query GetSessionForShuffle($id: ID!) {
  session(id: $id) {
    id
    assignments {
      id
      order
    }
  }
}
    `;
export function useGetSessionForShuffleQuery(variables: import("@/graphql/types").GetSessionForShuffleQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForShuffleQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForShuffleQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShuffleQuery, import("@/graphql/types").GetSessionForShuffleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShuffleQuery, import("@/graphql/types").GetSessionForShuffleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShuffleQuery, import("@/graphql/types").GetSessionForShuffleQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionForShuffleQuery, import("@/graphql/types").GetSessionForShuffleQueryVariables>(GetSessionForShuffleDocument, variables, options);
}
export function useGetSessionForShuffleLazyQuery(variables: import("@/graphql/types").GetSessionForShuffleQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForShuffleQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForShuffleQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShuffleQuery, import("@/graphql/types").GetSessionForShuffleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShuffleQuery, import("@/graphql/types").GetSessionForShuffleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShuffleQuery, import("@/graphql/types").GetSessionForShuffleQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionForShuffleQuery, import("@/graphql/types").GetSessionForShuffleQueryVariables>(GetSessionForShuffleDocument, variables, options);
}
export type GetSessionForShuffleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionForShuffleQuery, import("@/graphql/types").GetSessionForShuffleQueryVariables>;
export const GetSessionForStaffAssignmentDialogDocument = gql`
    query GetSessionForStaffAssignmentDialog($id: ID!) {
  session(id: $id) {
    ...sessionForStaffAssignmentDialog
  }
}
    ${SessionForStaffAssignmentDialogFragmentDoc}`;
export function useGetSessionForStaffAssignmentDialogQuery(variables: import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForStaffAssignmentDialogQuery, import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForStaffAssignmentDialogQuery, import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForStaffAssignmentDialogQuery, import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionForStaffAssignmentDialogQuery, import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables>(GetSessionForStaffAssignmentDialogDocument, variables, options);
}
export function useGetSessionForStaffAssignmentDialogLazyQuery(variables: import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForStaffAssignmentDialogQuery, import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForStaffAssignmentDialogQuery, import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForStaffAssignmentDialogQuery, import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionForStaffAssignmentDialogQuery, import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables>(GetSessionForStaffAssignmentDialogDocument, variables, options);
}
export type GetSessionForStaffAssignmentDialogQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionForStaffAssignmentDialogQuery, import("@/graphql/types").GetSessionForStaffAssignmentDialogQueryVariables>;
export const AddAdditionalBookingContactDocument = gql`
    mutation AddAdditionalBookingContact($input: AddAdditionalBookingContactsToSessionInput!) {
  addAdditionalBookingContactsToSession(input: $input) {
    id
    additionalBookingContacts {
      ...userWithContacts
    }
  }
}
    ${UserWithContactsFragmentDoc}`;
export function useAddAdditionalBookingContactMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").AddAdditionalBookingContactMutation, import("@/graphql/types").AddAdditionalBookingContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").AddAdditionalBookingContactMutation, import("@/graphql/types").AddAdditionalBookingContactMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").AddAdditionalBookingContactMutation, import("@/graphql/types").AddAdditionalBookingContactMutationVariables>(AddAdditionalBookingContactDocument, options);
}
export type AddAdditionalBookingContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").AddAdditionalBookingContactMutation, import("@/graphql/types").AddAdditionalBookingContactMutationVariables>;
export const ClearSessionAssignmentForStaffAssignmentDocument = gql`
    mutation ClearSessionAssignmentForStaffAssignment($input: ClearSessionAssignmentInput!) {
  clearSessionAssignment(input: $input) {
    ...sessionAssignmentForStaffAssignment
  }
}
    ${SessionAssignmentForStaffAssignmentFragmentDoc}`;
export function useClearSessionAssignmentForStaffAssignmentMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").ClearSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").ClearSessionAssignmentForStaffAssignmentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").ClearSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").ClearSessionAssignmentForStaffAssignmentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").ClearSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").ClearSessionAssignmentForStaffAssignmentMutationVariables>(ClearSessionAssignmentForStaffAssignmentDocument, options);
}
export type ClearSessionAssignmentForStaffAssignmentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").ClearSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").ClearSessionAssignmentForStaffAssignmentMutationVariables>;
export const DeleteSessionAssignmentForStaffAssignmentDocument = gql`
    mutation DeleteSessionAssignmentForStaffAssignment($input: DeleteSessionAssignmentInput!) {
  deleteSessionAssignment(input: $input) {
    id
  }
}
    `;
export function useDeleteSessionAssignmentForStaffAssignmentMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").DeleteSessionAssignmentForStaffAssignmentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").DeleteSessionAssignmentForStaffAssignmentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").DeleteSessionAssignmentForStaffAssignmentMutationVariables>(DeleteSessionAssignmentForStaffAssignmentDocument, options);
}
export type DeleteSessionAssignmentForStaffAssignmentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").DeleteSessionAssignmentForStaffAssignmentMutationVariables>;
export const GetSessionForShowDocument = gql`
    query GetSessionForShow($id: ID!) {
  session(id: $id) {
    ...sessionForShow
  }
}
    ${SessionForShowFragmentDoc}`;
export function useGetSessionForShowQuery(variables: import("@/graphql/types").GetSessionForShowQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForShowQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForShowQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShowQuery, import("@/graphql/types").GetSessionForShowQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShowQuery, import("@/graphql/types").GetSessionForShowQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShowQuery, import("@/graphql/types").GetSessionForShowQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionForShowQuery, import("@/graphql/types").GetSessionForShowQueryVariables>(GetSessionForShowDocument, variables, options);
}
export function useGetSessionForShowLazyQuery(variables: import("@/graphql/types").GetSessionForShowQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForShowQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForShowQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShowQuery, import("@/graphql/types").GetSessionForShowQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShowQuery, import("@/graphql/types").GetSessionForShowQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForShowQuery, import("@/graphql/types").GetSessionForShowQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionForShowQuery, import("@/graphql/types").GetSessionForShowQueryVariables>(GetSessionForShowDocument, variables, options);
}
export type GetSessionForShowQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionForShowQuery, import("@/graphql/types").GetSessionForShowQueryVariables>;
export const RemoveAdditionalBookingContactDocument = gql`
    mutation RemoveAdditionalBookingContact($input: RemoveAdditionalBookingContactsFromSessionInput!) {
  removeAdditionalBookingContactsFromSession(input: $input) {
    id
    additionalBookingContacts {
      ...userWithContacts
    }
  }
}
    ${UserWithContactsFragmentDoc}`;
export function useRemoveAdditionalBookingContactMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveAdditionalBookingContactMutation, import("@/graphql/types").RemoveAdditionalBookingContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveAdditionalBookingContactMutation, import("@/graphql/types").RemoveAdditionalBookingContactMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").RemoveAdditionalBookingContactMutation, import("@/graphql/types").RemoveAdditionalBookingContactMutationVariables>(RemoveAdditionalBookingContactDocument, options);
}
export type RemoveAdditionalBookingContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").RemoveAdditionalBookingContactMutation, import("@/graphql/types").RemoveAdditionalBookingContactMutationVariables>;
export const RemoveSessionBookingContactDocument = gql`
    mutation RemoveSessionBookingContact($input: RemoveBookingContactFromSessionInput!) {
  removeBookingContactFromSession(input: $input) {
    id
    bookingContact {
      id
    }
  }
}
    `;
export function useRemoveSessionBookingContactMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveSessionBookingContactMutation, import("@/graphql/types").RemoveSessionBookingContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveSessionBookingContactMutation, import("@/graphql/types").RemoveSessionBookingContactMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").RemoveSessionBookingContactMutation, import("@/graphql/types").RemoveSessionBookingContactMutationVariables>(RemoveSessionBookingContactDocument, options);
}
export type RemoveSessionBookingContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").RemoveSessionBookingContactMutation, import("@/graphql/types").RemoveSessionBookingContactMutationVariables>;
export const RemoveSessionContactDocument = gql`
    mutation RemoveSessionContact($input: RemoveContactFromSessionInput!) {
  removeContactFromSession(input: $input) {
    id
    contact {
      id
    }
  }
}
    `;
export function useRemoveSessionContactMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveSessionContactMutation, import("@/graphql/types").RemoveSessionContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").RemoveSessionContactMutation, import("@/graphql/types").RemoveSessionContactMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").RemoveSessionContactMutation, import("@/graphql/types").RemoveSessionContactMutationVariables>(RemoveSessionContactDocument, options);
}
export type RemoveSessionContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").RemoveSessionContactMutation, import("@/graphql/types").RemoveSessionContactMutationVariables>;
export const ListSessionsForPickPastEventsDocument = gql`
    query ListSessionsForPickPastEvents($filter: SessionFilter, $paging: OffsetPaging, $sorting: [SessionSort!]) {
  sessions(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...session
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${SessionFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListSessionsForPickPastEventsQuery(variables: import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForPickPastEventsQuery, import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForPickPastEventsQuery, import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForPickPastEventsQuery, import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListSessionsForPickPastEventsQuery, import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables>(ListSessionsForPickPastEventsDocument, variables, options);
}
export function useListSessionsForPickPastEventsLazyQuery(variables: import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForPickPastEventsQuery, import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForPickPastEventsQuery, import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionsForPickPastEventsQuery, import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListSessionsForPickPastEventsQuery, import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables>(ListSessionsForPickPastEventsDocument, variables, options);
}
export type ListSessionsForPickPastEventsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListSessionsForPickPastEventsQuery, import("@/graphql/types").ListSessionsForPickPastEventsQueryVariables>;
export const SetSessionBookingContactDocument = gql`
    mutation SetSessionBookingContact($input: SetBookingContactOnSessionInput!) {
  setBookingContactOnSession(input: $input) {
    id
    bookingContact {
      ...userWithContacts
    }
  }
}
    ${UserWithContactsFragmentDoc}`;
export function useSetSessionBookingContactMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetSessionBookingContactMutation, import("@/graphql/types").SetSessionBookingContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetSessionBookingContactMutation, import("@/graphql/types").SetSessionBookingContactMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").SetSessionBookingContactMutation, import("@/graphql/types").SetSessionBookingContactMutationVariables>(SetSessionBookingContactDocument, options);
}
export type SetSessionBookingContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").SetSessionBookingContactMutation, import("@/graphql/types").SetSessionBookingContactMutationVariables>;
export const SetUserOnSessionAssignmentForStaffAssignmentDocument = gql`
    mutation SetUserOnSessionAssignmentForStaffAssignment($input: SetUserOnSessionAssignmentInput!) {
  setUserOnSessionAssignment(input: $input) {
    ...sessionAssignmentForStaffAssignment
  }
}
    ${SessionAssignmentForStaffAssignmentFragmentDoc}`;
export function useSetUserOnSessionAssignmentForStaffAssignmentMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetUserOnSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").SetUserOnSessionAssignmentForStaffAssignmentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").SetUserOnSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").SetUserOnSessionAssignmentForStaffAssignmentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").SetUserOnSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").SetUserOnSessionAssignmentForStaffAssignmentMutationVariables>(SetUserOnSessionAssignmentForStaffAssignmentDocument, options);
}
export type SetUserOnSessionAssignmentForStaffAssignmentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").SetUserOnSessionAssignmentForStaffAssignmentMutation, import("@/graphql/types").SetUserOnSessionAssignmentForStaffAssignmentMutationVariables>;
export const GetSessionWithSchedulingDataDocument = gql`
    query GetSessionWithSchedulingData($id: ID!) {
  session(id: $id) {
    ...sessionWithSchedulingData
  }
}
    ${SessionWithSchedulingDataFragmentDoc}`;
export function useGetSessionWithSchedulingDataQuery(variables: import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithSchedulingDataQuery, import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithSchedulingDataQuery, import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithSchedulingDataQuery, import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionWithSchedulingDataQuery, import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables>(GetSessionWithSchedulingDataDocument, variables, options);
}
export function useGetSessionWithSchedulingDataLazyQuery(variables: import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithSchedulingDataQuery, import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithSchedulingDataQuery, import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionWithSchedulingDataQuery, import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionWithSchedulingDataQuery, import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables>(GetSessionWithSchedulingDataDocument, variables, options);
}
export type GetSessionWithSchedulingDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionWithSchedulingDataQuery, import("@/graphql/types").GetSessionWithSchedulingDataQueryVariables>;
export const ListExperiencesForCategoryDocument = gql`
    query ListExperiencesForCategory($category: ExperienceCategory!, $visible: Boolean) {
  experiences(filter: {category: {eq: $category}, visible: {is: $visible}}) {
    nodes {
      ...experienceForList
    }
  }
}
    ${ExperienceForListFragmentDoc}`;
export function useListExperiencesForCategoryQuery(variables: import("@/graphql/types").ListExperiencesForCategoryQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListExperiencesForCategoryQueryVariables> | ReactiveFunction<import("@/graphql/types").ListExperiencesForCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForCategoryQuery, import("@/graphql/types").ListExperiencesForCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForCategoryQuery, import("@/graphql/types").ListExperiencesForCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForCategoryQuery, import("@/graphql/types").ListExperiencesForCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListExperiencesForCategoryQuery, import("@/graphql/types").ListExperiencesForCategoryQueryVariables>(ListExperiencesForCategoryDocument, variables, options);
}
export function useListExperiencesForCategoryLazyQuery(variables: import("@/graphql/types").ListExperiencesForCategoryQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListExperiencesForCategoryQueryVariables> | ReactiveFunction<import("@/graphql/types").ListExperiencesForCategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForCategoryQuery, import("@/graphql/types").ListExperiencesForCategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForCategoryQuery, import("@/graphql/types").ListExperiencesForCategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesForCategoryQuery, import("@/graphql/types").ListExperiencesForCategoryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListExperiencesForCategoryQuery, import("@/graphql/types").ListExperiencesForCategoryQueryVariables>(ListExperiencesForCategoryDocument, variables, options);
}
export type ListExperiencesForCategoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListExperiencesForCategoryQuery, import("@/graphql/types").ListExperiencesForCategoryQueryVariables>;
export const GetExperienceForExperienceCardDocument = gql`
    query GetExperienceForExperienceCard($id: ID!) {
  experience(id: $id) {
    ...experienceForExperienceCard
  }
}
    ${ExperienceForExperienceCardFragmentDoc}`;
export function useGetExperienceForExperienceCardQuery(variables: import("@/graphql/types").GetExperienceForExperienceCardQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetExperienceForExperienceCardQueryVariables> | ReactiveFunction<import("@/graphql/types").GetExperienceForExperienceCardQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceCardQuery, import("@/graphql/types").GetExperienceForExperienceCardQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceCardQuery, import("@/graphql/types").GetExperienceForExperienceCardQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceCardQuery, import("@/graphql/types").GetExperienceForExperienceCardQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetExperienceForExperienceCardQuery, import("@/graphql/types").GetExperienceForExperienceCardQueryVariables>(GetExperienceForExperienceCardDocument, variables, options);
}
export function useGetExperienceForExperienceCardLazyQuery(variables: import("@/graphql/types").GetExperienceForExperienceCardQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetExperienceForExperienceCardQueryVariables> | ReactiveFunction<import("@/graphql/types").GetExperienceForExperienceCardQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceCardQuery, import("@/graphql/types").GetExperienceForExperienceCardQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceCardQuery, import("@/graphql/types").GetExperienceForExperienceCardQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetExperienceForExperienceCardQuery, import("@/graphql/types").GetExperienceForExperienceCardQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetExperienceForExperienceCardQuery, import("@/graphql/types").GetExperienceForExperienceCardQueryVariables>(GetExperienceForExperienceCardDocument, variables, options);
}
export type GetExperienceForExperienceCardQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetExperienceForExperienceCardQuery, import("@/graphql/types").GetExperienceForExperienceCardQueryVariables>;
export const CopyExperienceDocument = gql`
    mutation CopyExperience($id: ID!) {
  copyExperience(id: $id) {
    ...experienceForExperienceCard
  }
}
    ${ExperienceForExperienceCardFragmentDoc}`;
export function useCopyExperienceMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CopyExperienceMutation, import("@/graphql/types").CopyExperienceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CopyExperienceMutation, import("@/graphql/types").CopyExperienceMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CopyExperienceMutation, import("@/graphql/types").CopyExperienceMutationVariables>(CopyExperienceDocument, options);
}
export type CopyExperienceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CopyExperienceMutation, import("@/graphql/types").CopyExperienceMutationVariables>;
export const CreateExperienceFormatDocument = gql`
    mutation CreateExperienceFormat($input: CreateExperienceFormatInput!) {
  createExperienceFormat(input: $input) {
    ...experienceFormatForExperienceCard
  }
}
    ${ExperienceFormatForExperienceCardFragmentDoc}`;
export function useCreateExperienceFormatMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateExperienceFormatMutation, import("@/graphql/types").CreateExperienceFormatMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateExperienceFormatMutation, import("@/graphql/types").CreateExperienceFormatMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateExperienceFormatMutation, import("@/graphql/types").CreateExperienceFormatMutationVariables>(CreateExperienceFormatDocument, options);
}
export type CreateExperienceFormatMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateExperienceFormatMutation, import("@/graphql/types").CreateExperienceFormatMutationVariables>;
export const DeleteExperienceDocument = gql`
    mutation DeleteExperience($id: ID!) {
  deleteExperience(id: $id)
}
    `;
export function useDeleteExperienceMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteExperienceMutation, import("@/graphql/types").DeleteExperienceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteExperienceMutation, import("@/graphql/types").DeleteExperienceMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteExperienceMutation, import("@/graphql/types").DeleteExperienceMutationVariables>(DeleteExperienceDocument, options);
}
export type DeleteExperienceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteExperienceMutation, import("@/graphql/types").DeleteExperienceMutationVariables>;
export const UpdateExperienceDocument = gql`
    mutation UpdateExperience($input: UpdateOneExperienceInput!) {
  updateExperience(input: $input) {
    id
    title
    subtitle
    description
    visible
    bookable
    subscribers
    position
    category
    status
    tags
    tier
    attributes {
      gCalPrefix
      hubSpotDisabled
      webSiteDisabled
    }
    overview {
      titleTag
      description
      cost
      path
      attendees
      label
      youtube
      features {
        ...experienceOverviewItem
      }
      feedbacks {
        ...experienceOverviewItem
      }
      studies {
        ...experienceOverviewItem
      }
      keywords
      url
    }
  }
}
    ${ExperienceOverviewItemFragmentDoc}`;
export function useUpdateExperienceMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateExperienceMutation, import("@/graphql/types").UpdateExperienceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateExperienceMutation, import("@/graphql/types").UpdateExperienceMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateExperienceMutation, import("@/graphql/types").UpdateExperienceMutationVariables>(UpdateExperienceDocument, options);
}
export type UpdateExperienceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateExperienceMutation, import("@/graphql/types").UpdateExperienceMutationVariables>;
export const DeleteExperienceFormatDocument = gql`
    mutation DeleteExperienceFormat($id: ID!) {
  deleteExperienceFormat(input: {id: $id}) {
    id
  }
}
    `;
export function useDeleteExperienceFormatMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteExperienceFormatMutation, import("@/graphql/types").DeleteExperienceFormatMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteExperienceFormatMutation, import("@/graphql/types").DeleteExperienceFormatMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteExperienceFormatMutation, import("@/graphql/types").DeleteExperienceFormatMutationVariables>(DeleteExperienceFormatDocument, options);
}
export type DeleteExperienceFormatMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteExperienceFormatMutation, import("@/graphql/types").DeleteExperienceFormatMutationVariables>;
export const UpdateExperienceFormatDocument = gql`
    mutation UpdateExperienceFormat($input: UpdateOneExperienceFormatInput!) {
  updateExperienceFormat(input: $input) {
    ...experienceFormatForEdit
  }
}
    ${ExperienceFormatForEditFragmentDoc}`;
export function useUpdateExperienceFormatMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateExperienceFormatMutation, import("@/graphql/types").UpdateExperienceFormatMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateExperienceFormatMutation, import("@/graphql/types").UpdateExperienceFormatMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateExperienceFormatMutation, import("@/graphql/types").UpdateExperienceFormatMutationVariables>(UpdateExperienceFormatDocument, options);
}
export type UpdateExperienceFormatMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateExperienceFormatMutation, import("@/graphql/types").UpdateExperienceFormatMutationVariables>;
export const CreateExperienceForListDocument = gql`
    mutation CreateExperienceForList($input: CreateExperienceInput!) {
  createExperience(input: $input) {
    ...experienceForList
  }
}
    ${ExperienceForListFragmentDoc}`;
export function useCreateExperienceForListMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateExperienceForListMutation, import("@/graphql/types").CreateExperienceForListMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateExperienceForListMutation, import("@/graphql/types").CreateExperienceForListMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateExperienceForListMutation, import("@/graphql/types").CreateExperienceForListMutationVariables>(CreateExperienceForListDocument, options);
}
export type CreateExperienceForListMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateExperienceForListMutation, import("@/graphql/types").CreateExperienceForListMutationVariables>;
export const ListExperiencesDocument = gql`
    query ListExperiences($filter: ExperienceFilter) {
  experiences(filter: $filter) {
    nodes {
      ...experienceForList
    }
  }
}
    ${ExperienceForListFragmentDoc}`;
export function useListExperiencesQuery(variables: import("@/graphql/types").ListExperiencesQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListExperiencesQueryVariables> | ReactiveFunction<import("@/graphql/types").ListExperiencesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesQuery, import("@/graphql/types").ListExperiencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesQuery, import("@/graphql/types").ListExperiencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesQuery, import("@/graphql/types").ListExperiencesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListExperiencesQuery, import("@/graphql/types").ListExperiencesQueryVariables>(ListExperiencesDocument, variables, options);
}
export function useListExperiencesLazyQuery(variables: import("@/graphql/types").ListExperiencesQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListExperiencesQueryVariables> | ReactiveFunction<import("@/graphql/types").ListExperiencesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesQuery, import("@/graphql/types").ListExperiencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesQuery, import("@/graphql/types").ListExperiencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListExperiencesQuery, import("@/graphql/types").ListExperiencesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListExperiencesQuery, import("@/graphql/types").ListExperiencesQueryVariables>(ListExperiencesDocument, variables, options);
}
export type ListExperiencesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListExperiencesQuery, import("@/graphql/types").ListExperiencesQueryVariables>;
export const ListOrdersForOrdersListDocument = gql`
    query ListOrdersForOrdersList($workspaceId: ID!, $paging: OffsetPaging) {
  orders(
    filter: {workspaceId: {eq: $workspaceId}}
    paging: $paging
    sorting: {direction: DESC, field: createdAt}
  ) {
    nodes {
      ...orderForOrderListItem
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${OrderForOrderListItemFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListOrdersForOrdersListQuery(variables: import("@/graphql/types").ListOrdersForOrdersListQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListOrdersForOrdersListQueryVariables> | ReactiveFunction<import("@/graphql/types").ListOrdersForOrdersListQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOrdersForOrdersListQuery, import("@/graphql/types").ListOrdersForOrdersListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOrdersForOrdersListQuery, import("@/graphql/types").ListOrdersForOrdersListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOrdersForOrdersListQuery, import("@/graphql/types").ListOrdersForOrdersListQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListOrdersForOrdersListQuery, import("@/graphql/types").ListOrdersForOrdersListQueryVariables>(ListOrdersForOrdersListDocument, variables, options);
}
export function useListOrdersForOrdersListLazyQuery(variables: import("@/graphql/types").ListOrdersForOrdersListQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListOrdersForOrdersListQueryVariables> | ReactiveFunction<import("@/graphql/types").ListOrdersForOrdersListQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOrdersForOrdersListQuery, import("@/graphql/types").ListOrdersForOrdersListQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOrdersForOrdersListQuery, import("@/graphql/types").ListOrdersForOrdersListQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOrdersForOrdersListQuery, import("@/graphql/types").ListOrdersForOrdersListQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListOrdersForOrdersListQuery, import("@/graphql/types").ListOrdersForOrdersListQueryVariables>(ListOrdersForOrdersListDocument, variables, options);
}
export type ListOrdersForOrdersListQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListOrdersForOrdersListQuery, import("@/graphql/types").ListOrdersForOrdersListQueryVariables>;
export const GetSessionForWorkspaceDocument = gql`
    query GetSessionForWorkspace($id: ID!) {
  session(id: $id) {
    ...sessionForWorkspace
  }
}
    ${SessionForWorkspaceFragmentDoc}`;
export function useGetSessionForWorkspaceQuery(variables: import("@/graphql/types").GetSessionForWorkspaceQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForWorkspaceQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForWorkspaceQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForWorkspaceQuery, import("@/graphql/types").GetSessionForWorkspaceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForWorkspaceQuery, import("@/graphql/types").GetSessionForWorkspaceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForWorkspaceQuery, import("@/graphql/types").GetSessionForWorkspaceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionForWorkspaceQuery, import("@/graphql/types").GetSessionForWorkspaceQueryVariables>(GetSessionForWorkspaceDocument, variables, options);
}
export function useGetSessionForWorkspaceLazyQuery(variables: import("@/graphql/types").GetSessionForWorkspaceQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForWorkspaceQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForWorkspaceQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForWorkspaceQuery, import("@/graphql/types").GetSessionForWorkspaceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForWorkspaceQuery, import("@/graphql/types").GetSessionForWorkspaceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForWorkspaceQuery, import("@/graphql/types").GetSessionForWorkspaceQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionForWorkspaceQuery, import("@/graphql/types").GetSessionForWorkspaceQueryVariables>(GetSessionForWorkspaceDocument, variables, options);
}
export type GetSessionForWorkspaceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionForWorkspaceQuery, import("@/graphql/types").GetSessionForWorkspaceQueryVariables>;
export const GetOpenWeveForOpenWeveCardDocument = gql`
    query GetOpenWeveForOpenWeveCard($id: ID!) {
  openWeve(id: $id) {
    ...openWeveForOpenWeveCard
  }
}
    ${OpenWeveForOpenWeveCardFragmentDoc}`;
export function useGetOpenWeveForOpenWeveCardQuery(variables: import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables> | ReactiveFunction<import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOpenWeveForOpenWeveCardQuery, import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOpenWeveForOpenWeveCardQuery, import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOpenWeveForOpenWeveCardQuery, import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetOpenWeveForOpenWeveCardQuery, import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables>(GetOpenWeveForOpenWeveCardDocument, variables, options);
}
export function useGetOpenWeveForOpenWeveCardLazyQuery(variables: import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables> | ReactiveFunction<import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOpenWeveForOpenWeveCardQuery, import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOpenWeveForOpenWeveCardQuery, import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetOpenWeveForOpenWeveCardQuery, import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetOpenWeveForOpenWeveCardQuery, import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables>(GetOpenWeveForOpenWeveCardDocument, variables, options);
}
export type GetOpenWeveForOpenWeveCardQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetOpenWeveForOpenWeveCardQuery, import("@/graphql/types").GetOpenWeveForOpenWeveCardQueryVariables>;
export const UpdateOpenWeveDocument = gql`
    mutation UpdateOpenWeve($input: UpdateOpenWeveInput!) {
  updateOpenWeve(input: $input) {
    ...openWeveForEdit
  }
}
    ${OpenWeveForEditFragmentDoc}`;
export function useUpdateOpenWeveMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateOpenWeveMutation, import("@/graphql/types").UpdateOpenWeveMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateOpenWeveMutation, import("@/graphql/types").UpdateOpenWeveMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateOpenWeveMutation, import("@/graphql/types").UpdateOpenWeveMutationVariables>(UpdateOpenWeveDocument, options);
}
export type UpdateOpenWeveMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateOpenWeveMutation, import("@/graphql/types").UpdateOpenWeveMutationVariables>;
export const CreateOpenWeveForListDocument = gql`
    mutation CreateOpenWeveForList($input: CreateOpenWeveInput!) {
  createOpenWeve(input: $input) {
    ...openWeveForList
  }
}
    ${OpenWeveForListFragmentDoc}`;
export function useCreateOpenWeveForListMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateOpenWeveForListMutation, import("@/graphql/types").CreateOpenWeveForListMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateOpenWeveForListMutation, import("@/graphql/types").CreateOpenWeveForListMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateOpenWeveForListMutation, import("@/graphql/types").CreateOpenWeveForListMutationVariables>(CreateOpenWeveForListDocument, options);
}
export type CreateOpenWeveForListMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateOpenWeveForListMutation, import("@/graphql/types").CreateOpenWeveForListMutationVariables>;
export const DeleteOpenWeveDocument = gql`
    mutation DeleteOpenWeve($input: DeleteOpenWeveInput!) {
  deleteOpenWeve(input: $input) {
    id
  }
}
    `;
export function useDeleteOpenWeveMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteOpenWeveMutation, import("@/graphql/types").DeleteOpenWeveMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteOpenWeveMutation, import("@/graphql/types").DeleteOpenWeveMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteOpenWeveMutation, import("@/graphql/types").DeleteOpenWeveMutationVariables>(DeleteOpenWeveDocument, options);
}
export type DeleteOpenWeveMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteOpenWeveMutation, import("@/graphql/types").DeleteOpenWeveMutationVariables>;
export const ListOpenWevesDocument = gql`
    query ListOpenWeves($workspaceId: ID) {
  openWeves(filter: {workspace: {id: {eq: $workspaceId}}}) {
    nodes {
      ...openWeveForList
    }
  }
}
    ${OpenWeveForListFragmentDoc}`;
export function useListOpenWevesQuery(variables: import("@/graphql/types").ListOpenWevesQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListOpenWevesQueryVariables> | ReactiveFunction<import("@/graphql/types").ListOpenWevesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOpenWevesQuery, import("@/graphql/types").ListOpenWevesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOpenWevesQuery, import("@/graphql/types").ListOpenWevesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOpenWevesQuery, import("@/graphql/types").ListOpenWevesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListOpenWevesQuery, import("@/graphql/types").ListOpenWevesQueryVariables>(ListOpenWevesDocument, variables, options);
}
export function useListOpenWevesLazyQuery(variables: import("@/graphql/types").ListOpenWevesQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListOpenWevesQueryVariables> | ReactiveFunction<import("@/graphql/types").ListOpenWevesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOpenWevesQuery, import("@/graphql/types").ListOpenWevesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOpenWevesQuery, import("@/graphql/types").ListOpenWevesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListOpenWevesQuery, import("@/graphql/types").ListOpenWevesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListOpenWevesQuery, import("@/graphql/types").ListOpenWevesQueryVariables>(ListOpenWevesDocument, variables, options);
}
export type ListOpenWevesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListOpenWevesQuery, import("@/graphql/types").ListOpenWevesQueryVariables>;
export const GetSessionForSessionGroupPageDocument = gql`
    query GetSessionForSessionGroupPage($id: ID!) {
  sessionGroup(id: $id) {
    ...sessionGroupForSessionGroupPage
  }
}
    ${SessionGroupForSessionGroupPageFragmentDoc}`;
export function useGetSessionForSessionGroupPageQuery(variables: import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForSessionGroupPageQuery, import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForSessionGroupPageQuery, import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForSessionGroupPageQuery, import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionForSessionGroupPageQuery, import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables>(GetSessionForSessionGroupPageDocument, variables, options);
}
export function useGetSessionForSessionGroupPageLazyQuery(variables: import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForSessionGroupPageQuery, import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForSessionGroupPageQuery, import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionForSessionGroupPageQuery, import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionForSessionGroupPageQuery, import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables>(GetSessionForSessionGroupPageDocument, variables, options);
}
export type GetSessionForSessionGroupPageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionForSessionGroupPageQuery, import("@/graphql/types").GetSessionForSessionGroupPageQueryVariables>;
export const CreateAiPromptDocument = gql`
    mutation CreateAiPrompt($input: CreateOneAiPromptInput!) {
  createAiPrompt(input: $input) {
    ...aiPromptForAdminSettings
  }
}
    ${AiPromptForAdminSettingsFragmentDoc}`;
export function useCreateAiPromptMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateAiPromptMutation, import("@/graphql/types").CreateAiPromptMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateAiPromptMutation, import("@/graphql/types").CreateAiPromptMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateAiPromptMutation, import("@/graphql/types").CreateAiPromptMutationVariables>(CreateAiPromptDocument, options);
}
export type CreateAiPromptMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateAiPromptMutation, import("@/graphql/types").CreateAiPromptMutationVariables>;
export const DeleteAiPromptDocument = gql`
    mutation DeleteAiPrompt($id: ID!) {
  deleteAiPrompt(input: {id: $id}) {
    id
  }
}
    `;
export function useDeleteAiPromptMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteAiPromptMutation, import("@/graphql/types").DeleteAiPromptMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteAiPromptMutation, import("@/graphql/types").DeleteAiPromptMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteAiPromptMutation, import("@/graphql/types").DeleteAiPromptMutationVariables>(DeleteAiPromptDocument, options);
}
export type DeleteAiPromptMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteAiPromptMutation, import("@/graphql/types").DeleteAiPromptMutationVariables>;
export const GetAiPromptForEditDocument = gql`
    query GetAiPromptForEdit($id: ID!) {
  aiPrompt(id: $id) {
    ...aiPromptForAdminSettings
  }
}
    ${AiPromptForAdminSettingsFragmentDoc}`;
export function useGetAiPromptForEditQuery(variables: import("@/graphql/types").GetAiPromptForEditQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetAiPromptForEditQueryVariables> | ReactiveFunction<import("@/graphql/types").GetAiPromptForEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetAiPromptForEditQuery, import("@/graphql/types").GetAiPromptForEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetAiPromptForEditQuery, import("@/graphql/types").GetAiPromptForEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetAiPromptForEditQuery, import("@/graphql/types").GetAiPromptForEditQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetAiPromptForEditQuery, import("@/graphql/types").GetAiPromptForEditQueryVariables>(GetAiPromptForEditDocument, variables, options);
}
export function useGetAiPromptForEditLazyQuery(variables: import("@/graphql/types").GetAiPromptForEditQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetAiPromptForEditQueryVariables> | ReactiveFunction<import("@/graphql/types").GetAiPromptForEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetAiPromptForEditQuery, import("@/graphql/types").GetAiPromptForEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetAiPromptForEditQuery, import("@/graphql/types").GetAiPromptForEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetAiPromptForEditQuery, import("@/graphql/types").GetAiPromptForEditQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetAiPromptForEditQuery, import("@/graphql/types").GetAiPromptForEditQueryVariables>(GetAiPromptForEditDocument, variables, options);
}
export type GetAiPromptForEditQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetAiPromptForEditQuery, import("@/graphql/types").GetAiPromptForEditQueryVariables>;
export const ListAiPromptsDocument = gql`
    query ListAiPrompts($paging: OffsetPaging) {
  aiPrompts(paging: $paging) {
    nodes {
      ...aiPromptForListForAdminSettings
    }
    pageInfo {
      hasNextPage
    }
  }
}
    ${AiPromptForListForAdminSettingsFragmentDoc}`;
export function useListAiPromptsQuery(variables: import("@/graphql/types").ListAiPromptsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListAiPromptsQueryVariables> | ReactiveFunction<import("@/graphql/types").ListAiPromptsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListAiPromptsQuery, import("@/graphql/types").ListAiPromptsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListAiPromptsQuery, import("@/graphql/types").ListAiPromptsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListAiPromptsQuery, import("@/graphql/types").ListAiPromptsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListAiPromptsQuery, import("@/graphql/types").ListAiPromptsQueryVariables>(ListAiPromptsDocument, variables, options);
}
export function useListAiPromptsLazyQuery(variables: import("@/graphql/types").ListAiPromptsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListAiPromptsQueryVariables> | ReactiveFunction<import("@/graphql/types").ListAiPromptsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListAiPromptsQuery, import("@/graphql/types").ListAiPromptsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListAiPromptsQuery, import("@/graphql/types").ListAiPromptsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListAiPromptsQuery, import("@/graphql/types").ListAiPromptsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListAiPromptsQuery, import("@/graphql/types").ListAiPromptsQueryVariables>(ListAiPromptsDocument, variables, options);
}
export type ListAiPromptsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListAiPromptsQuery, import("@/graphql/types").ListAiPromptsQueryVariables>;
export const UpdateAiPromptDocument = gql`
    mutation UpdateAiPrompt($input: UpdateOneAiPromptInput!) {
  updateAiPrompt(input: $input) {
    ...aiPromptForAdminSettings
  }
}
    ${AiPromptForAdminSettingsFragmentDoc}`;
export function useUpdateAiPromptMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateAiPromptMutation, import("@/graphql/types").UpdateAiPromptMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateAiPromptMutation, import("@/graphql/types").UpdateAiPromptMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateAiPromptMutation, import("@/graphql/types").UpdateAiPromptMutationVariables>(UpdateAiPromptDocument, options);
}
export type UpdateAiPromptMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateAiPromptMutation, import("@/graphql/types").UpdateAiPromptMutationVariables>;
export const CreateInstructionDocument = gql`
    mutation CreateInstruction($input: CreateOneInstructionInput!) {
  createInstruction(input: $input) {
    id
    type
    hosts
    documentType
    content
    description
    title
  }
}
    `;
export function useCreateInstructionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateInstructionMutation, import("@/graphql/types").CreateInstructionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateInstructionMutation, import("@/graphql/types").CreateInstructionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateInstructionMutation, import("@/graphql/types").CreateInstructionMutationVariables>(CreateInstructionDocument, options);
}
export type CreateInstructionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateInstructionMutation, import("@/graphql/types").CreateInstructionMutationVariables>;
export const GetInstructionDocument = gql`
    query GetInstruction($id: ID!) {
  instruction(id: $id) {
    id
    type
    hosts
    documentType
    content
    description
    title
  }
}
    `;
export function useGetInstructionQuery(variables: import("@/graphql/types").GetInstructionQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetInstructionQueryVariables> | ReactiveFunction<import("@/graphql/types").GetInstructionQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetInstructionQuery, import("@/graphql/types").GetInstructionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetInstructionQuery, import("@/graphql/types").GetInstructionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetInstructionQuery, import("@/graphql/types").GetInstructionQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetInstructionQuery, import("@/graphql/types").GetInstructionQueryVariables>(GetInstructionDocument, variables, options);
}
export function useGetInstructionLazyQuery(variables: import("@/graphql/types").GetInstructionQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetInstructionQueryVariables> | ReactiveFunction<import("@/graphql/types").GetInstructionQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetInstructionQuery, import("@/graphql/types").GetInstructionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetInstructionQuery, import("@/graphql/types").GetInstructionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetInstructionQuery, import("@/graphql/types").GetInstructionQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetInstructionQuery, import("@/graphql/types").GetInstructionQueryVariables>(GetInstructionDocument, variables, options);
}
export type GetInstructionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetInstructionQuery, import("@/graphql/types").GetInstructionQueryVariables>;
export const ListInstructionsDocument = gql`
    query ListInstructions($paging: OffsetPaging) {
  instructions(paging: $paging) {
    nodes {
      id
      type
      documentType
    }
    pageInfo {
      hasNextPage
    }
  }
}
    `;
export function useListInstructionsQuery(variables: import("@/graphql/types").ListInstructionsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListInstructionsQueryVariables> | ReactiveFunction<import("@/graphql/types").ListInstructionsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListInstructionsQuery, import("@/graphql/types").ListInstructionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListInstructionsQuery, import("@/graphql/types").ListInstructionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListInstructionsQuery, import("@/graphql/types").ListInstructionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListInstructionsQuery, import("@/graphql/types").ListInstructionsQueryVariables>(ListInstructionsDocument, variables, options);
}
export function useListInstructionsLazyQuery(variables: import("@/graphql/types").ListInstructionsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListInstructionsQueryVariables> | ReactiveFunction<import("@/graphql/types").ListInstructionsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListInstructionsQuery, import("@/graphql/types").ListInstructionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListInstructionsQuery, import("@/graphql/types").ListInstructionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListInstructionsQuery, import("@/graphql/types").ListInstructionsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListInstructionsQuery, import("@/graphql/types").ListInstructionsQueryVariables>(ListInstructionsDocument, variables, options);
}
export type ListInstructionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListInstructionsQuery, import("@/graphql/types").ListInstructionsQueryVariables>;
export const UpdateInstructionDocument = gql`
    mutation UpdateInstruction($input: UpdateOneInstructionInput!) {
  updateInstruction(input: $input) {
    id
    type
    hosts
    documentType
    content
    description
  }
}
    `;
export function useUpdateInstructionMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateInstructionMutation, import("@/graphql/types").UpdateInstructionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateInstructionMutation, import("@/graphql/types").UpdateInstructionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateInstructionMutation, import("@/graphql/types").UpdateInstructionMutationVariables>(UpdateInstructionDocument, options);
}
export type UpdateInstructionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateInstructionMutation, import("@/graphql/types").UpdateInstructionMutationVariables>;
export const CreateModifierDocument = gql`
    mutation CreateModifier($input: CreateModifierInput!) {
  createModifier(input: $input) {
    ...modifierForAdminSettings
  }
}
    ${ModifierForAdminSettingsFragmentDoc}`;
export function useCreateModifierMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateModifierMutation, import("@/graphql/types").CreateModifierMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateModifierMutation, import("@/graphql/types").CreateModifierMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateModifierMutation, import("@/graphql/types").CreateModifierMutationVariables>(CreateModifierDocument, options);
}
export type CreateModifierMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateModifierMutation, import("@/graphql/types").CreateModifierMutationVariables>;
export const DeleteModifierDocument = gql`
    mutation DeleteModifier($id: ID!) {
  deleteModifier(input: {id: $id}) {
    id
  }
}
    `;
export function useDeleteModifierMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteModifierMutation, import("@/graphql/types").DeleteModifierMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteModifierMutation, import("@/graphql/types").DeleteModifierMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteModifierMutation, import("@/graphql/types").DeleteModifierMutationVariables>(DeleteModifierDocument, options);
}
export type DeleteModifierMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteModifierMutation, import("@/graphql/types").DeleteModifierMutationVariables>;
export const ListModifiersDocument = gql`
    query ListModifiers($paging: OffsetPaging) {
  modifiers(paging: $paging) {
    nodes {
      ...modifierForAdminSettings
    }
    pageInfo {
      hasNextPage
    }
  }
}
    ${ModifierForAdminSettingsFragmentDoc}`;
export function useListModifiersQuery(variables: import("@/graphql/types").ListModifiersQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListModifiersQueryVariables> | ReactiveFunction<import("@/graphql/types").ListModifiersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersQuery, import("@/graphql/types").ListModifiersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersQuery, import("@/graphql/types").ListModifiersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersQuery, import("@/graphql/types").ListModifiersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListModifiersQuery, import("@/graphql/types").ListModifiersQueryVariables>(ListModifiersDocument, variables, options);
}
export function useListModifiersLazyQuery(variables: import("@/graphql/types").ListModifiersQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListModifiersQueryVariables> | ReactiveFunction<import("@/graphql/types").ListModifiersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersQuery, import("@/graphql/types").ListModifiersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersQuery, import("@/graphql/types").ListModifiersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListModifiersQuery, import("@/graphql/types").ListModifiersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListModifiersQuery, import("@/graphql/types").ListModifiersQueryVariables>(ListModifiersDocument, variables, options);
}
export type ListModifiersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListModifiersQuery, import("@/graphql/types").ListModifiersQueryVariables>;
export const CreatePriceModifierDocument = gql`
    mutation CreatePriceModifier($input: CreateOnePriceModifierInput!) {
  createPriceModifier(input: $input) {
    ...priceModifierForAdminSettings
  }
}
    ${PriceModifierForAdminSettingsFragmentDoc}`;
export function useCreatePriceModifierMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreatePriceModifierMutation, import("@/graphql/types").CreatePriceModifierMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreatePriceModifierMutation, import("@/graphql/types").CreatePriceModifierMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreatePriceModifierMutation, import("@/graphql/types").CreatePriceModifierMutationVariables>(CreatePriceModifierDocument, options);
}
export type CreatePriceModifierMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreatePriceModifierMutation, import("@/graphql/types").CreatePriceModifierMutationVariables>;
export const DeletePriceModifierDocument = gql`
    mutation DeletePriceModifier($id: ID!) {
  deletePriceModifier(input: {id: $id}) {
    id
  }
}
    `;
export function useDeletePriceModifierMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeletePriceModifierMutation, import("@/graphql/types").DeletePriceModifierMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeletePriceModifierMutation, import("@/graphql/types").DeletePriceModifierMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeletePriceModifierMutation, import("@/graphql/types").DeletePriceModifierMutationVariables>(DeletePriceModifierDocument, options);
}
export type DeletePriceModifierMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeletePriceModifierMutation, import("@/graphql/types").DeletePriceModifierMutationVariables>;
export const GetPriceModifierDocument = gql`
    query GetPriceModifier($id: ID!) {
  priceModifier(id: $id) {
    ...priceModifierForAdminSettings
  }
}
    ${PriceModifierForAdminSettingsFragmentDoc}`;
export function useGetPriceModifierQuery(variables: import("@/graphql/types").GetPriceModifierQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetPriceModifierQueryVariables> | ReactiveFunction<import("@/graphql/types").GetPriceModifierQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetPriceModifierQuery, import("@/graphql/types").GetPriceModifierQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetPriceModifierQuery, import("@/graphql/types").GetPriceModifierQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetPriceModifierQuery, import("@/graphql/types").GetPriceModifierQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetPriceModifierQuery, import("@/graphql/types").GetPriceModifierQueryVariables>(GetPriceModifierDocument, variables, options);
}
export function useGetPriceModifierLazyQuery(variables: import("@/graphql/types").GetPriceModifierQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetPriceModifierQueryVariables> | ReactiveFunction<import("@/graphql/types").GetPriceModifierQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetPriceModifierQuery, import("@/graphql/types").GetPriceModifierQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetPriceModifierQuery, import("@/graphql/types").GetPriceModifierQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetPriceModifierQuery, import("@/graphql/types").GetPriceModifierQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetPriceModifierQuery, import("@/graphql/types").GetPriceModifierQueryVariables>(GetPriceModifierDocument, variables, options);
}
export type GetPriceModifierQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetPriceModifierQuery, import("@/graphql/types").GetPriceModifierQueryVariables>;
export const ListPriceModifierDocument = gql`
    query ListPriceModifier($paging: OffsetPaging) {
  priceModifiers(paging: $paging) {
    nodes {
      ...priceModifierForAdminSettings
    }
    pageInfo {
      hasNextPage
    }
  }
}
    ${PriceModifierForAdminSettingsFragmentDoc}`;
export function useListPriceModifierQuery(variables: import("@/graphql/types").ListPriceModifierQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListPriceModifierQueryVariables> | ReactiveFunction<import("@/graphql/types").ListPriceModifierQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPriceModifierQuery, import("@/graphql/types").ListPriceModifierQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPriceModifierQuery, import("@/graphql/types").ListPriceModifierQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPriceModifierQuery, import("@/graphql/types").ListPriceModifierQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListPriceModifierQuery, import("@/graphql/types").ListPriceModifierQueryVariables>(ListPriceModifierDocument, variables, options);
}
export function useListPriceModifierLazyQuery(variables: import("@/graphql/types").ListPriceModifierQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListPriceModifierQueryVariables> | ReactiveFunction<import("@/graphql/types").ListPriceModifierQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPriceModifierQuery, import("@/graphql/types").ListPriceModifierQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPriceModifierQuery, import("@/graphql/types").ListPriceModifierQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListPriceModifierQuery, import("@/graphql/types").ListPriceModifierQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListPriceModifierQuery, import("@/graphql/types").ListPriceModifierQueryVariables>(ListPriceModifierDocument, variables, options);
}
export type ListPriceModifierQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListPriceModifierQuery, import("@/graphql/types").ListPriceModifierQueryVariables>;
export const UpdatePriceModifierDocument = gql`
    mutation UpdatePriceModifier($input: UpdateOnePriceModifierInput!) {
  updatePriceModifier(input: $input) {
    ...priceModifierForAdminSettings
  }
}
    ${PriceModifierForAdminSettingsFragmentDoc}`;
export function useUpdatePriceModifierMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdatePriceModifierMutation, import("@/graphql/types").UpdatePriceModifierMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdatePriceModifierMutation, import("@/graphql/types").UpdatePriceModifierMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdatePriceModifierMutation, import("@/graphql/types").UpdatePriceModifierMutationVariables>(UpdatePriceModifierDocument, options);
}
export type UpdatePriceModifierMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdatePriceModifierMutation, import("@/graphql/types").UpdatePriceModifierMutationVariables>;
export const CreateSessionPhoneDocument = gql`
    mutation CreateSessionPhone($input: CreateOneSessionPhoneInput!) {
  createSessionPhone(input: $input) {
    ...sessionPhoneForAdminSettings
  }
}
    ${SessionPhoneForAdminSettingsFragmentDoc}`;
export function useCreateSessionPhoneMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateSessionPhoneMutation, import("@/graphql/types").CreateSessionPhoneMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateSessionPhoneMutation, import("@/graphql/types").CreateSessionPhoneMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateSessionPhoneMutation, import("@/graphql/types").CreateSessionPhoneMutationVariables>(CreateSessionPhoneDocument, options);
}
export type CreateSessionPhoneMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateSessionPhoneMutation, import("@/graphql/types").CreateSessionPhoneMutationVariables>;
export const DeleteSessionPhoneDocument = gql`
    mutation DeleteSessionPhone($id: ID!) {
  deleteSessionPhone(input: {id: $id}) {
    id
  }
}
    `;
export function useDeleteSessionPhoneMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteSessionPhoneMutation, import("@/graphql/types").DeleteSessionPhoneMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteSessionPhoneMutation, import("@/graphql/types").DeleteSessionPhoneMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteSessionPhoneMutation, import("@/graphql/types").DeleteSessionPhoneMutationVariables>(DeleteSessionPhoneDocument, options);
}
export type DeleteSessionPhoneMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteSessionPhoneMutation, import("@/graphql/types").DeleteSessionPhoneMutationVariables>;
export const GetSessionPhoneForEditDocument = gql`
    query GetSessionPhoneForEdit($id: ID!) {
  sessionPhone(id: $id) {
    ...sessionPhoneForAdminSettings
  }
}
    ${SessionPhoneForAdminSettingsFragmentDoc}`;
export function useGetSessionPhoneForEditQuery(variables: import("@/graphql/types").GetSessionPhoneForEditQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionPhoneForEditQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionPhoneForEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionPhoneForEditQuery, import("@/graphql/types").GetSessionPhoneForEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionPhoneForEditQuery, import("@/graphql/types").GetSessionPhoneForEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionPhoneForEditQuery, import("@/graphql/types").GetSessionPhoneForEditQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetSessionPhoneForEditQuery, import("@/graphql/types").GetSessionPhoneForEditQueryVariables>(GetSessionPhoneForEditDocument, variables, options);
}
export function useGetSessionPhoneForEditLazyQuery(variables: import("@/graphql/types").GetSessionPhoneForEditQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetSessionPhoneForEditQueryVariables> | ReactiveFunction<import("@/graphql/types").GetSessionPhoneForEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionPhoneForEditQuery, import("@/graphql/types").GetSessionPhoneForEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionPhoneForEditQuery, import("@/graphql/types").GetSessionPhoneForEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetSessionPhoneForEditQuery, import("@/graphql/types").GetSessionPhoneForEditQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetSessionPhoneForEditQuery, import("@/graphql/types").GetSessionPhoneForEditQueryVariables>(GetSessionPhoneForEditDocument, variables, options);
}
export type GetSessionPhoneForEditQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetSessionPhoneForEditQuery, import("@/graphql/types").GetSessionPhoneForEditQueryVariables>;
export const ListSessionPhonesDocument = gql`
    query ListSessionPhones($paging: OffsetPaging) {
  sessionPhones(paging: $paging) {
    nodes {
      ...sessionPhoneForAdminSettings
    }
    pageInfo {
      hasNextPage
    }
  }
}
    ${SessionPhoneForAdminSettingsFragmentDoc}`;
export function useListSessionPhonesQuery(variables: import("@/graphql/types").ListSessionPhonesQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionPhonesQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionPhonesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionPhonesQuery, import("@/graphql/types").ListSessionPhonesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionPhonesQuery, import("@/graphql/types").ListSessionPhonesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionPhonesQuery, import("@/graphql/types").ListSessionPhonesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListSessionPhonesQuery, import("@/graphql/types").ListSessionPhonesQueryVariables>(ListSessionPhonesDocument, variables, options);
}
export function useListSessionPhonesLazyQuery(variables: import("@/graphql/types").ListSessionPhonesQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListSessionPhonesQueryVariables> | ReactiveFunction<import("@/graphql/types").ListSessionPhonesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionPhonesQuery, import("@/graphql/types").ListSessionPhonesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionPhonesQuery, import("@/graphql/types").ListSessionPhonesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListSessionPhonesQuery, import("@/graphql/types").ListSessionPhonesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListSessionPhonesQuery, import("@/graphql/types").ListSessionPhonesQueryVariables>(ListSessionPhonesDocument, variables, options);
}
export type ListSessionPhonesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListSessionPhonesQuery, import("@/graphql/types").ListSessionPhonesQueryVariables>;
export const UpdateSessionPhoneDocument = gql`
    mutation UpdateSessionPhone($input: UpdateOneSessionPhoneInput!) {
  updateSessionPhone(input: $input) {
    ...sessionPhoneForAdminSettings
  }
}
    ${SessionPhoneForAdminSettingsFragmentDoc}`;
export function useUpdateSessionPhoneMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionPhoneMutation, import("@/graphql/types").UpdateSessionPhoneMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateSessionPhoneMutation, import("@/graphql/types").UpdateSessionPhoneMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateSessionPhoneMutation, import("@/graphql/types").UpdateSessionPhoneMutationVariables>(UpdateSessionPhoneDocument, options);
}
export type UpdateSessionPhoneMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateSessionPhoneMutation, import("@/graphql/types").UpdateSessionPhoneMutationVariables>;
export const CreateZoneAreaDocument = gql`
    mutation CreateZoneArea($input: CreateZoneAreaInput!) {
  createZoneArea(input: $input) {
    ...zoneAreaForAdminSettings
  }
}
    ${ZoneAreaForAdminSettingsFragmentDoc}`;
export function useCreateZoneAreaMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateZoneAreaMutation, import("@/graphql/types").CreateZoneAreaMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateZoneAreaMutation, import("@/graphql/types").CreateZoneAreaMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateZoneAreaMutation, import("@/graphql/types").CreateZoneAreaMutationVariables>(CreateZoneAreaDocument, options);
}
export type CreateZoneAreaMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateZoneAreaMutation, import("@/graphql/types").CreateZoneAreaMutationVariables>;
export const DeleteZoneAreaDocument = gql`
    mutation DeleteZoneArea($id: ID!) {
  deleteZoneArea(input: {id: $id}) {
    id
  }
}
    `;
export function useDeleteZoneAreaMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteZoneAreaMutation, import("@/graphql/types").DeleteZoneAreaMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteZoneAreaMutation, import("@/graphql/types").DeleteZoneAreaMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteZoneAreaMutation, import("@/graphql/types").DeleteZoneAreaMutationVariables>(DeleteZoneAreaDocument, options);
}
export type DeleteZoneAreaMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteZoneAreaMutation, import("@/graphql/types").DeleteZoneAreaMutationVariables>;
export const GetZoneAreaForEditDocument = gql`
    query GetZoneAreaForEdit($id: ID!) {
  zoneArea(id: $id) {
    ...zoneAreaForZoneAreaForm
  }
}
    ${ZoneAreaForZoneAreaFormFragmentDoc}`;
export function useGetZoneAreaForEditQuery(variables: import("@/graphql/types").GetZoneAreaForEditQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetZoneAreaForEditQueryVariables> | ReactiveFunction<import("@/graphql/types").GetZoneAreaForEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForEditQuery, import("@/graphql/types").GetZoneAreaForEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForEditQuery, import("@/graphql/types").GetZoneAreaForEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForEditQuery, import("@/graphql/types").GetZoneAreaForEditQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetZoneAreaForEditQuery, import("@/graphql/types").GetZoneAreaForEditQueryVariables>(GetZoneAreaForEditDocument, variables, options);
}
export function useGetZoneAreaForEditLazyQuery(variables: import("@/graphql/types").GetZoneAreaForEditQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetZoneAreaForEditQueryVariables> | ReactiveFunction<import("@/graphql/types").GetZoneAreaForEditQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForEditQuery, import("@/graphql/types").GetZoneAreaForEditQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForEditQuery, import("@/graphql/types").GetZoneAreaForEditQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetZoneAreaForEditQuery, import("@/graphql/types").GetZoneAreaForEditQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetZoneAreaForEditQuery, import("@/graphql/types").GetZoneAreaForEditQueryVariables>(GetZoneAreaForEditDocument, variables, options);
}
export type GetZoneAreaForEditQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetZoneAreaForEditQuery, import("@/graphql/types").GetZoneAreaForEditQueryVariables>;
export const ListZoneAreasDocument = gql`
    query ListZoneAreas($paging: OffsetPaging) {
  zoneAreas(paging: $paging) {
    nodes {
      ...zoneAreaForAdminSettings
    }
    pageInfo {
      hasNextPage
    }
  }
}
    ${ZoneAreaForAdminSettingsFragmentDoc}`;
export function useListZoneAreasQuery(variables: import("@/graphql/types").ListZoneAreasQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListZoneAreasQueryVariables> | ReactiveFunction<import("@/graphql/types").ListZoneAreasQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasQuery, import("@/graphql/types").ListZoneAreasQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasQuery, import("@/graphql/types").ListZoneAreasQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasQuery, import("@/graphql/types").ListZoneAreasQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListZoneAreasQuery, import("@/graphql/types").ListZoneAreasQueryVariables>(ListZoneAreasDocument, variables, options);
}
export function useListZoneAreasLazyQuery(variables: import("@/graphql/types").ListZoneAreasQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListZoneAreasQueryVariables> | ReactiveFunction<import("@/graphql/types").ListZoneAreasQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasQuery, import("@/graphql/types").ListZoneAreasQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasQuery, import("@/graphql/types").ListZoneAreasQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListZoneAreasQuery, import("@/graphql/types").ListZoneAreasQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListZoneAreasQuery, import("@/graphql/types").ListZoneAreasQueryVariables>(ListZoneAreasDocument, variables, options);
}
export type ListZoneAreasQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListZoneAreasQuery, import("@/graphql/types").ListZoneAreasQueryVariables>;
export const UpdateZoneAreaDocument = gql`
    mutation UpdateZoneArea($input: UpdateOneZoneAreaInput!) {
  updateZoneArea(input: $input) {
    ...zoneAreaForZoneAreaForm
  }
}
    ${ZoneAreaForZoneAreaFormFragmentDoc}`;
export function useUpdateZoneAreaMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateZoneAreaMutation, import("@/graphql/types").UpdateZoneAreaMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateZoneAreaMutation, import("@/graphql/types").UpdateZoneAreaMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateZoneAreaMutation, import("@/graphql/types").UpdateZoneAreaMutationVariables>(UpdateZoneAreaDocument, options);
}
export type UpdateZoneAreaMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateZoneAreaMutation, import("@/graphql/types").UpdateZoneAreaMutationVariables>;
export const MeForProfileSettingsDocument = gql`
    query MeForProfileSettings {
  me {
    ...userForProfileSettings
  }
}
    ${UserForProfileSettingsFragmentDoc}`;
export function useMeForProfileSettingsQuery(options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeForProfileSettingsQuery, import("@/graphql/types").MeForProfileSettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeForProfileSettingsQuery, import("@/graphql/types").MeForProfileSettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeForProfileSettingsQuery, import("@/graphql/types").MeForProfileSettingsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").MeForProfileSettingsQuery, import("@/graphql/types").MeForProfileSettingsQueryVariables>(MeForProfileSettingsDocument, {}, options);
}
export function useMeForProfileSettingsLazyQuery(options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeForProfileSettingsQuery, import("@/graphql/types").MeForProfileSettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeForProfileSettingsQuery, import("@/graphql/types").MeForProfileSettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").MeForProfileSettingsQuery, import("@/graphql/types").MeForProfileSettingsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").MeForProfileSettingsQuery, import("@/graphql/types").MeForProfileSettingsQueryVariables>(MeForProfileSettingsDocument, {}, options);
}
export type MeForProfileSettingsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").MeForProfileSettingsQuery, import("@/graphql/types").MeForProfileSettingsQueryVariables>;
export const GetWorkspaceForSettingsDocument = gql`
    query GetWorkspaceForSettings($id: ID!) {
  workspace(id: $id) {
    ...workspaceForSettings
  }
}
    ${WorkspaceForSettingsFragmentDoc}`;
export function useGetWorkspaceForSettingsQuery(variables: import("@/graphql/types").GetWorkspaceForSettingsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceForSettingsQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceForSettingsQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForSettingsQuery, import("@/graphql/types").GetWorkspaceForSettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForSettingsQuery, import("@/graphql/types").GetWorkspaceForSettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForSettingsQuery, import("@/graphql/types").GetWorkspaceForSettingsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetWorkspaceForSettingsQuery, import("@/graphql/types").GetWorkspaceForSettingsQueryVariables>(GetWorkspaceForSettingsDocument, variables, options);
}
export function useGetWorkspaceForSettingsLazyQuery(variables: import("@/graphql/types").GetWorkspaceForSettingsQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetWorkspaceForSettingsQueryVariables> | ReactiveFunction<import("@/graphql/types").GetWorkspaceForSettingsQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForSettingsQuery, import("@/graphql/types").GetWorkspaceForSettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForSettingsQuery, import("@/graphql/types").GetWorkspaceForSettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetWorkspaceForSettingsQuery, import("@/graphql/types").GetWorkspaceForSettingsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetWorkspaceForSettingsQuery, import("@/graphql/types").GetWorkspaceForSettingsQueryVariables>(GetWorkspaceForSettingsDocument, variables, options);
}
export type GetWorkspaceForSettingsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetWorkspaceForSettingsQuery, import("@/graphql/types").GetWorkspaceForSettingsQueryVariables>;
export const UpdateWorkspaceDocument = gql`
    mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
  updateWorkspace(input: $input) {
    id
    name
    company
    notifications
  }
}
    `;
export function useUpdateWorkspaceMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateWorkspaceMutation, import("@/graphql/types").UpdateWorkspaceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateWorkspaceMutation, import("@/graphql/types").UpdateWorkspaceMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateWorkspaceMutation, import("@/graphql/types").UpdateWorkspaceMutationVariables>(UpdateWorkspaceDocument, options);
}
export type UpdateWorkspaceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateWorkspaceMutation, import("@/graphql/types").UpdateWorkspaceMutationVariables>;
export const UpdateWorkspaceStaffDocument = gql`
    mutation UpdateWorkspaceStaff($input: UpdateWorkspaceStaffInput!) {
  updateWorkspaceStaff(input: $input) {
    ...workspaceForSettings
  }
}
    ${WorkspaceForSettingsFragmentDoc}`;
export function useUpdateWorkspaceStaffMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateWorkspaceStaffMutation, import("@/graphql/types").UpdateWorkspaceStaffMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateWorkspaceStaffMutation, import("@/graphql/types").UpdateWorkspaceStaffMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateWorkspaceStaffMutation, import("@/graphql/types").UpdateWorkspaceStaffMutationVariables>(UpdateWorkspaceStaffDocument, options);
}
export type UpdateWorkspaceStaffMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateWorkspaceStaffMutation, import("@/graphql/types").UpdateWorkspaceStaffMutationVariables>;
export const CreateBookingUserForUserManagementFormDocument = gql`
    mutation CreateBookingUserForUserManagementForm($input: CreateBookingUserInput!) {
  createBookingUser(input: $input) {
    id
  }
}
    `;
export function useCreateBookingUserForUserManagementFormMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateBookingUserForUserManagementFormMutation, import("@/graphql/types").CreateBookingUserForUserManagementFormMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateBookingUserForUserManagementFormMutation, import("@/graphql/types").CreateBookingUserForUserManagementFormMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateBookingUserForUserManagementFormMutation, import("@/graphql/types").CreateBookingUserForUserManagementFormMutationVariables>(CreateBookingUserForUserManagementFormDocument, options);
}
export type CreateBookingUserForUserManagementFormMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateBookingUserForUserManagementFormMutation, import("@/graphql/types").CreateBookingUserForUserManagementFormMutationVariables>;
export const CreatePlantForUserManagementFormDocument = gql`
    mutation createPlantForUserManagementForm($input: CreatePlantInput!) {
  createPlant(input: $input) {
    id
  }
}
    `;
export function useCreatePlantForUserManagementFormMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreatePlantForUserManagementFormMutation, import("@/graphql/types").CreatePlantForUserManagementFormMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreatePlantForUserManagementFormMutation, import("@/graphql/types").CreatePlantForUserManagementFormMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreatePlantForUserManagementFormMutation, import("@/graphql/types").CreatePlantForUserManagementFormMutationVariables>(CreatePlantForUserManagementFormDocument, options);
}
export type CreatePlantForUserManagementFormMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreatePlantForUserManagementFormMutation, import("@/graphql/types").CreatePlantForUserManagementFormMutationVariables>;
export const CreateStaffUserForUserManagementFormDocument = gql`
    mutation createStaffUserForUserManagementForm($input: CreateStaffUserInput!) {
  createStaffUser(input: $input) {
    id
  }
}
    `;
export function useCreateStaffUserForUserManagementFormMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateStaffUserForUserManagementFormMutation, import("@/graphql/types").CreateStaffUserForUserManagementFormMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateStaffUserForUserManagementFormMutation, import("@/graphql/types").CreateStaffUserForUserManagementFormMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateStaffUserForUserManagementFormMutation, import("@/graphql/types").CreateStaffUserForUserManagementFormMutationVariables>(CreateStaffUserForUserManagementFormDocument, options);
}
export type CreateStaffUserForUserManagementFormMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateStaffUserForUserManagementFormMutation, import("@/graphql/types").CreateStaffUserForUserManagementFormMutationVariables>;
export const UpdateUserForUserManagementFormDocument = gql`
    mutation UpdateUserForUserManagementForm($input: UpdateUserInput!) {
  updateUser(input: $input) {
    ...userForUserManagementForm
  }
}
    ${UserForUserManagementFormFragmentDoc}`;
export function useUpdateUserForUserManagementFormMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateUserForUserManagementFormMutation, import("@/graphql/types").UpdateUserForUserManagementFormMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateUserForUserManagementFormMutation, import("@/graphql/types").UpdateUserForUserManagementFormMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateUserForUserManagementFormMutation, import("@/graphql/types").UpdateUserForUserManagementFormMutationVariables>(UpdateUserForUserManagementFormDocument, options);
}
export type UpdateUserForUserManagementFormMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateUserForUserManagementFormMutation, import("@/graphql/types").UpdateUserForUserManagementFormMutationVariables>;
export const DeleteUserForUserManagementDocument = gql`
    mutation DeleteUserForUserManagement($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
  }
}
    `;
export function useDeleteUserForUserManagementMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteUserForUserManagementMutation, import("@/graphql/types").DeleteUserForUserManagementMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteUserForUserManagementMutation, import("@/graphql/types").DeleteUserForUserManagementMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteUserForUserManagementMutation, import("@/graphql/types").DeleteUserForUserManagementMutationVariables>(DeleteUserForUserManagementDocument, options);
}
export type DeleteUserForUserManagementMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteUserForUserManagementMutation, import("@/graphql/types").DeleteUserForUserManagementMutationVariables>;
export const GetUserForUserManagementFormDocument = gql`
    query GetUserForUserManagementForm($id: ID!) {
  user(id: $id) {
    ...userForUserManagementForm
  }
}
    ${UserForUserManagementFormFragmentDoc}`;
export function useGetUserForUserManagementFormQuery(variables: import("@/graphql/types").GetUserForUserManagementFormQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetUserForUserManagementFormQueryVariables> | ReactiveFunction<import("@/graphql/types").GetUserForUserManagementFormQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserManagementFormQuery, import("@/graphql/types").GetUserForUserManagementFormQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserManagementFormQuery, import("@/graphql/types").GetUserForUserManagementFormQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserManagementFormQuery, import("@/graphql/types").GetUserForUserManagementFormQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetUserForUserManagementFormQuery, import("@/graphql/types").GetUserForUserManagementFormQueryVariables>(GetUserForUserManagementFormDocument, variables, options);
}
export function useGetUserForUserManagementFormLazyQuery(variables: import("@/graphql/types").GetUserForUserManagementFormQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetUserForUserManagementFormQueryVariables> | ReactiveFunction<import("@/graphql/types").GetUserForUserManagementFormQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserManagementFormQuery, import("@/graphql/types").GetUserForUserManagementFormQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserManagementFormQuery, import("@/graphql/types").GetUserForUserManagementFormQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetUserForUserManagementFormQuery, import("@/graphql/types").GetUserForUserManagementFormQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetUserForUserManagementFormQuery, import("@/graphql/types").GetUserForUserManagementFormQueryVariables>(GetUserForUserManagementFormDocument, variables, options);
}
export type GetUserForUserManagementFormQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetUserForUserManagementFormQuery, import("@/graphql/types").GetUserForUserManagementFormQueryVariables>;
export const ListUsersForUserManagementDocument = gql`
    query ListUsersForUserManagement($filter: UserFilter, $paging: OffsetPaging, $sorting: [UserSort!]) {
  users(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...userForUserManagement
    }
    pageInfo {
      ...offsetPageInfo
    }
  }
}
    ${UserForUserManagementFragmentDoc}
${OffsetPageInfoFragmentDoc}`;
export function useListUsersForUserManagementQuery(variables: import("@/graphql/types").ListUsersForUserManagementQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForUserManagementQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForUserManagementQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserManagementQuery, import("@/graphql/types").ListUsersForUserManagementQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserManagementQuery, import("@/graphql/types").ListUsersForUserManagementQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserManagementQuery, import("@/graphql/types").ListUsersForUserManagementQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListUsersForUserManagementQuery, import("@/graphql/types").ListUsersForUserManagementQueryVariables>(ListUsersForUserManagementDocument, variables, options);
}
export function useListUsersForUserManagementLazyQuery(variables: import("@/graphql/types").ListUsersForUserManagementQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListUsersForUserManagementQueryVariables> | ReactiveFunction<import("@/graphql/types").ListUsersForUserManagementQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserManagementQuery, import("@/graphql/types").ListUsersForUserManagementQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserManagementQuery, import("@/graphql/types").ListUsersForUserManagementQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListUsersForUserManagementQuery, import("@/graphql/types").ListUsersForUserManagementQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListUsersForUserManagementQuery, import("@/graphql/types").ListUsersForUserManagementQueryVariables>(ListUsersForUserManagementDocument, variables, options);
}
export type ListUsersForUserManagementQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListUsersForUserManagementQuery, import("@/graphql/types").ListUsersForUserManagementQueryVariables>;
export const UpdateUserForUserManagementDocument = gql`
    mutation UpdateUserForUserManagement($input: UpdateUserInput!) {
  updateUser(input: $input) {
    ...userForUserManagement
  }
}
    ${UserForUserManagementFragmentDoc}`;
export function useUpdateUserForUserManagementMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateUserForUserManagementMutation, import("@/graphql/types").UpdateUserForUserManagementMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateUserForUserManagementMutation, import("@/graphql/types").UpdateUserForUserManagementMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateUserForUserManagementMutation, import("@/graphql/types").UpdateUserForUserManagementMutationVariables>(UpdateUserForUserManagementDocument, options);
}
export type UpdateUserForUserManagementMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateUserForUserManagementMutation, import("@/graphql/types").UpdateUserForUserManagementMutationVariables>;
export const CreateVenueForVenueManagementDocument = gql`
    mutation CreateVenueForVenueManagement($input: CreateOneVenueInput!) {
  createVenue(input: $input) {
    ...venueForVenueManagement
  }
}
    ${VenueForVenueManagementFragmentDoc}`;
export function useCreateVenueForVenueManagementMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateVenueForVenueManagementMutation, import("@/graphql/types").CreateVenueForVenueManagementMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateVenueForVenueManagementMutation, import("@/graphql/types").CreateVenueForVenueManagementMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateVenueForVenueManagementMutation, import("@/graphql/types").CreateVenueForVenueManagementMutationVariables>(CreateVenueForVenueManagementDocument, options);
}
export type CreateVenueForVenueManagementMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateVenueForVenueManagementMutation, import("@/graphql/types").CreateVenueForVenueManagementMutationVariables>;
export const CreateVenueReviewDocument = gql`
    mutation CreateVenueReview($input: CreateOneVenueReviewInput!) {
  createVenueReview(input: $input) {
    ...venueReviewForCreateVenueReviewForm
  }
}
    ${VenueReviewForCreateVenueReviewFormFragmentDoc}`;
export function useCreateVenueReviewMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateVenueReviewMutation, import("@/graphql/types").CreateVenueReviewMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateVenueReviewMutation, import("@/graphql/types").CreateVenueReviewMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateVenueReviewMutation, import("@/graphql/types").CreateVenueReviewMutationVariables>(CreateVenueReviewDocument, options);
}
export type CreateVenueReviewMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateVenueReviewMutation, import("@/graphql/types").CreateVenueReviewMutationVariables>;
export const CreateVenueReviewForVenuePageDocument = gql`
    mutation CreateVenueReviewForVenuePage($input: CreateOneVenueReviewInput!) {
  createVenueReview(input: $input) {
    ...venueReviewForVenuePage
  }
}
    ${VenueReviewForVenuePageFragmentDoc}`;
export function useCreateVenueReviewForVenuePageMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateVenueReviewForVenuePageMutation, import("@/graphql/types").CreateVenueReviewForVenuePageMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").CreateVenueReviewForVenuePageMutation, import("@/graphql/types").CreateVenueReviewForVenuePageMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").CreateVenueReviewForVenuePageMutation, import("@/graphql/types").CreateVenueReviewForVenuePageMutationVariables>(CreateVenueReviewForVenuePageDocument, options);
}
export type CreateVenueReviewForVenuePageMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").CreateVenueReviewForVenuePageMutation, import("@/graphql/types").CreateVenueReviewForVenuePageMutationVariables>;
export const DeleteVenueForVenueManagementDocument = gql`
    mutation DeleteVenueForVenueManagement($id: ID!) {
  deleteVenue(input: {id: $id}) {
    id
  }
}
    `;
export function useDeleteVenueForVenueManagementMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteVenueForVenueManagementMutation, import("@/graphql/types").DeleteVenueForVenueManagementMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteVenueForVenueManagementMutation, import("@/graphql/types").DeleteVenueForVenueManagementMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteVenueForVenueManagementMutation, import("@/graphql/types").DeleteVenueForVenueManagementMutationVariables>(DeleteVenueForVenueManagementDocument, options);
}
export type DeleteVenueForVenueManagementMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteVenueForVenueManagementMutation, import("@/graphql/types").DeleteVenueForVenueManagementMutationVariables>;
export const DeleteVenueReviewForVenuePageDocument = gql`
    mutation DeleteVenueReviewForVenuePage($id: ID!) {
  deleteVenueReview(input: {id: $id}) {
    id
  }
}
    `;
export function useDeleteVenueReviewForVenuePageMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteVenueReviewForVenuePageMutation, import("@/graphql/types").DeleteVenueReviewForVenuePageMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").DeleteVenueReviewForVenuePageMutation, import("@/graphql/types").DeleteVenueReviewForVenuePageMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").DeleteVenueReviewForVenuePageMutation, import("@/graphql/types").DeleteVenueReviewForVenuePageMutationVariables>(DeleteVenueReviewForVenuePageDocument, options);
}
export type DeleteVenueReviewForVenuePageMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").DeleteVenueReviewForVenuePageMutation, import("@/graphql/types").DeleteVenueReviewForVenuePageMutationVariables>;
export const GetGameZoneForVenueManagementDocument = gql`
    query GetGameZoneForVenueManagement($id: ID!) {
  gameZone(id: $id) {
    id
    name
    point
    hull
  }
}
    `;
export function useGetGameZoneForVenueManagementQuery(variables: import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables> | ReactiveFunction<import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetGameZoneForVenueManagementQuery, import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetGameZoneForVenueManagementQuery, import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetGameZoneForVenueManagementQuery, import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetGameZoneForVenueManagementQuery, import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables>(GetGameZoneForVenueManagementDocument, variables, options);
}
export function useGetGameZoneForVenueManagementLazyQuery(variables: import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables> | ReactiveFunction<import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetGameZoneForVenueManagementQuery, import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetGameZoneForVenueManagementQuery, import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetGameZoneForVenueManagementQuery, import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetGameZoneForVenueManagementQuery, import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables>(GetGameZoneForVenueManagementDocument, variables, options);
}
export type GetGameZoneForVenueManagementQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetGameZoneForVenueManagementQuery, import("@/graphql/types").GetGameZoneForVenueManagementQueryVariables>;
export const GetVenueForVenuePageDocument = gql`
    query GetVenueForVenuePage($id: ID!) {
  venue(id: $id) {
    ...venueForVenuePage
  }
}
    ${VenueForVenuePageFragmentDoc}`;
export function useGetVenueForVenuePageQuery(variables: import("@/graphql/types").GetVenueForVenuePageQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetVenueForVenuePageQueryVariables> | ReactiveFunction<import("@/graphql/types").GetVenueForVenuePageQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetVenueForVenuePageQuery, import("@/graphql/types").GetVenueForVenuePageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetVenueForVenuePageQuery, import("@/graphql/types").GetVenueForVenuePageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetVenueForVenuePageQuery, import("@/graphql/types").GetVenueForVenuePageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").GetVenueForVenuePageQuery, import("@/graphql/types").GetVenueForVenuePageQueryVariables>(GetVenueForVenuePageDocument, variables, options);
}
export function useGetVenueForVenuePageLazyQuery(variables: import("@/graphql/types").GetVenueForVenuePageQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").GetVenueForVenuePageQueryVariables> | ReactiveFunction<import("@/graphql/types").GetVenueForVenuePageQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetVenueForVenuePageQuery, import("@/graphql/types").GetVenueForVenuePageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetVenueForVenuePageQuery, import("@/graphql/types").GetVenueForVenuePageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").GetVenueForVenuePageQuery, import("@/graphql/types").GetVenueForVenuePageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").GetVenueForVenuePageQuery, import("@/graphql/types").GetVenueForVenuePageQueryVariables>(GetVenueForVenuePageDocument, variables, options);
}
export type GetVenueForVenuePageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").GetVenueForVenuePageQuery, import("@/graphql/types").GetVenueForVenuePageQueryVariables>;
export const ListVenueReviewsForVenuePageDocument = gql`
    query ListVenueReviewsForVenuePage($filter: VenueReviewFilter!, $paging: OffsetPaging) {
  venueReviews(filter: $filter, paging: $paging) {
    nodes {
      ...venueReviewForVenuePage
    }
    pageInfo {
      hasNextPage
    }
  }
}
    ${VenueReviewForVenuePageFragmentDoc}`;
export function useListVenueReviewsForVenuePageQuery(variables: import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables> | ReactiveFunction<import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenueReviewsForVenuePageQuery, import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenueReviewsForVenuePageQuery, import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenueReviewsForVenuePageQuery, import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListVenueReviewsForVenuePageQuery, import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables>(ListVenueReviewsForVenuePageDocument, variables, options);
}
export function useListVenueReviewsForVenuePageLazyQuery(variables: import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables> | ReactiveFunction<import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables>, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenueReviewsForVenuePageQuery, import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenueReviewsForVenuePageQuery, import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenueReviewsForVenuePageQuery, import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListVenueReviewsForVenuePageQuery, import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables>(ListVenueReviewsForVenuePageDocument, variables, options);
}
export type ListVenueReviewsForVenuePageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListVenueReviewsForVenuePageQuery, import("@/graphql/types").ListVenueReviewsForVenuePageQueryVariables>;
export const ListVenuesForVenueManagementDocument = gql`
    query ListVenuesForVenueManagement($paging: OffsetPaging, $filter: VenueFilter, $sorting: [VenueSort!]) {
  venues(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...venueForVenueManagement
    }
    pageInfo {
      hasNextPage
    }
  }
}
    ${VenueForVenueManagementFragmentDoc}`;
export function useListVenuesForVenueManagementQuery(variables: import("@/graphql/types").ListVenuesForVenueManagementQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListVenuesForVenueManagementQueryVariables> | ReactiveFunction<import("@/graphql/types").ListVenuesForVenueManagementQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenuesForVenueManagementQuery, import("@/graphql/types").ListVenuesForVenueManagementQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenuesForVenueManagementQuery, import("@/graphql/types").ListVenuesForVenueManagementQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenuesForVenueManagementQuery, import("@/graphql/types").ListVenuesForVenueManagementQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<import("@/graphql/types").ListVenuesForVenueManagementQuery, import("@/graphql/types").ListVenuesForVenueManagementQueryVariables>(ListVenuesForVenueManagementDocument, variables, options);
}
export function useListVenuesForVenueManagementLazyQuery(variables: import("@/graphql/types").ListVenuesForVenueManagementQueryVariables | VueCompositionApi.Ref<import("@/graphql/types").ListVenuesForVenueManagementQueryVariables> | ReactiveFunction<import("@/graphql/types").ListVenuesForVenueManagementQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenuesForVenueManagementQuery, import("@/graphql/types").ListVenuesForVenueManagementQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenuesForVenueManagementQuery, import("@/graphql/types").ListVenuesForVenueManagementQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<import("@/graphql/types").ListVenuesForVenueManagementQuery, import("@/graphql/types").ListVenuesForVenueManagementQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<import("@/graphql/types").ListVenuesForVenueManagementQuery, import("@/graphql/types").ListVenuesForVenueManagementQueryVariables>(ListVenuesForVenueManagementDocument, variables, options);
}
export type ListVenuesForVenueManagementQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<import("@/graphql/types").ListVenuesForVenueManagementQuery, import("@/graphql/types").ListVenuesForVenueManagementQueryVariables>;
export const UpdateVenueForVenuePageDocument = gql`
    mutation updateVenueForVenuePage($input: UpdateOneVenueInput!) {
  updateVenue(input: $input) {
    ...venueForVenuePage
  }
}
    ${VenueForVenuePageFragmentDoc}`;
export function useUpdateVenueForVenuePageMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateVenueForVenuePageMutation, import("@/graphql/types").UpdateVenueForVenuePageMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateVenueForVenuePageMutation, import("@/graphql/types").UpdateVenueForVenuePageMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateVenueForVenuePageMutation, import("@/graphql/types").UpdateVenueForVenuePageMutationVariables>(UpdateVenueForVenuePageDocument, options);
}
export type UpdateVenueForVenuePageMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateVenueForVenuePageMutation, import("@/graphql/types").UpdateVenueForVenuePageMutationVariables>;
export const UpdateVenueReviewDocument = gql`
    mutation updateVenueReview($input: UpdateOneVenueReviewInput!) {
  updateVenueReview(input: $input) {
    ...venueReviewForVenuePage
  }
}
    ${VenueReviewForVenuePageFragmentDoc}`;
export function useUpdateVenueReviewMutation(options: VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateVenueReviewMutation, import("@/graphql/types").UpdateVenueReviewMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<import("@/graphql/types").UpdateVenueReviewMutation, import("@/graphql/types").UpdateVenueReviewMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<import("@/graphql/types").UpdateVenueReviewMutation, import("@/graphql/types").UpdateVenueReviewMutationVariables>(UpdateVenueReviewDocument, options);
}
export type UpdateVenueReviewMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<import("@/graphql/types").UpdateVenueReviewMutation, import("@/graphql/types").UpdateVenueReviewMutationVariables>;