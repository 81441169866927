import type { Nullable } from '@/types'

export const STATUSES = ['done', 'uploading', 'uploaded', 'error'] as const

export type Status = (typeof STATUSES)[number]

export enum FileType {
  IMAGE = 'Image',
  VIDEO = 'Video',
  AUDIO = 'Audio',
}

export interface FileCardFile {
  src?: Nullable<string>
  name?: Nullable<string>
  size?: Nullable<number>
  type?: Nullable<FileType>
}

export interface FileCardProps {
  file: FileCardFile
  status?: Status
  title: string
  deletable?: boolean
  deleting?: boolean
  progress?: number
  downloadable?: boolean
  downloading?: boolean
  errorMessage?: string
}

export interface FileCardEvents {
  download: undefined
  delete: undefined
  abort: undefined
  'update:name': string
}
