
import Vue, { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import { Status, STATUSES } from '../../types'

export default Vue.extend({
  name: 'WeveStatusIndicator',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    status: {
      type: String as PropType<Status>,
      required: true,
      validator: (val: Status) => STATUSES.includes(val),
    },
    text: {
      type: String,
      default: undefined,
    },
  },
  render(h, { props, data }) {
    return h(
      props.tag,
      mergeData(data, {
        class: { [`app-status-indicator--status--${props.status}`]: true },
        staticClass: 'app-status-indicator',
      }),
      props.text !== undefined
        ? [h('span', { staticClass: 'sr-only' }, props.text)]
        : null,
    )
  },
})
