
import type { VNode, PropType } from 'vue'
import type { WithEvents } from 'vue-typed-emit'
import { map } from 'lodash-es'
import mixins from 'vue-typed-mixins'

import { SlotsMixin, ThemeMixin } from '../../mixins'
import WeveCheckbox from '../Checkbox/Checkbox.vue'
import WeveTextField from '../TextField/TextField.vue'
import { ListItem, DisabledPredicate } from './types'

export interface Events {
  select: unknown | unknown[] | undefined
  position: { oldIndex: number; newIndex: number }
}

const Extendable = mixins(SlotsMixin, ThemeMixin)

export default (Extendable as WithEvents<Events, typeof Extendable>).extend({
  name: 'WeveList',
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    items: {
      type: [Array, Object] as PropType<
        Array<ListItem> | Record<string, ListItem>
      >,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: undefined,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    ordered: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Boolean,
      default: false,
    },
    identity: {
      type: String,
      default: 'id',
    },
    itemTitleKey: {
      type: [String, Function] as PropType<
        string | ((item: ListItem) => string)
      >,
      default: 'title',
    },
    itemSubtitleKey: {
      type: String,
      default: 'subtitle',
    },
    disabledPredicate: {
      type: Function as PropType<DisabledPredicate>,
      default: null,
    },
    invalidPredicate: {
      type: Function as PropType<DisabledPredicate>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isSelected(item: ListItem) {
      const value = item[this.identity]

      if (this.multiple) {
        return this.value.includes(value)
      } else {
        return value === this.value
      }
    },
    onInput(item: ListItem) {
      const { value: selected, identity, toggleable, multiple } = this
      const value = item[identity]
      if (toggleable) {
        if (multiple) {
          if (selected.includes(value)) {
            this.$emit(
              'select',
              selected.filter((v: unknown) => v !== value),
            )
          } else {
            this.$emit('select', selected.concat(value))
          }
        } else {
          if (value === selected) {
            this.$emit('select', undefined)
          } else {
            this.$emit('select', value)
          }
        }
      } else {
        if (multiple) {
          if (!selected.includes(value)) {
            this.$emit('select', selected.concat(value))
          }
        } else {
          return this.$emit('select', value)
        }
      }
    },
  },
  render(h): VNode {
    const {
      ordered,
      items,
      identity,
      selectable,
      toggleable,
      checkbox,
      sortable,
      itemTitleKey,
      itemSubtitleKey,
    } = this
    const interactable = selectable || toggleable
    let order = 0
    const tag = ordered ? 'ol' : 'ul'

    return h(
      tag,
      {
        // TODO(Andrew): add aria-labelledby and aria-activedescendant
        attrs: {
          role: interactable === true ? 'listbox' : undefined,
          tabindex: interactable === true ? '0' : undefined,
        },
        staticClass: 'weve-list',
        class: {
          'weve-list--interactable': interactable,
          'weve-list--checkbox': checkbox,
          'weve-list--sortable': sortable,
          'w-theme--light': this.light,
          'w-theme--dark': this.dark,
        },
      },
      map(items, (item: Record<string, string | number>, index) => {
        const itemTitle =
          typeof itemTitleKey === 'string'
            ? item[itemTitleKey].toString()
            : itemTitleKey(item)
        const disabled = this.disabledPredicate
          ? this.disabledPredicate(item)
          : false
        const invalid = this.invalidPredicate
          ? this.invalidPredicate(item)
          : false

        return h(
          'li',
          {
            key: item[identity],
            attrs: {
              role: 'option',
            },
            class: {
              'weve-list__item--active': interactable && this.isSelected(item),
              'weve-list__item--invalid': invalid,
              'weve-list__item--disabled': disabled,
            },
            staticClass: 'weve-list__item',
            on: interactable
              ? {
                  click: (e: Event) => {
                    const target = e.target as HTMLElement
                    if (target.closest('input') !== null) {
                      return
                    }
                    this.onInput(item)
                  },
                }
              : undefined,
          },
          [
            h('div', { staticClass: 'weve-list__item-body' }, [
              checkbox
                ? h(WeveCheckbox, {
                    props: {
                      checked: this.isSelected(item),
                      label: 'Selected',
                      noLabel: true,
                      readonly: true,
                      disabled: disabled,
                    },
                    staticClass: 'weve-list__checkbox',
                  })
                : null,
              sortable
                ? h(WeveTextField, {
                    props: {
                      value: index + 1,
                      hideLabel: true,
                      label: 'Position',
                      placeholder: 'N',
                      type: 'number',
                      disabled: this.disabled,
                    },
                    staticClass: 'weve-list__input',
                    on: {
                      change: (value: string) => {
                        const oldIndex = Number(index)
                        const newIndex = Number(value) - 1
                        if (oldIndex !== newIndex) {
                          this.$emit('position', {
                            oldIndex,
                            newIndex,
                          })
                        }
                      },
                    },
                  })
                : null,
              h(
                'div',
                {
                  staticClass: 'weve-list__item-text',
                  class: {
                    'weve-list__item-text--row': this.row,
                  },
                },
                [
                  h('span', { staticClass: 'weve-list__item-title' }, [
                    ordered && sortable === false ? `${++order}) ` : null,
                    itemTitle,
                  ]),
                  item[itemSubtitleKey] !== undefined ||
                  this.hasSlot('subtitle')
                    ? h(
                        'span',
                        { staticClass: 'weve-list__item-subtitle' },
                        this.hasSlot('subtitle')
                          ? this.normalizeSlot('subtitle', { item })
                          : item[itemSubtitleKey],
                      )
                    : undefined,
                ],
              ),
              this.hasSlot('item-append')
                ? h(
                    'div',
                    { staticClass: 'weve-list__item-append' },
                    this.normalizeSlot('item-append', { item }),
                  )
                : null,
            ]),
          ],
        )
      }),
    )
  },
})
