var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.className},[_c('div',{staticClass:"app-spinbutton__top",class:{ 'sr-only': _vm.noLabel }},[_c('WeveFormControlLabel',{attrs:{"for":_vm.id},on:{"click":function($event){return _vm.$refs.field.focus()}}},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1),_c('div',{ref:"field",class:[
      'app-spinbutton__field',
      { 'app-spinbutton__field--focused': _vm.hasFocus },
    ],attrs:{"id":_vm.id,"role":"group","tabindex":"-1"},on:{"!focus":function($event){return _vm.onFocusBlur.apply(null, arguments)},"!blur":function($event){return _vm.onFocusBlur.apply(null, arguments)},"keydown":_vm.onKeydown}},[_c('button',{class:[
        'app-spinbutton__button',
        { 'app-spinbutton__button--disabled': _vm.minusDisabled },
      ],attrs:{"tabindex":"-1","type":"button","disabled":_vm.minusDisabled,"aria-label":"Decrement","aria-keyshortcuts":"ArrowDown"},on:{"click":_vm.stepDown}},[_c('WeveIcon',{attrs:{"name":"minus"}})],1),_c('input',{ref:"input",staticClass:"app-spinbutton__value",attrs:{"role":"spinbutton","readonly":_vm.inputtable === false || _vm.readonly,"aria-valuemin":_vm.min,"aria-valuemax":_vm.max,"aria-valuenow":_vm.val,"aria-valuetext":_vm.val,"disabled":_vm.disabled,"inputmode":"numeric"},domProps:{"value":_vm.localValue},on:{"input":_vm.onInput,"blur":_vm.onBlur}}),_c('button',{class:[
        'app-spinbutton__button',
        { 'app-spinbutton__button--disabled': _vm.plusDisabled },
      ],attrs:{"tabindex":"-1","type":"button","disabled":_vm.plusDisabled,"aria-label":"Increment","aria-keyshortcuts":"ArrowUp"},on:{"click":_vm.stepUp}},[_c('WeveIcon',{attrs:{"name":"plus"}})],1),(_vm.$_status !== undefined)?_c('StatusIndicator',{staticClass:"app-spinbutton__indicator",attrs:{"status":_vm.$_status}}):_vm._e()],1),(_vm.hint || _vm.hasSlot('hint') || _vm.message)?_c('div',{staticClass:"app-spinbutton__bottom"},[_c('WeveFormControlHint',{staticClass:"app-spinbutton__hint"},[_vm._t("hint",function(){return [_vm._v(_vm._s(_vm.hint))]})],2),(_vm.message)?_c('WeveFormControlMessage',{staticClass:"app-spinbutton__message",attrs:{"variant":_vm.$_message_variant}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }