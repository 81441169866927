
import Vue, { PropType } from 'vue'

import type { Option } from './types'

import AppRadio from '../Radio/Radio.vue'
import WeveFormControlLabel from '../FormControlLabel/FormControlLabel.vue'
import { generateId } from '../../utils'

export default Vue.extend({
  name: 'WeveRadioGroup',
  components: {
    AppRadio,
    WeveFormControlLabel,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Number, String, Boolean],
      default: undefined,
    },
    options: {
      type: Array as PropType<Array<Option>>,
      required: true,
    },
    name: {
      type: String,
      default() {
        return generateId()
      },
    },
    label: {
      type: String,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasValue(): boolean {
      const { value } = this
      return value !== null && value !== undefined
    },
  },
})
