import type { Ref } from 'vue'
import { ref, watch, onMounted } from 'vue'

import type { Undefined, Nullable } from '@/types'

import { getCurrentInstance } from '@/util'

declare global {
  interface Window {
    grecaptcha: {
      render: (el: HTMLElement, options: unknown) => void
      reset: (el: HTMLElement) => void
      ready: (callback: () => void) => void
    }
  }
}

export default function useGoogleRecaptcha(el: Ref<Nullable<HTMLElement>>) {
  const instance = getCurrentInstance()
  const token = ref<Undefined<string>>(undefined)
  const loaded = ref(false)

  async function load() {
    await instance.proxy.$loadScript(
      'https://www.google.com/recaptcha/api.js?render=explicit',
    )
    window.grecaptcha.ready(() => {
      loaded.value = true
    })
  }

  onMounted(load)

  async function render(el: HTMLElement) {
    return window.grecaptcha.render(el, {
      sitekey: process.env.VUE_APP_CAPTCHA_KEY,
      callback: (response: string) => {
        token.value = response
      },
    })
  }

  watch([el, loaded], async () => {
    if (el.value !== null && loaded.value) {
      await render(el.value)
    }
  })

  function reset() {
    token.value = undefined
    if (el.value !== null) {
      window.grecaptcha.reset(el.value)
    }
  }

  return {
    token,
    reset,
  }
}
