
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveFormAsterisk',
  functional: true,
  render(h, { data }) {
    return h(
      'span',
      mergeData(
        { attrs: { title: 'Required' }, staticClass: 'app-form-asterisk' },
        data,
      ),
      [
        h(
          'svg',
          {
            staticClass: 'app-form-asterisk__icon',
            attrs: { viewBox: '0 0 512 512' },
          },
          [
            h('path', {
              attrs: {
                d: 'M478 334l-142-78 142-78c12-7 16-21 9-33l-19-34c-7-12-22-15-33-8l-139 84 3-162c1-14-10-25-24-25h-38c-14 0-25 11-24 25l3 162-139-84c-11-7-26-4-33 8l-19 34c-7 12-3 26 9 33l142 78-142 78c-12 7-16 21-9 33l19 34c7 12 22 15 33 8l139-84-3 162c-1 14 10 25 24 25h38c14 0 25-11 24-25l-3-162 139 84c11 7 26 4 33-8l19-34c7-12 3-26-9-33z',
                fill: 'currentColor',
              },
            }),
          ],
        ),
        h('span', { staticClass: 'sr-only' }, 'Required'),
      ],
    )
  },
})
