import qs from 'qs'

import Request from '../Request'
import BaseService from '../BaseService'
import type CalculateDto from './CalculateDto'
import type {
  CalculateResult,
  CartItem,
  CreatePaymentIntentDto,
  CreatePaymentIntentResponse,
  CartRequestDto,
} from './CartService.types'

export default class CartService extends BaseService {
  calculate<T extends CartItem = CartItem>(
    calculateDto: CalculateDto,
  ): Request<CalculateResult<T>> {
    const ac = new AbortController()
    return new Request(
      this.client
        .get<CalculateResult<T>>('/cart/calculate', {
          signal: ac.signal,
          params: calculateDto.toParams(),
          paramsSerializer: (params) => {
            return qs.stringify(params)
          },
        })
        .then(({ data }) => data),
      ac,
    )
  }

  async createPaymentIntent(
    createPaymentIntentDto: CreatePaymentIntentDto,
  ): Promise<CreatePaymentIntentResponse> {
    const { data } = await this.client.post<CreatePaymentIntentResponse>(
      '/cart/payment-intent',
      createPaymentIntentDto,
    )
    return data
  }

  async createRequest(payload: CartRequestDto): Promise<void> {
    await this.client.post(`/cart/request`, payload)
  }
}
