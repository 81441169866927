import Vue from 'vue'
import type { WithProperties } from 'vue-typed-properties'

import type { Theme } from '../types'

export default (Vue as WithProperties<{ theme: Theme }>).extend({
  inject: {
    theme: {
      default: 'light',
    },
  },
  computed: {
    light(): boolean {
      return this.theme === 'light'
    },
    dark(): boolean {
      return this.theme !== 'light'
    },
  },
})
