import type { Nullable } from '@/types'
import type { WorkspaceForStoreFragment } from '@/graphql/types'
import { storage } from '@/plugins/storage'
import { StorageKey } from '@/plugins/storage/Storage.types'

import { State as AuthModuleState } from './modules/AuthModule/state'

const workspaceId = storage.get(StorageKey.WORKSPACE_ID)
const lastSessionId = storage.get(StorageKey.LAST_SESSION_ID)

const state = {
  workspaceId,
  lastSessionId,
  workspace: null as Nullable<WorkspaceForStoreFragment>,
}

export default state

type StateRoot = typeof state

export interface State extends StateRoot {
  auth: AuthModuleState
  route: {
    params: {
      sessionId: string | undefined
    }
  }
}
