import type { PropType } from 'vue'

import type { VueComponentListeners, TextInputValue } from '../types'
import StateMessageMixin, {
  type StateMessageMixinProps,
} from './state-message.mixin'

export interface TextInputMixinProps extends StateMessageMixinProps {
  value?: TextInputValue
  disabled: boolean
  readonly: boolean
  hint?: string
  help?: string
}

const TextInputMixin = StateMessageMixin.extend({
  props: {
    value: {
      type: [String, Number] as PropType<TextInputValue>,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: undefined,
    },
    help: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    listeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        input: (event: InputEvent) => {
          const value = (event.target as HTMLInputElement | HTMLTextAreaElement)
            .value
          this.$emit('input', value)
        },
      }
    },
  },
})

export default TextInputMixin
