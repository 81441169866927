
import type { PropType } from 'vue'
import type { WithEvents } from 'vue-typed-emit'

import { ThemeMixin } from '../../mixins'
import { Status, STATUSES } from '../../types'
import { Event, Events } from './EventCard.types'

import WeveIcon from '../Icon/Icon.vue'
import WeveStatusIndicator from '../StatusIndicator/StatusIndicator.vue'
import WeveFormControlMessage from '../FormControlMessage/FormControlMessage.vue'
import { hasSlot } from '../../utils'

export default (ThemeMixin as WithEvents<Events, typeof ThemeMixin>).extend({
  name: 'WeveEventCard',
  components: {
    WeveIcon,
    WeveStatusIndicator,
    WeveFormControlMessage,
  },
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String as PropType<Status>,
      default: undefined,
      validator: (val: Status) => STATUSES.includes(val),
    },
    message: {
      type: String,
      default: undefined,
    },
    details: {
      type: String,
      default: undefined,
    },
    explicit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.toggleable === true) {
        this.$emit('toggle', this.event.id, !this.selected)
      } else {
        this.$emit('select', this.event.id)
      }
    },
    hasSlot,
  },
})
