
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import { toInitials } from './Avatar.util'

export default ThemeMixin.extend({
  name: 'WeveAvatar',
  functional: true,
  props: {
    src: {
      type: String,
      default: undefined,
    },
    alt: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      default: undefined,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  render(h, { props, data, injections }) {
    return h(
      props.tag,
      mergeData(data, {
        staticClass: 'weve-avatar',
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
      }),
      [
        typeof props.src !== 'string' && typeof props.displayName === 'string'
          ? h(
              'span',
              { staticClass: 'weve-avatar__initials' },
              toInitials(props.displayName),
            )
          : h('img', {
              attrs: {
                src: props.src || require('../../../../assets/user.svg'),
                alt: props.alt,
              },
              staticClass: 'weve-avatar__img',
            }),
      ],
    )
  },
})
