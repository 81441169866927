
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'

import { getCurrentInstance } from '@/util'

import { SelectionCardType, SELECTION_CARD_MODEL } from './SelectionCard.types'
import SelectionCard from './SelectionCard.vue'

export default defineComponent({
  name: 'WeveSelectionCardCheckbox',
  components: {
    SelectionCard,
  },
  inheritAttrs: false,
  model: SELECTION_CARD_MODEL,
  props: {
    checked: {
      type: Set as PropType<Set<string>>,
      default: null,
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: {
    change: (value: Set<string>) => value instanceof Set,
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance()
    const checked$ = computed(
      () => props.checked !== null && props.checked.has(props.value),
    )
    const listeners = computed(() => ({
      ...instance.proxy.$listeners,
      change: (event: InputEvent) => {
        const set = new Set(props.checked)
        if ((event.target as HTMLInputElement).checked) {
          set.add(props.value)
        } else {
          set.delete(props.value)
        }
        emit('change', set)
      },
    }))
    return {
      checked$,
      listeners,
      SelectionCardType,
    }
  },
})
