
import { defineComponent, computed } from 'vue'

import { getCurrentInstance } from '@/util'

import { SelectionCardType, SELECTION_CARD_MODEL } from './SelectionCard.types'
import SelectionCard from './SelectionCard.vue'

export default defineComponent({
  name: 'WeveSelectionCardRadio',
  components: {
    SelectionCard,
  },
  inheritAttrs: false,
  model: SELECTION_CARD_MODEL,
  props: {
    checked: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: {
    change: (value: string) => typeof value === 'string',
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance()
    const checked$ = computed(() => props.checked === props.value)
    const listeners = computed(() => ({
      ...instance.proxy.$listeners,
      change: (event: InputEvent) => {
        if ((event.target as HTMLInputElement).checked) {
          emit('change', props.value)
        }
      },
    }))
    return {
      checked$,
      listeners,
      SelectionCardType,
    }
  },
})
