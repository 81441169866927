
import type { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import type { Undefined } from '@/types'

import { ThemeMixin } from '../../mixins'
import WeveIcon from '../Icon/Icon.vue'
import WeveTooltip from '../Tooltip/Tooltip.vue'

export default ThemeMixin.extend({
  name: 'WeveInlineHelp',
  functional: true,
  props: {
    link: {
      type: String as PropType<Undefined<string>>,
      default: undefined,
    },
  },
  render(h, { props, children, data, injections }) {
    return h(
      WeveTooltip,
      {
        props: {
          placement: 'top',
        },
        staticClass: 'app-inline-help__tooltip',
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
        scopedSlots: {
          activator: ({ attrs }) => {
            return h(
              props.link !== undefined ? 'a' : 'span',
              mergeData(data, {
                attrs: {
                  ...attrs,
                  tabindex: props.link === undefined ? 0 : undefined,
                  href: props.link,
                  target: props.link === undefined ? undefined : '_blank',
                },
                staticClass: 'app-inline-help',
              }),
              [
                h(WeveIcon, {
                  props: { name: 'info' },
                  staticClass: 'app-inline-help__icon',
                }),
              ],
            )
          },
        },
      },
      children,
    )
  },
})
