import type {
  UserId,
  ExperienceId,
  SessionId,
  SessionAssignmentId,
} from '@/types'

import ScheduleSchema from '@/modules/schedule/ScheduleSchema'
import SessionAvailabilitySchema from '@/modules/schedule/SessionAvailabilitySchema'
import type ExperienceScheduleSchema from '@/modules/schedule/ExperienceScheduleSchema'

import {
  CancellationNotAllowed,
  FeeRequired,
  RescheduleRequestRequired,
} from './SchedulerService.exceptions'
import Request from './Request'
import BaseService from './BaseService'
import type {
  AvailableDatesResponse,
  ReserveDto,
  RescheduleForceDto,
  BookDto,
  AvailableHostResult,
  AssignRandomProducerResult,
  SessionAssignmentDto,
} from './SchedulerService.types'
import { CancelCode, RescheduleCode } from './SchedulerService.types'

export interface TimeSlot {
  available: boolean
  date: Date
}

export default class SchedulerService extends BaseService {
  availableDatesForSchema(schema: ScheduleSchema): Request<Date[]> {
    const ac = new AbortController()
    return new Request(
      this.client
        .get<AvailableDatesResponse>('/scheduler/schema/dates', {
          params: schema.toParams(),
          signal: ac.signal,
        })
        .then(({ data }) => data.map((item) => new Date(item))),
      ac,
    )
  }

  availableDatesForSession(schema: SessionAvailabilitySchema): Request<Date[]> {
    const ac = new AbortController()
    return new Request(
      this.client
        .get<AvailableDatesResponse>(
          `/scheduler/session/${schema.session}/dates`,
          {
            params: schema.toParams(),
            signal: ac.signal,
          },
        )
        .then(({ data }) => data.map((date) => new Date(date))),
      ac,
    )
  }

  availableDatesForExperience(
    id: ExperienceId,
    options: ExperienceScheduleSchema,
  ): Request<Date[]> {
    const ac = new AbortController()
    return new Request(
      this.client
        .get<AvailableDatesResponse>(
          `/scheduler/public/experience/${id}/dates`,
          {
            params: options.toParams(),
            signal: ac.signal,
          },
        )
        .then(({ data }) => data.map((date) => new Date(date))),
      ac,
    )
  }

  timeSlotsForSchema(schema: ScheduleSchema): Request<TimeSlot[]> {
    const ac = new AbortController()
    return new Request(
      this.client
        .get<TimeSlot[]>('/scheduler/public/schema/slots', {
          params: schema.toParams(),
          signal: ac.signal,
        })
        .then(({ data }) => {
          for (const slot of data) {
            slot.date = new Date(slot.date)
          }
          return data
        }),
      ac,
    )
  }

  async reserve(reserveDto: ReserveDto): Promise<void> {
    try {
      await this.client.post(
        `/scheduler/session/${reserveDto.sessionId}/reserve`,
        reserveDto.toParams(),
      )
    } catch (e) {
      if (
        BaseService.isServerErrorWithMetadata<{
          code: RescheduleCode
        }>(e)
      ) {
        if (e.response.data.metadata.code === RescheduleCode.FEE_REQUIRED) {
          throw new FeeRequired(e.response.data.message)
        }
        if (
          e.response.data.metadata.code ===
          RescheduleCode.RESCHEDULE_REQUEST_REQUIRED
        ) {
          throw new RescheduleRequestRequired(e.response.data.message)
        }
      }
      throw e
    }
  }

  async book(bookDto: BookDto): Promise<void> {
    await this.client.post(
      `/scheduler/session/${bookDto.sessionId}/book`,
      bookDto.toBody(),
    )
  }

  availableHosts(id: SessionId): Request<AvailableHostResult> {
    const ac = new AbortController()
    return new Request(
      this.client
        .get<AvailableHostResult>(`/scheduler/session/${id}/hosts`, {
          signal: ac.signal,
        })
        .then(({ data }) => data)
        .catch((e) => {
          if (
            BaseService.isServerErrorWithMetadata<{
              code: RescheduleCode
            }>(e)
          ) {
            if (
              e.response.data.metadata.code ===
                RescheduleCode.WRONG_ZONE_AREA ||
              e.response.data.metadata.code === RescheduleCode.NOT_ASSIGNABLE
            ) {
              return []
            }
          }
          throw e
        }),
      ac,
    )
  }

  async assignHost(
    id: SessionAssignmentId,
    userId: UserId,
    approval?: boolean,
  ): Promise<SessionAssignmentDto> {
    const { data } = await this.client.put<SessionAssignmentDto>(
      `scheduler/session-assignment/${id}/host`,
      {
        id: userId,
        approval,
      },
    )
    return data
  }

  async rescheduleForce(rescheduleForceDto: RescheduleForceDto): Promise<void> {
    await this.client.post(
      `/scheduler/session/${rescheduleForceDto.sessionId}/reschedule/force`,
      rescheduleForceDto.toBody(),
    )
  }

  async assignRandomProducer(
    id: SessionId,
  ): Promise<AssignRandomProducerResult> {
    const { data } = await this.client.put<AssignRandomProducerResult>(
      `scheduler/session/${id}/random/ep`,
    )
    return data
  }

  async assignRandomHost(
    id: SessionAssignmentId,
  ): Promise<SessionAssignmentDto> {
    const { data } = await this.client.put<SessionAssignmentDto>(
      `scheduler/session-assignment/${id}/random/host`,
    )
    return data
  }

  async shuffleHosts(id: SessionId): Promise<void> {
    await this.client.post(`scheduler/session/${id}/shuffle`)
  }

  async cancel(id: SessionId): Promise<void> {
    try {
      await this.client.delete(`/session/${id}`)
    } catch (e) {
      if (
        BaseService.isServerErrorWithMetadata<{
          code: CancelCode
        }>(e) &&
        e.response.data.metadata.code === CancelCode.CANCELLATION_NOT_ALLOWED
      ) {
        throw new CancellationNotAllowed(e.response.data.message)
      }
      throw e
    }
  }
}
