import type { Ref } from 'vue'
import { computed } from 'vue'

import type { SessionLocation } from '@/graphql/types'
import { SessionLocationType } from '@/graphql/types'
import { indexBy } from '@/util'

export default function useSessionLocations(locations: Ref<SessionLocation[]>) {
  const indexed = computed(() => indexBy(locations.value, 'type'))
  const starting = computed(
    () => indexed.value.get(SessionLocationType.START) ?? null,
  )
  const ending = computed(
    () => indexed.value.get(SessionLocationType.END) ?? null,
  )
  const venue = computed(
    () => indexed.value.get(SessionLocationType.VENUE) ?? null,
  )
  return {
    starting,
    ending,
    venue,
  }
}
