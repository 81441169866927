
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'

export default ThemeMixin.extend({
  name: 'WeveEventCardSkeleton',
  functional: true,
  render(h, { data, injections }) {
    return h(
      'div',
      mergeData(data, {
        staticClass: 'app-event-card-skeleton',
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme === 'dark',
        },
      }),
      [
        h('div', { staticClass: 'app-event-card-skeleton__content' }, [
          h('div', {
            staticClass: 'app-event-card-skeleton__thumbnail skeleton-element',
          }),
          h('div', { staticClass: 'app-event-card-skeleton__text' }, [
            h('div', { staticClass: 'skeleton-element' }),
            h('div', { staticClass: 'skeleton-element' }),
            h('div', { staticClass: 'skeleton-element' }),
          ]),
        ]),
      ],
    )
  },
})
