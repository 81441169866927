import type { Stripe } from '@stripe/stripe-js'

import StripeContainer from '@/modules/stripe'

export default function useStripe() {
  async function get(): Promise<Stripe> {
    return (await StripeContainer.getInstance()).stripe
  }

  return {
    get,
  }
}
