
import type { PropType } from 'vue'
import type { RawLocation } from 'vue-router'
import type { WithEvents } from 'vue-typed-emit'
import { mergeData } from 'vue-functional-data-merge'

import { Status, STATUSES } from '../../types'
import { ThemeMixin } from '../../mixins'
import { executeListener } from '../../utils'
import WeveIcon from '../Icon/Icon.vue'
import WeveStatusIndicator from '../StatusIndicator/StatusIndicator.vue'
import FormControlMessage from '../FormControlMessage/FormControlMessage.vue'
import type { WidgetEvents } from './Widget.types'
import WidgetTitle from './WidgetTitle.vue'

export default (
  ThemeMixin as WithEvents<WidgetEvents, typeof ThemeMixin>
).extend({
  name: 'WeveWidget',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    title: {
      type: [String, Number],
      default: undefined,
    },
    icon: {
      type: String,
      required: true,
    },
    status: {
      type: String as PropType<Status>,
      default: undefined,
      validator: (val: Status) => STATUSES.includes(val),
    },
    message: {
      type: String,
      default: undefined,
    },
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: undefined,
    },
  },
  render(h, { props, data, slots, listeners, injections }) {
    const link = props.to !== undefined

    return h(
      link ? 'router-link' : props.tag,
      mergeData(data, {
        props: link
          ? { to: props.to, activeClass: 'app-widget--selected' }
          : undefined,
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
        staticClass: 'app-widget',
        on: link
          ? undefined
          : {
              click: () => {
                executeListener(listeners.select)
              },
            },
      }),
      [
        link
          ? null
          : h(
              'button',
              {
                staticClass: 'sr-only',
                on: {
                  click: () => {
                    executeListener(listeners.select)
                  },
                },
              },
              'Select',
            ),
        h('div', { staticClass: 'app-widget__icon' }, [
          h(WeveIcon, { props: { name: props.icon } }),
        ]),
        h(
          'div',
          {
            staticClass: 'app-widget__content',
          },
          slots().default ?? [
            props.title
              ? h(WidgetTitle, undefined, props.title.toString())
              : null,
          ],
        ),
        props.status
          ? h(WeveStatusIndicator, {
              props: { status: props.status },
              staticClass: 'app-widget__status-indicator',
            })
          : undefined,
        props.message
          ? h(
              FormControlMessage,
              {
                props: { variant: props.status },
                staticClass: 'app-widget__message',
              },
              props.message,
            )
          : undefined,
      ],
    )
  },
})
