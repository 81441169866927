import type { PropType, VNode } from 'vue'
import mixins from 'vue-typed-mixins'

import { ThemeMixin, ScrollLockMixin } from '../../mixins'
import { generateId, hasSlot, normalizeSlot } from '../../utils'
import TextField from '../TextField/TextField.vue'
import Spinner from '../Spinner/Spinner.vue'
import type { AutocompleteOption } from './Autocomplete.types'

import './Autocomplete.scss'

const Extendable = mixins(ThemeMixin, ScrollLockMixin)

export default Extendable.extend({
  name: 'WeveAutocomplete',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: undefined,
    },
    current: {
      type: String,
      default: undefined,
    },
    search: {
      type: String,
      default: undefined,
    },
    options: {
      type: Array as PropType<AutocompleteOption[]>,
      default: () => [],
    },
    uid: {
      type: String,
      default: () => generateId(),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
      last: '',
    }
  },
  computed: {
    shown(): boolean {
      return this.focused && this.options.length > 0
    },
  },
  watch: {
    current() {
      this.last = ''
    },
    value() {
      this.focused = false
    },
  },
  methods: {
    hasSlot,
    normalizeSlot,
    onFocus() {
      this.focused = true
      this.lockScroll()
      this.$emit('focus')
    },
    onSearch(value: string) {
      this.$emit('update:search', value)
    },
    onBlur() {
      this.focused = false
      this.unlockScroll()
      this.$emit('blur')
      this.$emit('update:search', '')
    },
    onMousedown(e: MouseEvent) {
      e.preventDefault()
    },
    onRemove() {
      this.$emit('clear')
    },
  },
  render(h): VNode {
    return h(
      'div',
      {
        staticClass: 'weve-autocomplete',
        class: {
          'weve-autocomplete--shown': this.shown,
          'w-theme--light': this.light,
          'w-theme--dark': this.dark,
        },
      },
      [
        h(TextField, {
          props: {
            value:
              this.focused && this.readonly === false
                ? this.search
                : this.last !== ''
                  ? this.last
                  : this.current,
            placeholder: this.focused ? this.current : undefined,
            inputClass: 'weve-autocomplete__field',
            removable:
              this.readonly === false &&
              this.value !== '' &&
              this.value !== null &&
              this.value !== undefined &&
              this.clearable,
            readonly: this.readonly,
            ...this.$attrs,
          },
          on: {
            input: this.onSearch,
            focus: this.onFocus,
            blur: this.onBlur,
            remove: this.onRemove,
          },
          scopedSlots: {
            ...this.$scopedSlots,
            inner: () => {
              return [
                h(
                  'ul',
                  {
                    attrs: { id: this.uid, role: 'listbox' },
                    staticClass: 'weve-autocomplete__list',
                  },
                  this.options.map((option) =>
                    h(
                      'li',
                      {
                        key: option.value,
                        staticClass: 'weve-autocomplete__option',
                        on: {
                          mousedown: this.onMousedown,
                          click: () => {
                            this.$emit('input', option.value, option)
                            this.last = option.label
                          },
                        },
                      },
                      this.hasSlot('option')
                        ? this.normalizeSlot('option', option)
                        : option.label,
                    ),
                  ),
                ),
                h(
                  'div',
                  {
                    staticClass: 'weve-autocomplete__spinner',
                    class: {
                      'weve-autocomplete__spinner--visible': this.loading,
                    },
                  },
                  [h(Spinner)],
                ),
              ]
            },
          },
        }),
      ],
    )
  },
})
