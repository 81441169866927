
import type { PropType } from 'vue'
import Vue from 'vue'

import { WeveButton, WeveDialog, WeveStaticContent } from '@ui'

import type { NotifyCallback } from '../notify.types'

export default Vue.extend({
  name: 'DefaultNotify',
  components: {
    WeveButton,
    WeveDialog,
    WeveStaticContent,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Message',
    },
    callback: {
      type: Function as PropType<NotifyCallback>,
      default: null,
    },
  },
  methods: {
    ok() {
      if (this.callback !== null) this.callback()
      this.$destroy()
    },
  },
})
