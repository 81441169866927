import { computed, inject } from 'vue'

export default function useTheme() {
  const theme = inject('theme')
  const light = computed(() => theme === 'light')
  const dark = computed(() => theme !== 'light')
  return {
    theme,
    light,
    dark,
  }
}
