
import { nanoid } from 'nanoid/non-secure'
import mixins from 'vue-typed-mixins'

import { ThemeMixin, MessageMixin } from '../../mixins'
import WeveIcon from '../Icon/Icon.vue'
import WeveFormControlLabel from '../FormControlLabel/FormControlLabel.vue'
import AppFromControlHint from '../FormControlHint/FormControlHint.vue'
import AppFromControlMessage from '../FormControlMessage/FormControlMessage.vue'

const Extendable = mixins(ThemeMixin, MessageMixin)

export default Extendable.extend({
  name: 'WeveFileArea',
  components: {
    WeveIcon,
    WeveFormControlLabel,
    AppFromControlHint,
    AppFromControlMessage,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      default: undefined,
    },
    uid: {
      type: String,
      default() {
        return nanoid()
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      draggedIn: false,
    }
  },
  watch: {
    disabled(value) {
      if (value) {
        this.draggedIn = false
      }
    },
  },
  methods: {
    onDragIn() {
      this.draggedIn = true
    },
    onDragLeave() {
      this.draggedIn = false
    },
    onFileInput({ target }: InputEvent) {
      if (target === null) return
      const { files } = target as HTMLInputElement
      if (files === null) return
      this.addFiles(files)
    },
    onFileDrop({ dataTransfer }: DragEvent) {
      if (this.disabled) return
      this.draggedIn = false
      if (dataTransfer === null) return null
      const { files } = dataTransfer
      this.addFiles(files)
    },
    addFiles(files: FileList) {
      this.$emit('input', this.multiple ? files : files[0])
    },
  },
})
