import { ATTENDEES_PER_ROOM } from '@/constants'

export default class AttendeesValidator {
  constructor(
    private readonly size: number,
    private readonly attendees: number,
  ) {}

  get valid(): boolean {
    return (
      this.perRoom <= ATTENDEES_PER_ROOM ||
      (this.size === 0 && this.attendees > 0)
    )
  }

  get perRoom(): number {
    return this.size > 0 ? Math.ceil(this.attendees / this.size) : 0
  }

  get teams(): number {
    if (this.attendees <= 0) return 0
    if (this.attendees === 1) return 1
    if (this.attendees <= 8) return 2
    if (this.attendees <= 15) return 3
    if (this.attendees <= 20) return 4
    return 5
  }
}
