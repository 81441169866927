export const GRIDS = ['12', '24'] as const

export type Grid = (typeof GRIDS)[number]

export const GAPS = ['sm', 'default', 'md', 'lg'] as const

export type Gap = (typeof GAPS)[number]
export interface Props {
  tag?: string
  gap?: Gap
}
