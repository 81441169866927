
import type { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import type { ButtonSize } from '../Button/Button.types'
import { BUTTON_SIZES } from '../Button/Button.types'

export default ThemeMixin.extend({
  name: 'WeveButtonSkeleton',
  functional: true,
  props: {
    size: {
      type: String as PropType<ButtonSize>,
      default: 'default' as const,
      validator: (val: ButtonSize) => BUTTON_SIZES.includes(val),
    },
  },
  render(h, { props, data, injections }) {
    return h(
      'div',
      mergeData(data, {
        class: {
          [`weve-button-skeleton--size--${props.size}`]: true,
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme === 'dark',
        },
        staticClass: 'skeleton-element weve-button-skeleton',
      }),
    )
  },
})
