import { AssignmentApprovalState } from '@/graphql/types'

import BaseService from './BaseService'

export type AssignmentInfo = {
  city: string
  startsAt: string
  state: AssignmentApprovalState
  name: string
}

export default class AssignmentService extends BaseService {
  async info(code: string) {
    const { data } = await this.client.get<AssignmentInfo>(
      `/assignment/${code}`,
    )
    return data
  }

  async accept(code: string) {
    const { data } = await this.client.post<AssignmentInfo>(
      `/assignment/${code}/accept`,
    )
    return data
  }

  async decline(code: string) {
    const { data } = await this.client.post<AssignmentInfo>(
      `/assignment/${code}/decline`,
    )
    return data
  }

  async getRating(userId: string) {
    const { data } = await this.client.get<number>(
      `/assignment/rating/${userId}`,
    )
    return data
  }
}
