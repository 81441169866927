
import type { PropType } from 'vue'

import { Status, STATUSES } from '../../types'
import { ThemeMixin } from '../../mixins'
import { hasSlot } from '../../utils'
import WeveIcon from '../Icon/Icon.vue'
import WeveStatusIndicator from '../StatusIndicator/StatusIndicator.vue'
import WeveFormControlMessage from '../FormControlMessage/FormControlMessage.vue'

export default ThemeMixin.extend({
  name: 'WeveEventHighlightCard',
  components: {
    WeveIcon,
    WeveStatusIndicator,
    WeveFormControlMessage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    attendees: {
      type: Number,
      default: undefined,
    },
    teams: {
      type: Number,
      default: undefined,
    },
    rooms: {
      type: Number,
      default: undefined,
    },
    image: {
      type: String,
      default: undefined,
    },
    status: {
      type: String as PropType<Status>,
      default: undefined,
      validator: (val: Status) => STATUSES.includes(val),
    },
    message: {
      type: String,
      default: undefined,
    },
    truncate: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    hasSlot,
  },
})
