
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import WeveCheckbox from '../../Checkbox/Checkbox.vue'

export default Vue.extend({
  name: 'WeveMultiselectOption',
  functional: true,
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, data }) {
    return h(
      'div',
      mergeData(data, {
        attrs: { title: props.text },
        staticClass: 'app-multiselect__option',
      }),
      [
        h(WeveCheckbox, {
          props: {
            checked: props.selected,
            label: props.text,
            noLabel: true,
            readonly: true,
            disabled: props.disabled,
          },
          staticClass: 'app-multiselect__option-checkbox',
        }),
        h('span', { staticClass: 'app-multiselect__option-text' }, props.text),
      ],
    )
  },
})
