
import type { PropType } from 'vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'WeveTableText',
  functional: true,
  props: {
    text: {
      type: [String, Number] as PropType<string>,
      default: undefined,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, children }) {
    return h(
      'span',
      {
        attrs: { title: props.text },
        staticClass: 'weve-table__cell-text',
        class: { block: props.truncate, truncate: props.truncate },
      },
      props.text ?? children,
    )
  },
})
