import type { Nullable } from '@/types'
import type { LocationInput } from '@/graphql/types'

export async function locationOfCity(
  autocomplete: google.maps.places.AutocompleteService,
  geocoder: google.maps.Geocoder,
  city: string,
): Promise<Nullable<LocationInput>> {
  const request: google.maps.places.AutocompletionRequest = {
    input: city,
    language: 'en',
    types: ['(cities)'],
  }
  const { predictions } = await autocomplete.getPlacePredictions(request)
  if (predictions.length === 0) return null
  const prediction = predictions[0]
  const { results } = await geocoder.geocode({ placeId: prediction.place_id })
  if (results.length === 0) return null
  const [result] = results
  return {
    placeId: prediction.place_id,
    name: prediction.description,
    address: prediction.description,
    point: {
      type: 'Point',
      coordinates: [
        result.geometry.location.lng(),
        result.geometry.location.lat(),
      ],
    },
  }
}
