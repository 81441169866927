import type { PropType, VNode } from 'vue'
import mixins from 'vue-typed-mixins'
import type { WithRefs } from 'vue-typed-refs'
import type { WithEvents } from 'vue-typed-emit'
import type { WithProperties } from 'vue-typed-properties'
import Pickr from '@simonwep/pickr'

import { MessageMixin, ThemeMixin } from '../../mixins'
import FormControlLabel from '../FormControlLabel/FormControlLabel.vue'
import FormControlMessage from '../FormControlMessage/FormControlMessage.vue'
import type { WeveColorPickerEvents } from './ColorPicker.types'

import './ColorPicker.scss'

const Extendable = mixins(MessageMixin, ThemeMixin)

export default (
  Extendable as WithEvents<
    WeveColorPickerEvents,
    WithRefs<
      { root: HTMLElement },
      WithProperties<{ pickr: Pickr }, typeof Extendable>
    >
  >
).extend({
  name: 'WeveColorPicker',
  props: {
    value: {
      type: String as PropType<string | null | undefined>,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.pickr.setColor(value ?? null, true)
      this.pickr.applyColor(true)
    },
    disabled(value) {
      if (value) {
        this.pickr.disable()
        this._getButton().disabled = true
      } else {
        this.pickr.enable()
        this._getButton().disabled = false
      }
    },
  },
  mounted() {
    this.pickr = Pickr.create({
      el: this.$refs.root,
      theme: 'nano',
      lockOpacity: true,
      default: this.value ?? undefined,
      swatches: null,
      components: {
        preview: true,
        hue: true,
        interaction: {
          input: true,
          clear: true,
          save: true,
        },
      },
      disabled: this.disabled,
    })
    if (this.disabled) {
      this._getButton().disabled = true
    }
    this.pickr.on('save', (color: Pickr.HSVaColor | null) => {
      if (color === null) {
        this.$emit('input', null)
        return
      }
      this.$emit('input', color.toHEXA().toString().toLowerCase())
      this.pickr.hide()
    })
  },
  beforeDestroy() {
    this.pickr.destroyAndRemove()
  },
  methods: {
    open() {
      if (this.disabled) {
        return
      }
      this._getButton().focus()
      this.pickr.show()
    },
    _getButton() {
      return this.pickr.getRoot().button
    },
  },
  render(h): VNode {
    return h(
      'div',
      {
        staticClass: 'weve-color-picker',
        class: {
          'weve-color-picker--disabled': this.disabled,
          'w-theme--light': this.light,
          'w-theme--dark': this.dark,
        },
      },
      [
        h('div', { staticClass: 'weve-color-picker__inner' }, [
          h('div', { ref: 'root' }),
          h(
            FormControlLabel,
            {
              props: { tag: 'span', disabled: this.disabled },
              on: { click: this.open },
            },
            this.label,
          ),
        ]),
        this.message !== undefined
          ? h(
              'div',
              {
                staticClass:
                  'weve-form-control__bottom weve-color-picker__bottom',
              },
              [
                h(
                  FormControlMessage,
                  {
                    props: { variant: this.messageVariant },
                    staticClass: 'weve-color-picker__message',
                  },
                  [this.message],
                ),
              ],
            )
          : null,
      ],
    )
  },
})
