import { useTokenManager } from '@/use/services/useTokenManager'
import SocketContainer from '@/modules/socket'

export function useWebsocket() {
  const { store } = useTokenManager()
  const socket = SocketContainer.getInstance({ token: store.token })
  function emit(message: string, payload: unknown) {
    socket.emit(message, payload)
  }

  return {
    emit,
    socket,
  }
}
