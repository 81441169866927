import type { Container } from 'inversify'

import Token from '@/di/di.token'
import {
  AuthService,
  SessionService,
  SchedulerService,
  CartService,
} from '@/services'

export default class ServicesManager {
  constructor(private readonly container: Container) {}

  get auth(): AuthService {
    return this.container.get<AuthService>(Token.AUTH_SERVICE)
  }

  get session(): SessionService {
    return this.container.get<SessionService>(Token.SESSION_SERVICE)
  }

  get scheduler(): SchedulerService {
    return this.container.get<SchedulerService>(Token.SCHEDULER_SERVICE)
  }

  get cart(): CartService {
    return this.container.get<CartService>(Token.CART_SERVICE)
  }
}
