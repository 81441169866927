import Vue from 'vue'
import type { Stripe } from '@stripe/stripe-js'
import type { WithProperties } from 'vue-typed-properties'

import StripeInstance from '@/modules/stripe'

const StripeMixin = (Vue as WithProperties<{ stripe: Stripe }>).extend({
  name: 'StripeMixin',
  async created() {
    this.stripe = (await StripeInstance.getInstance()).stripe
  },
})

export default StripeMixin
