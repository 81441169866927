import { useWebsocket } from '@/use/useWebsocket'

const SCOPE = 'payment'

export default function useSocketPayment() {
  const { emit, socket } = useWebsocket()

  function joinStripeRoom<T extends { traceId: string }>(
    traceId: string,
  ): Promise<T> {
    emit('subscribeTraceId', traceId)
    return new Promise((resolve) => {
      socket.subscribe(SCOPE, 'paid', (data: T) => {
        if (data.traceId !== traceId) return
        leaveStripeRoom(traceId)
        resolve(data)
      })
    })
  }
  function leaveStripeRoom(traceId: string) {
    socket.unsubscribeScope(SCOPE)
    emit('unsubscribeTraceId', traceId)
  }

  return { joinStripeRoom, leaveStripeRoom }
}
