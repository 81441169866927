import type { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'

export default class StripeContainer {
  private static instance: StripeContainer

  constructor(readonly stripe: Stripe) {}

  static async getInstance(): Promise<StripeContainer> {
    if (StripeContainer.instance === undefined) {
      const STRIPE_KEY = process.env.VUE_APP_STRIPE_KEY
      if (STRIPE_KEY === undefined) {
        throw new Error(
          'STRIPE_KEY is not provided, please make sure you have `VUE_APP_STRIPE_KEY` set in your environment',
        )
      }
      const stripe = await loadStripe(STRIPE_KEY)
      if (stripe === null) {
        throw new Error('Error during Stripe initialization')
      }

      StripeContainer.instance = new StripeContainer(stripe)
    }

    return StripeContainer.instance
  }
}
