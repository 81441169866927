import { ref, onMounted } from 'vue'

import { getCurrentInstance } from '@/util'

function noop() {
  // noop
}

declare global {
  interface Window {
    noop: typeof noop
  }
}

window.noop = noop

export default function useGoogleMaps() {
  const instance = getCurrentInstance()
  const loaded = ref(false)
  async function load() {
    await instance.proxy.$loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places,drawing&callback=noop`,
    )
    loaded.value = true
  }
  onMounted(load)
  return {
    loaded,
  }
}
