
import type { PropType } from 'vue'
import type { RawLocation } from 'vue-router'
import { mergeData } from 'vue-functional-data-merge'

import { ExtendedEvent, SKIP_CLICK_OUTSIDE } from '../../../constants'
import { ThemeMixin } from '../../../mixins'
import Icon from '../../Icon/Icon.vue'
import { Color, COLORS } from './types'

export default ThemeMixin.extend({
  name: 'WeveMenuItem',
  functional: true,
  props: {
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: undefined,
    },
    href: {
      type: String,
      default: '#',
    },
    target: {
      type: String,
      default: '_self',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String as PropType<Color>,
      default: 'default' as const,
      validator: (val: Color) => COLORS.includes(val),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, children, data, injections }) {
    const href = props.to === undefined ? props.href : undefined

    return h('li', { attrs: { role: 'presentation' } }, [
      h(
        props.to !== undefined ? 'router-link' : 'a',
        mergeData(data, {
          props: props.to !== undefined ? { to: props.to } : undefined,
          class: {
            [`weve-menu-item--color--${props.color}`]:
              props.color !== 'default',
            'weve-menu-item--disabled': props.disabled,
            'w-theme--light': injections.theme === 'light',
            'w-theme--dark': injections.theme === 'dark',
          },
          attrs: {
            role: 'menuitem',
            href,
            target: props.target,
            'aria-disabled': props.disabled,
          },
          staticClass: 'weve-menu-item',
          on: {
            click: (e: ExtendedEvent<PointerEvent>) => {
              e[SKIP_CLICK_OUTSIDE] = true

              if (href === '#') {
                e.preventDefault()
              }
            },
          },
        }),

        [
          h('span', { staticClass: 'weve-menu-item__text' }, children),
          props.active
            ? h(Icon, {
                props: { name: 'check', size: 'md' },
                staticClass: 'weve-menu-item__icon',
              })
            : undefined,
        ],
      ),
    ])
  },
})
