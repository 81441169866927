import type { Ref, PluginObject } from 'vue'
import { watch } from 'vue'
import type { ApolloClient } from '@apollo/client/core'
import type VueRouter from 'vue-router'

import type { WorkspaceId } from '@/types'
import type {
  WorkspaceForStoreFragment,
  GetWorkspaceForStoreQuery,
  GetWorkspaceForStoreQueryVariables,
} from '@/graphql/types'
import getWorkspaceForStoreDocument from '@/store/graphql/getWorkspaceForStore.graphql'

const WorkspacePlugin: PluginObject<{
  id: Ref<WorkspaceId | undefined>
  client: ApolloClient<unknown>
  setter: (value: WorkspaceForStoreFragment | null) => void
  router: VueRouter
}> = {
  install(Vue, options) {
    if (options === undefined) {
      throw new Error('`WorkspacePlugin` requires `options` to be provided')
    }

    watch(
      options.id,
      async (value) => {
        if (value === undefined) {
          options.setter(null)
          return
        }
        const { data } = await options.client.query<
          GetWorkspaceForStoreQuery,
          GetWorkspaceForStoreQueryVariables
        >({
          query: getWorkspaceForStoreDocument,
          variables: {
            id: value,
          },
        })
        const workspace = data.workspace ?? null
        if (workspace === null) {
          options.router.push({ name: 'workspace' })
        } else {
          options.setter(workspace)
        }
      },
      { immediate: true },
    )
  },
}

export default WorkspacePlugin
