import type { PropType } from 'vue'
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveColumnBoxWidgetBlock',
  functional: true,
  props: {
    title: {
      type: [String, Number],
      required: true,
    },
    text: {
      type: String,
      default: undefined,
    },
    size: {
      type: String as PropType<'sm'>,
      default: undefined,
      validator: (val) => val === 'sm',
    },
  },
  render(h, { props, data }) {
    return h(
      'div',
      mergeData(data, {
        staticClass: 'weve-column-box-widget__block',
        class: {
          'weve-column-box-widget__block--size--sm': props.size === 'sm',
        },
      }),
      [
        h(
          'span',
          { staticClass: 'weve-column-box-widget__title' },
          props.title.toString(),
        ),
        h('br'),
        props.text !== undefined
          ? h(
              'span',
              { staticClass: 'weve-column-box-widget__text' },
              props.text,
            )
          : null,
      ],
    )
  },
})
