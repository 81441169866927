import { getCurrentInstance } from '@/util'

import type { Notify } from '@/plugins/notify'

export default function useNotify() {
  const instance = getCurrentInstance()

  function n(...args: Parameters<Notify>) {
    return instance.proxy.$notify(...args)
  }

  return { notify: n }
}
