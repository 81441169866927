import { WeveTextButton } from '@ui'
import { useNotify } from '@/use'
import { getCurrentInstance } from '@/util'
import usePurchaseDialog from '@/components/PurchaseDialog/use/usePurchaseDialog'

export default function useNotifyNoCredits() {
  const instance = getCurrentInstance()
  const { _v, $createElement: h } = instance.proxy
  const { notify } = useNotify()
  const { show } = usePurchaseDialog()
  function n(missing: number) {
    const verb = missing > 1 ? 'are' : 'is'
    const { hide } = notify(
      [
        h('p', undefined, [
          _v(
            `You do not have enough available credits. ${missing} more ${verb} required. You may purchase more credits `,
          ),
          h(
            WeveTextButton,
            {
              props: { link: true },
              on: {
                click: () => {
                  hide()
                  show(missing)
                },
              },
            },
            'here',
          ),
          _v('.'),
        ]),
      ],
      { title: 'Not enough credits' },
    )
  }
  return { notify: n }
}
