import type { UnwrapSet } from '@/types'

export const DIALOG_SIZES = new Set([
  'default',
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  'xxl',
  'auto',
] as const)

export type DialogSize = UnwrapSet<typeof DIALOG_SIZES>

export interface DialogProps {
  value: boolean
  title: string
  size?: DialogSize
  scrollable?: boolean
  noPadding?: boolean
}
