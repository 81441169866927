import Vue from 'vue'

import type { UserForStoreFragment } from '@/graphql/types'

export default Vue.extend({
  name: 'ViewerMixin',
  computed: {
    viewer(): UserForStoreFragment {
      return this.$store.state.auth.user
    },
  },
})
