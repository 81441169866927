import { computed } from 'vue'

import type { Undefined, WorkspaceId } from '@/types'

import useStore from '../useStore'

const SET = new Set<WorkspaceId>(
  (process.env.VUE_APP_AUTO_WEVE_AND_OPEN_WEVE_ELIGIBLE_WORKSPACES ?? '').split(
    ',',
  ),
)

export default function useWorkspace() {
  const { store } = useStore()
  const workspace = computed(() => store.state.workspace)
  const subscriber = computed(() => workspace.value?.subscriber ?? undefined)
  const isDiyEligible = computed(
    () =>
      subscriber.value ||
      (workspace.value !== null && SET.has(workspace.value.id)),
  )
  const content = computed<Undefined<string>>(() => {
    if (workspace.value?.content !== true) return undefined
    return process.env.VUE_APP_CONTENT_TOOL_URL ?? undefined
  })
  return {
    workspace,
    subscriber,
    isDiyEligible,
    content,
  }
}
