
import type { PropType } from 'vue'
import type { WithEvents } from 'vue-typed-emit'
import type { WithProperties } from 'vue-typed-properties'

import { ThemeMixin } from '../../mixins'
import WeveIcon from '../Icon/Icon.vue'
import WeveButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import WeveSpinner from '../Spinner/Spinner.vue'
import {
  Status,
  STATUSES,
  FileCardFile,
  FileCardEvents,
  FileType,
} from './FileCard.types'
import { formatBytes } from './FileCard.util'

export default (
  ThemeMixin as WithEvents<
    FileCardEvents,
    WithProperties<{ FileType: typeof FileType }, typeof ThemeMixin>
  >
).extend({
  name: 'FileCard',
  components: {
    WeveIcon,
    WeveButtonIcon,
    WeveSpinner,
  },
  props: {
    file: {
      type: Object as PropType<FileCardFile>,
      required: true,
    },
    status: {
      type: String as PropType<Status>,
      default: 'done' as const,
      validator: (val: Status) => STATUSES.includes(val),
    },
    title: {
      type: String,
      required: true,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    deleting: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    downloadable: {
      type: Boolean,
      default: true,
    },
    downloading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDone: false,
    }
  },
  computed: {
    size(): string | undefined {
      const { size } = this.file
      return size !== undefined && size !== null ? formatBytes(size) : undefined
    },
    text(): string | undefined {
      switch (this.status) {
        case 'uploading':
          return this.message ?? 'Uploading…'
        case 'uploaded':
          return this.message ?? 'Upload Complete'
        case 'error':
          return this.message ?? 'Upload Error'
        default:
          return undefined
      }
    },
    hasError(): boolean {
      return this.status === 'error'
    },
    src(): string | undefined {
      const { src, type } = this.file
      if (
        src === undefined ||
        src === null ||
        src === '' ||
        type === undefined ||
        type === null
      ) {
        return undefined
      }

      return src
    },
  },
  created() {
    this.FileType = FileType
  },
  methods: {
    download() {
      this.$emit('download')
    },
    onNameInput(e: InputEvent) {
      this.$emit('update:name', (e.target as HTMLSpanElement).innerText)
    },
  },
})
