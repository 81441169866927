import type {
  Undefined,
  DateTimeString,
  UserId,
  SessionId,
  SessionAssignmentId,
  DateTimeRange,
} from '@/types'
import type {
  SessionRole,
  AssignmentType,
  AssignmentApprovalState,
} from '@/graphql/types'
import type { UserDto } from '@/modules/user/user.types'

export type AvailableDatesResponse = string[]

export enum CancelCode {
  CANCELLATION_NOT_ALLOWED = 'CANCELLATION_NOT_ALLOWED',
}

export enum RescheduleCode {
  FEE_REQUIRED = 'FEE_REQUIRED',
  RESCHEDULE_REQUEST_REQUIRED = 'RESCHEDULE_REQUEST_REQUIRED',
  WRONG_ZONE_AREA = 'WRONG_ZONE_AREA',
  NOT_ASSIGNABLE = 'NOT_ASSIGNABLE',
}

export class ReserveDto {
  constructor(
    readonly sessionId: SessionId,
    readonly start: Date,
    readonly duration: number,
    readonly timezone: string,
    readonly scheduleOffset?: Undefined<number>,
  ) {}

  toParams(): Record<string, unknown> {
    return {
      start: this.start.toISOString(),
      duration: this.duration,
      timezone: this.timezone,
      scheduleOffset: this.scheduleOffset,
    }
  }
}

export class BookDto {
  constructor(
    readonly sessionId: SessionId,
    readonly start: Date,
    readonly duration: number,
    readonly timezone: string,
    readonly quantity: number,
  ) {}

  toBody(): Record<string, unknown> {
    return {
      start: this.start.toISOString(),
      duration: this.duration,
      timezone: this.timezone,
      quantity: this.quantity,
    }
  }
}

export class RescheduleForceDto {
  constructor(
    readonly sessionId: string,
    readonly start: Date,
    readonly duration: number,
    readonly timezone: string,
    readonly scheduleOffset?: number,
  ) {}

  toBody(): Record<string, unknown> {
    return {
      start: this.start.toISOString(),
      duration: this.duration,
      timezone: this.timezone,
      scheduleOffset: this.scheduleOffset,
    }
  }
}

export type AvailableHostResult = { id: UserId; fullName: string }[]

export interface AssignRandomProducerResult {
  id: SessionAssignmentId
}

export type SessionAssignmentApprovalDto = {
  id: SessionAssignmentId
  state: AssignmentApprovalState
}

export type SessionAssignmentDto = {
  id: SessionAssignmentId
  role: SessionRole
  type: AssignmentType
  order: number
  updatedAt: DateTimeString
  user?: UserDto
  travel?: DateTimeRange
  travelConfirmed?: boolean
  approval?: SessionAssignmentApprovalDto
}
