
import { Portal, MountingPortal } from 'portal-vue'
// @ts-expect-error v-click-outside has no typings
import { directive as clickOutside } from 'v-click-outside'
import type { WithRefs } from 'vue-typed-refs'
import mixins from 'vue-typed-mixins'

import type { VueComponentListeners } from '@/types'

import { createPopperMixin, ThemeMixin } from '../../mixins'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import { generateId } from '../../utils'

type Refs = {
  menu: HTMLUListElement
}

const Extendable = mixins(
  createPopperMixin({ placement: 'bottom-start' }),
  ThemeMixin,
)

export default (Extendable as WithRefs<Refs, typeof Extendable>).extend({
  name: 'WeveMenu',
  components: { Portal, MountingPortal, ButtonIcon },
  directives: {
    clickOutside,
  },
  props: {
    uid: {
      type: String,
      default() {
        return generateId()
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    activatorId(): string {
      return `menu-activator-${this.uid}`
    },
    activatorAttrsComputed(): Record<string, string | boolean> {
      return {
        id: this.activatorId,
        'aria-haspopup': 'true',
        'aria-expanded': String(this.isOpen),
      }
    },
    activatorOn(): VueComponentListeners {
      return {
        click: this.toggle,
      }
    },
  },
  watch: {
    isOpen(value: boolean) {
      if (value === true) {
        this.$nextTick(() => {
          this.createPopperInstance(this.getActivator(), this.$refs.menu, {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 4],
                },
              },
            ],
          })
        })
      }
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    hide() {
      this.isOpen = false
    },
    getActivator() {
      const activator = document.getElementById(this.activatorId)
      if (activator) return activator
      // TODO: message
      throw new Error('')
    },
    clickOutsideMiddleware(e: MouseEvent) {
      const { target } = e

      if (target !== null) {
        if (this.$refs.menu.contains(target as Node) === true) {
          return false
        }
      }

      return true
    },
  },
})
