import Vue from 'vue'
import type { WithProperties } from 'vue-typed-properties'

const ConfirmMixin = (
  Vue as WithProperties<{
    resolve: (value: boolean) => void
  }>
).extend({
  name: 'ConfirmMixin',
  inheritAttrs: false,
  data() {
    return {
      value: false,
    }
  },
  watch: {
    value(value) {
      if (value === false) {
        this.resolve(false)
      }
    },
  },
  methods: {
    confirm() {
      this.value = true
      return new Promise<boolean>((resolve) => {
        this.resolve = resolve
      })
    },
    accept() {
      this.resolve(true)
      this.value = false
    },
    decline() {
      this.resolve(false)
      this.value = false
    },
  },
})

export default ConfirmMixin
