import type { Nullable } from '@/types'

import type { User } from './types'

const state = {
  user: null as Nullable<User>,
  authenticating: true,
}

export default state

export type State = typeof state
