import type { UnwrapSet } from '@/types'

export const BUTTON_ICON_VARIANTS = new Set([
  'default',
  'dark',
  'light',
] as const)
export const BUTTON_ICON_COLORS = new Set(['default', 'primary'] as const)

export type ButtonIconVariant = UnwrapSet<typeof BUTTON_ICON_VARIANTS>
export type ButtonIconColor = UnwrapSet<typeof BUTTON_ICON_COLORS>

export interface ButtonIconProps {
  icon: string
  label: string
  variant?: ButtonIconVariant
  color?: ButtonIconColor
  disabled?: boolean
  loading?: boolean
  active?: boolean
  inverse?: boolean
}
