/** @private */
export enum SelectionCardType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export const SELECTION_CARD_MODEL = {
  prop: 'checked',
  event: 'change',
}
