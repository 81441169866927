import { getCurrentInstance } from '@/util'

import type { Confirm } from '@/plugins/confirm'

export default function useConfirm() {
  const instance = getCurrentInstance()

  function c(...args: Parameters<Confirm>) {
    return instance.proxy.$confirm(...args)
  }

  return { confirm: c }
}
