import type { Ref } from 'vue'
import { computed } from 'vue'

import { getCurrentInstance } from '@/util'

export default function useSyncProp<T>(ref: Ref<T>, name: string) {
  const instance = getCurrentInstance()

  const model = computed({
    get: () => ref.value,
    set: (v) => {
      instance.proxy.$emit(`update:${name}`, v)
    },
  })

  return model
}
