
import type { PropType, VNode } from 'vue'
import Vue from 'vue'
import { BTooltip } from 'bootstrap-vue'

import { generateId } from '../../utils'
import type { TooltipPlacement } from './Tooltip.types'
import { TOOLTIP_PLACEMENTS } from './Tooltip.types'
import { TooltipActivatorNotProvidedError } from './Tooltip.errors'

export default Vue.extend({
  name: 'WeveTooltip',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    uid: {
      type: String,
      default() {
        return generateId()
      },
    },
    placement: {
      type: String as PropType<TooltipPlacement>,
      default: 'bottom',
      validator: (value: TooltipPlacement) => TOOLTIP_PLACEMENTS.has(value),
    },
    delay: {
      type: Object as PropType<{ show: number; hide: number }>,
      default: () => ({ show: 250, hide: 100 }),
    },
    triggers: {
      type: String,
      default: 'focus hover',
    },
    offset: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 200,
    },
  },
  computed: {
    activatorAttrs(): Record<string, string> {
      return {
        id: this.uid,
      }
    },
  },
  render(h): VNode {
    const { activator } = this.$scopedSlots

    if (activator === undefined) {
      throw new TooltipActivatorNotProvidedError()
    }

    return h(
      this.tag,
      {
        staticClass: 'app-tooltip',
      },
      [
        activator({ attrs: this.activatorAttrs }),
        h(
          BTooltip,
          {
            props: {
              target: this.uid,
              placement: this.placement,
              delay: this.delay,
              boundary: 'window',
              customClass: 'app-tooltip',
              triggers: this.triggers,
              offset: this.offset,
              disabled: this.disabled,
              width: this.width,
            },
          },
          this.$slots.default,
        ),
      ],
    )
  },
})
