import type {
  Nullable,
  Point,
  WorkspaceId,
  ExperienceId,
  SessionGroupId,
  ExperinceCustomizationTitle,
} from '@/types'
import type {
  ExperienceFormatType,
  HostType,
  SessionIntegration,
  LocationInput,
} from '@/graphql/types'
import { PackageLevel } from '@/graphql/types'

export enum SessionFile {
  ATTENDEES = 'attendees',
  VGE = 'vge',
}

export type CreateSessionDto = {
  workspaceId: WorkspaceId
  experienceId: ExperienceId
  format: ExperienceFormatType
  hostType: HostType
  timezone: string
  attendees: number
  duration: number
  date: Nullable<Date>
  location: Nullable<LocationInput>
  packageLevel?: PackageLevel
  customizations: Nullable<Set<ExperinceCustomizationTitle>>
  group?: SessionGroupId
}

export enum CreateCode {
  NOT_ENOUGH_CREDITS = 'NOT_ENOUGH_CREDITS',
  SUBSCRIBERS_ONLY = 'SUBSCRIBERS_ONLY',
}

export enum PriceCode {
  UNKNOWN_PRICE_ZONE = 'UNKNOWN_PRICE_ZONE',
}

export enum UpdateSlotsCode {
  NOT_ENOUGH_CREDITS = 'NOT_ENOUGH_CREDITS',
  NOT_ENOUGH_HOSTS = 'NOT_ENOUGH_HOSTS',
  NOT_ENOUGH_HOSTS_AND_CREDITS = 'NOT_ENOUGH_HOSTS_AND_CREDITS',
}

export type SyncResult = SessionIntegration

export type SessionPriceInput = {
  format: ExperienceFormatType
  hostType: HostType
  attendees: number
  duration: number
  point?: Point
  packageLevel?: PackageLevel
  experienceId: ExperienceId
  workspaceId?: WorkspaceId
  date?: Date
}

export type SessionQuote = {
  credits: number
  price: number
}

export type ToGroupResult = {
  id: SessionGroupId
  name: string
}
