import type { PluginObject } from 'vue'
import type { Store } from 'vuex'

import container from '@/di/di.container'

const DiPlugin: PluginObject<{ store: Store<unknown> }> = {
  install(Vue, options) {
    Vue.prototype.$di = container
    if (options !== undefined) {
      options.store.$di = container
    }
  },
}

export default DiPlugin
