import { merge } from 'lodash-es'

const FirstName = { max: 32 }
const LastName = { max: 32 }
const Email = { email: true, lowercase: true }
const Password = { password: true }
const EventTitle = { max: 96 }

const VALIDATION_RULES = {
  FirstName,
  RequiredFirstName: merge({}, FirstName, { required: true }),
  LastName,
  RequiredLastName: merge({}, LastName, { required: true }),
  Email,
  RequiredEmail: merge({}, Email, { required: true }),
  Password,
  RequiredPassword: merge({}, Password, { required: true }),
  EventTitle,
  RequiredEventTitle: merge({}, EventTitle, { required: true }),
  EventSubtitle: { max: 64 },
  EventDescription: { max: 256 },
  TriviaSenName: { required: true, max: 70 },
  MissionTitle: { required: true, max: 18 },
  MissionText: { required: true, max: 144 },
  MissionAnswer: { required: true, max: 64 },
}

export default VALIDATION_RULES
