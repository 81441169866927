import {
  Container,
  BindingScopeEnum,
  decorate,
  injectable,
  inject,
} from 'inversify'
import 'reflect-metadata'
import axios, { type AxiosInstance } from 'axios';

import RefresherImpl from '@/core/auth/RefresherImpl'
import Client from '@/api/client'
import TokensManagerImpl from '@/core/auth/TokensManagerImpl'
import type BaseService from '@/services/BaseService';
import {
  AuthService,
  SessionService,
  SessionGroupService,
  WorkspaceService,
  SchedulerService,
  CartService,
  EmailService,
  PlantService,
  PricingService,
  AiPromptService,
  AssignmentService,
} from '@/services'

import Token from './di.token'

const REFRESHER = Symbol('REFRESHER')

const container = new Container({
  skipBaseClassChecks: true,
  defaultScope: BindingScopeEnum.Singleton,
})

const refresher = new RefresherImpl(axios)
container.bind(REFRESHER).toConstantValue(refresher)
decorate(injectable(), TokensManagerImpl)
container.bind(Token.TOKENS_MANAGER).to(TokensManagerImpl)
decorate(inject(REFRESHER), TokensManagerImpl, 0)
const { VUE_APP_API_BASE_URL } = process.env
if (VUE_APP_API_BASE_URL === undefined) {
  throw new Error(
    'VUE_APP_API_BASE_URL is not provided, please make sure you have `VUE_APP_API_BASE_URL` set in your environment',
  )
}
const instance = new Client(
  VUE_APP_API_BASE_URL,
  container.get<TokensManagerImpl>(Token.TOKENS_MANAGER),
)
container.bind(Token.HTTP_CLIENT).toConstantValue(instance.client)

const containerize = (
  token: Token,
  constructor: new (client: AxiosInstance) => BaseService,
) => {
  decorate(injectable(), constructor)
  decorate(inject(Token.HTTP_CLIENT), constructor, 0)
  container.bind(token).to(constructor)
}

containerize(Token.AUTH_SERVICE, AuthService)
containerize(Token.SESSION_SERVICE, SessionService)
containerize(Token.SESSION_GROUP_SERVICE, SessionGroupService)
containerize(Token.WORKSPACE_SERVICE, WorkspaceService)
containerize(Token.SCHEDULER_SERVICE, SchedulerService)
containerize(Token.CART_SERVICE, CartService)
containerize(Token.EMAIL_SERVICE, EmailService)
containerize(Token.PLANT_SERVICE, PlantService)
containerize(Token.PRICING_SERVICE, PricingService)
containerize(Token.AI_PROMPT_SERVICE, AiPromptService)
containerize(Token.ASSIGNMENT_SERVICE, AssignmentService)

export default container
