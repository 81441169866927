import type { PluginObject } from 'vue'

import type { Accessor } from './types'

import AccessorImpl from './accessor'

const PermissionsPlugin: PluginObject<{ accessor?: Accessor }> = {
  install(Vue, options) {
    const accessor = options?.accessor ?? new AccessorImpl()
    Vue.prototype.$permissions = accessor
  },
}

export default PermissionsPlugin
