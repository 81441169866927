import {
  string,
  number,
  refine,
  intersection,
  type,
  is,
  object,
} from 'superstruct'

import type { UserId } from '@/types'
import type {
  Maybe,
  SessionLocation,
  SessionGroupDetails,
  SessionForShowFragment,
} from '@/graphql/types'
import {
  ExperienceCategory,
  SessionLocationType,
  SessionType,
  HostType,
  SyncType,
  SessionRole,
  SessionRelationType,
} from '@/graphql/types'
import { ExperienceTitle } from '@/data'
import AttendeesValidator from '@/modules/core/AttendeesValidator'
import { isDefined, indexBy } from '@/util'

import usePermissions from './usePermissions'

const NonEmptyString = refine(string(), 'NonEmptyString', (value) => {
  return value.length > 0
})

const GreaterThenZero = refine(number(), 'GreaterThenZero', (value) => {
  return value > 0
})

const SessionGroupDetailsType = type({
  playedPreviously: NonEmptyString,
  multiSession: NonEmptyString,
  multicultural: NonEmptyString,
  young: NonEmptyString,
  familiar: NonEmptyString,
  sameLocation: NonEmptyString,
  departments: NonEmptyString,
})

const SessionEventDetailsType = type({
  name: NonEmptyString,
  contact: object(),
})

const RemoteSessionEventDetailsType = intersection([
  SessionEventDetailsType,
  type({
    size: GreaterThenZero,
  }),
])

function computePastEventsValidity(
  relations: { type: SessionRelationType }[],
  group?: Maybe<{ playedPreviously?: Maybe<string> }>,
  details?: Maybe<string>,
): boolean {
  const previously = group?.playedPreviously
  const details$ = details ?? ''
  if (
    previously === 'Virtual' ||
    previously === 'In-Person' ||
    previously === 'Both'
  ) {
    const past = relations.filter(
      (relation) => relation.type === SessionRelationType.PAST,
    )
    if (past.length > 0) {
      return true
    }
    if (details$ !== '') {
      return true
    }
    return false
  }
  return true
}

function computeFutureEventsValidity(
  relations: { type: SessionRelationType }[],
  group?: Maybe<{ multiSession?: Maybe<string> }>,
  details?: Maybe<string>,
): boolean {
  const multi = group?.multiSession
  const details$ = details ?? ''
  if (multi === 'Yes') {
    const future = relations.filter(
      (relation) => relation.type === SessionRelationType.FUTURE,
    )
    if (future.length > 0) {
      return true
    }
    if (details$ !== '') {
      return true
    }
    return false
  }
  return true
}

function computeGroupDetailsValidity(session: {
  type: SessionType
  hostType: HostType
  hasUpgrade: boolean
  supportsPrizes: boolean
  experience?: Maybe<{ title: string; category?: ExperienceCategory }>
  meta: { group?: Maybe<SessionGroupDetails> }
}): boolean {
  if (
    session.type === SessionType.IN_PERSON ||
    session.type === SessionType.HYBRID
  ) {
    const experience = session.experience ?? null
    const group = session.meta.group ?? null
    const isBlackoutExperience =
      experience !== null && experience.title === ExperienceTitle.BLACKOUT
    const isGameShowExperience =
      experience !== null &&
      experience.category === ExperienceCategory.GAME_SHOW
    if (group === null) return false
    if (session.hostType === HostType.ON_SITE) {
      if (isDefined(group.teamsOrigin) === false) return false
      if (isDefined(group.food) === false) return false
      if (isDefined(group.alcohol) === false) return false
    }
    if (isDefined(group.tv) === false) return false
    if (isDefined(group.rating) === false && isBlackoutExperience === false) {
      return false
    }
    if (isDefined(group.description) === false) return false
    if (session.supportsPrizes && isDefined(group.prizesOrigin) === false) {
      return false
    }
    const prizesShippingAddress = group.prizesShippingAddress ?? ''
    if (
      session.supportsPrizes &&
      group.prizesOrigin === 'Go Game' &&
      prizesShippingAddress === ''
    ) {
      return false
    }
    if (
      session.hasUpgrade &&
      isGameShowExperience === false &&
      isBlackoutExperience === false
    ) {
      const bar = session.meta.group?.bar ?? ''
      if (bar === '') return false
    }
    return true
  }
  return is(session.meta.group, SessionGroupDetailsType)
}

function computeHostsAssigned(session: {
  diy: boolean
  assignments: { role: SessionRole; user?: Maybe<{ id: UserId }> }[]
}): boolean {
  if (session.diy) return true
  return session.assignments
    .filter((assignment) => assignment.role === SessionRole.HOST)
    .every((room) => room.user !== null && room.user !== undefined)
}

function computeStaffAssigned(session: {
  diy: boolean
  assignments: { role: SessionRole }[]
}): boolean {
  if (session.diy) return true
  return (
    session.assignments.find(
      (assignment) => assignment.role === SessionRole.PRODUCER,
    ) !== undefined
  )
}

function computeEventDetailsValidity(
  session: {
    diy: boolean
    name: string
    type: SessionType
    contact?: Maybe<{ id: UserId }>
    templateId?: Maybe<string>
    size: number
    syncType: SyncType
  },
  staff: boolean,
): boolean {
  if (staff) {
    let valid = is(
      session,
      // @ts-expect-error TODO
      session.type === SessionType.REMOTE && session.diy === false
        ? RemoteSessionEventDetailsType
        : SessionEventDetailsType,
    )
    if (valid) {
      if (session.syncType === SyncType.THIRD_PARTY) {
        valid = true
      } else {
        valid = isDefined(session.templateId)
      }
    }
    return valid
  }
  return is(
    session,
    // @ts-expect-error TODO
    session.type === SessionType.REMOTE && session.diy === false
      ? RemoteSessionEventDetailsType
      : SessionEventDetailsType,
  )
}

function computeEventLocationValidity(
  session: {
    irl: boolean
    city?: Maybe<string>
    gameZoneId?: Maybe<string>
    locations: SessionLocation[]
    supportsGameZone: boolean
  },
  staff: boolean,
): boolean {
  const city = session.city ?? undefined
  if (city === undefined || city === '') return false
  const indexed = indexBy(session.locations, 'type')
  if (indexed.get(SessionLocationType.START) === undefined) return false
  if (session.irl && indexed.get(SessionLocationType.END) === undefined)
    return false
  if (staff) {
    const zone = session.gameZoneId ?? undefined
    if (session.supportsGameZone && zone === undefined) return false
  }
  return true
}

export default function useSessionValidation() {
  const { isStaff } = usePermissions()

  function computeEventDetailsValidityLocal(
    session: Parameters<typeof computeEventDetailsValidity>[0],
  ) {
    return computeEventDetailsValidity(session, isStaff.value)
  }

  function computeEventLocationValidityLocal(
    session: Parameters<typeof computeEventLocationValidity>[0],
  ) {
    return computeEventLocationValidity(session, isStaff.value)
  }

  function computeValidity(session: SessionForShowFragment) {
    const eventDetailsValid = computeEventDetailsValidityLocal(session)
    const contactDetailsValid =
      session.contact !== undefined && session.contact !== null
    const groupDetailsValid = computeGroupDetailsValidity(session)
    const pastEventsValid = computePastEventsValidity(
      session.relations,
      session.meta.group,
      session.pastEventsDetails,
    )
    const futureEventsValid = computeFutureEventsValidity(
      session.relations,
      session.meta.group,
      session.futureEventsDetails,
    )
    const hostsAssigned = computeHostsAssigned(session)
    const staffAssigned = computeStaffAssigned(session)

    if (isStaff.value) {
      return (
        eventDetailsValid &&
        (session.hasGroupDetails === false
          ? true
          : groupDetailsValid && pastEventsValid && futureEventsValid) &&
        (session.diy ? true : contactDetailsValid) &&
        (session.diy ? true : hostsAssigned) &&
        (session.diy ? true : staffAssigned) &&
        (session.diy
          ? true
          : new AttendeesValidator(session.size, session.attendees).valid)
      )
    } else {
      if (eventDetailsValid === false) return false
      if (session.diy !== true && contactDetailsValid === false) return false
      if (
        session.hasGroupDetails &&
        (groupDetailsValid === false ||
          pastEventsValid === false ||
          futureEventsValid === false)
      )
        return false
      return true
    }
  }

  return {
    computeEventDetailsValidity: computeEventDetailsValidityLocal,
    computeEventLocationValidity: computeEventLocationValidityLocal,
    computeGroupDetailsValidity,
    computePastEventsValidity,
    computeFutureEventsValidity,
    computeHostsAssigned,
    computeStaffAssigned,
    computeValidity,
  }
}
