import { ref } from 'vue'

import type { Undefined } from '@/types'
import type { ExperienceFormatType, HostType } from '@/graphql/types'
import { useDebouncedRef } from '@/use'

const format = ref<Undefined<ExperienceFormatType>>(undefined)
const host = ref<Undefined<HostType>>(undefined)
const query = ref('')

export default function useExperienceFilters() {
  const debouncedQuery = useDebouncedRef(query)
  return { format, host, query, debouncedQuery }
}
