
import type { VNode } from 'vue'

import { ThemeMixin } from '../../mixins'
import type { VueComponentListeners } from '../../types'
import { generateId } from '../../utils'
import FormControlHint from '../FormControlHint/FormControlHint.vue'

export default ThemeMixin.extend({
  name: 'WeveToggle',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default() {
        return generateId()
      },
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    inputListeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        change: (event: InputEvent) => {
          this.$emit('change', (event.target as HTMLInputElement).checked)
        },
      }
    },
  },
  render(h): VNode {
    return h(
      'div',
      {
        staticClass: 'app-toggle app-form-control',
        class: {
          'app-toggle--disabled': this.disabled,
          'app-toggle--block': this.block,
          'w-theme--light': this.light,
          'w-theme--dark': this.dark,
        },
      },
      [
        h('div', { staticClass: 'app-toggle__inner' }, [
          h('span', { staticClass: 'app-toggle__text' }, this.label),
          h('input', {
            attrs: {
              ...this.$attrs,
              id: this.id,
              type: 'checkbox',
              disabled: this.disabled,
            },
            domProps: {
              checked: this.checked,
            },
            staticClass: 'app-toggle__input',
            on: this.inputListeners,
          }),
          h(
            'label',
            { attrs: { for: this.id }, staticClass: 'app-toggle__imitator' },
            [
              h(
                'span',
                {
                  staticClass: 'app-toggle__label',
                },
                [this.label],
              ),
            ],
          ),
        ]),
        this.hint !== undefined
          ? h('div', { staticClass: 'app-toggle__bottom' }, [
              h(FormControlHint, undefined, this.hint),
            ])
          : null,
      ],
    )
  },
})
