import type { ActionTree } from 'vuex'

import type { Maybe } from '@/types'
import type {
  UserAttributesInput,
  MeQuery,
  MeQueryVariables,
  UpdateUserForStoreMutation,
  UpdateUserForStoreMutationVariables,
} from '@/graphql/types'
import Token from '@/di/di.token'
import apollo from '@/apollo'

import meDocument from './graphql/me.graphql'
import updateUserForStoreDocument from './graphql/updateUserForStore.graphql'
import type { State } from './state'
import GetterTypes from './getter-types'
import MutationTypes from './mutation-types'
import ActionTypes from './action-types'

const actions: ActionTree<State, unknown> = {
  async [ActionTypes.LOGIN](
    _,
    { email, password }: { email: string; password: string },
  ) {
    const result = await this.$services.auth.login(email, password)
    if (result.success) {
      const storage = this.$di.get(Token.TOKENS_MANAGER)
      storage.token = result.token
      storage.refreshToken = result.refreshToken
      return { success: true }
    }
    return { success: false, reason: result.reason, session: result.session }
  },
  async [ActionTypes.ENSURE_AUTHENTICATED]({ state, getters, commit }) {
    if (getters[GetterTypes.AUTHENTICATED]) {
      return true
    }
    const storage = this.$di.get(Token.TOKENS_MANAGER)
    if (storage.empty) {
      commit(MutationTypes.SET_AUTHENTICATING, false)
      return false
    }
    try {
      const result = await apollo.query<MeQuery, MeQueryVariables>({
        query: meDocument,
      })
      const user = result.data.me
      commit(MutationTypes.SET_USER, user)
      console.log('[Auth]: Current User', user)
      apollo
        .watchQuery<MeQuery, MeQueryVariables>({
          query: meDocument,
          fetchPolicy: 'cache-only',
        })
        .subscribe({
          next({ data }) {
            const updated = data.me
            commit(MutationTypes.SET_USER, { ...state.user, ...updated })
          },
        })

      if (
        window.self === window.top &&
        process.env.VUE_APP_LOGROCKET_ENABLED === 'true'
      ) {
        const LogRocketService = () => import('@/services/LogRocketService')
        LogRocketService().then(({ identify }) => identify(user))
      }
      return true
    } catch {
      return false
    } finally {
      commit(MutationTypes.SET_AUTHENTICATING, false)
    }
  },

  [ActionTypes.LOGOUT]() {
    const tokensStorage = this.$di.get(Token.TOKENS_MANAGER)
    tokensStorage.clear()
    window.location.href = '/'
  },
  async [ActionTypes.COMPLETE_PASSWORD](
    _,
    {
      email,
      password,
      session,
    }: { email: string; password: string; session: string },
  ) {
    const { token, refreshToken } = await this.$services.auth.completePassword({
      email,
      password,
      session,
    })
    const tokensStorage = this.$di.get(Token.TOKENS_MANAGER)
    tokensStorage.token = token
    tokensStorage.refreshToken = refreshToken
  },
  async [ActionTypes.FORGOT_PASSWORD](_, { email }: { email: string }) {
    return this.$services.auth.forgotPassword({ email })
  },
  async [ActionTypes.FORGOT_PASSWORD_SUBMIT](
    _,
    {
      email,
      code,
      password,
    }: { email: string; code: string; password: string },
  ) {
    await this.$services.auth.forgotPasswordConfirm({ email, code, password })
  },
  async [ActionTypes.UPDATE_USER](
    { state },
    payload: {
      firstName?: Maybe<string>
      lastName?: Maybe<string>
      phone?: Maybe<string>
      meetingLink?: Maybe<string>
      attributes?: Maybe<UserAttributesInput>
    },
  ) {
    if (state.user === null) return
    await apollo.mutate<
      UpdateUserForStoreMutation,
      UpdateUserForStoreMutationVariables
    >({
      mutation: updateUserForStoreDocument,
      variables: {
        input: {
          id: state.user.id,
          update: payload,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateUser: {
          __typename: 'User',
          id: state.user.id,
          firstName: payload.firstName ?? state.user.firstName,
          lastName: payload.lastName ?? state.user.lastName,
          meetingLink: payload.meetingLink ?? state.user.meetingLink,
          fullName: state.user.fullName,
          phone: payload.phone ?? state.user.phone,
          attributes: payload.attributes ?? {
            __typename: 'UserAttributes',
            email: null,
          },
        },
      },
    })
  },
}

export default actions
