import type { Ref } from 'vue'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'

import { usePlacesAutocomplete } from '@/modules/location'

export default function useGeoCitySearch(
  query: Ref<string>,
  enabled: Ref<boolean>,
) {
  const { autocomplete } = usePlacesAutocomplete()
  const loaded = computed(() => autocomplete.value !== null)
  const queryEnabled = computed(
    () => enabled.value && autocomplete.value !== null,
  )
  const { data, isFetching: loading } = useQuery(
    ['places', query],
    () => {
      if (autocomplete.value === null) return Promise.resolve(null)
      return autocomplete.value.getPlacePredictions({
        input: query.value,
        language: 'en',
        types: ['(cities)'],
      })
    },
    { enabled: queryEnabled },
  )
  const options = computed(() => {
    return (
      data.value?.predictions.map((prediction) => ({
        placeId: prediction.place_id,
        value: prediction.description,
        label: prediction.description,
      })) ?? []
    )
  })
  const first = computed(() => options.value[0] ?? null)
  return {
    loaded,
    loading,
    options,
    first,
  }
}
