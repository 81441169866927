import { computed } from 'vue'

import GetterTypes from '@/store/getter-types'

import useStore from './useStore'

export default function useAuthenticated() {
  const { store } = useStore()
  const authenticated = computed<boolean>(
    () => store.getters[GetterTypes.AUTHENTICATED],
  )
  const authenticating = computed(() => store.state.auth.authenticating)
  return {
    authenticated,
    authenticating,
  }
}
