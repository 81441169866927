
import Vue from 'vue'

export default Vue.extend({
  name: 'WeveWidgetTitle',
  functional: true,
  render(h, { children }) {
    return h(
      'span',
      {
        staticClass: 'app-widget__title truncate',
      },
      children,
    )
  },
})
