
import { mergeData } from 'vue-functional-data-merge'
import randomInteger from 'random-int'

import { ThemeMixin } from '../../mixins'

export default ThemeMixin.extend({
  name: 'WeveEventCardSkeleton',
  functional: true,
  props: {
    items: {
      type: Number,
      default: 4,
    },
    interactable: {
      type: Boolean,
      default: true,
    },
  },
  render(h, { props, data, injections }) {
    const children = []

    for (let i = 0; i < props.items; i++) {
      children.push(
        h('li', { key: i, staticClass: 'app-list-skeleton__item' }, [
          h('div', {
            staticClass: 'skeleton-element app-list-skeleton__element',

            style: {
              width: `${randomInteger(20, 60)}%`,
            },
          }),
        ]),
      )
    }

    return h(
      'ul',
      mergeData(data, {
        staticClass: 'app-list-skeleton',
        class: {
          'app-list-skeleton--interactable': props.interactable,
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme === 'dark',
        },
      }),
      children,
    )
  },
})
