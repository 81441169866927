import { computed } from 'vue'

import { getCurrentInstance } from '@/util'
import { format } from '@/constants/calendly'

export default function useTimezone() {
  const instance = getCurrentInstance()
  const timezone = instance.proxy.$timezone
  const formatted = computed(() => format(timezone))
  return {
    timezone,
    formatted,
  }
}
