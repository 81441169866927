
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveDropdownDivider',
  functional: true,
  render(h, { data }) {
    return h(
      'li',
      mergeData(data, {
        attrs: {
          role: 'presentation',
        },
        staticClass: 'weve-dropdown__divider',
      }),
      [
        h('hr', {
          attrs: { role: 'separator', 'aria-orientation': 'horizontal' },
          staticClass: 'weve-dropdown__divider-element',
        }),
      ],
    )
  },
})
