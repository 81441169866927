import Vue from 'vue'
import type { WithProperties } from 'vue-typed-properties'

import type { Nullable } from '@/types'
import type ScheduleSchema from '@/modules/schedule/ScheduleSchema'
import type { TimeSlot } from '@/services/SchedulerService'
import type Request from '@/services/Request'

export default (
  Vue as WithProperties<{ request: Nullable<Request<TimeSlot[]>> }>
).extend({
  name: 'TimeSlotsProvider',
  data() {
    return {
      timeSlots: [] as TimeSlot[],
      loadingTimeSlots: false,
    }
  },
  created() {
    this.request = null
  },
  beforeDestroy() {
    this.cancelTimeSlotsRequest()
  },
  methods: {
    async timeSlotsForSchema(schema: ScheduleSchema) {
      const request = this.$services.scheduler.timeSlotsForSchema(schema)
      await this._requestTimeSlots(request)
    },
    cancelTimeSlotsRequest() {
      if (this.request === null) {
        return
      }
      this.request.cancel()
    },
    async _requestTimeSlots(request: Request<TimeSlot[]>) {
      this.cancelTimeSlotsRequest()
      this.timeSlots = []
      this.request = request
      this.loadingTimeSlots = true
      try {
        this.timeSlots = await request.promise
      } finally {
        this.request = null
        this.loadingTimeSlots = false
      }
    },
  },
})
