import Vue from 'vue'
import type { ScopedSlotChildren } from 'vue/types/vnode'

export default Vue.extend({
  name: 'SlotsMixin',
  methods: {
    hasSlot(name = 'default'): boolean {
      return (
        this.$slots[name] !== undefined || this.$scopedSlots[name] !== undefined
      )
    },
    normalizeSlot(name = 'default', scope = {}): ScopedSlotChildren {
      const slot = this.$scopedSlots[name] || this.$slots[name]
      return typeof slot === 'function' ? slot(scope) : slot
    },
  },
})
