
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import WeveFormAsterisk from '../FormAsterisk/FormAsterisk.vue'

export default ThemeMixin.extend({
  name: 'WeveFormControlLabel',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'label',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, data, children, injections }) {
    return h(
      props.tag,
      mergeData(data, {
        class: {
          ['app-form-control-label--disabled']: props.disabled,
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
        staticClass: 'app-form-control-label',
      }),
      [children, props.required ? h(WeveFormAsterisk) : undefined],
    )
  },
})
