const UPPERCASE = /[A-Z]/
const LOWERCASE = /[a-z]/
const NUMBER = /[0-9]/
const SYMBOL = /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/

export default class PasswordValidator {
  static validate(password: string): boolean {
    if (password.length < 8) return false
    if (password.length > 32) return false
    if (password.match(UPPERCASE) === null) return false
    if (password.match(LOWERCASE) === null) return false
    if (password.match(NUMBER) === null) return false
    if (password.match(SYMBOL) === null) return false
    return true
  }
}
