import {
  ApolloClient,
  Observable,
  InMemoryCache,
  ApolloLink,
  Reference,
  FieldFunctionOptions,
} from '@apollo/client/core'
import type { FieldPolicy } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { getOperationDefinition } from '@apollo/client/utilities'
import { parseISO, differenceInBusinessDays } from 'date-fns'
import omitDeep from 'omit-deep-lodash'
import { HttpLink } from '@apollo/client/link/http'
import { onError } from '@apollo/client/link/error'
import type { TypedTypePolicies } from '@/graphql/apollo-helpers'

import typeDefs from '@/graphql/schema.graphql'

import type {
  Availability,
  GlobalUnavailability,
  Order,
  Session,
  SessionIntegration,
  SessionGroup,
  Experience,
  Mission,
} from '@/graphql/types'
import {
  PackageLevel,
  SessionType,
  HostType,
  SessionRole,
  IntegrationType,
} from '@/graphql/types'
import type { Nullable, ModifierId } from '@/types'
import type { TokensManager } from '@/core/auth/interfaces/TokensManager'

import container from '../di/di.container'
import Token from '../di/di.token'

const tokens = container.get<TokensManager>(Token.TOKENS_MANAGER)

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const def = getOperationDefinition(operation.query)
  if (def !== undefined && def.operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, '__typename')
  }
  return forward(operation)
})

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_API_GRAPHQL_BASE_URL,
})

const authLink = setContext((_, { headers = {} }) => {
  const token = tokens.token

  if (token !== undefined) {
    headers.authorization = `Bearer ${token}`
  }

  return {
    headers,
  }
})

class UnauthenticatedException extends Error {
  constructor() {
    super('UNAUTHENTICATED')
  }
}
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors === undefined) return forward(operation)

  for (const err of graphQLErrors) {
    if (err.extensions.code === 'UNAUTHENTICATED') {
      return new Observable((observer) => {
        tokens
          .refresh()
          .then((token) => {
            if (token === undefined) {
              throw new UnauthenticatedException()
            }
            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...headers,
                authorization: `Bearer ${token}`,
              },
            }))
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            }
            forward(operation).subscribe(subscriber)
          })
          .catch((error) => {
            observer.error(error)
          })
      })
    }
  }

  return forward(operation)
})

const link = ApolloLink.from([cleanTypenameLink, errorLink, authLink, httpLink])

export function OffsetLimitPagination(): FieldPolicy<
  { nodes: Reference[] },
  { nodes: Reference[] },
  { nodes: Reference[] },
  FieldFunctionOptions<{ paging?: { offset?: number; limit?: number } }>
> {
  return {
    keyArgs: ['filter', 'sorting'],
    read(existing, options) {
      if (existing === undefined) return existing
      if (options.args === null) return existing
      const paging = options.args.paging ?? null
      if (paging === null) return existing
      const offset = paging.offset ?? 0
      const limit = paging.limit ?? existing.nodes.length
      return {
        ...existing,
        nodes: existing.nodes.slice(offset, offset + limit),
      }
    },
    merge(existing, incoming, options) {
      const nodes = existing ? existing.nodes.slice(0) : []

      if (
        options.args !== null &&
        options.args.paging !== null &&
        options.args.paging !== undefined
      ) {
        const offset = options.args.paging.offset ?? 0
        for (let i = 0; i < incoming.nodes.length; ++i) {
          nodes[offset + i] = incoming.nodes[i]
        }
      } else {
        nodes.push(...incoming.nodes)
      }

      return { ...incoming, nodes }
    },
  }
}

const DEFAULT_OFFSET_LIMIT_PAGINATION = OffsetLimitPagination()

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      aiPrompts: DEFAULT_OFFSET_LIMIT_PAGINATION,
      experiences: DEFAULT_OFFSET_LIMIT_PAGINATION,
      instructions: DEFAULT_OFFSET_LIMIT_PAGINATION,
      modifiers: DEFAULT_OFFSET_LIMIT_PAGINATION,
      priceModifiers: DEFAULT_OFFSET_LIMIT_PAGINATION,
      zoneAreas: DEFAULT_OFFSET_LIMIT_PAGINATION,
      sessionPhones: DEFAULT_OFFSET_LIMIT_PAGINATION,
      gameCities: DEFAULT_OFFSET_LIMIT_PAGINATION,
      gameZones: DEFAULT_OFFSET_LIMIT_PAGINATION,
      users: DEFAULT_OFFSET_LIMIT_PAGINATION,
      workspaces: DEFAULT_OFFSET_LIMIT_PAGINATION,
      breadcrumbMissionLists: DEFAULT_OFFSET_LIMIT_PAGINATION,
      sessions: DEFAULT_OFFSET_LIMIT_PAGINATION,
      missionSets: DEFAULT_OFFSET_LIMIT_PAGINATION,
      experienceFormats: DEFAULT_OFFSET_LIMIT_PAGINATION,
      orders: DEFAULT_OFFSET_LIMIT_PAGINATION,
      templates: DEFAULT_OFFSET_LIMIT_PAGINATION,
      userAudios: DEFAULT_OFFSET_LIMIT_PAGINATION,
    },
  },
  User: {
    fields: {
      modifiers: {
        merge: false,
      },
      availabilities: { merge: false },
    },
  },
  Availability: {
    fields: {
      datetime: {
        read(_, { readField }): Availability['datetime'] {
          const date = readField<Availability['date']>('date') ?? undefined
          if (date === undefined) return null
          return parseISO(date)
        },
      },
    },
  },
  GlobalUnavailability: {
    fields: {
      startDate: {
        read(_, { readField }): GlobalUnavailability['startDate'] | null {
          const start =
            readField<GlobalUnavailability['startsAt']>('startsAt') ?? undefined
          if (start !== undefined) return parseISO(start)
          return null
        },
      },
      endDate: {
        read(_, { readField }): GlobalUnavailability['endDate'] | null {
          const end =
            readField<GlobalUnavailability['endsAt']>('endsAt') ?? undefined
          if (end !== undefined) return parseISO(end)
          return null
        },
      },
    },
  },
  Order: {
    fields: {
      paidDate: {
        read(_, { readField }): Order['paidDate'] | null {
          const paid = readField<Order['paidAt']>('paidAt') ?? undefined
          if (paid !== undefined) return new Date(paid)
          return null
        },
      },
      createdDate: {
        read(_, { readField }): Order['createdDate'] | null {
          const created =
            readField<Order['createdAt']>('createdAt') ?? undefined
          if (created !== undefined) return new Date(created)
          return null
        },
      },
    },
  },
  Session: {
    fields: {
      remote: {
        read(_, { readField }): Session['remote'] {
          const type = readField<Session['type']>('type')
          return type === SessionType.REMOTE
        },
      },
      irl: {
        read(_, { readField }): Session['irl'] {
          const type = readField<Session['type']>('type')
          return type === SessionType.IN_PERSON
        },
      },
      hybrid: {
        read(_, { readField }): Session['hybrid'] {
          const type = readField<Session['type']>('type')
          return type === SessionType.HYBRID
        },
      },
      onScreen: {
        read(_, { readField }): Session['onScreen'] {
          const hostType = readField<Session['hostType']>('hostType')
          return hostType === HostType.ON_SCREEN
        },
      },
      modifications: {
        merge: false,
        read(_, { readField }): ModifierId[] {
          const result = new Set<ModifierId>()
          const modifiers = readField<Reference[]>('modifiers') ?? []
          const extract = (array: readonly Reference[]) => {
            for (const modifier of array) {
              const id = readField<ModifierId>('id', modifier)
              if (id !== undefined) {
                result.add(id)
              }
            }
          }
          extract(modifiers)
          return [...result]
        },
      },
      assignments: { merge: false },
      files: { merge: false },
      integrations: { merge: false },
      relations: { merge: false },
      locations: { merge: false },
      startDate: {
        read(_, { readField }) {
          const start = readField<Session['startsAt']>('startsAt') ?? undefined
          if (start !== undefined) return new Date(start)
          return null
        },
      },
      endDate: {
        read(_, { readField }) {
          const end = readField<Session['endsAt']>('endsAt') ?? undefined
          if (end !== undefined) return new Date(end)
          return null
        },
      },
      scheduleStartDate: {
        read(_, { readField }) {
          const date =
            readField<Session['scheduleStartsAt']>('scheduleStartsAt') ??
            undefined
          if (date !== undefined) return new Date(date)
          return null
        },
      },
      scheduled: {
        read(_, { readField }): Session['scheduled'] {
          const start = readField<Session['startsAt']>('startsAt') ?? undefined
          return start !== undefined
        },
      },
      upcoming: {
        read(_, { readField }): Session['upcoming'] {
          const start = readField<Session['startDate']>('startDate') ?? null
          if (start === null) return false
          const difference = differenceInBusinessDays(start, new Date())
          if (difference <= 1) return true
          return false
        },
      },
      complete: {
        read(_, { readField }) {
          const startDate = readField<Session['startDate']>('startDate') ?? null
          if (startDate === null) {
            return false
          }
          return startDate.valueOf() < Date.now()
        },
      },
      hasUpgrade: {
        read(_, { readField }) {
          const type = readField<Session['packageLevel']>('packageLevel')
          return type === PackageLevel.PRO || type === PackageLevel.PREMIUM
        },
      },
      createdDate: {
        read(_, { readField }) {
          const created =
            readField<Session['createdAt']>('createdAt') ?? undefined
          if (created !== undefined) return new Date(created)
          return null
        },
      },
      meta: {
        merge: true,
      },
      producer: {
        read(_, { readField }) {
          const assignments = readField<Reference[]>('assignments') ?? []
          let user: Nullable<Reference> = null
          for (const assignment of assignments) {
            const role = readField<SessionRole>('role', assignment)
            if (role === SessionRole.PRODUCER) {
              user = readField<Nullable<Reference>>('user', assignment) ?? null
              break
            }
          }
          return user
        },
      },
      hosts: {
        read(_, { readField }) {
          const assignments = readField<Reference[]>('assignments') ?? []
          const hosts: Reference[] = []
          for (const assignment of assignments) {
            const role = readField<SessionRole>('role', assignment)
            if (role === SessionRole.HOST) {
              hosts.push(assignment)
            }
          }
          return hosts
        },
      },
      producers: {
        read(_, { readField }) {
          const assignments = readField<Reference[]>('assignments') ?? []
          const hosts: Reference[] = []
          for (const assignment of assignments) {
            const role = readField<SessionRole>('role', assignment)
            if (
              role === SessionRole.PRODUCER ||
              role === SessionRole.SUPPORTING_PRODUCER
            ) {
              const user =
                readField<Nullable<Reference>>('user', assignment) ?? null
              if (user === null) continue
              hosts.push(user)
            }
          }
          return hosts
        },
      },
      hasBreadcrumbIntegration: {
        read(_, { readField }) {
          const integrations = readField<Reference[]>('integrations') ?? []
          return integrations.some((integration) => {
            const type = readField<IntegrationType>('type', integration)
            return type === IntegrationType.BREADCRUMB
          })
        },
      },
      links: {
        read(_, { readField }): Session['links'] {
          const res = new Map<IntegrationType, string>()
          const integrations = readField<Reference[]>('integrations') ?? []
          for (const integration of integrations) {
            const type = readField<SessionIntegration['type']>(
              'type',
              integration,
            )
            const url = readField<SessionIntegration['url']>('url', integration)
            if (type === undefined || url === undefined || url === null)
              continue
            res.set(type, url)
          }
          return res
        },
      },
    },
  },
  SessionGroup: {
    fields: {
      attendees: {
        read(_, { readField }) {
          const sessions = readField<SessionGroup['sessions']>('sessions') ?? []
          let value = 0
          for (const session of sessions) {
            const attendees =
              readField<Session['attendees']>('attendees', session) ?? 0
            value += attendees
          }
          return value
        },
      },
    },
  },
  Customization: {
    fields: {
      media: { merge: false },
    },
  },
  Experience: {
    fields: {
      updatedDate: {
        read(_, { readField }) {
          const start =
            readField<Experience['updatedAt']>('updatedAt') ?? undefined
          if (start !== undefined) return new Date(start)
          return null
        },
      },
    },
  },
  ExperienceFormat: {
    fields: {
      modifiers: {
        merge: false,
      },
    },
  },
  Mission: {
    fields: {
      createdAtDate: {
        read(_, { readField }): Nullable<Mission['createdAtDate']> {
          const createdAt =
            readField<Mission['createdAt']>('createdAt') ?? undefined
          if (createdAt !== undefined) return parseISO(createdAt)
          return null
        },
      },
    },
  },
}

const cache = new InMemoryCache({ typePolicies })

const apolloClient = new ApolloClient({
  typeDefs,
  link,
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
})

export default apolloClient
