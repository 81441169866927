import { ref } from 'vue'

export default function useDialog(initial = false) {
  const visible = ref(initial)

  function show() {
    visible.value = true
  }

  function hide() {
    visible.value = false
  }

  return {
    visible,
    show,
    hide,
  }
}
