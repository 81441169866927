
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../../mixins'

export default ThemeMixin.extend({
  name: 'WeveMenuDivider',
  functional: true,
  render(h, { data, injections }) {
    return h(
      'li',
      mergeData(data, {
        attrs: { role: 'presentation' },
      }),
      [
        h('hr', {
          attrs: { role: 'separator', 'aria-orientation': 'horizontal' },
          staticClass: 'weve-menu__divider',
          class: {
            'w-theme--light': injections.theme === 'light',
            'w-theme--dark': injections.theme !== 'light',
          },
        }),
      ],
    )
  },
})
