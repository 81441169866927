import type { UnwrapSet } from '@/types'

export const PILL_VARIANTS = new Set(['primary', 'secondary'] as const)
export type PillVariant = UnwrapSet<typeof PILL_VARIANTS>

export interface PillProps {
  variant?: PillVariant
  tag?: string
  removable?: boolean
  disabled?: boolean
}
