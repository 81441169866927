import { computed } from 'vue'

import { PermissionRole } from '@/graphql/types'
import { getCurrentInstance } from '@/util'

import store from '../store'
import GetterTypes from '../store/getter-types'
import useWorkspaceId from './useWorkspaceId'

export default function usePermissions() {
  const instance = getCurrentInstance()
  const { workspaceId } = useWorkspaceId()
  const isAdmin = computed<boolean>(() => store.getters[GetterTypes.IS_ADMIN])
  const isStaff = computed<boolean>(() => store.getters[GetterTypes.IS_STAFF])
  const isSalesperson = computed<boolean>(
    () => store.getters[GetterTypes.IS_SALESPERSON],
  )
  const isEventProducer = computed<boolean>(
    () => store.getters[GetterTypes.IS_EVENT_PRODUCER],
  )
  const isHost = computed<boolean>(() => store.getters[GetterTypes.IS_HOST])
  const isBookingUser = computed<boolean>(
    () => store.getters[GetterTypes.IS_BOOKING_USER],
  )
  const hasStaffPermissions = computed<boolean>(
    () => store.getters[GetterTypes.HAS_STAFF_PERMISSIONS],
  )
  const isHostWithoutStaffPermissions = computed<boolean>(
    () => isHost.value && hasStaffPermissions.value === false,
  )
  const hasCurrentWorkspaceAdminPermissions = computed(() => {
    return instance.proxy.$permissions.hasAccess(
      workspaceId.value,
      PermissionRole.ADMIN,
    )
  })

  return {
    isAdmin,
    isStaff,
    isBookingUser,
    isSalesperson,
    isEventProducer,
    isHost,
    hasStaffPermissions,
    isHostWithoutStaffPermissions,
    hasCurrentWorkspaceAdminPermissions,
  }
}
