
import mixins from 'vue-typed-mixins'
import type { WithEvents } from 'vue-typed-emit'
import type { WithRefs } from 'vue-typed-refs'

import type { ClassDefinition } from '../../types'
import { hasSlot } from '../../utils'
import { IdentifiableMixin, StateMessageMixin, ThemeMixin } from '../../mixins'
import WeveIcon from '../Icon/Icon.vue'
import WeveFormControlLabel from '../FormControlLabel/FormControlLabel.vue'
import WeveFormControlHint from '../FormControlHint/FormControlHint.vue'
import WeveFormControlMessage from '../FormControlMessage/FormControlMessage.vue'
import StatusIndicator from '../StatusIndicator/StatusIndicator.vue'
import type { Events, Source } from './Spinbutton.types'

const CODE_DOWN = 40
const CODE_UP = 38
const KEY_CODES = new Set([CODE_UP, CODE_DOWN])

export type Refs = {
  field: HTMLElement
  input: HTMLInputElement
}

const Extendable = mixins(IdentifiableMixin, StateMessageMixin, ThemeMixin)

const NUMBER_REGEX = /^-?\d*$/
const DECIMAL_REGEX = /^-?\d*\.?\d*$/

export default (
  Extendable as WithEvents<Events, WithRefs<Refs, typeof Extendable>>
).extend({
  name: 'WeveSpinbutton',
  components: {
    WeveIcon,
    WeveFormControlLabel,
    WeveFormControlHint,
    WeveFormControlMessage,
    StatusIndicator,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: undefined,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10000,
    },
    step: {
      type: Number,
      default: 1,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: undefined,
    },
    inputtable: {
      type: Boolean,
      default: true,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    decimal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const val = this.value ?? 0
    return {
      hasFocus: false,
      localValue: val.toString(),
    }
  },
  computed: {
    val(): number {
      return this.value ?? 0
    },
    minusDisabled(): boolean {
      return this.disabled || this.readonly || this.val <= this.min
    },
    plusDisabled(): boolean {
      return this.disabled || this.readonly || this.val >= this.max
    },
    className(): ClassDefinition {
      return {
        'app-form-control': true,
        'app-spinbutton': true,
        [`app-spinbutton--size--${this.size}`]: this.size !== undefined,
        'app-spinbutton--disabled': this.disabled,
        'app-spinbutton--invalid': this.invalid,
        'w-theme--light': this.light,
        'w-theme--dark': this.dark,
      }
    },
    max$(): number {
      if (this.value > this.max) {
        return this.value
      }
      return this.max
    },
  },
  watch: {
    value(value: number) {
      this.localValue = value.toString()
    },
  },
  methods: {
    setValue(value: number, source: Source) {
      if (this.disabled) return
      const { min, max$ } = this
      const v = value > max$ ? max$ : value < min ? min : value
      this.$emit('input', v, { source })
      this.localValue = v.toString()
    },
    stepValue(direction: number) {
      const step = this.step * direction
      const value = this.val + step
      this.setValue(value, 'button')
    },
    stepUp() {
      this.stepValue(+1)
    },
    stepDown() {
      this.stepValue(-1)
    },
    onFocusBlur(event: Event) {
      if (this.disabled === false) {
        this.hasFocus = event.type === 'focus'
      } else {
        this.hasFocus = false
      }
    },
    onKeydown(event: KeyboardEvent) {
      const { keyCode, altKey, ctrlKey, metaKey } = event

      if (this.disabled || altKey || ctrlKey || metaKey) {
        return
      }

      if (KEY_CODES.has(keyCode)) {
        if (keyCode === CODE_UP) {
          this.stepUp()
          return
        }
        this.stepDown()
      }
    },
    onInput(event: InputEvent) {
      const target = event.target as HTMLInputElement
      const { value } = target
      const regex = this.decimal ? DECIMAL_REGEX : NUMBER_REGEX
      const valid = regex.test(value)
      if (valid) {
        this.localValue = value
      } else {
        target.value = this.localValue
      }
    },
    onBlur(e: InputEvent) {
      const target = e.target as HTMLInputElement
      if (this.localValue.toString() === this.val.toString()) {
        return
      }
      const value = this.decimal
        ? parseFloat(this.localValue)
        : parseInt(this.localValue, 10)
      if (Number.isNaN(value)) {
        this.localValue = this.val.toString()
        target.value = this.localValue
        return
      }
      this.setValue(value, 'input')
    },
    hasSlot,
  },
})
