import type { ClassDefinition, Status } from '../../types'

export enum Variant {
  Default = 'default',
  Transparent = 'transparent',
}

export interface CardProps {
  title: string
  variant?: Variant
  tag?: string
  closeable?: boolean
  backable?: boolean
  status?: Status
  topClass?: ClassDefinition
  innerClass?: ClassDefinition
  contentClass?: ClassDefinition
  titleTag?: string
  hideHeader?: boolean
}
