/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue, { PropType } from 'vue'

type GetOptionLabel = (item: any) => string | null | undefined

export interface Props {
  options: unknown[]
  optionValue?: string
  optionLabel?: string
  getOptionLabel?: GetOptionLabel
}

const SelectMixin = Vue.extend({
  name: 'SelectMixin',
  props: {
    options: {
      type: Array as PropType<any[]>,
      required: true,
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    getOptionLabel: {
      type: Function as PropType<GetOptionLabel>,
      default: null,
    },
  },
  computed: {
    _optionsByValue(): Map<unknown, unknown> {
      const map = new Map<unknown, unknown>()
      for (const option of this.options) {
        const value = this._getOptionValue(option)
        map.set(value, option)
      }
      return map
    },
  },
  methods: {
    _getOptionValue(option: any): any {
      if (typeof option === 'object' && option !== null) {
        return option[this.optionValue]
      }
      return option
    },
    _getOptionLabel(option: any): any {
      const { getOptionLabel } = this
      if (typeof option === 'object' && option !== null) {
        return getOptionLabel !== null
          ? getOptionLabel(option)
          : option[this.optionLabel]
      }
      return option
    },
    _getOptionByValue(value: unknown): unknown {
      const option = this._optionsByValue.get(value)
      return option
    },
  },
})

export default SelectMixin
