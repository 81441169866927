export default function indexBy<
  T extends Record<string, unknown>,
  K extends keyof T,
>(items: Iterable<T>, fieldName: K): Map<T[K], T> {
  const map = new Map<T[K], T>()
  for (const item of items) {
    map.set(item[fieldName], item)
  }
  return map
}
