
import type { VNode } from 'vue'

import { WeveFormControlLabel, WeveFormControlHint } from '@ui'

import { ThemeMixin } from '../../mixins'
import Skeleton from '../Skeleton/Skeleton.vue'

export default ThemeMixin.extend({
  name: 'PasswordStrength',
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    hint: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    message(): string {
      if (this.value === undefined) return ''
      if (this.value === 0) return 'Too Weak'
      if (this.value === 1 || this.value === 2) return 'Moderate'
      if (this.value === 3) return 'Strong'
      if (this.value === 4) return 'Very Strong'
      return ''
    },
  },
  methods: {
    active(index: number): boolean {
      if (this.value === undefined) return false
      if (this.value === 0) return index <= 0
      if (this.value === 1 || this.value === 2) return index <= 1
      if (this.value === 3) return index <= 2
      if (this.value === 4) return index <= 3
      return false
    },
  },
  render(h): VNode {
    return h(
      'div',
      {
        staticClass: 'weve-password-strength',
        class: {
          [`weve-password-strength--strength--${this.value}`]: true,
          'w-theme--light': this.light,
          'w-theme--dark': this.dark,
        },
      },
      [
        h(
          WeveFormControlLabel,
          {
            props: { tag: 'span' },
            staticClass: 'mb-2 ',
          },
          'Strength',
        ),
        h(
          'div',
          { staticClass: 'weve-password-strength__elements' },
          new Array(4).fill(0).map((_, index) =>
            this.loading
              ? h(Skeleton, {
                  props: { type: 'simple' },
                  staticClass: 'weve-password-strength__skeleton',
                })
              : h('div', {
                  staticClass: 'weve-password-strength__element',
                  class: {
                    'weve-password-strength__element--active':
                      this.active(index),
                  },
                }),
          ),
        ),
        h(
          'span',
          {
            attrs: { 'aria-live': 'polite' },
            staticClass: 'weve-password-strength__text',
          },
          this.message,
        ),
        h(
          WeveFormControlHint,
          {
            props: { tag: 'p' },
            staticClass: 'mt-1 mb-0',
          },
          this.hint,
        ),
      ],
    )
  },
})
