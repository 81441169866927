import type { StorageKey } from './Storage.types'

export default class Storage {
  set(key: StorageKey, value: string): void {
    localStorage.setItem(key, value)
  }

  get(key: StorageKey): string | undefined {
    return localStorage.getItem(key) ?? undefined
  }

  remove(key: StorageKey): void {
    localStorage.removeItem(key)
  }
}
