import type { MarkRequired } from 'ts-essentials'
import type { AxiosError, AxiosInstance } from 'axios'
import axios from 'axios'

export default abstract class BaseService {
  constructor(protected readonly client: AxiosInstance) {}

  static isServerError(
    e: unknown,
  ): e is MarkRequired<AxiosError<{ message: string }>, 'response'> {
    if (axios.isAxiosError(e)) {
      if (e.response === undefined) return false
      return true
    }
    return false
  }

  static isServerErrorWithMetadata<T>(
    e: unknown,
  ): e is MarkRequired<
    AxiosError<{ message: string; metadata: T }>,
    'response'
  > {
    if (BaseService.isServerError(e)) {
      if ((e.response.data as { metadata?: T }).metadata === undefined)
        return false
      return true
    }
    return false
  }
}
