
import type { WithRefs } from 'vue-typed-refs'
import TurndownService from 'turndown'
import type { WithProperties } from 'vue-typed-properties'

import { ThemeMixin } from '@/components/ui/mixins'

import WeveButton from '../Button/Button.vue'
import WeveIcon from '../Icon/Icon.vue'
import WeveMenu from '../Menu/Menu.vue'
import WeveMenuItem from '../Menu/components/MenuItem.vue'
import WeveFormControlLabel from '../FormControlLabel/FormControlLabel.vue'
import { removeClassAttributes } from './Clipboard.util'

const turndownService = new TurndownService()

export type Refs = {
  content: HTMLElement
  reseter: HTMLElement
}

function copyWithStyle(element: Element) {
  const selection = window.getSelection()
  if (selection === null) {
    throw new Error('`selection` is `null`')
  }
  const range = document.createRange()
  range.selectNodeContents(element)

  selection.removeAllRanges()
  selection.addRange(range)

  document.execCommand('copy')

  selection.removeAllRanges()
}

export default (
  ThemeMixin as WithRefs<
    Refs,
    WithProperties<
      {
        shadowRoot: ShadowRoot | null
      },
      typeof ThemeMixin
    >
  >
).extend({
  name: 'AppClipboard',
  components: {
    WeveButton,
    WeveIcon,
    WeveMenu,
    WeveMenuItem,
    WeveFormControlLabel,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      markdown: '',
      html: '',
    }
  },
  mounted() {
    this.shadowRoot = this.$refs.reseter.attachShadow({ mode: 'open' })
    this.update()
  },
  updated() {
    this.update()
  },
  methods: {
    update() {
      const { innerHTML } = this.$refs.content
      this.markdown = turndownService.turndown(innerHTML)
      this.html = removeClassAttributes(innerHTML)
      if (this.shadowRoot !== null) {
        this.shadowRoot.innerHTML = innerHTML
      }
    },
    copy() {
      if (this.shadowRoot !== null) {
        const { firstElementChild } = this.shadowRoot
        if (firstElementChild !== null) {
          copyWithStyle(firstElementChild)
          this.$toast('Copied')
        }
      } else {
        this.$notify(
          'Your browser does not support `shadowRoot`, please, use other options',
        )
      }
    },
  },
})
