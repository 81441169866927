
import { Portal, MountingPortal } from 'portal-vue'
// @ts-expect-error v-click-outside has no typings
import { directive as clickOutside } from 'v-click-outside'
import type { WithRefs } from 'vue-typed-refs'
import mixins from 'vue-typed-mixins'

import type { VueComponentListeners } from '../../types'
import { createPopperMixin, ThemeMixin } from '../../mixins'
import { Options } from '../../mixins/popper'
import { ExtendedEvent, SKIP_CLICK_OUTSIDE } from '../../constants'
import { generateId, hasSlot } from '../../utils'
import WeveIcon from '../Icon/Icon.vue'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'

type Refs = {
  root: HTMLElement
  activator: HTMLButtonElement
  content: HTMLElement
}

const Extendable = mixins(createPopperMixin(), ThemeMixin)

export default (Extendable as WithRefs<Refs, typeof Extendable>).extend({
  name: 'WeveDropdown',
  components: {
    Portal,
    MountingPortal,
    WeveIcon,
    ButtonIcon,
  },
  directives: {
    clickOutside,
  },
  props: {
    uid: {
      type: String,
      default() {
        return generateId()
      },
    },
    title: {
      type: String,
      default: undefined,
    },
    activatorAttrs: {
      type: Object,
      default: null,
    },
    contentClass: {
      type: [String, Object, Array],
      default: undefined,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    clickOutside: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    activatorId(): string {
      return `dropdown-activator-${this.uid}`
    },
    activatorAttrsComputed(): Record<string, string | boolean> {
      return {
        id: this.activatorId,
        'aria-haspopup': 'true',
        'aria-expanded': String(this.isOpen),
        ...this.activatorAttrs,
      }
    },
    activatorClassNames(): Array<string | Record<string, boolean> | boolean> {
      return [
        'weve-dropdown__activator',
        {
          'weve-dropdown__activator--active': this.simple && this.isOpen,
          'weve-dropdown__activator--simple': this.simple,
          'weve-dropdown__activator--disabled': this.disabled,
          'w-theme--light': this.light,
          'w-theme--dark': this.dark,
        },
        this.activatorAttrsComputed.class,
      ]
    },
    activatorOn(): VueComponentListeners {
      return {
        click: this.toggle,
      }
    },
    computedOptions(): Partial<Options> {
      if (this.simple === true) {
        return { placement: 'bottom-end' }
      } else {
        return {
          placement: 'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 12],
              },
            },
          ],
        }
      }
    },
  },
  watch: {
    isOpen(value: boolean) {
      if (value === true) {
        this.$nextTick(() => {
          this.createPopperInstance(
            this.getActivator(),
            this.$refs.content,
            this.computedOptions,
          )
          this.$refs.content.focus()
        })
      }
    },
  },
  methods: {
    getActivator() {
      return document.getElementById(this.activatorId) ?? this.$refs.activator
    },
    /** @public */
    open() {
      this.isOpen = true
    },
    /** @public */
    hide() {
      this.isOpen = false
    },
    /** @public */
    toggle() {
      this.isOpen = !this.isOpen
    },
    clickOutsideMiddleware(e: ExtendedEvent<MouseEvent>) {
      const { target } = e

      if (e[SKIP_CLICK_OUTSIDE]) return false

      if (target !== null) {
        if (this.$refs.content.contains(target as Node) === true) {
          return false
        }
      }

      return true
    },
    hasSlot,
  },
})
