import type { Ref } from 'vue'
import { shallowRef, watch } from 'vue'

export default function usePreviousNonNullish<T>(
  reference: Ref<T>,
  valid: (v: T) => boolean = (v) => v !== null && v !== undefined,
) {
  const inner = shallowRef(reference.value)
  watch(reference, (v) => {
    if (valid(v) === false) return
    inner.value = v
  })
  return inner
}
