var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BModal',_vm._g(_vm._b({ref:"modal",attrs:{"title":_vm.title,"hide-footer":"","centered":_vm.centered,"no-fade":"","scrollable":_vm.scrollable,"dialog-class":[
    {
      [`app-dialog app-dialog--size--${_vm.size}`]: _vm.size,
      'w-theme--light': _vm.light,
      'w-theme--dark': _vm.dark,
    },
    _vm.$attrs['dialog-class'],
  ],"body-class":[
    { '--no-padding': _vm.size === 'auto' || _vm.noPadding },
    _vm.$attrs['body-class'],
  ],"ignore-enforce-focus-selector":_vm.CLIPBOARD_TEXTAREA_SELECTOR},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h2',{staticClass:"app-dialog__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('ButtonIcon',{staticClass:"app-dialog__close",attrs:{"icon":"close","label":"Close"},on:{"click":function($event){_vm.model = false}}}),_vm._t("header-append")]},proxy:true}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'BModal',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }