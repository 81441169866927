
import Vue from 'vue'
import { startOfDay, endOfDay } from 'date-fns'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import { FullDateRange, VueComponentListeners } from '../../types'

export default Vue.extend({
  name: 'WeveCalendar',
  components: {
    DatePicker,
  },
  inheritAttrs: false,
  setup() {
    const attributes = [
      {
        dot: 'indigo',
        dates: new Date(),
      },
    ]
    return { attributes }
  },
  computed: {
    listeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        input: this.input,
      }
    },
  },
  methods: {
    input(value: Date | FullDateRange) {
      if (value instanceof Date) {
        this.$emit('input', startOfDay(value))
      } else {
        this.$emit('input', {
          start: startOfDay(value.start),
          end: endOfDay(value.end),
        })
      }
    },
  },
})
