import axios from 'axios'

import { Nullable, SessionId, Undefined } from '@/types'
import { isDefined } from '@/util'

import BaseService from './BaseService'
import {
  EmailTemplate,
  EmailTemplateBase,
  EmailTemplateForEdit,
  EmailTemplateForPreview,
  SendEmail,
} from './EmailService.types'
import {
  SendEmailException,
  TemplateException,
} from './EmailService.exceptions'

function processException<E extends { new (message: string): Error }>(
  e: unknown,
  ReturnError: E,
): Error {
  if (axios.isAxiosError(e)) {
    const data = e.response?.data as
      | (unknown & { message?: string[] | string })
      | undefined
    if (
      data !== undefined &&
      data !== null &&
      typeof data === 'object' &&
      'message' in data
    ) {
      if (Array.isArray(data?.message)) {
        const values = data.message as string[]
        return new ReturnError(values.reduce((acc, val) => `${acc}${val};`, ''))
      }
      return new ReturnError(String(data.message))
    }
  }
  return new ReturnError('Something went wrong')
}

export default class EmailService extends BaseService {
  async send(payload: SendEmail): Promise<void> {
    try {
      await this.client.post(`/email/send/`, payload)
    } catch (e) {
      throw processException(e, SendEmailException)
    }
  }

  async getTemplates(): Promise<EmailTemplateBase[]> {
    try {
      const { data } = await this.client.get<EmailTemplateBase[]>(
        `/email/templates`,
      )
      return data
    } catch (e) {
      throw processException(e, TemplateException)
    }
  }

  async getTemplate(
    templateId: Nullable<number>,
    sessionId?: Undefined<SessionId>,
  ): Promise<EmailTemplateForPreview> {
    try {
      const searchParams: { templateId?: string; sessionId?: string } = {}
      if (isDefined(templateId))
        searchParams['templateId'] = templateId.toString()
      if (isDefined(sessionId)) searchParams['sessionId'] = sessionId
      const params = new URLSearchParams(searchParams)
      const { data } = await this.client.get<EmailTemplateForPreview>(
        `/email/template`,
        { params },
      )
      return data
    } catch (e) {
      throw processException(e, TemplateException)
    }
  }

  async getTemplateForEdit(id: number): Promise<EmailTemplateForEdit> {
    try {
      const { data } = await this.client.get<EmailTemplateForEdit>(
        `/email/template/${id}/edit`,
      )
      return data
    } catch (e) {
      throw processException(e, TemplateException)
    }
  }

  async update(templateId: number, update: Partial<EmailTemplate>) {
    try {
      await this.client.post(`/email/template/${templateId}`, update)
    } catch (e) {
      throw processException(e, TemplateException)
    }
  }
}
