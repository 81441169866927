
import type { PropType, VNode } from 'vue'
import mixins from 'vue-typed-mixins'
import type { WithRefs } from 'vue-typed-refs'

import { IdentifiableMixin, TextInputMixin, ThemeMixin } from '../../mixins'
import WeveFormControlLabel from '../FormControlLabel/FormControlLabel.vue'
import WeveFormControlHint from '../FormControlHint/FormControlHint.vue'
import WeveStatusIndicator from '../StatusIndicator/StatusIndicator.vue'
import WeveFormControlMessage from '../FormControlMessage/FormControlMessage.vue'
import WeveInlineHelp from '../InlineHelp/InlineHelp.vue'
import type { TextareaSize, TextareaRefs } from './Textarea.types'
import { TEXTAREA_SIZES } from './Textarea.types'

const Extendable = mixins(IdentifiableMixin, TextInputMixin, ThemeMixin)

export default (Extendable as WithRefs<TextareaRefs, typeof Extendable>).extend(
  {
    name: 'WeveTextarea',
    inheritAttrs: false,
    props: {
      hideLabel: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String as PropType<TextareaSize>,
        default: undefined,
        validator: (val: TextareaSize) => TEXTAREA_SIZES.has(val),
      },
      autoheight: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      resize() {
        if (this.autoheight === false) return
        const { field } = this.$refs
        field.style.height = '0px'
        field.style.height = `${field.scrollHeight}px`
      },
    },
    mounted() {
      this.resize()
    },
    watch: {
      value() {
        this.$nextTick(() => {
          this.resize()
        })
      },
    },
    render(h): VNode {
      return h(
        'div',
        {
          class: {
            'app-textarea--disabled': this.disabled,
            'app-textarea--readonly': this.readonly,
            'app-textarea--invalid': this.invalid,
            'app-textarea--autoheight': this.autoheight,
            'app-form-control': true,
            'w-theme--light': this.light,
            'w-theme--dark': this.dark,
            [`app-textarea--size--${this.size}`]: this.size !== undefined,
          },
          staticClass: 'app-textarea',
        },
        [
          this.hideLabel
            ? undefined
            : h('div', { staticClass: 'app-textarea__top' }, [
                h(
                  WeveFormControlLabel,
                  {
                    props: { disabled: this.disabled || this.readonly },
                    attrs: { for: this.id },
                    staticClass: 'app-textarea__label',
                  },
                  this.label,
                ),
                this.help !== undefined
                  ? h(
                      WeveInlineHelp,
                      { staticClass: 'app-textarea__help' },
                      this.help,
                    )
                  : undefined,
              ]),
          h(
            'div',
            {
              staticClass: 'app-textarea__inner',
              on: {
                click: (e: MouseEvent) => {
                  if (e.target !== e.currentTarget) return
                  this.$refs.field.focus()
                },
              },
            },
            [
              h('textarea', {
                ref: 'field',
                attrs: {
                  ...this.$attrs,
                  id: this.id,
                  disabled: this.disabled,
                  readonly: this.readonly,
                  placeholder: this.placeholder,
                },
                domProps: {
                  value: this.value,
                },
                staticClass: 'app-textarea__field',
                class:
                  this.$_status !== undefined
                    ? 'app-textarea__field--indicator'
                    : '',
                on: this.listeners,
              }),
              this.$_status !== undefined
                ? h(WeveStatusIndicator, {
                    props: { status: this.$_status },
                    staticClass: 'app-textarea__status-indicator',
                  })
                : undefined,
              this.$slots['append-inner'],
            ],
          ),
          this.hint !== undefined || this.message !== undefined
            ? h('div', { staticClass: 'app-textarea__bottom' }, [
                this.hint !== undefined
                  ? h(WeveFormControlHint, undefined, [this.hint])
                  : undefined,
                this.message !== undefined &&
                this.$_message_variant !== undefined
                  ? h(
                      WeveFormControlMessage,
                      {
                        props: { variant: this.$_message_variant },
                        staticClass: 'app-textarea__message',
                      },
                      [this.message],
                    )
                  : undefined,
              ])
            : undefined,
        ],
      )
    },
  },
)
