
import Vue, { PropType } from 'vue'
import type { WithEvents } from 'vue-typed-emit'

import type { Events, Option } from './types'

import WeveCheckbox from '../Checkbox/Checkbox.vue'
import WeveFormControlLabel from '../FormControlLabel/FormControlLabel.vue'
import { generateId } from '../../utils'

export default (Vue as WithEvents<Events>).extend({
  name: 'WeveCheckboxGroup',
  components: {
    WeveCheckbox,
    WeveFormControlLabel,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Array, Object] as PropType<
        unknown[] | Record<string, boolean> | null | undefined
      >,
      default: null,
    },
    options: {
      type: Array as PropType<Array<Option>>,
      required: true,
    },
    name: {
      type: String,
      default() {
        return generateId()
      },
    },
    label: {
      type: String,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isChecked(v: unknown): boolean {
      const { value } = this
      if (Array.isArray(value)) {
        return value.includes(v)
      } else if (
        typeof value === 'object' &&
        value !== null &&
        typeof v === 'string'
      ) {
        return value[v] === true
      }
      return false
    },
    onChange(flag: boolean, v: unknown): void {
      const { value, object } = this

      if (object && typeof v === 'string') {
        const obj = value ?? null
        this.$emit('change', { ...obj, [v]: flag })
        return
      }

      const array = Array.isArray(value) ? value : []

      if (flag) {
        this.$emit('change', [...array, v])
        return
      }
      this.$emit(
        'change',
        array.filter((item) => item !== v),
      )
    },
  },
})
