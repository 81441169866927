
import Vue from 'vue'
import type { PropType } from 'vue'

import type { SkeletonType } from './Skeleton.types'
import { SKELETON_TYPES } from './Skeleton.types'

import ButtonIconSkeleton from './ButtonIconSkeleton.vue'
import SkeletonElement from './SkeletonElement.vue'
import AvatarCardSkeleton from './AvatarSkeleton.vue'
import EventCardSkeleton from './EventCardSkeleton.vue'
import TextFieldSkeleton from './TextFieldSkeleton.vue'
import TextareaSkeleton from './TextareaSkeleton.vue'
import SelectSkeleton from './SelectSkeleton.vue'
import ButtonSkeleton from './ButtonSkeleton.vue'
import ListSkeleton from './ListSkeleton.vue'
import SelectionCardSkeleton from './SelectionCardSkeleton.vue'
import DefinitionListSkeleton from './DefinitionListSkeleton.vue'
import WidgetSkeleton from './WidgetSkeleton.vue'
import SelectOptionSkeleton from './SelectOptionSkeleton.vue'
import CheckboxSkeleton from './CheckboxSkeleton.vue'
import ToggleSkeleton from './ToggleSkeleton.vue'

const MAP = {
  'button-icon': ButtonIconSkeleton,
  simple: SkeletonElement,
  avatar: AvatarCardSkeleton,
  'event-card': EventCardSkeleton,
  'text-field': TextFieldSkeleton,
  textarea: TextareaSkeleton,
  select: SelectSkeleton,
  button: ButtonSkeleton,
  list: ListSkeleton,
  'definition-list': DefinitionListSkeleton,
  widget: WidgetSkeleton,
  'select-option': SelectOptionSkeleton,
  checkbox: CheckboxSkeleton,
  toggle: ToggleSkeleton,
  'selection-card': SelectionCardSkeleton,
}

export default Vue.extend({
  name: 'WeveSkeleton',
  functional: true,
  props: {
    type: {
      type: String as PropType<SkeletonType>,
      required: true,
      validator: (val: SkeletonType) => SKELETON_TYPES.has(val),
    },
  },
  render(h, { props, data }) {
    return h(MAP[props.type], data)
  },
})
