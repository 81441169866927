import type { Ref } from 'vue'
import { computed } from 'vue'

import type { Nullable, UnwrapArray } from '@/types'
import { SessionType, IntegrationType } from '@/graphql/types'

export default function useSessionSyncIntegration<
  T extends { type: SessionType; integrations: { type: IntegrationType }[] },
>(session: Ref<T>) {
  const integration = computed(() => {
    const type =
      session.value.type === SessionType.IN_PERSON
        ? IntegrationType.BREADCRUMB
        : IntegrationType.WEVE
    return (session.value.integrations.find(
      (integration) => integration.type === type,
    ) ?? null) as Nullable<UnwrapArray<T['integrations']>>
  })
  return {
    integration,
  }
}
