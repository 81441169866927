
import type { VNode } from 'vue'

import type { VueComponentListeners } from '../../types'
import { ThemeMixin } from '../../mixins'
import { generateId, hasSlot, normalizeSlot } from '../../utils'
import WeveIcon from '../Icon/Icon.vue'
import WeveInlineHelp from '../InlineHelp/InlineHelp.vue'

export default ThemeMixin.extend({
  name: 'WeveCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
    },
    id: {
      type: String,
      default() {
        return generateId()
      },
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    inputListeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        change: (event: InputEvent) => {
          event.preventDefault()
          const target = event.target as HTMLInputElement
          if (this.indeterminate) {
            this.$emit('change', false)
          } else {
            this.$emit('change', target.checked)
          }
          // see https://github.com/vuejs/vue/issues/9535
          target.checked = !target.checked
        },
      }
    },
  },
  methods: {
    hasSlot,
    normalizeSlot,
  },
  render(h): VNode {
    return h(
      'div',
      {
        class: {
          'app-checkbox--checked': this.checked,
          'app-checkbox--indeterminate': this.indeterminate,
          'app-checkbox--disabled': this.disabled,
          'app-checkbox--readonly': this.readonly,
          'app-checkbox--block': this.block,
          'w-theme--dark': this.dark,
          'w-theme--light': this.light,
        },
        staticClass: 'app-checkbox app-form-control',
      },
      [
        h('input', {
          attrs: {
            ...this.$attrs,
            id: this.id,
            type: 'checkbox',
            disabled: this.disabled,
            readonly: this.readonly,
          },
          domProps: {
            checked: this.checked,
            indeterminate: this.indeterminate,
          },
          staticClass: 'app-checkbox__input',
          on: this.inputListeners,
        }),
        h(
          'label',
          { attrs: { for: this.id }, staticClass: 'app-checkbox__label' },
          [
            h('span', { staticClass: 'app-checkbox__imitator' }, [
              h(WeveIcon, {
                props: {
                  name: this.indeterminate ? 'minus' : 'check',
                },
              }),
            ]),
            h(
              'span',
              {
                class: { 'sr-only': this.noLabel },
                staticClass: 'app-checkbox__label-text',
              },
              this.hasSlot('label') ? this.normalizeSlot('label') : this.label,
            ),
            this.help !== undefined
              ? h(
                  WeveInlineHelp,
                  { staticClass: 'app-text-input__help' },
                  this.help,
                )
              : undefined,
          ],
        ),
      ],
    )
  },
})
