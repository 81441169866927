import type { PropType } from 'vue'
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import type { PillGroupSize } from './PillGroup.types'
import { PILL_GROUPS_SIZES } from './PillGroup.types'

import './PillGroup.scss'

export default Vue.extend({
  name: 'PillGroup',
  functional: true,
  props: {
    gap: {
      type: String as PropType<PillGroupSize>,
      default: 'default' as const,
      validator: (val: PillGroupSize) => PILL_GROUPS_SIZES.has(val),
    },
  },
  render(h, { props, data, children }) {
    return h('div', mergeData(data, { staticClass: 'weve-pill-group' }), [
      h(
        'ul',
        {
          staticClass: 'weve-pill-group__list',
          class: `weve-pill-group__list--gap--${props.gap}`,
        },
        children.map((child) =>
          h('li', { staticClass: 'weve-pill-group__item' }, [child]),
        ),
      ),
    ])
  },
})
