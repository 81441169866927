import type { Ref } from 'vue'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'

import type { Nullable, Coordinates, Point, PlaceId } from '@/types'
import { useGeocoder, usePlacesAutocomplete } from '@/modules/location'

import type { LocationSearchOption } from './useGeoLocationSearch.types'

export default function useGeoLocationSearch(
  query: Ref<string>,
  enabled: Ref<boolean>,
  context: Nullable<Ref<Nullable<Coordinates>>> = null,
) {
  const geocoder = useGeocoder()
  const autocomplete = usePlacesAutocomplete()
  const loaded = computed(
    () =>
      geocoder.geocoder.value !== null &&
      autocomplete.autocomplete.value !== null,
  )
  const isQueryEnabled = computed(
    () => enabled.value && geocoder.geocoder.value !== null,
  )

  const {
    data,
    isFetching: loading,
    refetch,
  } = useQuery(
    ['places', query],
    () => {
      if (autocomplete.autocomplete.value === null) return Promise.resolve(null)
      const request: google.maps.places.AutocompletionRequest = {
        input: query.value,
        language: 'en',
      }
      if (context !== null && context.value !== null) {
        const location = new google.maps.LatLng(
          context.value[1],
          context.value[0],
        )
        request.location = location
        request.radius = 10000
      }
      return autocomplete.autocomplete.value.getPlacePredictions(request)
    },
    { enabled: isQueryEnabled },
  )

  const options = computed<LocationSearchOption[]>(() => {
    if (data.value === null || data.value === undefined) return []
    return data.value.predictions.map((prediction) => ({
      value: prediction.place_id,
      name: prediction.structured_formatting.main_text,
      label: prediction.description,
      address: prediction.description,
    }))
  })

  async function geocode(
    placeId: PlaceId,
  ): Promise<Nullable<{ placeId: PlaceId; point: Point; address: string }>> {
    const { results } = await (await geocoder.get()).geocode({ placeId })
    const [result] = results
    if (result === undefined) return null
    return {
      placeId,
      address: result.formatted_address,
      point: {
        type: 'Point',
        coordinates: [
          result.geometry.location.lng(),
          result.geometry.location.lat(),
        ],
      },
    }
  }

  return {
    loaded,
    loading,
    options,
    refetch,
    geocode,
  }
}
