
import type { VNode } from 'vue'

import { ThemeMixin } from '../../mixins'
import { hasSlot, normalizeSlot } from '../../utils'
import Container from '../Container/Container.vue'

export default ThemeMixin.extend({
  name: 'WeveSubNavigation',
  methods: {
    hasSlot,
    normalizeSlot,
  },
  render(h): VNode {
    return h(
      Container,
      {
        staticClass: 'app-sub-navigation',
        class: {
          'w-theme--light': this.light,
          'w-theme--dark': this.dark,
        },
      },
      [
        h('nav', { staticClass: 'app-sub-navigation__nav' }, [
          this.hasSlot('prepend')
            ? h(
                'div',
                { staticClass: 'app-sub-navigation__prepend' },
                this.normalizeSlot('prepend'),
              )
            : null,
          this.$slots.default,
        ]),
      ],
    )
  },
})
