import { isEqual, omitBy } from 'lodash-es'

import isFalsy from './isFalsy'

export default function isEqualWithoutFalsy(
  a: Record<string, unknown>,
  b: Record<string, unknown>,
): boolean {
  const left = omitBy(a, isFalsy)
  const right = omitBy(b, isFalsy)
  return isEqual(left, right)
}
