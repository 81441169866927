
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveTableTd',
  functional: true,
  props: {
    text: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, children, data }) {
    return h(
      'td',
      mergeData(data, {
        staticClass: 'weve-table__td',
      }),
      [
        props.text
          ? h(
              'span',
              { staticClass: 'weve-table__cell-text block truncate' },
              children,
            )
          : children,
      ],
    )
  },
})
