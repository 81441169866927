export default class Request<T> {
  constructor(
    public readonly promise: Promise<T>,
    private readonly ac: AbortController,
  ) {}

  cancel(): void {
    this.ac.abort()
  }

  attach(signal: AbortSignal): void {
    signal.addEventListener('abort', () => {
      this.cancel()
    })
  }
}
