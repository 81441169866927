import type { Ref } from 'vue'
import { ref, watch } from 'vue'

export default function useSaved(dirty: Ref<boolean>) {
  const saved = ref(false)
  watch(dirty, () => {
    saved.value = false
  })
  return { saved }
}
