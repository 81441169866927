
import type { PropType } from 'vue'
import type { RawLocation } from 'vue-router'
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import WeveSpinner from '../Spinner/Spinner.vue'
import type { ButtonVariant, ButtonSize } from './Button.types'
import {
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  BUTTON_FILLED_VARIANTS,
  BUTTON_OUTLINED_VARIANTS,
} from './Button.types'

export default ThemeMixin.extend({
  name: 'WeveButton',
  functional: true,
  props: {
    variant: {
      type: String as PropType<ButtonVariant>,
      default: 'primary' as const,
      validator: (val: ButtonVariant) => BUTTON_VARIANTS.includes(val),
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: 'default' as const,
      validator: (val: ButtonSize) => BUTTON_SIZES.includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: undefined,
    },
    href: {
      type: String,
      default: undefined,
    },
  },
  render(h, { props, children, slots, scopedSlots, data, injections }) {
    let { prepend, append } = slots()

    if (prepend === undefined && scopedSlots.prepend !== undefined) {
      prepend = scopedSlots.prepend({})
    }

    if (append === undefined && scopedSlots.append !== undefined) {
      append = scopedSlots.append({})
    }

    const hasPrependSlot = prepend !== undefined
    const hasAppendSlot = append !== undefined

    const tag =
      props.to !== undefined
        ? 'router-link'
        : props.href !== undefined
        ? 'a'
        : 'button'

    return h(
      tag,
      mergeData(data, {
        props:
          tag === 'router-link'
            ? {
                to: props.to,
              }
            : undefined,
        attrs: {
          type: tag === 'button' ? props.type : undefined,
          href: tag === 'a' ? props.href : undefined,
          disabled: props.disabled || props.loading,
        },
        class: {
          [`weve-button--variant--${props.variant}`]: true,
          'weve-button--filled': BUTTON_FILLED_VARIANTS.has(props.variant),
          'weve-button--outlined': BUTTON_OUTLINED_VARIANTS.has(props.variant),
          [`weve-button--size--${props.size}`]: true,
          'weve-button--disabled': props.disabled || props.loading,
          'weve-button--block': props.block,
          'weve-button--with-prepend': hasPrependSlot,
          'weve-button--with-append': hasAppendSlot,
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
        staticClass: 'weve-button',
      }),
      [
        h('span', { staticClass: 'weve-button__inner' }, [
          [
            hasPrependSlot
              ? h('span', { staticClass: 'weve-button__prepend' }, prepend)
              : undefined,
            props.loading === false ? children : h(WeveSpinner),
            hasAppendSlot
              ? h('span', { staticClass: 'weve-button__append' }, append)
              : undefined,
          ],
        ]),
      ],
    )
  },
})
