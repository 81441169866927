import type { AxiosInstance, AxiosError } from 'axios'
import axios from 'axios'
import type { TokensManager } from '@/core/auth/interfaces/TokensManager'

declare module 'axios' {
  interface AxiosRequestConfig {
    retry?: true
  }
}

export default class Client {
  public readonly client: AxiosInstance

  constructor(
    url: string,
    private readonly tokens: TokensManager,
  ) {
    const instance = axios.create({ baseURL: url })
    this.client = instance

    this.client.interceptors.request.use((config) => {
      if (this.tokens.token === undefined) {
        return config
      }
      const updated = {
        ...config,
      }
      updated.headers.Authorization = `Bearer ${this.tokens.token}`
      return updated
    })

    this.client.interceptors.response.use(
      (r) => r,
      async (error: AxiosError) => {
        if (
          this.tokens.refreshToken === undefined ||
          error.response === undefined ||
          error.response.status !== 401 ||
          error.config === undefined ||
          error.config.retry ||
          error.config.url === '/auth/login'
        ) {
          throw error
        }
        const token = await this.tokens.refresh()
        if (token === undefined) return
        const config = {
          ...error.config,
          retry: true,
        } as const
        return this.client(config)
      },
    )
  }
}
