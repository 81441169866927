
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'

export default ThemeMixin.extend({
  name: 'WeveDefinitionList',
  functional: true,
  props: {
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, data, children, injections }) {
    return h(
      'dl',
      mergeData(data, {
        staticClass: 'app-definition-list',
        class: {
          'app-definition-list--bordered': props.bordered,
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
      }),
      children,
    )
  },
})
