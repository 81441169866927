import type { Undefined, Nullable } from '@/types'

export default class StringMap<K extends { toString(): string }, V> {
  private readonly map: Map<string, V>
  constructor(entries?: Nullable<[K, V][]>) {
    this.map = new Map(entries?.map(([k, v]) => [k.toString(), v]))
  }

  get(key: K): Undefined<V> {
    return this.map.get(key.toString())
  }
}
