
import type { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import { executeListener } from '../../utils'
import WeveIcon from '../Icon/Icon.vue'
import type { PillVariant } from './Pill.types'
import { PILL_VARIANTS } from './Pill.types'

export default ThemeMixin.extend({
  name: 'WevePill',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    variant: {
      type: String as PropType<PillVariant>,
      default: 'primary' as const,
      validator: (val: PillVariant) => PILL_VARIANTS.has(val),
    },
    removable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, children, data, listeners, injections }) {
    return h(
      props.tag,
      mergeData(data, {
        class: {
          [`app-pill--variant--${props.variant}`]: true,
          'app-pill--removable': props.removable,
          'app-pill--wrap': props.wrap,
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme === 'dark',
        },
        staticClass: 'app-pill',
      }),
      [
        h('span', { staticClass: 'app-pill__inner' }, [
          h('span', { staticClass: 'app-pill__content' }, children),
          props.removable
            ? h(
                'button',
                {
                  class: {
                    'app-pill__button--disabled': props.disabled,
                  },
                  attrs: {
                    type: 'button',
                    disabled: props.disabled,
                    'aria-label': 'Delete',
                  },
                  staticClass: 'app-pill__button',
                  on: {
                    click() {
                      executeListener(listeners.remove)
                    },
                  },
                },
                [
                  h(WeveIcon, {
                    props: { name: 'close' },
                  }),
                ],
              )
            : undefined,
        ]),
      ],
    )
  },
})
