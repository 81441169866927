import type { SessionGroupId } from '@/types'

import BaseService from '../BaseService'
import type { CreateSessionGroupDto } from './SessionGroupService.types'

class SessionGroupService extends BaseService {
  async create(dto: CreateSessionGroupDto): Promise<{ id: SessionGroupId }> {
    const { data } = await this.client.post<{ id: SessionGroupId }>(
      '/session-group',
      dto,
    )
    return data
  }
}

export default SessionGroupService
