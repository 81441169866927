import Vue from 'vue'

export interface StateMixinProps {
  invalid?: boolean
  valid?: boolean
  required?: boolean
}

export default Vue.extend({
  name: 'StateMixin',
  props: {
    invalid: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
})
