
import { ThemeMixin } from '../../mixins'

export default ThemeMixin.extend({
  name: 'SelectOptionSkeleton',
  functional: true,
  render(h, { injections }) {
    return h(
      'div',
      {
        staticClass: 'app-select-option-skeleton',
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme === 'dark',
        },
      },
      [
        h('div', {
          staticClass: 'skeleton-element app-select-option-skeleton__element',
        }),
      ],
    )
  },
})
