import { PermissionRole } from '@/graphql/types'
import GetterTypes from '@/store/getter-types'

import WorkspaceMixin from './workspace.mixin'

export default WorkspaceMixin.extend({
  name: 'PermissionsMixin',
  computed: {
    isStaff(): boolean {
      return this.$store.getters[GetterTypes.IS_STAFF]
    },
    isAdmin(): boolean {
      return this.$store.getters[GetterTypes.IS_ADMIN]
    },
    isHost(): boolean {
      return this.$store.getters[GetterTypes.IS_HOST]
    },
    isBookingUser(): boolean {
      return this.$store.getters[GetterTypes.IS_BOOKING_USER]
    },
    hasCurrentWorkspaceAdminPermissions(): boolean {
      return this.$permissions.hasAccess(this.workspaceId, PermissionRole.ADMIN)
    },
  },
})
