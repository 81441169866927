import { computed } from 'vue'

import type { Undefined, SessionId } from '@/types'
import GetterTypes from '@/store/getter-types'
import MutationTypes from '@/store/mutation-types'

import useStore from './useStore'

export default function useLastSessionId() {
  const { store } = useStore()
  const lastSessionId = computed<Undefined<SessionId>>({
    get: () => store.getters[GetterTypes.LAST_SESSION_ID],
    set: (value) => store.commit(MutationTypes.SET_LAST_SESSION_ID, value),
  })
  return { lastSessionId }
}
