type KeyOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: unknown
}

export default function groupBy<
  T extends Record<string, unknown>,
  K extends KeyOfType<T, PropertyKey>,
>(items: Iterable<T>, field: K): Map<T[K], T[]> {
  const map = new Map<T[K], T[]>()
  for (const item of items) {
    const array = map.get(item[field]) ?? []
    array.push(item)
    map.set(item[field], array)
  }
  return map
}
