import type { WorkspaceId } from '@/types'
import type { PermissionRole } from '@/graphql/types'

import BaseService from './BaseService'
import type { InviteResponse } from './WorkspaceService.types'

export default class WorkspaceService extends BaseService {
  async invite(
    workspaceId: WorkspaceId,
    email: string,
    role: PermissionRole,
  ): Promise<InviteResponse> {
    const { data } = await this.client.post<InviteResponse>('/auth/invite', {
      workspaceId,
      email,
      role,
    })
    return data
  }

  async report(id: WorkspaceId): Promise<Blob> {
    const { data } = await this.client.get(
      `/workspace/${id}/report/activities`,
      {
        responseType: 'blob',
      },
    )
    return data
  }
}
