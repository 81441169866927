import type { MarkRequired } from 'ts-essentials'

import type { MarkNonNullable } from '@/types'

export default function nonNillable<
  T extends Record<string, unknown>,
  K extends keyof T = keyof T,
>(key: K) {
  return (item: T): item is T & MarkNonNullable<MarkRequired<T, K>, K> => {
    return item[key] !== undefined && item[key] !== null
  }
}
