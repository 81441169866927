
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveDivider',
  functional: true,
  render(h, { data }) {
    return h(
      'hr',
      mergeData(data, {
        attrs: { role: 'separator', 'aria-orientation': 'horizontal' },
        staticClass: 'app-divider',
      }),
    )
  },
})
