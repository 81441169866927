import { ApolloError } from '@apollo/client/core'
import { UseMutationReturn } from '@vue/apollo-composable/dist/useMutation'
import useNotifyNoCredits from '@/use/useNotifyNoCredits'

export default function useOnErrorNoCredits(
  onError: UseMutationReturn<unknown, unknown>['onError'],
) {
  const { notify } = useNotifyNoCredits()
  onError((apolloError: ApolloError) => {
    const [error] = apolloError.graphQLErrors
    if (
      error.extensions.code === 'NOT_ENOUGH_CREDITS' &&
      error.extensions.missing !== undefined
    ) {
      notify(error.extensions.missing)
    }
  })
}
