import { Options } from '../../mixins/popper'

export interface Props {
  title?: string
  options?: Options
  activatorAttrs?: Record<string, unknown>
  contentAttrs?: Record<string, unknown>
  clickOutside?: boolean
}

export const COLORS = ['default', 'danger'] as const

export type Color = (typeof COLORS)[number]
