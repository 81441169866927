import type { VNode } from 'vue'
import Vue from 'vue'

export default Vue.extend({
  provide: {
    theme: 'light',
  },
  render(h): VNode {
    const content = this.$slots.default
    if (content === undefined) return h()
    return content[0]
  },
})
