
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'SubNavigationList',
  functional: true,
  render(h, { children, data }) {
    return h(
      'ul',
      mergeData(data, { staticClass: 'app-subnav-list' }),
      children
        .filter((child) => child.tag !== undefined)
        .map((child) => {
          return h(
            'li',
            { key: child.key, staticClass: 'app-subnav-list__item' },
            [child],
          )
        }),
    )
  },
})
