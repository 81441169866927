
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'

export default ThemeMixin.extend({
  name: 'DefinitionListSkeleton',
  functional: true,
  props: {
    items: {
      type: Number,
      default: 4,
    },
  },
  render(h, { props, data, injections }) {
    const children = []

    for (let i = 0; i < props.items; i++) {
      children.push(
        h(
          'li',
          {
            key: i,
            staticClass: 'app-definition-list-skeleton__item',
            class: {
              'w-theme--light': injections.theme === 'light',
              'w-theme--dark': injections.theme === 'dark',
            },
          },
          [
            h('div', {
              staticClass:
                'skeleton-element app-definition-list-skeleton__element app-definition-list-skeleton__element--left',
            }),
            h('div', {
              staticClass:
                'skeleton-element app-definition-list-skeleton__element app-definition-list-skeleton__element--right',
            }),
          ],
        ),
      )
    }

    return h(
      'ul',
      mergeData(data, {
        staticClass: 'app-definition-list-skeleton',
      }),
      children,
    )
  },
})
