export const TIMEZONE_OPTIONS = [
  { group: 'US/Canada', identifier: 'America/Los_Angeles' },
  { group: 'US/Canada', identifier: 'America/Denver' },
  { group: 'US/Canada', identifier: 'America/Chicago' },
  { group: 'US/Canada', identifier: 'America/New_York' },
  { group: 'US/Canada', identifier: 'America/Whitehorse' },
  { group: 'US/Canada', identifier: 'America/Anchorage' },
  { group: 'US/Canada', identifier: 'America/Phoenix' },
  { group: 'US/Canada', identifier: 'America/St_Johns' },
  { group: 'US/Canada', identifier: 'Pacific/Honolulu' },
  { group: 'America', identifier: 'America/Adak' },
  { group: 'America', identifier: 'America/Argentina/Buenos_Aires' },
  { group: 'America', identifier: 'America/Asuncion' },
  { group: 'America', identifier: 'America/Bogota' },
  { group: 'America', identifier: 'America/Campo_Grande' },
  { group: 'America', identifier: 'America/Caracas' },
  { group: 'America', identifier: 'America/Godthab' },
  { group: 'America', identifier: 'America/Goose_Bay' },
  { group: 'America', identifier: 'America/Guatemala' },
  { group: 'America', identifier: 'America/Havana' },
  { group: 'America', identifier: 'America/Mazatlan' },
  { group: 'America', identifier: 'America/Mexico_City' },
  { group: 'America', identifier: 'America/Montevideo' },
  { group: 'America', identifier: 'America/Miquelon' },
  { group: 'America', identifier: 'America/Noronha' },
  { group: 'America', identifier: 'America/Santiago' },
  { group: 'America', identifier: 'America/Santa_Isabel' },
  { group: 'America', identifier: 'America/Santo_Domingo' },
  { group: 'America', identifier: 'America/Sao_Paulo' },
  { group: 'Africa', identifier: 'Africa/Cairo' },
  { group: 'Africa', identifier: 'Africa/Johannesburg' },
  { group: 'Africa', identifier: 'Africa/Lagos' },
  { group: 'Africa', identifier: 'Africa/Windhoek' },
  { group: 'Asia', identifier: 'Asia/Amman' },
  { group: 'Asia', identifier: 'Asia/Baghdad' },
  { group: 'Asia', identifier: 'Asia/Baku' },
  { group: 'Asia', identifier: 'Asia/Beirut' },
  { group: 'Asia', identifier: 'Asia/Damascus' },
  { group: 'Asia', identifier: 'Asia/Dhaka' },
  { group: 'Asia', identifier: 'Asia/Dubai' },
  { group: 'Asia', identifier: 'Asia/Gaza' },
  { group: 'Asia', identifier: 'Asia/Irkutsk' },
  { group: 'Asia', identifier: 'Asia/Jakarta' },
  { group: 'Asia', identifier: 'Asia/Jerusalem' },
  { group: 'Asia', identifier: 'Asia/Kabul' },
  { group: 'Asia', identifier: 'Asia/Kamchatka' },
  { group: 'Asia', identifier: 'Asia/Karachi' },
  { group: 'Asia', identifier: 'Asia/Kathmandu' },
  { group: 'Asia', identifier: 'Asia/Kolkata' },
  { group: 'Asia', identifier: 'Asia/Krasnoyarsk' },
  { group: 'Asia', identifier: 'Asia/Omsk' },
  { group: 'Asia', identifier: 'Asia/Rangoon' },
  { group: 'Asia', identifier: 'Asia/Shanghai' },
  { group: 'Asia', identifier: 'Asia/Tehran' },
  { group: 'Asia', identifier: 'Asia/Tokyo' },
  { group: 'Asia', identifier: 'Asia/Vladivostok' },
  { group: 'Asia', identifier: 'Asia/Yakutsk' },
  { group: 'Asia', identifier: 'Asia/Yekaterinburg' },
  { group: 'Asia', identifier: 'Asia/Yerevan' },
  { group: 'Atlantic', identifier: 'Atlantic/Azores' },
  { group: 'Atlantic', identifier: 'Atlantic/Cape_Verde' },
  { group: 'Australia', identifier: 'Australia/Adelaide' },
  { group: 'Australia', identifier: 'Australia/Brisbane' },
  { group: 'Australia', identifier: 'Australia/Darwin' },
  { group: 'Australia', identifier: 'Australia/Eucla' },
  { group: 'Australia', identifier: 'Australia/Lord_Howe' },
  { group: 'Australia', identifier: 'Australia/Perth' },
  { group: 'Australia', identifier: 'Australia/Sydney' },
  { group: 'UTC', identifier: 'UTC' },
  { group: 'Europe', identifier: 'Europe/Berlin' },
  { group: 'Europe', identifier: 'Europe/Helsinki' },
  { group: 'Europe', identifier: 'Europe/London' },
  { group: 'Europe', identifier: 'Europe/Minsk' },
  { group: 'Europe', identifier: 'Europe/Moscow' },
  { group: 'Pacific', identifier: 'Pacific/Apia' },
  { group: 'Pacific', identifier: 'Pacific/Auckland' },
  { group: 'Pacific', identifier: 'Pacific/Chatham' },
  { group: 'Pacific', identifier: 'Pacific/Easter' },
  { group: 'Pacific', identifier: 'Pacific/Fiji' },
  { group: 'Pacific', identifier: 'Pacific/Gambier' },
  { group: 'Pacific', identifier: 'Pacific/Kiritimati' },
  { group: 'Pacific', identifier: 'Pacific/Majuro' },
  { group: 'Pacific', identifier: 'Pacific/Marquesas' },
  { group: 'Pacific', identifier: 'Pacific/Norfolk' },
  { group: 'Pacific', identifier: 'Pacific/Noumea' },
  { group: 'Pacific', identifier: 'Pacific/Pago_Pago' },
  { group: 'Pacific', identifier: 'Pacific/Pitcairn' },
  { group: 'Pacific', identifier: 'Pacific/Tarawa' },
  { group: 'Pacific', identifier: 'Pacific/Tongatapu' },
]

export const TIMEZONE_TO_DISPLAY_NAME: Record<string, string> = {
  'America/Whitehorse': 'Yukon Time',
  'America/Los_Angeles': 'Pacific Time - US & Canada',
  'America/Denver': 'Mountain Time - US & Canada',
  'America/Chicago': 'Central Time - US & Canada',
  'America/New_York': 'Eastern Time - US & Canada',
  'America/Anchorage': 'Alaska Time',
  'America/Phoenix': 'Arizona Time',
  'America/St_Johns': 'Newfoundland Time',
  'Pacific/Honolulu': 'Hawaii Time',
  'America/Adak': 'America/Adak',
  'America/Argentina/Buenos_Aires': 'Buenos Aires Time',
  'America/Asuncion': 'Asuncion Time',
  'America/Bogota': 'Bogota, Jamaica, Lima Time',
  'America/Campo_Grande': 'America/Campo Grande',
  'America/Caracas': 'Caracas Time',
  'America/Godthab': 'America/Godthab',
  'America/Goose_Bay': 'Atlantic Time',
  'America/Guatemala': 'Saskatchewan, Guatemala, Costa Rica Time',
  'America/Havana': 'America/Havana',
  'America/Mazatlan': 'America/Mazatlan',
  'America/Mexico_City': 'Mexico City Time',
  'America/Montevideo': 'Montevideo Time',
  'America/Miquelon': 'America/Miquelon',
  'America/Noronha': 'America/Noronha',
  'America/Santiago': 'Santiago Time',
  'America/Santa_Isabel': 'America/Santa Isabel',
  'America/Santo_Domingo': 'Atlantic Standard Time',
  'America/Sao_Paulo': 'Brasilia Time',
  'Africa/Casablanca': 'Morocco Time',
  'Africa/Cairo': 'Africa/Cairo',
  'Africa/Johannesburg': 'Central Africa Time',
  'Africa/Lagos': 'West Africa Time',
  'Africa/Windhoek': 'Africa/Windhoek',
  'Asia/Amman': 'Jordan Time',
  'Asia/Baghdad': 'Baghdad, East Africa Time',
  'Asia/Baku': 'Asia/Baku',
  'Asia/Beirut': 'Lebanon Time',
  'Asia/Damascus': 'Syria Time',
  'Asia/Dhaka': 'Asia/Dhaka',
  'Asia/Dubai': 'Dubai Time',
  'Asia/Gaza': 'Asia/Gaza',
  'Asia/Irkutsk': 'Asia/Irkutsk',
  'Asia/Jakarta': 'Indochina Time',
  'Asia/Jerusalem': 'Israel Time',
  'Asia/Kabul': 'Kabul Time',
  'Asia/Kamchatka': 'Pacific/Majuro',
  'Asia/Karachi': 'Pakistan, Maldives Time',
  'Asia/Kathmandu': 'Kathmandu Time',
  'Asia/Kolkata': 'India, Sri Lanka Time',
  'Asia/Krasnoyarsk': 'Krasnoyarsk Time',
  'Asia/Omsk': 'Asia/Omsk',
  'Asia/Rangoon': 'Asia/Rangoon',
  'Asia/Shanghai': 'China, Singapore, Perth',
  'Asia/Tehran': 'Tehran Time',
  'Asia/Tokyo': 'Japan, Korea Time',
  'Asia/Vladivostok': 'Asia/Vladivostok',
  'Asia/Yakutsk': 'Asia/Yakutsk',
  'Asia/Yekaterinburg': 'Yekaterinburg Time',
  'Asia/Yerevan': 'Asia/Yerevan',
  'Atlantic/Azores': 'Azores Time',
  'Atlantic/Cape_Verde': 'Cape Verde Time',
  'Australia/Adelaide': 'Adelaide Time',
  'Australia/Brisbane': 'Brisbane Time',
  'Australia/Darwin': 'Australia/Darwin',
  'Australia/Eucla': 'Australia/Eucla',
  'Australia/Lord_Howe': 'Australia/Lord Howe',
  'Australia/Perth': 'Australia/Perth',
  'Australia/Sydney': 'Sydney, Melbourne Time',
  UTC: 'UTC Time',
  'Europe/Berlin': 'Central European Time',
  'Europe/Helsinki': 'Eastern European Time',
  'Europe/London': 'UK, Ireland, Lisbon Time',
  'Europe/Minsk': 'Minsk Time',
  'Europe/Moscow': 'Moscow Time',
  'Europe/Chisinau': 'Moldova Time',
  'Pacific/Apia': 'Pacific/Apia',
  'Pacific/Auckland': 'Auckland Time',
  'Pacific/Chatham': 'Pacific/Chatham',
  'Pacific/Easter': 'Pacific/Easter',
  'Pacific/Fiji': 'Pacific/Fiji',
  'Pacific/Gambier': 'Pacific/Gambier',
  'Pacific/Kiritimati': 'Pacific/Kiritimati',
  'Pacific/Majuro': 'Pacific/Majuro',
  'Pacific/Marquesas': 'Pacific/Marquesas',
  'Pacific/Norfolk': 'Pacific/Norfolk',
  'Pacific/Noumea': 'Pacific/Noumea',
  'Pacific/Pago_Pago': 'Pacific/Pago Pago',
  'Pacific/Pitcairn': 'Pacific/Pitcairn',
  'Pacific/Tarawa': 'Pacific/Tarawa',
  'Pacific/Tongatapu': 'Pacific/Tongatapu',
  'Africa/Nairobi': 'East Africa Time',
  'America/Argentina/San_Luis': 'Western Argentina Time',
  'America/Cayenne': 'French Guiana Time',
  'America/Eirunepe': 'Acre Time',
  'America/Guayaquil': 'Ecuador Time',
  'America/Guyana': 'Guyana Time',
  'America/La_Paz': 'Bolivia Time',
  'America/Lima': 'Peru Time',
  'America/Paramaribo': 'Suriname Time',
  'America/Scoresbysund': 'East Greenland Time',
  'Antarctica/Davis': 'Davis Time',
  'Antarctica/DumontDUrville': 'Dumont-d\u2019Urville Time',
  'Antarctica/Macquarie': 'Macquarie Island Time',
  'Antarctica/Mawson': 'Mawson Time',
  'Antarctica/Rothera': 'Rothera Time',
  'Antarctica/Syowa': 'Syowa Time',
  'Antarctica/Vostok': 'Vostok Time',
  'Asia/Almaty': 'East Kazakhstan Time',
  'Asia/Anadyr': 'Anadyr Time',
  'Asia/Aqtau': 'West Kazakhstan Time',
  'Asia/Ashgabat': 'Turkmenistan Time',
  'Asia/Bangkok': 'Indochina Time',
  'Asia/Bishkek': 'Kyrgyzstan Time',
  'Asia/Brunei': 'Brunei Darussalam Time',
  'Asia/Calcutta': 'India Standard Time',
  'Asia/Choibalsan': 'Choibalsan Time',
  'Asia/Dili': 'East Timor Time',
  'Asia/Dushanbe': 'Tajikistan Time',
  'Asia/Hong_Kong': 'Hong Kong Time',
  'Asia/Hovd': 'Hovd Time',
  'Asia/Jayapura': 'Eastern Indonesia Time',
  'Asia/Katmandu': 'Nepal Time',
  'Asia/Kuala_Lumpur': 'Malaysia Time',
  'Asia/Magadan': 'Magadan Time',
  'Asia/Makassar': 'Central Indonesia Time',
  'Asia/Manila': 'Philippine Time',
  'Asia/Novosibirsk': 'Novosibirsk Time',
  'Asia/Sakhalin': 'Sakhalin Time',
  'Asia/Singapore': 'Singapore Standard Time',
  'Asia/Taipei': 'Taipei Time',
  'Asia/Tashkent': 'Uzbekistan Time',
  'Asia/Tbilisi': 'Georgia Time',
  'Asia/Thimphu': 'Bhutan Time',
  'Asia/Ulaanbaatar': 'Ulaanbaatar Time',
  'Atlantic/South_Georgia': 'South Georgia Time',
  'Atlantic/Stanley': 'Falkland Islands Time',
  'Europe/Samara': 'Samara Time',
  'Indian/Chagos': 'Indian Ocean Time',
  'Indian/Christmas': 'Christmas Island Time',
  'Indian/Cocos': 'Cocos Islands Time',
  'Indian/Kerguelen': 'French Southern & Antarctic Time',
  'Indian/Mahe': 'Seychelles Time',
  'Indian/Maldives': 'Maldives Time',
  'Indian/Mauritius': 'Mauritius Time',
  'Indian/Reunion': 'R\xe9union Time',
  'Pacific/Bougainville': 'Papua New Guinea Time',
  'Pacific/Efate': 'Vanuatu Time',
  'Pacific/Enderbury': 'Phoenix Islands Time',
  'Pacific/Fakaofo': 'Tokelau Time',
  'Pacific/Funafuti': 'Tuvalu Time',
  'Pacific/Galapagos': 'Galapagos Time',
  'Pacific/Guadalcanal': 'Solomon Islands Time',
  'Pacific/Guam': 'Chamorro Standard Time',
  'Pacific/Kosrae': 'Kosrae Time',
  'Pacific/Nauru': 'Nauru Time',
  'Pacific/Niue': 'Niue Time',
  'Pacific/Palau': 'Palau Time',
  'Pacific/Ponape': 'Ponape Time',
  'Pacific/Rarotonga': 'Cook Islands Time',
  'Pacific/Tahiti': 'Tahiti Time',
  'Pacific/Truk': 'Chuuk Time',
  'Pacific/Wake': 'Wake Island Time',
  'Pacific/Wallis': 'Wallis & Futuna Time',
}

export const TIMEZONE_MAPPING: Record<string, string> = {
  'Europe/Dublin': 'Europe/London',
  'Europe/Guernsey': 'Europe/London',
  'Europe/Isle_of_Man': 'Europe/London',
  'Europe/Jersey': 'Europe/London',
  'America/Punta_Arenas': 'America/Argentina/Buenos_Aires',
  'Antarctica/Palmer': 'America/Argentina/Buenos_Aires',
  'Asia/Urumqi': 'Asia/Shanghai',
  'Europe/Istanbul': 'Europe/Moscow',
  'Africa/El_Aaiun': 'Africa/Casablanca',
  'Europe/Kaliningrad': 'Africa/Cairo',
  'Asia/Hebron': 'Asia/Gaza',
  'Africa/Algiers': 'Africa/Lagos',
  'Africa/Tunis': 'Africa/Lagos',
  'Africa/Tripoli': 'Africa/Johannesburg',
  'America/Anguilla': 'America/Santo_Domingo',
  'America/Antigua': 'America/Santo_Domingo',
  'America/Aruba': 'America/Santo_Domingo',
  'America/Barbados': 'America/Santo_Domingo',
  'America/Blanc-Sablon': 'America/Santo_Domingo',
  'America/Curacao': 'America/Santo_Domingo',
  'America/Dominica': 'America/Santo_Domingo',
  'America/Grenada': 'America/Santo_Domingo',
  'America/Guadeloupe': 'America/Santo_Domingo',
  'America/Kralendijk': 'America/Santo_Domingo',
  'America/Lower_Princes': 'America/Santo_Domingo',
  'America/Marigot': 'America/Santo_Domingo',
  'America/Martinique': 'America/Santo_Domingo',
  'America/Montserrat': 'America/Santo_Domingo',
  'America/Port_of_Spain': 'America/Santo_Domingo',
  'America/Puerto_Rico': 'America/Santo_Domingo',
  'America/St_Barthelemy': 'America/Santo_Domingo',
  'America/St_Kitts': 'America/Santo_Domingo',
  'America/St_Lucia': 'America/Santo_Domingo',
  'America/St_Thomas': 'America/Santo_Domingo',
  'America/St_Vincent': 'America/Santo_Domingo',
  'America/Tortola': 'America/Santo_Domingo',
  'America/Bahia_Banderas': 'America/Mexico_City',
  'America/Merida': 'America/Mexico_City',
  'America/Monterrey': 'America/Mexico_City',
  'America/Belize': 'America/Guatemala',
  'America/Costa_Rica': 'America/Guatemala',
  'America/El_Salvador': 'America/Guatemala',
  'America/Managua': 'America/Guatemala',
  'America/Regina': 'America/Guatemala',
  'America/Swift_Current': 'America/Guatemala',
  'America/Tegucigalpa': 'America/Guatemala',
  'America/Creston': 'America/Phoenix',
  'America/Dawson_Creek': 'America/Phoenix',
  'America/Fort_Nelson': 'America/Phoenix',
  'America/Hermosillo': 'America/Phoenix',
  'America/Cancun': 'America/Bogota',
  'America/Cayman': 'America/Bogota',
  'America/Coral_Harbour': 'America/Bogota',
  'America/Jamaica': 'America/Bogota',
  'America/Panama': 'America/Bogota',
  'Australia/Lindeman': 'Australia/Brisbane',
  'Africa/Abidjan': 'UTC',
  'Africa/Accra': 'UTC',
  'Africa/Addis_Ababa': 'Africa/Nairobi',
  'Africa/Asmera': 'Africa/Nairobi',
  'Africa/Bamako': 'UTC',
  'Africa/Bangui': 'Africa/Lagos',
  'Africa/Banjul': 'UTC',
  'Africa/Bissau': 'UTC',
  'Africa/Blantyre': 'Africa/Johannesburg',
  'Africa/Brazzaville': 'Africa/Lagos',
  'Africa/Bujumbura': 'Africa/Johannesburg',
  'Africa/Ceuta': 'Europe/Berlin',
  'Africa/Conakry': 'UTC',
  'Africa/Dakar': 'UTC',
  'Africa/Dar_es_Salaam': 'Africa/Nairobi',
  'Africa/Djibouti': 'Africa/Nairobi',
  'Africa/Douala': 'Africa/Lagos',
  'Africa/Freetown': 'UTC',
  'Africa/Gaborone': 'Africa/Johannesburg',
  'Africa/Harare': 'Africa/Johannesburg',
  'Africa/Juba': 'Africa/Johannesburg',
  'Africa/Kampala': 'Africa/Nairobi',
  'Africa/Khartoum': 'Africa/Johannesburg',
  'Africa/Kigali': 'Africa/Johannesburg',
  'Africa/Kinshasa': 'Africa/Lagos',
  'Africa/Libreville': 'Africa/Lagos',
  'Africa/Lome': 'UTC',
  'Africa/Luanda': 'Africa/Lagos',
  'Africa/Lubumbashi': 'Africa/Johannesburg',
  'Africa/Lusaka': 'Africa/Johannesburg',
  'Africa/Malabo': 'Africa/Lagos',
  'Africa/Maputo': 'Africa/Johannesburg',
  'Africa/Maseru': 'Africa/Johannesburg',
  'Africa/Mbabane': 'Africa/Johannesburg',
  'Africa/Mogadishu': 'Africa/Nairobi',
  'Africa/Monrovia': 'UTC',
  'Africa/Ndjamena': 'Africa/Lagos',
  'Africa/Niamey': 'Africa/Lagos',
  'Africa/Nouakchott': 'UTC',
  'Africa/Ouagadougou': 'UTC',
  'Africa/Porto-Novo': 'Africa/Lagos',
  'Africa/Sao_Tome': 'Africa/Lagos',
  'America/Araguaina': 'America/Sao_Paulo',
  'America/Argentina/La_Rioja': 'America/Argentina/Buenos_Aires',
  'America/Argentina/Rio_Gallegos': 'America/Argentina/Buenos_Aires',
  'America/Argentina/Salta': 'America/Argentina/Buenos_Aires',
  'America/Argentina/San_Juan': 'America/Argentina/Buenos_Aires',
  'America/Argentina/Tucuman': 'America/Argentina/Buenos_Aires',
  'America/Argentina/Ushuaia': 'America/Argentina/Buenos_Aires',
  'America/Bahia': 'America/Sao_Paulo',
  'America/Belem': 'America/Sao_Paulo',
  'America/Boa_Vista': 'America/Campo_Grande',
  'America/Boise': 'America/Denver',
  'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
  'America/Cambridge_Bay': 'America/Denver',
  'America/Catamarca': 'America/Argentina/Buenos_Aires',
  'America/Chihuahua': 'America/Mazatlan',
  'America/Cordoba': 'America/Argentina/Buenos_Aires',
  'America/Cuiaba': 'America/Campo_Grande',
  'America/Danmarkshavn': 'UTC',
  'America/Dawson': 'America/Whitehorse',
  'America/Detroit': 'America/New_York',
  'America/Edmonton': 'America/Denver',
  'America/Fortaleza': 'America/Sao_Paulo',
  'America/Glace_Bay': 'America/Goose_Bay',
  'America/Grand_Turk': 'America/New_York',
  'America/Halifax': 'America/Goose_Bay',
  'America/Indiana/Knox': 'America/Chicago',
  'America/Indiana/Marengo': 'America/New_York',
  'America/Indiana/Petersburg': 'America/New_York',
  'America/Indiana/Tell_City': 'America/Chicago',
  'America/Indiana/Vevay': 'America/New_York',
  'America/Indiana/Vincennes': 'America/New_York',
  'America/Indiana/Winamac': 'America/New_York',
  'America/Indianapolis': 'America/New_York',
  'America/Inuvik': 'America/Denver',
  'America/Iqaluit': 'America/New_York',
  'America/Jujuy': 'America/Argentina/Buenos_Aires',
  'America/Juneau': 'America/Anchorage',
  'America/Kentucky/Monticello': 'America/New_York',
  'America/Louisville': 'America/New_York',
  'America/Maceio': 'America/Sao_Paulo',
  'America/Manaus': 'America/Campo_Grande',
  'America/Matamoros': 'America/Chicago',
  'America/Mendoza': 'America/Argentina/Buenos_Aires',
  'America/Menominee': 'America/Chicago',
  'America/Metlakatla': 'America/Anchorage',
  'America/Moncton': 'America/Goose_Bay',
  'America/Nassau': 'America/New_York',
  'America/Nipigon': 'America/New_York',
  'America/Nome': 'America/Anchorage',
  'America/North_Dakota/Beulah': 'America/Chicago',
  'America/North_Dakota/Center': 'America/Chicago',
  'America/North_Dakota/New_Salem': 'America/Chicago',
  'America/Ojinaga': 'America/Denver',
  'America/Pangnirtung': 'America/New_York',
  'America/Port-au-Prince': 'America/New_York',
  'America/Porto_Velho': 'America/Campo_Grande',
  'America/Rainy_River': 'America/Chicago',
  'America/Rankin_Inlet': 'America/Chicago',
  'America/Recife': 'America/Sao_Paulo',
  'America/Resolute': 'America/Chicago',
  'America/Rio_Branco': 'America/Eirunepe',
  'America/Santarem': 'America/Sao_Paulo',
  'America/Sitka': 'America/Anchorage',
  'America/Thule': 'America/Goose_Bay',
  'America/Thunder_Bay': 'America/New_York',
  'America/Tijuana': 'America/Los_Angeles',
  'America/Toronto': 'America/New_York',
  'America/Vancouver': 'America/Los_Angeles',
  'America/Winnipeg': 'America/Chicago',
  'America/Yakutat': 'America/Anchorage',
  'America/Yellowknife': 'America/Denver',
  'Antarctica/Casey': 'Australia/Perth',
  'Antarctica/McMurdo': 'Pacific/Auckland',
  'Arctic/Longyearbyen': 'Europe/Berlin',
  'Asia/Aden': 'Asia/Baghdad',
  'Asia/Aqtobe': 'Asia/Aqtau',
  'Asia/Atyrau': 'Asia/Aqtau',
  'Asia/Bahrain': 'Asia/Baghdad',
  'Asia/Chita': 'Asia/Yakutsk',
  'Asia/Colombo': 'Asia/Calcutta',
  'Asia/Famagusta': 'Europe/Helsinki',
  'Asia/Khandyga': 'Asia/Yakutsk',
  'Asia/Kuching': 'Asia/Kuala_Lumpur',
  'Asia/Kuwait': 'Asia/Baghdad',
  'Asia/Macau': 'Asia/Shanghai',
  'Asia/Muscat': 'Asia/Dubai',
  'Asia/Nicosia': 'Europe/Helsinki',
  'Asia/Novokuznetsk': 'Asia/Krasnoyarsk',
  'Asia/Oral': 'Asia/Aqtau',
  'Asia/Phnom_Penh': 'Asia/Bangkok',
  'Asia/Pontianak': 'Asia/Jakarta',
  'Asia/Pyongyang': 'Asia/Tokyo',
  'Asia/Qatar': 'Asia/Baghdad',
  'Asia/Qyzylorda': 'Asia/Almaty',
  'Asia/Riyadh': 'Asia/Baghdad',
  'Asia/Saigon': 'Asia/Bangkok',
  'Asia/Samarkand': 'Asia/Tashkent',
  'Asia/Seoul': 'Asia/Tokyo',
  'Asia/Srednekolymsk': 'Asia/Magadan',
  'Asia/Ust-Nera': 'Asia/Vladivostok',
  'Asia/Vientiane': 'Asia/Bangkok',
  'Atlantic/Bermuda': 'America/Goose_Bay',
  'Atlantic/Canary': 'Europe/London',
  'Atlantic/Faeroe': 'Europe/London',
  'Atlantic/Madeira': 'Europe/London',
  'Atlantic/Reykjavik': 'UTC',
  'Atlantic/St_Helena': 'UTC',
  'Australia/Broken_Hill': 'Australia/Adelaide',
  'Australia/Currie': 'Australia/Sydney',
  'Australia/Hobart': 'Australia/Sydney',
  'Australia/Melbourne': 'Australia/Sydney',
  CST6CDT: 'America/Chicago',
  'Etc/GMT': 'UTC',
  EST5EDT: 'America/New_York',
  'Europe/Amsterdam': 'Europe/Berlin',
  'Europe/Andorra': 'Europe/Berlin',
  'Europe/Astrakhan': 'Europe/Moscow',
  'Europe/Athens': 'Europe/Helsinki',
  'Europe/Belgrade': 'Europe/Berlin',
  'Europe/Bratislava': 'Europe/Berlin',
  'Europe/Brussels': 'Europe/Berlin',
  'Europe/Bucharest': 'Europe/Helsinki',
  'Europe/Budapest': 'Europe/Berlin',
  'Europe/Busingen': 'Europe/Berlin',
  'Europe/Copenhagen': 'Europe/Berlin',
  'Europe/Gibraltar': 'Europe/Berlin',
  'Europe/Kiev': 'Europe/Helsinki',
  'Europe/Lisbon': 'Europe/London',
  'Europe/Ljubljana': 'Europe/Berlin',
  'Europe/Luxembourg': 'Europe/Berlin',
  'Europe/Madrid': 'Europe/Berlin',
  'Europe/Malta': 'Europe/Berlin',
  'Europe/Mariehamn': 'Europe/Helsinki',
  'Europe/Monaco': 'Europe/Berlin',
  'Europe/Oslo': 'Europe/Berlin',
  'Europe/Paris': 'Europe/Berlin',
  'Europe/Podgorica': 'Europe/Berlin',
  'Europe/Prague': 'Europe/Berlin',
  'Europe/Riga': 'Europe/Helsinki',
  'Europe/Rome': 'Europe/Berlin',
  'Europe/San_Marino': 'Europe/Berlin',
  'Europe/Sarajevo': 'Europe/Berlin',
  'Europe/Saratov': 'Europe/Moscow',
  'Europe/Simferopol': 'Europe/Moscow',
  'Europe/Skopje': 'Europe/Berlin',
  'Europe/Sofia': 'Europe/Helsinki',
  'Europe/Stockholm': 'Europe/Berlin',
  'Europe/Tallinn': 'Europe/Helsinki',
  'Europe/Tirane': 'Europe/Berlin',
  'Europe/Ulyanovsk': 'Europe/Moscow',
  'Europe/Uzhgorod': 'Europe/Helsinki',
  'Europe/Vaduz': 'Europe/Berlin',
  'Europe/Vatican': 'Europe/Berlin',
  'Europe/Vienna': 'Europe/Berlin',
  'Europe/Vilnius': 'Europe/Helsinki',
  'Europe/Volgograd': 'Europe/Moscow',
  'Europe/Warsaw': 'Europe/Berlin',
  'Europe/Zagreb': 'Europe/Berlin',
  'Europe/Zaporozhye': 'Europe/Helsinki',
  'Europe/Zurich': 'Europe/Berlin',
  'Indian/Antananarivo': 'Africa/Nairobi',
  'Indian/Comoro': 'Africa/Nairobi',
  'Indian/Mayotte': 'Africa/Nairobi',
  MST7MDT: 'America/Denver',
  'Pacific/Johnston': 'Pacific/Honolulu',
  'Pacific/Kwajalein': 'Pacific/Majuro',
  'Pacific/Midway': 'Pacific/Pago_Pago',
  'Pacific/Port_Moresby': 'Pacific/Bougainville',
  'Pacific/Saipan': 'Pacific/Guam',
  PST8PDT: 'America/Los_Angeles',
  'America/Argentina/Punta_Arenas': 'America/Argentina/Buenos_Aires',
  'America/La_Rioja': 'America/Argentina/Buenos_Aires',
  'America/Rio_Gallegos': 'America/Argentina/Buenos_Aires',
  'America/Salta': 'America/Argentina/Buenos_Aires',
  'America/San_Juan': 'America/Argentina/Buenos_Aires',
  'America/Tucuman': 'America/Argentina/Buenos_Aires',
  'America/Ushuaia': 'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca': 'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba': 'America/Argentina/Buenos_Aires',
  'America/Argentina/Jujuy': 'America/Argentina/Buenos_Aires',
  'America/Argentina/Mendoza': 'America/Argentina/Buenos_Aires',
}

export function format(timezone: string): string {
  const mapped = TIMEZONE_MAPPING[timezone] ?? timezone
  return TIMEZONE_TO_DISPLAY_NAME[mapped] ?? mapped
}
