import type { InjectionKey } from 'vue'
import { inject as originalInject } from 'vue'

export default function inject<T>(key: InjectionKey<T> | string): T {
  const injected = originalInject<T>(key)
  if (injected === undefined) {
    throw new Error(`There is no provided value for "${key}" key`)
  }
  return injected
}
