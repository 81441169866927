import { getCurrentInstance } from '@/util'

import type { toast } from '@/components/ui/plugin'

export default function useToast() {
  const instance = getCurrentInstance()

  function t(...args: Parameters<typeof toast>) {
    instance.proxy.$root.$toast(...args)
  }

  function success(message: string) {
    t(message, { variant: 'success' })
  }

  function error(message: string) {
    t(message, { variant: 'error' })
  }

  return { toast: t, success, error }
}
