
import type { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'
import type { RawLocation } from 'vue-router'

import { ThemeMixin } from '../../mixins'

export default ThemeMixin.extend({
  name: 'WeveLink',
  functional: true,
  props: {
    href: {
      type: String,
      default: undefined,
    },
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: undefined,
    },
  },
  render(h, { props, data, children, injections }) {
    const tag = props.to !== undefined ? 'router-link' : 'a'

    return h(
      tag,
      mergeData(data, {
        props: tag === 'router-link' ? { to: props.to } : undefined,
        attrs:
          tag === 'a'
            ? {
                href: props.href,
              }
            : undefined,
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
        staticClass: 'app-link',
      }),
      children,
    )
  },
})
