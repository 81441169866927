
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveBadge',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'span',
    },
  },
  render(h, { props, children, data }) {
    return h(props.tag, mergeData(data, { staticClass: 'app-badge' }), children)
  },
})
