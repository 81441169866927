import type { MutationTree } from 'vuex'

import type { Undefined, Nullable, WorkspaceId, SessionId } from '@/types'
import type { WorkspaceForStoreFragment } from '@/graphql/types'
import { storage } from '@/plugins/storage'
import { StorageKey } from '@/plugins/storage/Storage.types'

import type { State } from './state'
import MutationTypes from './mutation-types'

const mutations: MutationTree<State> = {
  [MutationTypes.SET_WORKSPACE_ID](state, id: WorkspaceId) {
    state.workspaceId = id
    storage.set(StorageKey.WORKSPACE_ID, id)
  },
  [MutationTypes.SET_WORKSPACE](
    state,
    workspace: Nullable<WorkspaceForStoreFragment>,
  ) {
    state.workspace = workspace
  },
  [MutationTypes.SET_LAST_SESSION_ID](state, id: Undefined<SessionId>) {
    state.lastSessionId = id
    if (id === undefined) {
      storage.remove(StorageKey.LAST_SESSION_ID)
    } else {
      storage.set(StorageKey.LAST_SESSION_ID, id)
    }
  },
}

export default mutations
