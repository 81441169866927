import Vue, { provide, computed } from 'vue'
import VueMeta from 'vue-meta'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { sync } from 'vuex-router-sync'
import LoadScript from 'vue-plugin-load-script'
import { VueQueryPlugin } from '@tanstack/vue-query'

import UIPlugin from './components/ui'

import router from './router'
import apolloClient from './apollo'
import store from './store'
import { StateWatcher } from './store/watcher'
import container from './di/di.container'
import Token from './di/di.token'

import MutationTypes from './store/mutation-types'

import './plugins/validation'
import DiPlugin from './plugins/di'
import ServicesPlugin from './plugins/services'
import PermissionsPlugin from './plugins/permissions'
import ClipboardPlugin from './plugins/clipboard'
import NotifyPlugin from './plugins/notify'
import ConfirmPlugin from './plugins/confirm'
import WebStoragePlugin from './plugins/storage'
import TimezonePlugin from './plugins/timezone/timezone.plugin'
import WorkspacePlugin from './plugins/workspace'

import App from './App.vue'

import './styles/tailwind.css'
import './styles/index.scss'
import './components/ui/styles/index.scss'

Vue.config.productionTip = false

const workspaceId = computed(() => store.state.workspaceId)

Vue.use(DiPlugin, { store })
Vue.use(ServicesPlugin, { store })
Vue.use(VueMeta)
Vue.use(VueQueryPlugin)
Vue.use(LoadScript)
Vue.use(UIPlugin)
Vue.use(PermissionsPlugin)
Vue.use(ClipboardPlugin)
Vue.use(NotifyPlugin)
Vue.use(ConfirmPlugin)
Vue.use(WebStoragePlugin)
Vue.use(TimezonePlugin)
Vue.use(WorkspacePlugin, {
  id: workspaceId,
  client: apolloClient,
  setter: (value) => store.commit(MutationTypes.SET_WORKSPACE, value),
  router,
})

sync(store, router)

if (
  window.self === window.top &&
  process.env.VUE_APP_LOGROCKET_ENABLED === 'true'
) {
  const LogRocketService = () => import('@/services/LogRocketService')
  LogRocketService().then(({ init }) => init('ndk2s5/event-manger', store))
}

new StateWatcher(
  apolloClient,
  container.get(Token.TOKENS_MANAGER),
  store.state,
).init()
new Vue({
  router,
  store,
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: (h) => h(App),
}).$mount('#app')
