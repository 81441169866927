import AuthGetterTypes from './modules/AuthModule/getter-types'

const GetterTypes = {
  WORKSPACE_ID: 'WORKSPACE_ID',
  LAST_SESSION_ID: 'LAST_SESSION_ID',
  IS_WORKSPACE_SELECTED: 'auth/IS_WORKSPACE_SELECTED',
  AUTHENTICATED: `auth/${AuthGetterTypes.AUTHENTICATED}`,
  USER_ID: 'auth/USER_ID',
  ROLE: 'auth/ROLE',
  HAS_ACCESS: 'auth/HAS_ACCESS',
  HAS_APPROPRIATE_ROLE: 'auth/HAS_APPROPRIATE_ROLE',
  IS_ADMIN: 'auth/IS_ADMIN',
  IS_EDITOR: 'auth/IS_EDITOR',
  IS_STAFF: `auth/${AuthGetterTypes.IS_STAFF}`,
  IS_EVENT_PRODUCER: `auth/${AuthGetterTypes.IS_EVENT_PRODUCER}`,
  IS_SALESPERSON: `auth/${AuthGetterTypes.IS_SALESPERSON}`,
  IS_HOST: `auth/${AuthGetterTypes.IS_HOST}`,
  IS_BOOKING_USER: `auth/${AuthGetterTypes.IS_BOOKING_USER}`,
  HAS_STAFF_PERMISSIONS: `auth/${AuthGetterTypes.HAS_STAFF_PERMISSIONS}`,
}

export default GetterTypes
