import Vue from 'vue'

import type { WorkspaceId } from '@/types'

export default Vue.extend({
  name: 'WorkspaceMixin',
  computed: {
    workspaceId(): WorkspaceId {
      return this.$store.state.workspaceId
    },
  },
})
