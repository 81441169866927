
import type { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import type { ClassDefinition } from '../../types'
import { executeListener } from '../../utils'
import { ThemeMixin } from '../../mixins'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import { Variant } from './Card.types'
import CardTitle from './CardTitle.vue'

export default ThemeMixin.extend({
  name: 'WeveCard',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    variant: {
      type: String as PropType<Variant>,
      default: Variant.Default,
      validator: (val: Variant) => Object.values(Variant).includes(val),
    },
    title: {
      type: String as PropType<string>,
      default: undefined,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    backable: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    topClass: {
      type: [String, Array, Object] as PropType<ClassDefinition>,
      default: undefined,
    },
    innerClass: {
      type: [String, Array, Object] as PropType<ClassDefinition>,
      default: undefined,
    },
    contentClass: {
      type: [String, Array, Object] as PropType<ClassDefinition>,
      default: undefined,
    },
    titleTag: {
      type: String,
      default: undefined,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, children, data, slots, listeners, injections }) {
    const { header = null, buttons: $buttons = null } = slots()

    return h(
      props.tag,
      mergeData(data, {
        class: {
          [`app-card--variant--${props.variant}`]: true,
          'app-card--scrollable': props.scrollable,
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
        staticClass: 'app-card',
      }),
      [
        h('div', { class: props.innerClass, staticClass: 'app-card__inner' }, [
          header !== null || props.title !== undefined || $buttons !== null
            ? h(
                'div',
                {
                  class: [
                    {
                      'sr-only': props.hideHeader,
                    },
                    props.topClass,
                  ],
                  staticClass: 'app-card__top',
                },
                [
                  h(
                    CardTitle,
                    {
                      props: {
                        tag: props.titleTag,
                      },
                    },
                    props.title,
                  ),
                  header,
                  $buttons !== null
                    ? h('div', { staticClass: 'app-card__buttons' }, $buttons)
                    : null,
                ],
              )
            : null,
          props.closeable === true
            ? h(ButtonIcon, {
                props: { icon: 'close', label: 'Close', size: 'md' },
                staticClass: 'app-card__button',
                on: {
                  click() {
                    executeListener(listeners.close)
                  },
                },
              })
            : undefined,
          props.backable === true
            ? h(ButtonIcon, {
                props: { icon: 'long-arrow-left-regular', label: 'Back' },
                staticClass: 'app-card__back',
                on: {
                  click: () => {
                    executeListener(listeners.back)
                  },
                },
              })
            : undefined,
          h(
            'div',
            {
              class: props.contentClass,
              staticClass: 'app-card__content',
              ...(listeners.scroll && {
                on: {
                  scroll: () => {
                    executeListener(listeners.scroll)
                  },
                },
              }),
            },
            children,
          ),
        ]),
      ],
    )
  },
})
