import type { User } from '@/graphql/types'
import { Role } from '@/graphql/types'

export type UserId = User['id']

export const ROLE_TO_DISPLAY_NAME: Record<Role, string> = {
  [Role.ADMIN]: 'Admin',
  [Role.CUSTOMER_SUCCESS_MANAGER]: 'Customer Success Manager',
  [Role.EVENT_PRODUCER]: 'Event Producer',
  [Role.SALESPERSON]: 'Salesperson',
  [Role.HOST]: 'Host',
  [Role.PLANT]: 'Plant',
  [Role.BOOKING_USER]: 'Booking User',
}

export const ROLE_OPTIONS = [
  {
    value: Role.ADMIN,
    label: ROLE_TO_DISPLAY_NAME[Role.ADMIN],
  },
  {
    value: Role.CUSTOMER_SUCCESS_MANAGER,
    label: ROLE_TO_DISPLAY_NAME[Role.CUSTOMER_SUCCESS_MANAGER],
  },
  {
    value: Role.EVENT_PRODUCER,
    label: ROLE_TO_DISPLAY_NAME[Role.EVENT_PRODUCER],
  },
  {
    value: Role.SALESPERSON,
    label: ROLE_TO_DISPLAY_NAME[Role.SALESPERSON],
  },
  {
    value: Role.HOST,
    label: ROLE_TO_DISPLAY_NAME[Role.HOST],
  },
  {
    value: Role.PLANT,
    label: ROLE_TO_DISPLAY_NAME[Role.PLANT],
  },
  {
    value: Role.BOOKING_USER,
    label: ROLE_TO_DISPLAY_NAME[Role.BOOKING_USER],
  },
]

export const HOST_SKILL_LEVEL_OPTIONS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
]
