
import type { PropType } from 'vue'
import Vue from 'vue'

import type { TooltipPlacement } from '../Tooltip/Tooltip.types'
import WeveAvatar from '../Avatar/Avatar.vue'
import WeveTooltip from '../Tooltip/Tooltip.vue'

export default Vue.extend({
  name: 'WeveAvatarWithTooltip',
  components: {
    WeveAvatar,
    WeveTooltip,
  },
  props: {
    displayName: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      default: undefined,
    },
    placement: {
      type: String as PropType<TooltipPlacement>,
      default: 'top',
    },
  },
})
