import Vue from 'vue'

const DialogMixin = Vue.extend({
  name: 'DialogMixin',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  watch: {
    value: {
      handler(v: boolean) {
        this.visible = v
      },
      immediate: true,
    },
    visible(v: boolean) {
      this.$emit('input', v)
    },
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
  },
})

export default DialogMixin
