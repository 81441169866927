
import { ThemeMixin } from '../../mixins'
import AppContainer from '../Container/Container.vue'

export default ThemeMixin.extend({
  name: 'WeveNavigation',
  components: {
    AppContainer,
  },
})
