import Vue from 'vue'

import ActionTypes from '@/store/action-types'
import { StorageKey } from '@/plugins/storage/Storage.types'

export default Vue.extend({
  name: 'AuthMixin',
  methods: {
    async logout() {
      await this.$store.dispatch(ActionTypes.LOGOUT)
      this.$storage.remove(StorageKey.WORKSPACE_ID)
      this.$storage.remove(StorageKey.LAST_SESSION_ID)
      window.location.href = '/login'
    },
  },
})
