import Vue from 'vue'
import Vuex from 'vuex'

import state, { State } from './state'
import mutations from './mutations'

import { AuthModule } from './modules'
Vue.use(Vuex)

export default new Vuex.Store({
  state: state as State,
  mutations,
  modules: {
    auth: AuthModule,
  },
})
