
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import WeveIcon from '../Icon/Icon.vue'

export default ThemeMixin.extend({
  name: 'WeveTextButton',
  functional: true,
  props: {
    icon: {
      type: String,
      default: undefined,
    },
    iconPosition: {
      type: String,
      default: 'left',
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, children, data, injections }) {
    const icon =
      props.icon !== undefined
        ? h(WeveIcon, {
            props: { name: props.icon },
            staticClass: 'weve-text-button__icon',
            class: {
              'weve-text-button__icon--left': props.iconPosition === 'left',
              'weve-text-button__icon--right': props.iconPosition === 'right',
            },
          })
        : null
    return h(
      'button',
      mergeData(data, {
        attrs: { type: 'button' },
        staticClass: 'weve-text-button',
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
          'weve-text-button--link': props.link,
        },
      }),
      [
        props.iconPosition === 'left' ? icon : null,
        children,
        props.iconPosition === 'right' ? icon : null,
      ],
    )
  },
})
