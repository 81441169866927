import { ref, computed, watch } from 'vue'

import useAuthenticated from './useAuthenticated'
import usePermissions from './usePermissions'
import useDevice from './useDevice'

const loaded = ref(false)

function onConversationsAPIReady() {
  loaded.value = true
}

if (window.HubSpotConversations !== undefined) {
  onConversationsAPIReady()
} else {
  window.hsConversationsOnReady = [onConversationsAPIReady]
}

export default function useHubSpotConversation() {
  const hasParent = window.self !== window.top

  const { authenticated, authenticating } = useAuthenticated()
  const { isStaff } = usePermissions()
  const { isMobile } = useDevice()

  const visible = computed(() => {
    if (authenticating.value) return false
    if (loaded.value === false) return false
    if (hasParent) return false
    if (isStaff.value) return false
    if (authenticated.value && isMobile.value) return true
    if (authenticated.value) return false
    return true
  })

  watch(
    visible,
    (v) => {
      if (v) {
        load()
        return
      }
      remove()
    },
    { immediate: true },
  )

  function load() {
    if (window.HubSpotConversations === undefined) return
    window.HubSpotConversations.widget.load()
  }

  function remove() {
    if (window.HubSpotConversations === undefined) return
    window.HubSpotConversations.widget.remove()
  }
}
