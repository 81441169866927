
import type { PropType } from 'vue'
import { BModal } from 'bootstrap-vue'
import type { WithRefs } from 'vue-typed-refs'
import type { WithProperties } from 'vue-typed-properties'

import { ThemeMixin } from '../../mixins'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import type { DialogSize } from './Dialog.types'
import { DIALOG_SIZES } from './Dialog.types'

export type Refs = {
  modal: InstanceType<typeof BModal> & { $refs: { content: Element } }
}

export default (
  ThemeMixin as WithRefs<
    Refs,
    WithProperties<{ CLIPBOARD_TEXTAREA_SELECTOR: string }, typeof ThemeMixin>
  >
).extend({
  components: {
    BModal,
    ButtonIcon,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<DialogSize>,
      default: 'default' as const,
      validator: (val: DialogSize) => DIALOG_SIZES.has(val),
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get(): boolean {
        return this.value
      },
      set(value: boolean) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.CLIPBOARD_TEXTAREA_SELECTOR = 'textarea'
  },
  methods: {
    getModal() {
      return this.$refs.modal
    },
    /** @public */
    close() {
      this.$emit('input', false)
    },
  },
})
