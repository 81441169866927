
import { ValidationProvider } from 'vee-validate'

import { ValidationMixin } from '../../mixins'

import WeveTextarea from '../Textarea/Textarea.vue'

export default ValidationMixin.extend({
  name: 'AppValidationTextarea',
  components: {
    ValidationProvider,
    WeveTextarea,
  },
  inheritAttrs: false,
})
