import type { PluginObject } from 'vue'

import ClipboardDirective from './directive'

const ClipboardPlugin: PluginObject<never> = {
  install(Vue) {
    Vue.directive('clipboard', ClipboardDirective)
  },
}

export default ClipboardPlugin
