import Vue from 'vue'
import type { WithProperties } from 'vue-typed-properties'
import type { ApolloClient } from '@apollo/client/core'
import apollo from '@/apollo'

export default (
  Vue as WithProperties<{ $apollo: ApolloClient<unknown> }>
).extend({
  name: 'ApolloMixin',
  created() {
    this.$apollo = apollo
  },
})
