import type { Nullable } from '@/types'

export default class DefaultStringMap<K extends { toString(): string }, V> {
  private readonly map: Map<string, V>
  constructor(
    private readonly fallback: V | (() => V),
    entries?: Nullable<[K, V][]>,
  ) {
    this.map = new Map(entries?.map(([k, v]) => [k.toString(), v]))
  }

  get(key: K): V {
    const value = this.map.get(key.toString())
    if (value === undefined)
      return this.fallback instanceof Function ? this.fallback() : this.fallback
    return value
  }
}
