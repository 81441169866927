import type { Coordinates } from '@/types'
import type { ExperienceFormatType } from '@/graphql/types'
import type { PricingCategory } from '@/modules/pricing/pricing.types'

import BaseService from './BaseService'
import type { PricingConfig, Pricing } from './PricingService.types'

export default class PricingService extends BaseService {
  async getConfig(): Promise<PricingConfig> {
    const { data } = await this.client.get('/pricing/config')
    return data
  }

  async getData(
    type: ExperienceFormatType,
    category: PricingCategory,
    coordinates: Coordinates,
  ): Promise<{ prices: Pricing }> {
    const { data } = await this.client.get('/pricing/data', {
      params: {
        type,
        category,
        coordinates,
      },
    })
    return data
  }
}
