import type { NavigationGuard } from 'vue-router'

import store from '@/store'
import GetterTypes from '@/store/getter-types'
import MutationTypes from '@/store/mutation-types'
import ActionTypes from '@/store/action-types'

const authGuard: NavigationGuard = async (to, _, next) => {
  const { meta } = to

  const authenticated: boolean = await store.dispatch(
    ActionTypes.ENSURE_AUTHENTICATED,
  )

  if (meta?.authenticated === false) {
    return next()
  }

  if (authenticated && meta?.unauthenticated === true) {
    return next('/')
  }

  if (meta?.unauthenticated === true) {
    return next()
  }

  if (authenticated === false) {
    if (to.name === 'event' && typeof to.params.id === 'string') {
      store.commit(MutationTypes.SET_LAST_SESSION_ID, to.params.id)
    }
    return next({ name: 'login' })
  }

  if (
    store.getters[GetterTypes.IS_WORKSPACE_SELECTED] === false &&
    to.name !== 'workspace' &&
    !(to.name === 'event' && typeof to.params.id === 'string')
  ) {
    return next({ name: 'workspace' })
  }

  return next()
}

export default authGuard
