import type { Module } from 'vuex'

import type { State as RootState } from '../../state'

import state, { State } from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const AuthModule: Module<State, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default AuthModule
