
import Vue from 'vue'

import WeveButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import ValidateableTextField from '../ValidateableTextField/ValidateableTextField.vue'
import TextField from '../TextField/TextField.vue'
import WeveTooltip from '../Tooltip/Tooltip.vue'

export type Type = 'text' | 'password'

export default Vue.extend({
  name: 'AppPasswordTextField',
  components: {
    WeveButtonIcon,
    ValidateableTextField,
    TextField,
    WeveTooltip,
  },
  inheritAttrs: false,
  props: {
    validation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: 'password' as Type,
    }
  },
  computed: {
    component(): string {
      return this.validation === true ? 'ValidateableTextField' : 'TextField'
    },
    message(): string {
      if (this.type === 'password') {
        return 'Show password'
      } else {
        return 'Hide password'
      }
    },
  },
  methods: {
    toggle() {
      this.type = this.type === 'password' ? 'text' : 'password'
    },
  },
})
