import Vue from 'vue'

import type { Undefined } from '@/types'
import { isFalsy } from '@/util'

export interface Props {
  vid?: string
  mode?: string
  value?: unknown
  label: string
  rules?: Record<string, unknown>
  silent?: boolean
}

const ValidationMixin = Vue.extend({
  name: 'ValidationMixin',
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    mode: {
      type: String,
      default: 'eager',
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
    silent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name(): string {
      return `"${this.label}"`
    },
    required(): boolean {
      if (isFalsy(this.value) === false) {
        return false
      }

      return this.rules.required === true
    },
    hint(): Undefined<string> {
      if (this.silent) return undefined
      if (this.rules.max !== undefined) {
        const { value } = this
        if (typeof value === 'string' || typeof value === 'number') {
          return `${Math.max(
            this.rules.max - value.toString().length,
            0,
          )} Characters Left`
        }
        return `${this.rules.max} Characters Left`
      }

      return undefined
    },
  },
  methods: {
    getMessage({
      errors,
      valid,
    }: {
      errors: string[]
      valid: boolean
    }): string | undefined {
      const [error] = errors
      if (error !== undefined) return error
      if (valid) return undefined
      if (this.required === true && Boolean(this.value) === false)
        return 'Required'
      return undefined
    },
  },
})

export default ValidationMixin
