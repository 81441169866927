import type { VNode, PluginObject } from 'vue'
import { ModalPlugin } from 'bootstrap-vue'

import { ConfirmMixin } from '@/mixins'

import DefaultConfirm from './components/DefaultConfirm.vue'

function confirm(
  this: Vue,
  message: string | VNode[],
  options: { title?: string } = {}
) {
  const div = document.createElement('div')
  document.body.append(div)
  const dc: InstanceType<typeof ConfirmMixin> = new DefaultConfirm({
    propsData: {
      title: options.title,
      text: message,
    },
  })
  if (typeof message !== 'string') {
    dc.$slots.default = message
  }
  dc.$mount(div)
  return dc.confirm().finally(() => {
    dc.$destroy()
  })
}

export type Confirm = typeof confirm

const ConfirmPlugin: PluginObject<never> = {
  install(Vue) {
    const ConfirmMixin = Vue.extend({
      created() {
        this.$confirm = confirm
      },
    })

    Vue.use(ModalPlugin)
    Vue.mixin(ConfirmMixin)
  },
}

export default ConfirmPlugin
