import { watch } from 'vue'
import type { ApolloClient } from '@apollo/client/core'

import type { SessionStatus } from '@/graphql/types'
import type { State } from '@/store/state'
import SocketContainer from '@/modules/socket'
import type { TokensManager } from '@/core/auth/interfaces/TokensManager'
import WorkspaceCache from '@/modules/workspace/WorkspaceCache'
import SessionCache from '@/modules/session/SessionCache'

export class StateWatcher {
  constructor(
    private readonly client: ApolloClient<unknown>,
    private readonly tokensManager: TokensManager,
    private readonly state: State,
  ) {}
  init(): void {
    this.watchWorkspaceId()
    this.watchSessionId()
  }

  private watchWorkspaceId(): void {
    watch(
      () => {
        if (this.tokensManager.token === undefined) return undefined
        return this.state.workspaceId
      },
      (value, oldValue) => {
        if (value === undefined && oldValue === undefined) return
        const socket = SocketContainer.getInstance({
          token: this.tokensManager.token,
        })
        if (oldValue !== undefined) {
          socket.emit(`unsubscribeWorkspace`, oldValue)
          socket.unsubscribeScope(`workspace`)
        }
        if (value === undefined) {
          return
        }
        socket.emit(`subscribeWorkspace`, value)
        socket.subscribe(
          'workspace',
          `workspace:${value}:update`,
          ({ credits }: { credits?: number }) => {
            if (credits !== undefined) {
              WorkspaceCache.updateCredits(this.client, value, credits)
            }
          },
        )
      },
      {
        immediate: true,
      },
    )
  }

  private watchSessionId() {
    watch(
      () => this.state.lastSessionId,
      (value, oldValue) => {
        if (oldValue === undefined && value === undefined) {
          return
        }
        const socket = SocketContainer.getInstance({
          token: this.tokensManager.token,
        })
        if (oldValue !== undefined) {
          socket.emit('unsubscribeSession', oldValue)
          socket.unsubscribeScope('session')
        }
        if (value !== undefined) {
          socket.emit('subscribeSession', value)
          socket.subscribe('session', `session:${value}:integration`, () =>
            SessionCache.refetchIntegrations(this.client, value),
          )
          socket.subscribe('session', `session:${value}:assignment`, () =>
            SessionCache.refetchAssignments(this.client, value),
          )
          socket.subscribe(
            'session',
            `session:${value}:update`,
            ({
              credits,
              status,
            }: {
              credits?: number
              status?: SessionStatus
            }) => {
              if (credits !== undefined) {
                SessionCache.updateCredits(this.client, value, credits)
              }
              if (status !== undefined) {
                SessionCache.updateStatus(this.client, value, status)
              }
            },
          )
        }
      },
      { immediate: true },
    )
  }
}
