export enum Variant {
  Danger = 'danger',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
}

export interface Props {
  variant?: Variant
  shadow?: boolean
}
