import type { Ref } from 'vue'
import { computed } from 'vue'

import type { Nullable, Maybe, UserId } from '@/types'
import { SessionRole } from '@/graphql/types'
import { isDefined, indexBy } from '@/util'

type A = {
  role: SessionRole
  user?: Maybe<{ id: UserId }>
  rating?: Maybe<number>
}

type Session = { assignments: A[] }

type Assignment<T extends Session> = Nullable<T['assignments'][number]>

export default function useSessionAssignments<T extends Session>(
  session: Ref<T>,
) {
  const assignmentsByRole = computed(() =>
    indexBy(session.value.assignments, 'role'),
  )
  const producerAssignment = computed<Assignment<T>>(
    () => assignmentsByRole.value.get(SessionRole.PRODUCER) ?? null,
  )
  const producer = computed(() => producerAssignment.value?.user ?? null)
  const supportingProducerAssignment = computed<Assignment<T>>(
    () => assignmentsByRole.value.get(SessionRole.SUPPORTING_PRODUCER) ?? null,
  )
  const supportingProducer = computed(
    () => supportingProducerAssignment.value?.user ?? null,
  )
  const producerAssignments = computed<T['assignments']>(() =>
    [producerAssignment.value, supportingProducerAssignment.value].filter(
      isDefined,
    ),
  )
  const producers = computed(() =>
    [producer.value, supportingProducer.value].filter(isDefined),
  )
  const salespersonAssignment = computed(
    () => assignmentsByRole.value.get(SessionRole.SALESPERSON) ?? null,
  )
  const salesperson = computed(() => salespersonAssignment.value?.user ?? null)
  const hostAssignments = computed(() =>
    session.value.assignments.filter(
      (assignment) => assignment.role === SessionRole.HOST,
    ),
  )
  const plantsAssignments = computed(() =>
    session.value.assignments.filter(
      (assignment) => assignment.role === SessionRole.PLANT,
    ),
  )
  return {
    producerAssignment,
    producer,
    supportingProducerAssignment,
    supportingProducer,
    producerAssignments,
    producers,
    salespersonAssignment,
    salesperson,
    hostAssignments,
    plantsAssignments,
  }
}
