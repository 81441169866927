import type { ApolloClient } from '@apollo/client/core'

import type { WorkspaceId } from '@/types'
import type { WorkspaceWithCreditsFragment } from '@/graphql/types'

import workspaceWithCreditsDocument from './graphql/workspaceWithCredits.graphql'

export default class WorkspaceCache {
  static updateCredits(
    client: ApolloClient<unknown>,
    id: WorkspaceId,
    credits: number,
  ): void {
    client.writeFragment<WorkspaceWithCreditsFragment>({
      fragment: workspaceWithCreditsDocument,
      id: `Workspace:${id}`,
      data: {
        __typename: 'Workspace',
        id,
        consumables: {
          __typename: 'WorkspaceConsumables',
          credits,
        },
      },
    })
  }
}
