
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveCol',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    xs: {
      type: [String, Number],
      default: undefined,
    },
    sm: {
      type: [String, Number],
      default: undefined,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    shrink: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, children, data }) {
    return h(
      props.tag,
      mergeData(data, {
        class: {
          [`app-col--xs--${props.xs}`]: props.xs,
          [`app-col--sm--${props.sm}`]: props.sm,
          'app-col--grow': props.grow,
          'app-col--shrink': props.shrink,
        },
        staticClass: 'app-col',
      }),
      children,
    )
  },
})
