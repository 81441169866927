export default class DefaultMap<K, V> {
  private readonly map: Map<K, V>
  private readonly fallback: () => V

  constructor(fallback: () => V, entries?: readonly (readonly [K, V])[]) {
    this.map = new Map(entries)
    this.fallback = fallback
  }

  get(key: K): V {
    const value = this.map.get(key)
    if (value === undefined) {
      return this.fallback()
    }
    return value
  }

  set(key: K, value: V): this {
    this.map.set(key, value)
    return this
  }
}
