import {
  ConsumableName,
  PurchasableConsumableName,
  AssignmentType,
  SessionRole,
  SyncType,
  ExperienceFormatType,
  SessionDocument,
  PlayType,
} from '@/graphql/types'

export * from './experience'
export * from './modifiers'
export * from './permission'
export * from './session'
export * from './zone'

export const ASSIGNMENT_TYPE_TO_DISPLAY_NAME: Record<AssignmentType, string> = {
  [AssignmentType.AUTO]: 'Assigned',
  [AssignmentType.MANUAL]: 'Assigned manually',
  [AssignmentType.RANDOM]: 'Randomized',
}

export const SESSION_ROLE_TO_DISPLAY_NAME: Record<SessionRole, string> = {
  [SessionRole.PRODUCER]: 'Event Producer',
  [SessionRole.SUPPORTING_PRODUCER]: 'Supporting Event Producer',
  [SessionRole.SALESPERSON]: 'Salesperson',
  [SessionRole.PLANT]: 'Salesperson',
  [SessionRole.HOST]: 'Host',
}

export const CONSUMABLE_NAME_TO_DISPLAY_NAME: Record<ConsumableName, string> = {
  [ConsumableName.CREDIT]: 'Credit',
  [ConsumableName.OPEN_WEVE_ROOM]: 'Open Weve Room',
  [ConsumableName.RESCHEDULE_FEE]: 'Reschedule Fee',
}

export const PURCHASABLE_CONSUMABLE_NAME_TO_CONSUMABLE_NAME: Record<
  PurchasableConsumableName,
  ConsumableName
> = {
  [PurchasableConsumableName.CREDIT]: ConsumableName.CREDIT,
}

export const SYNC_TYPE_TO_DISPLAY_NAME: Record<SyncType, string> = {
  [SyncType.WEVE_MULTI_ROUND]: 'Game Show',
  [SyncType.WEVE_STANDARD]: 'Escape Room • IRL • DIY',
  [SyncType.VAS]: 'VAS',
  [SyncType.VAS_PLAY]: 'TGG Play (VAS)',
  [SyncType.BREADCRUMB]: 'Breadcrumb',
  [SyncType.PLAY]: 'TGG Play',
  [SyncType.THIRD_PARTY]: 'Third Party',
}

export const PLAY_TYPE_TO_DISPLAY_NAME: Record<PlayType, string> = {
  [PlayType.INDIVIDUAL]: 'Individual',
  [PlayType.TEAM]: 'Team',
}

export const EXPERIENCE_FORMAT_TYPE_TO_DISPLAY_NAME: Record<
  ExperienceFormatType,
  string
> = {
  [ExperienceFormatType.REMOTE]: 'Virtual',
  [ExperienceFormatType.HYBRID]: 'Hybrid',
  [ExperienceFormatType.IN_PERSON]: 'In-Person',
}

export const SESSION_DOCUMENT_TO_DISPLAY: Record<SessionDocument, string> = {
  [SessionDocument.IRL_ADMIN_INSTRUCTIONS]: 'Admin Instructions',
  [SessionDocument.IRL_LOGIN_INSTRUCTIONS]: 'Login Instructions',
  [SessionDocument.IRL_LOGIN_INSTRUCTIONS_NO_CODE]:
    'Login Instructions (No Code)',
  [SessionDocument.IRL_TALKING_POINTS]: 'Talking Points',
}
