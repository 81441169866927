export type PartnerStackSignUp = {
  firstName: string
  lastName: string
  email: string
  phone?: string
}

export default function usePartnerStack() {
  function signUp(data: PartnerStackSignUp) {
    if (window.growsumo?.data === undefined) {
      console.error('PartnerStack is not available')
      return
    }
    window.growsumo.data.name = `${data.firstName} ${data.lastName}`
    window.growsumo.data.email = data.email
    window.growsumo.data.phone = data.phone
    window.growsumo.data.customer_key = data.email
    window.growsumo.createSignup()
  }
  return {
    signUp,
  }
}
