
import Vue from 'vue'
import { ValidationProvider } from 'vee-validate'
import type { WithRefs } from 'vue-typed-refs'

import WeveSelect from '../Select/Select.vue'

export type Refs = {
  select: InstanceType<typeof WeveSelect>
}

export default (Vue as WithRefs<Refs>).extend({
  name: 'AppValidationSelect',
  components: {
    ValidationProvider,
    WeveSelect,
  },
  inheritAttrs: false,
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    mode: {
      type: String,
      // vue-select doesn't emit "change" event
      default: 'aggressive',
    },
    value: {},
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    name(): string {
      return this.label.toLowerCase()
    },
    required(): boolean {
      return this.rules.required === true
    },
  },
  methods: {
    getMessage({ errors }: { errors: string[] }): string | undefined {
      const [error] = errors
      if (error !== undefined) return error
      if (this.required === true && Boolean(this.value) === false)
        return 'Required'
      return undefined
    },
  },
})
