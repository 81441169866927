import Vue from 'vue'
import type { WithProperties } from 'vue-typed-properties'

import type Request from '@/services/Request'
import type ScheduleSchema from '@/modules/schedule/ScheduleSchema'

export default (
  Vue as WithProperties<{
    availableDatesProviderRequest: Request<Date[]> | undefined
  }>
).extend({
  name: 'AvailableDatesProvider',
  data() {
    return {
      availableDates: [] as Date[],
      loadingAvailableDates: false,
    }
  },
  beforeDestroy() {
    if (this.availableDatesProviderRequest !== undefined) {
      this.availableDatesProviderRequest.cancel()
    }
  },
  methods: {
    async getAvailableDatesForSchema(schema: ScheduleSchema) {
      const request = this.$services.scheduler.availableDatesForSchema(schema)
      await this._requestAvailableDates(request)
    },
    cancelAvailableDatesRequest() {
      if (this.availableDatesProviderRequest === undefined) {
        return
      }
      this.availableDatesProviderRequest.cancel()
      this.availableDatesProviderRequest = undefined
    },
    async _requestAvailableDates(request: Request<Date[]>) {
      this.cancelAvailableDatesRequest()
      this.availableDates = []
      this.availableDatesProviderRequest = request
      this.loadingAvailableDates = true
      try {
        this.availableDates = await request.promise
      } finally {
        this.availableDatesProviderRequest = undefined
        this.loadingAvailableDates = false
      }
      return request
    },
  },
})
