import { useDialogBuilder } from '@/use'

export default function usePurchaseDialog() {
  const { build } = useDialogBuilder()
  async function show(initial?: number) {
    const { default: options } = await import('../PurchaseDialog.vue?options')
    return build(options, { initial })
  }
  return {
    show,
  }
}
