
import Vue, { PropType, VNode } from 'vue'

import WeveButton from '../Button/Button.vue'

import { Variant, VARIANTS } from './types'

export default Vue.extend({
  name: 'AppCalendarButton',
  components: { WeveButton },
  props: {
    variant: {
      type: String as PropType<Variant>,
      required: true,
      validator: (val: Variant) => VARIANTS.includes(val),
    },
    title: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    location: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    start: {
      type: Date as PropType<Date | null>,
      default: null,
    },
    end: {
      type: Date as PropType<Date | null>,
      default: null,
    },
    details: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },
  computed: {
    url(): string {
      switch (this.variant) {
        case 'google':
          return this.googleUrl()
        case 'outlook':
          return this.outlookUrl()
        case 'ical':
          return this.icalUrl()
        default:
          return ''
      }
    },
  },
  methods: {
    googleUrl() {
      const formatString = (string: string) => {
        return encodeURIComponent(string).replace(/%20/g, '+')
      }
      const formatDate = (date: Date) => {
        return date ? date.toISOString().replace(/-|:|\.\d+/g, '') : null
      }
      const url = [
        'http://www.google.com/calendar/render?action=TEMPLATE&trp=false',
      ]
      if (this.title) {
        url.push(`text=${formatString(this.title)}`)
      }
      if (this.location) {
        url.push(`location=${formatString(this.location)}`)
      }
      if (this.start && this.end) {
        url.push(`dates=${formatDate(this.start)}/${formatDate(this.end)}`)
      }
      if (this.details) {
        url.push(`details=${formatString(this.details)}`)
      }
      return url.join('&')
    },
    outlookUrl() {
      const formatString = (string: string) => {
        return encodeURIComponent(string)
      }
      const formatDate = (date: Date) => {
        return date ? date.toISOString().replace(/\.\d+/g, '') : null
      }
      const url = [
        'https://outlook.live.com/calendar/0/deeplink/compose?rru=addevent',
      ]
      if (this.title) {
        url.push(`subject=${formatString(this.title)}`)
      }
      if (this.location) {
        url.push(`location=${formatString(this.location)}`)
      }
      if (this.start) {
        url.push(`startdt=${formatDate(this.start)}`)
      }
      if (this.end) {
        url.push(`enddt=${formatDate(this.end)}`)
      }
      if (this.details) {
        url.push(`body=${formatString(this.details)}`)
      }
      return url.join('&')
    },
    icalUrl() {
      const formatString = (string: string) => {
        return unescape(encodeURIComponent(string.replace(/\n/g, '\\N')))
      }
      const formatDate = (date: Date) => {
        return date ? date.toISOString().replace(/-|:|\.\d+/g, '') : null
      }
      const icsLines = [`BEGIN:VCALENDAR`, `VERSION:2.0`, `BEGIN:VEVENT`]
      if (this.title) {
        icsLines.push(`SUMMARY:${formatString(this.title)}`)
      }
      if (this.location) {
        icsLines.push(`LOCATION:${formatString(this.location)}`)
      }
      if (this.start) {
        icsLines.push(`DTSTART:${formatDate(this.start)}`)
      }
      if (this.end) {
        icsLines.push(`DTEND:${formatDate(this.end)}`)
      }
      if (this.details) {
        icsLines.push(`DESCRIPTION:${formatString(this.details)}`)
      }
      icsLines.push(`END:VEVENT`)
      icsLines.push(`END:VCALENDAR`)
      return `data:text/calendar;base64,${btoa(icsLines.join('\r\n'))}`
    },
  },
  render(h): VNode {
    return h(
      WeveButton,
      {
        props: { variant: 'secondary', href: this.url },
        attrs: { target: '_blank' },
        scopedSlots: this.$scopedSlots,
      },
      this.$slots.default,
    )
  },
})
