import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { Role } from '@/graphql/types'
import store from '@/store'
import GetterTypes from '@/store/getter-types'

import { authGuard } from './guards'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/register',
    name: 'register',
    component: () => import('@/view/Onboarding/Register.vue'),
    props: (to) => {
      const { query } = to
      return {
        code:
          typeof query.code === 'string' && query.code !== ''
            ? query.code
            : undefined,
      }
    },
    meta: {
      unauthenticated: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/Onboarding/Login.vue'),
    meta: {
      unauthenticated: true,
    },
  },
  {
    path: '/a/:code',
    redirect: '/assignment-approval/:code',
    meta: {
      authenticated: false,
    },
  },
  {
    path: '/assignment-approval/:code',
    name: 'assignment-approval',
    component: () =>
      import('@/view/AssignmentApproval/AssignmentApprovalPage.vue'),
    meta: {
      authenticated: false,
    },
    props: (route) => {
      return {
        code: route.params.code,
      }
    },
  },
  {
    path: '/password/change',
    name: 'password.change',
    component: () => import('@/view/Onboarding/PasswordChange.vue'),
    meta: {
      unauthenticated: true,
    },
    props: (to) => {
      const { email, session } = to.query
      return {
        email: typeof email === 'string' ? email : '',
        session: typeof session === 'string' ? session : '',
      }
    },
  },
  {
    path: '/password/forgot',
    name: 'password.forgot',
    component: () => import('@/view/Onboarding/PasswordForgot.vue'),
    meta: {
      unauthenticated: true,
    },
  },
  {
    path: '/workspace',
    name: 'workspace',
    component: () => import('@/view/Onboarding/Workspace.vue'),
  },
  {
    component: () =>
      import('@/view/Experiences/ExperienceQuotePage/ExperienceQuotePage.vue'),
    path: '/experience-quote/:id',
    name: 'experience-quote',
    props: (route) => {
      return {
        id: route.params.id,
        format: route.query.format,
        host: route.query.host,
        people: route.query.people ? Number(route.query.people) : undefined,
        location:
          typeof route.query.location === 'string'
            ? JSON.parse(route.query.location)
            : null,
      }
    },
    meta: {
      authenticated: false,
    },
  },
  {
    path: '/pricing-embedded',
    name: 'pricing-embedded',
    meta: {
      authenticated: false,
    },
    component: () => import('@/view/Pricing/PricingPage.vue'),
  },
  {
    path: '/',
    component: () => import('@/view/Home/Home.vue'),
    redirect: () => {
      return { name: 'experiences' }
    },
    children: [
      {
        component: () => import('@/view/EventPage/EventPage.vue'),
        path: 'event/:id?',
        name: 'event',
        props: (route) => {
          return {
            id: route.params.id,
            tab: route.query.tab,
          }
        },
        beforeEnter: (to, __, next) => {
          const last = store.state.lastSessionId
          if (to.params.id === undefined && last !== undefined) {
            next({ name: 'event', params: { id: last } })
            return
          }
          next()
        },
      },
      {
        component: () => import('@/view/SessionGroupPage/SessionGroupPage.vue'),
        path: 'group/:id',
        name: 'group',
        props: true,
      },
      {
        component: () => import('@/view/Experiences/ExperiencesPage.vue'),
        name: 'experiences',
        path: 'experiences',
        meta: {
          authenticated: false,
        },
      },
      {
        component: () =>
          import(
            '@/view/Experiences/ExperiencesCategoryPage/ExperiencesCategoryPage.vue'
          ),
        name: 'experiences.category',
        path: 'experiences/:category',
        props: (route) => {
          return {
            category: route.params.category.toUpperCase().replace('-', '_'),
          }
        },
        meta: {
          authenticated: false,
        },
      },
      {
        component: () => import('@/view/OpenWeve/OpenWevePage.vue'),
        name: 'open-weve',
        path: 'open-weve',
      },
      {
        component: () => import('@/view/Customization/CustomizationPage.vue'),
        path: 'customization',
        children: [
          {
            path: '',
            redirect: 'branding',
            name: 'customization',
          },
          {
            path: 'branding',
            name: 'customization.branding',
            component: () => import('@/view/Customization/EventSkinPage.vue'),
          },
          {
            path: 'content',
            name: 'customization.content',
            component: () => import('@/view/ContentLibrary/ContentLibrary.vue'),
          },
        ],
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/view/UserManagement/UserManagement.vue'),
        props: (route) => {
          const roles = route.query.roles || []
          return {
            session: route.query.session,
            roles: Array.isArray(roles) ? roles : [roles],
          }
        },
      },
      {
        path: 'availability',
        component: () => import('@/view/Availability/AvailabilityPage.vue'),
        children: [
          {
            path: '',
            name: 'availability',
            component: () => import('@/view/Availability/MyAvailability.vue'),
            beforeEnter: (to, __, next) => {
              const { user } = store.state.auth
              if (user === null) return next({ name: 'login' })
              if (
                user.role !== Role.EVENT_PRODUCER &&
                user.role !== Role.HOST &&
                typeof to.query.id !== 'string'
              ) {
                next({ name: 'availability.planning' })
              } else {
                next()
              }
            },
          },
          {
            path: 'planning',
            name: 'availability.planning',
            component: () =>
              import('@/view/Availability/ShiftPlanning/ShiftPlanning.vue'),
          },
          {
            path: 'event',
            name: 'availability.event',
            component: () =>
              import('@/view/Availability/AvailabilityEvent.vue'),
          },
          {
            path: 'overrides',
            name: 'availability.global-unavailability',
            component: () =>
              import(
                '@/view/Availability/AvailabilityGlobalUnavailabilities.vue'
              ),
          },
        ],
        props: () => {
          const isStaff = store.getters[GetterTypes.IS_STAFF]
          return {
            navigation: isStaff,
          }
        },
      },
      {
        path: 'packages',
        name: 'packages',
        meta: {
          authenticated: false,
        },
        component: () => import('@/view/Packages/PackagesPage.vue'),
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/view/Settings/Settings.vue'),
        children: [
          {
            path: 'profile',
            name: 'settings.profile',
            component: () =>
              import('@/view/Settings/ProfileSettings/ProfileSettings.vue'),
          },
          {
            path: 'workspace',
            name: 'settings.workspace',
            component: () =>
              import('@/view/Settings/WorkspaceSettings/WorkspaceSettings.vue'),
          },
          {
            path: 'admin',
            name: 'settings.admin',
            component: () =>
              import('@/view/Settings/AdminSettings/AdminSettings.vue'),
          },
        ],
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/view/Contact/SupportPage.vue'),
      },
      {
        path: 'actor-application',
        name: 'actor-application',
        meta: {
          authenticated: false,
        },
        component: () =>
          import('@/view/ActorApplication/ActorApplicationPage.vue'),
      },
      {
        path: 'venues',
        name: 'venues',
        component: () => import('@/view/VenueManagement/VenueManagement.vue'),
      },
      {
        path: 'venue/:id',
        name: 'venue',
        props: true,
        component: () => import('@/view/VenueManagement/VenuePage.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: () => {
      return { name: 'login' }
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(authGuard)

export default router
