export const BUTTON_VARIANTS = [
  'primary',
  'secondary',
  'tertiary',
  'accent',
  'danger',
] as const
export type ButtonVariant = (typeof BUTTON_VARIANTS)[number]
export const BUTTON_SIZES = ['default', 'sm'] as const
export type ButtonSize = (typeof BUTTON_SIZES)[number]

export interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  disabled?: boolean
  loading?: boolean
}

export const BUTTON_FILLED_VARIANTS = new Set([
  'primary',
  'secondary',
  'accent',
])
export const BUTTON_OUTLINED_VARIANTS = new Set(['tertiary', 'danger'])
