
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveMenuContent',
  functional: true,
  render(h, { children, data }) {
    return h(
      'li',
      mergeData(
        {
          attrs: { role: 'presentation' },
          on: {
            click: (e: MouseEvent) => {
              e.stopImmediatePropagation()
            },
          },
        },
        data,
      ),
      children,
    )
  },
})
