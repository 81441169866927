import type { UnwrapSet } from '@/types'

export const SKELETON_TYPES = new Set([
  'button-icon',
  'simple',
  'avatar',
  'event-card',
  'text-field',
  'textarea',
  'select',
  'button',
  'list',
  'definition-list',
  'widget',
  'select-option',
  'checkbox',
  'toggle',
  'selection-card',
] as const)
export type SkeletonType = UnwrapSet<typeof SKELETON_TYPES>

export interface Props {
  type: SkeletonType
}
