import { shallowRef, watch } from 'vue'

import type { Nullable } from '@/types'
import { useGoogleMaps } from '@/use'

const geocoder = shallowRef<Nullable<google.maps.Geocoder>>(null)

export default function usePlacesAutocomplete() {
  const { loaded } = useGoogleMaps()
  watch(
    loaded,
    async (v) => {
      if (v === false) return
      geocoder.value = new google.maps.Geocoder()
    },
    { immediate: true },
  )
  function get(): Promise<google.maps.Geocoder> {
    return new Promise((resolve) => {
      if (geocoder.value !== null) {
        resolve(geocoder.value)
        return
      }
      const unwatch = watch(geocoder, (v) => {
        if (v === null) return
        unwatch()
        resolve(v)
      })
    })
  }
  return { geocoder, get }
}
