
import type { PropType } from 'vue'
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import type { ChipVariant } from './Chip.types'
import { CHIP_VARIANTS } from './Chip.types'

export default Vue.extend({
  name: 'WeveChip',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'span',
    },

    variant: {
      type: String as PropType<ChipVariant>,
      default: 'default' as const,
      validator: (val: ChipVariant) => CHIP_VARIANTS.has(val),
    },
  },
  render(h, { props, children, data }) {
    return h(
      props.tag,
      mergeData(data, {
        staticClass: 'app-chip',
        class: {
          [`app-chip--variant--${props.variant}`]: true,
        },
      }),
      children,
    )
  },
})
