import { computed } from 'vue'

import type { UserForStoreFragment } from '@/graphql/types'

import useStore from './useStore'

export default function uewViewer() {
  const { store } = useStore()

  const viewer = computed(() => store.state.auth.user as UserForStoreFragment)

  return {
    viewer,
  }
}
