import { ref, onMounted, onUnmounted } from 'vue'

import type { Nullable } from '@/types'
import { getCurrentInstance, generateId } from '@/util'

import useToast from './useToast'

type HubSpotFormSubmitEvent<T extends Record<string, unknown>> = MessageEvent<{
  type?: string
  eventName?: string
  id?: string
  data: { name: keyof T; value: T[keyof T] }[]
}>

export default function useHubSpotForm<
  T extends Record<string, unknown> = Record<string, unknown>,
>(form: string, onSubmit: Nullable<() => void> = null) {
  const instance = getCurrentInstance()
  const { toast } = useToast()
  const id = generateId()
  const loading = ref(true)
  const node = ref<Nullable<HTMLDivElement>>(null)
  async function init() {
    await instance.proxy.$loadScript('//js.hsforms.net/forms/v2.js')
    window.hbspt.forms.create({
      region: 'na1',
      portalId: '2803895',
      formId: form,
      target: `#${CSS.escape(id)}`,
      translations: {
        en: {
          required: 'Required',
          submissionErrors: {
            MISSING_REQUIRED_FIELDS: 'Please complete all required fields',
          },
        },
      },
      onFormReady: ($form) => {
        node.value = $form
        loading.value = false
      },
      onFormSubmitted: () => {
        toast('Submitted ✉️')
        if (onSubmit !== null) {
          onSubmit()
        }
      },
    })
  }
  const listeners: Array<(event: HubSpotFormSubmitEvent<T>) => void> = []
  function onFormSubmitted(callback: (data: T) => void) {
    const listener = (event: HubSpotFormSubmitEvent<T>) => {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormSubmit' &&
        event.data.id === form
      ) {
        const object = {} as T
        for (const field of event.data.data) {
          object[field.name] = field.value
        }
        callback(object)
      }
    }
    listeners.push(listener)
    window.addEventListener('message', listener)
  }

  onMounted(init)

  onUnmounted(() => {
    for (const listener of listeners) {
      window.removeEventListener('message', listener)
    }
  })

  return {
    node,
    id,
    loading,
    onFormSubmitted,
  }
}
