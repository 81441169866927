
import type { PropType, Component } from 'vue'
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import { Variant } from './Alert.types'
import IconError from './icons/IconError'
import IconInfo from './icons/IconInfo'
import IconSuccess from './icons/IconSuccess'
import IconWarning from './icons/IconWarning'

const ICONS: Record<Variant, Component> = {
  [Variant.Danger]: IconError,
  [Variant.Info]: IconInfo,
  [Variant.Success]: IconSuccess,
  [Variant.Warning]: IconWarning,
}

export default Vue.extend({
  name: 'WeveAlert',
  functional: true,
  props: {
    variant: {
      type: String as PropType<Variant>,
      default: Variant.Danger,
      validator: (val: Variant) => Object.values(Variant).includes(val),
    },
    shadow: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, children, data }) {
    return h(
      'div',
      mergeData(data, {
        attrs: {
          role: 'alert',
        },
        staticClass: 'app-alert',
        class: {
          [`app-alert--variant--${props.variant}`]: true,
        },
      }),
      [
        h(ICONS[props.variant]),
        h('div', { staticClass: 'app-alert__content' }, children),
      ],
    )
  },
})
