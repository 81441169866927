import Vue from 'vue'
import axios from 'axios'
import { ApolloError } from '@apollo/client'

import BaseService from '@/services/BaseService'
import { extractErrorMessage } from '@/util'

export default Vue.extend({
  name: 'ServerErrorMixin',
  data() {
    return {
      serverError: '',
    }
  },
  computed: {
    hasServerError(): boolean {
      return this.serverError !== ''
    },
  },
  methods: {
    handleServerError(e: unknown) {
      if (e instanceof axios.Cancel) {
        return
      }
      if (BaseService.isServerError(e)) {
        this.serverError = extractErrorMessage(e)
      } else {
        throw e
      }
    },
    handleAplloError(e: unknown) {
      if (e instanceof ApolloError) {
        for (const error of e.graphQLErrors) {
          this.serverError += `${error.message}\n`
        }
      } else {
        throw e
      }
    },
    resetServerError() {
      this.serverError = ''
    },
  },
})
