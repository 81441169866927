const ActionTypes = {
  LOGIN: 'LOGIN',
  ENSURE_AUTHENTICATED: 'ENSURE_AUTHENTICATED',
  LOGOUT: 'LOGOUT',
  COMPLETE_PASSWORD: 'COMPLETE_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUBMIT: 'FORGOT_PASSWORD_SUBMIT',
  UPDATE_USER: 'UPDATE_USER',
}

export default ActionTypes
