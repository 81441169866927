
import { defineComponent } from 'vue'

import { ThemeProvider } from '@/providers'
import { useHubSpotConversation } from '@/use'

export default defineComponent({
  name: 'App',
  components: {
    ThemeProvider,
  },
  setup() {
    useHubSpotConversation()
  },
})
