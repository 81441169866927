
import type { VNode } from 'vue'

import type { VueComponentListeners } from '../../types'
import { ThemeMixin } from '../../mixins'
import { generateId } from '../../utils'
import WeveInlineHelp from '../InlineHelp/InlineHelp.vue'

export default ThemeMixin.extend({
  name: 'AppRadio',
  inheritAttrs: false,
  props: {
    checked: {
      type: Boolean,
    },
    id: {
      type: String,
      default() {
        return generateId()
      },
    },
    name: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputListeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        change: (event: InputEvent) => {
          if ((event.target as HTMLInputElement).checked) {
            this.$emit('change', this.value)
          }
        },
      }
    },
  },
  render(h): VNode {
    return h(
      'div',
      {
        class: {
          'app-radio--disabled': this.disabled,
          'w-theme--light': this.light,
          'w-theme--dark': this.dark,
        },
        staticClass: 'app-radio',
      },
      [
        h('input', {
          attrs: {
            ...this.$attrs,
            id: this.id,
            name: this.name,
            value: this.value,
            type: 'radio',
            disabled: this.disabled,
          },
          domProps: {
            checked: this.checked,
          },
          staticClass: 'app-radio__input',
          on: this.inputListeners,
        }),
        h(
          'label',
          { attrs: { for: this.id }, staticClass: 'app-radio__label' },
          [
            h('span', { staticClass: 'app-radio__imitator' }),
            h(
              'span',
              {
                staticClass: 'app-radio__label-text',
              },
              this.label,
            ),
            this.help &&
              h(WeveInlineHelp, { staticClass: 'app-radio__help' }, this.help),
          ],
        ),
      ],
    )
  },
})
