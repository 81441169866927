import type { PropType } from 'vue'
import Vue from 'vue'

import type { FormControlMessageVariant } from '../components/FormControlMessage/FormControlMessage.types'
import { FORM_CONTROL_MESSAGE_VARIANTS } from '../components/FormControlMessage/FormControlMessage.types'

export interface MessageMixinProps {
  message?: string
  messageVariant?: FormControlMessageVariant
}

export const props = {
  message: {
    type: String,
    default: undefined,
  },
  messageVariant: {
    type: String as PropType<FormControlMessageVariant>,
    default: undefined,
    validator: (val: FormControlMessageVariant): boolean =>
      FORM_CONTROL_MESSAGE_VARIANTS.includes(val),
  },
} as const

export default Vue.extend({
  name: 'MessageMixin',
  props,
})
