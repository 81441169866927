
import type { WithRefs } from 'vue-typed-refs'

import { ConfirmMixin } from '@/mixins'

import { WeveRow, WeveCol, WeveButton, WeveDialog } from '@ui'

export default (
  ConfirmMixin as WithRefs<{ button: HTMLButtonElement }, typeof ConfirmMixin>
).extend({
  name: 'RescheduleEventConfirmDialog',
  components: {
    WeveRow,
    WeveCol,
    WeveButton,
    WeveDialog,
  },
  props: {
    title: {
      type: String,
      default: 'Are you sure?',
    },
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    onShown() {
      this.$refs.button.focus()
    },
  },
})
