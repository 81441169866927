import { differenceInMinutes } from 'date-fns'
import type { Ref } from 'vue'
import { computed } from 'vue'

import type { SessionForSessionRescheduleFragment } from '@/graphql/types'
import { SessionType } from '@/graphql/types'

import usePermissions from '../usePermissions'

const ONE_DAY_IN_HOURS = 24

export default function useSessionReschedule(
  session: Ref<SessionForSessionRescheduleFragment>,
) {
  const { isStaff } = usePermissions()

  const disabled = computed(() => {
    const now = new Date()
    if (session.value.diy) {
      const endDate = session.value.endDate ?? null
      if (endDate === null) return false
      return differenceInMinutes(endDate, now) / 60 < 3
    }
    if (session.value.size === 0 && session.value.type === SessionType.REMOTE)
      return true
    if (isStaff.value) return false
    const startDate = session.value.startDate
    if (
      startDate !== null &&
      startDate !== undefined &&
      differenceInMinutes(now, startDate) / 60 < ONE_DAY_IN_HOURS
    ) {
      return false
    }
    return session.value.complete
  })

  return {
    disabled,
  }
}
