import axios from 'axios'
import type { AxiosInstance, AxiosResponse } from 'axios'

import type { Nullable, Undefined } from '@/types'

import type { Refresher } from './interfaces/Refresher'

type Response = {
  token: string
}

export default class RefresherImpl implements Refresher {
  private _refreshRequest: Nullable<Promise<AxiosResponse<Response>>> = null

  constructor(private readonly client: AxiosInstance) {}

  async refresh(refreshToken: string): Promise<Undefined<string>> {
    if (this._refreshRequest === null) {
      this._refreshRequest = this.client.post<Response>(
        `${process.env.VUE_APP_API_BASE_URL}/auth/refresh`,
        {
          refreshToken,
        },
      )
    }
    try {
      const {
        data: { token },
      } = await this._refreshRequest
      return token
    } catch (e) {
      // TODO: 401
      if (axios.isAxiosError(e)) {
        return undefined
      }
      throw e
    } finally {
      this._refreshRequest = null
    }
  }
}
