import isURL from 'is-url'
import { extend, setInteractionMode } from 'vee-validate'
import {
  required,
  email,
  min,
  max,
  confirmed,
  regex,
  min_value,
} from 'vee-validate/dist/rules'
// @ts-expect-error TODO(Andrew): remove
import en from 'vee-validate/dist/locale/en.json'

import PasswordValidator from '@/modules/validation/PasswordValidator'

setInteractionMode('passive')

extend('required', { ...required, message: 'Required' })
extend('email', { ...email, message: en.messages.email })
extend('min', { ...min, message: en.messages.min })
extend('max', { ...max, message: en.messages.max })
extend('min_value', { ...min_value, message: en.messages.min_value })
extend('confirmed', { ...confirmed, message: en.messages.confirmed })
extend('regex', { ...regex, message: 'Invalid' })
extend('lowercase', {
  validate: (value: string): boolean => value.toLowerCase() === value,
  message: 'The {_field_} field must be lowercase',
})
extend('url', {
  validate: (value: string): boolean => isURL(value),
  message: 'The {_field_} field must be a valid URL',
})

extend('password', {
  validate(value: string) {
    return PasswordValidator.validate(value)
  },
  message: 'Password does not match requirements',
})
