import { Role } from '@/graphql/types'

interface R {
  role: Role
}

const STAFF_ROLES = new Set([
  Role.ADMIN,
  Role.CUSTOMER_SUCCESS_MANAGER,
  Role.EVENT_PRODUCER,
  Role.SALESPERSON,
  Role.PLANT,
  Role.HOST,
])

export function userIsStaff(user: R): boolean {
  return STAFF_ROLES.has(user.role)
}
