import type { ManagerOptions, SocketOptions, Socket } from 'socket.io-client'
import io from 'socket.io-client'

import type { Undefined } from '@/types'

class EventListener {
  constructor(
    private readonly socket: Socket,
    private readonly event: Parameters<Socket['on']>[0],
    private readonly listener: Parameters<Socket['on']>[1],
  ) {
    socket.on(event, listener)
  }

  off() {
    this.socket.off(this.event, this.listener)
  }
}

export default class SocketContainer {
  private static instance: SocketContainer

  private connection: Undefined<Socket>

  private subscriptions = new Map<string, EventListener[]>()

  constructor(private token?: string) {}

  get socket(): Socket {
    if (this.connection === undefined) {
      const options: Partial<ManagerOptions & SocketOptions> = {
        transports: ['websocket'],
        upgrade: false,
      }
      if (this.token !== undefined) {
        options['auth'] = (cb) => {
          cb({
            token: `Bearer ${this.token}`,
          })
        }
      }
      const { VUE_APP_WEBSOCKET_URL } = process.env
      if (VUE_APP_WEBSOCKET_URL === undefined) {
        throw new Error(
          '`VUE_APP_WEBSOCKET_URL` is not provided, please make sure you have `VUE_APP_WEBSOCKET_URL` set in your environment',
        )
      }
      this.connection = io(VUE_APP_WEBSOCKET_URL, options)
    }
    return this.connection
  }

  emit(...args: Parameters<Socket['emit']>) {
    this.socket.emit(...args)
  }

  subscribe(
    scope: string,
    event: Parameters<Socket['on']>[0],
    listener: Parameters<Socket['on']>[1],
  ): void {
    const scopeSubscriptions = this.subscriptions.get(scope) ?? []
    scopeSubscriptions.push(new EventListener(this.socket, event, listener))
    this.subscriptions.set(scope, scopeSubscriptions)
  }

  unsubscribeScope(scope: string) {
    const scopeSubscriptions = this.subscriptions.get(scope) ?? []
    this.subscriptions.set(scope, [])
    for (const eventListener of scopeSubscriptions) {
      eventListener.off()
    }
  }

  static getInstance({
    token,
  }: {
    token?: Undefined<string>
  }): SocketContainer {
    if (SocketContainer.instance === undefined) {
      SocketContainer.instance = new SocketContainer(token)
    }
    return SocketContainer.instance
  }
}
