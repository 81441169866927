import type { GetterTree } from 'vuex'

import type { WorkspaceId } from '@/types'
import { Role, PermissionRole, UserPermission } from '@/graphql/types'
import { userIsStaff } from '@/modules/auth/helpers'

import type { State as RootState } from '../../state'
import type { State } from './state'
import GetterTypes from './getter-types'

const MAP: Record<Role, number> = {
  [Role.ADMIN]: 0,
  [Role.EVENT_PRODUCER]: 1,
  [Role.CUSTOMER_SUCCESS_MANAGER]: 2,
  [Role.SALESPERSON]: 3,
  [Role.HOST]: 4,
  [Role.PLANT]: 4,
  [Role.BOOKING_USER]: 5,
}

const ACCESS_LEVEL = {
  [PermissionRole.VIEWER]: new Set([
    PermissionRole.VIEWER,
    PermissionRole.EDITOR,
    PermissionRole.ADMIN,
  ]),
  [PermissionRole.EDITOR]: new Set([
    PermissionRole.VIEWER,
    PermissionRole.EDITOR,
  ]),
  [PermissionRole.ADMIN]: new Set([PermissionRole.ADMIN]),
}

const getters: GetterTree<State, RootState> = {
  [GetterTypes.AUTHENTICATED](state): boolean {
    return state.user !== null
  },
  [GetterTypes.IS_WORKSPACE_SELECTED](_, __, rootState): boolean {
    return rootState.workspaceId !== undefined
  },
  [GetterTypes.USER_ID](state): string | undefined {
    return state.user?.id
  },
  [GetterTypes.ROLE](state): Role | undefined {
    return state.user?.role
  },
  [GetterTypes.IS_STAFF]({ user }) {
    if (user === null) return false
    return userIsStaff(user)
  },
  [GetterTypes.IS_ADMIN](state): boolean {
    return state.user?.role === Role.ADMIN
  },
  [GetterTypes.IS_EVENT_PRODUCER](state) {
    return state.user?.role === Role.EVENT_PRODUCER
  },
  [GetterTypes.IS_SALESPERSON](state) {
    return state.user?.role === Role.SALESPERSON
  },
  [GetterTypes.IS_HOST](state) {
    return state.user?.role === Role.HOST
  },
  [GetterTypes.IS_BOOKING_USER](state) {
    if (state.user === null) return true
    return state.user.role === Role.BOOKING_USER
  },
  [GetterTypes.HAS_APPROPRIATE_ROLE](state) {
    const { user } = state
    if (user === null) return () => false
    return (role: Role) => MAP[user.role] <= MAP[role]
  },
  [GetterTypes.HAS_ACCESS](
    _,
    getters,
  ): (workspaceId: WorkspaceId, role: PermissionRole) => boolean {
    return (workspaceId: WorkspaceId, role: PermissionRole) => {
      const map: Map<WorkspaceId, Set<PermissionRole>> = getters[
        GetterTypes.PERMISSIONS
      ]
      const roles = map.get(workspaceId)
      if (roles === undefined) return false
      const required = ACCESS_LEVEL[role]
      for (const role of roles) {
        if (required.has(role)) return true
      }
      return false
    }
  },
  [GetterTypes.PERMISSIONS]({ user }) {
    const map = new Map<WorkspaceId, Set<PermissionRole>>()
    if (user === null) return map
    for (const permission of user.permissions) {
      const set = map.get(permission.workspaceId) ?? new Set()
      set.add(permission.role)
      map.set(permission.workspaceId, set)
    }
    return map
  },
  [GetterTypes.HAS_STAFF_PERMISSIONS](state) {
    if (state.user === null) return false
    return (
      state.user.permission === UserPermission.STAFF ||
      state.user.role === Role.ADMIN
    )
  },
}

export default getters
