
import type { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import type { FormControlHintSize } from './FormControlHint.types'
import { FORM_CONTROL_HINT_SIZES } from './FormControlHint.types'

export default ThemeMixin.extend({
  name: 'FormControlHint',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    size: {
      type: String as PropType<FormControlHintSize>,
      default: undefined,
      validator: (val: FormControlHintSize) =>
        FORM_CONTROL_HINT_SIZES.includes(val),
    },
  },
  render(h, { props, children, data, injections }) {
    return h(
      props.tag,
      mergeData(data, {
        class: {
          [`app-form-control-hint--size--${props.size}`]:
            props.size !== undefined,
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
        staticClass: 'app-form-control-hint',
      }),
      children,
    )
  },
})
