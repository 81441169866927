import Vue, { PropType } from 'vue'
import {
  Instance as PopperInstance,
  Options as PopperOptions,
  createPopper,
} from '@popperjs/core'
import { merge } from 'lodash-es'

export type Options = PopperOptions

const DEFAULT_OPTIONS: Partial<Options> = {
  strategy: 'fixed',
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createPopperMixin<T extends Partial<Options>>(options?: T) {
  const PopperMixin = Vue.extend({
    props: {
      options: {
        type: Object as PropType<Partial<Options>>,
        default() {
          return options ?? {}
        },
      },
    },
    data() {
      return {
        $_popperInstance: null as PopperInstance | null,
      }
    },
    beforeDestroy() {
      this.destroyPopperInstance()
    },
    methods: {
      createPopperInstance(
        target: Element,
        element: HTMLElement,
        options: Partial<Options> = {},
      ) {
        this.$_popperInstance = createPopper(
          target,
          element,
          merge({}, DEFAULT_OPTIONS, this.options, options),
        )
      },
      destroyPopperInstance() {
        const { $_popperInstance } = this
        if ($_popperInstance) {
          $_popperInstance.destroy()
          this.$_popperInstance = null
        }
      },
    },
  })
  return PopperMixin
}

export default createPopperMixin
