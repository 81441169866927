import type { PluginObject } from 'vue'

const TimezonePlugin: PluginObject<never> = {
  install(Vue) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    Vue.prototype.$timezone = timezone
  },
}

export default TimezonePlugin
