import { useEventListener } from '@vueuse/core'

import { isInputtable } from '@/util'

export default function useKeyPressCallback(
  keyName: string,
  callback: () => void,
) {
  async function handleKeyPress(event: KeyboardEvent) {
    if (event.target instanceof HTMLElement === false) return
    if (isInputtable(event.target)) return
    if (event.code !== keyName || event.metaKey || event.ctrlKey) return
    callback()
  }

  useEventListener('keypress', handleKeyPress)
}
