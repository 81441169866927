import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import './Stepper.scss'

export default Vue.extend({
  name: 'WeveStepper',
  functional: true,
  render(h, { data, children }) {
    return h(
      'nav',
      mergeData(data, {
        attrs: { 'aria-label': 'Progress' },
        staticClass: 'weve-stepper',
      }),
      [h('ol', { staticClass: 'weve-stepper__list' }, children)],
    )
  },
})
