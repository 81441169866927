const ActionTypes = {
  LOGIN: 'auth/LOGIN',
  LOGOUT: 'auth/LOGOUT',
  ENSURE_AUTHENTICATED: 'auth/ENSURE_AUTHENTICATED',
  GET_SESSION: 'auth/GET_SESSION',
  COMPLETE_PASSWORD: 'auth/COMPLETE_PASSWORD',
  FORGOT_PASSWORD: 'auth/FORGOT_PASSWORD',
  UPDATE_USER: 'auth/UPDATE_USER',
  FORGOT_PASSWORD_SUBMIT: 'auth/FORGOT_PASSWORD_SUBMIT',
  REFRESH_TOKEN: 'auth/REFRESH_TOKEN',
}

export default ActionTypes
