
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import WeveIcon from '../Icon/Icon.vue'

import { executeListener } from '../../utils'

export default Vue.extend({
  name: 'AppAnnouncementWidget',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
  render(h, { props, children, data, listeners }) {
    return h(
      props.tag,
      mergeData(data, { staticClass: 'app-announcement-widget' }),
      [
        h('div', { staticClass: 'app-announcement-widget__inner' }, [
          h(
            'div',
            { staticClass: 'app-announcement-widget__content' },
            children,
          ),
          h(
            'button',
            {
              attrs: { type: 'button', 'aria-label': 'Close' },
              staticClass: 'app-announcement-widget__button',
              on: {
                click() {
                  executeListener(listeners.close)
                },
              },
            },
            [h(WeveIcon, { props: { name: 'close' } })],
          ),
        ]),
      ],
    )
  },
})
