export class NotEnoughCreditsException extends Error {
  constructor(public readonly missing: number) {
    super(`Not enough credits. Missing "${missing}" credits.`)
  }
}

export class NotEnoughConsumablesException extends Error {
  constructor(public readonly missing: number) {
    super(`Not enough consumables. Missing "${missing}" consumables.`)
  }
}

export class UnknownPriceZoneException extends Error {
  constructor() {
    super('Unknown price zone')
  }
}

export class NotEnoughHostsException extends Error {
  constructor(public readonly missing: number, public readonly fee: number) {
    super(`Not enough hosts. Missing "${missing}" hosts`)
  }
}

export class SubscribersOnlyException extends Error {
  constructor() {
    super('Subscribers Only Operation')
  }
}

export class NotEnoughCreditsAndHostsException extends Error {
  constructor(
    private readonly notEnoughCreditsException: NotEnoughCreditsException,
    private readonly notEnoughHostsException: NotEnoughHostsException,
  ) {
    super(
      `${notEnoughHostsException.message}\n${notEnoughHostsException.message}`,
    )
  }

  get credits(): number {
    return this.notEnoughCreditsException.missing
  }

  get hosts(): number {
    return this.notEnoughHostsException.missing
  }

  get fee(): number {
    return this.notEnoughHostsException.fee
  }
}
