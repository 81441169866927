import type { OpenAiModel } from '@/graphql/types'
import BaseService from './BaseService'

export type AiPromptTestResult = { subject: string; body: string }
export type AiPromptTestPayload = {
  name: string
  model: OpenAiModel
  meetingLink: string
  inquiry: string
  prompt: string
}

export default class AiPromptService extends BaseService {
  async testPrompt(payload: AiPromptTestPayload): Promise<AiPromptTestResult> {
    const { data } = await this.client.post(`/ai/prompt/test`, payload)
    return data
  }
}
