import { computed } from 'vue'

import { SystemKey } from '@/graphql/types'

import useSystem from '../useSystem/useSystem'

export default function useTags() {
  const { value, loading } = useSystem(SystemKey.TAGS)

  const tags = computed<string[]>({
    get: () => {
      if (value.value === undefined) {
        return []
      }
      return JSON.parse(value.value)
    },
    set: (v: string[]) => {
      value.value = JSON.stringify(v)
    },
  })

  return {
    tags,
    loading,
  }
}
