import type {
  Session,
  SessionGroup,
  SessionAssignment,
  SessionRelation,
  SessionIntegration,
} from '@/graphql/types'
import { SessionCreationType } from '@/graphql/types'

export { SessionDocument } from '@/graphql/types'
export type SessionId = Session['id']
export type SessionAssignmentId = SessionAssignment['id']
export type SessionIntegrationId = SessionIntegration['id']
export type SessionGroupId = SessionGroup['id']
export type SessionRelationId = SessionRelation['id']

export const SESSION_CREATION_TYPE_TO_DISPLAY_NAME: Record<
  SessionCreationType,
  string
> = {
  [SessionCreationType.HUBSPOT]: 'HubSpot',
  [SessionCreationType.MANUAL]: 'Manual',
}
