
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveTableTr',
  functional: true,
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, children, data }) {
    return h(
      'tr',
      mergeData(data, {
        staticClass: 'weve-table__row',
        class: {
          'weve-table__row--active': props.active,
        },
      }),
      children,
    )
  },
})
