import type { Argument } from 'classnames'

export type ClassDefinition = Argument

export const STATUSES = [
  'success',
  'danger',
  'warning',
  'undetermined',
  'white',
] as const

export type Status = (typeof STATUSES)[number]

type Listener = (...args: any[]) => void
export type VueComponentListeners = Record<string, Listener | Listener[]>

export type FullDateRange = {
  start: Date
  end: Date
}

export type TextInputValue = string | number | null | undefined

export type Theme = 'light' | 'dark'
