import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'SpinnerContainer',
  functional: true,
  render(h, { data, children }) {
    return h(
      'div',
      mergeData(data, {
        staticClass:
          'absolute top-0 left-0 flex justify-center items-center w-full h-full',
      }),
      children,
    )
  },
})
