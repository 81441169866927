import type Vue from 'vue'
import type { ScopedSlotChildren } from 'vue/types/vnode'
import { isFunction } from 'lodash-es'
import { customAlphabet } from 'nanoid/non-secure'
import { alphanumeric } from 'nanoid-dictionary'

import type { Status } from '../types'

export function executeListener(
  // eslint-disable-next-line @typescript-eslint/ban-types
  argument: Function | Function[],
  ...args: unknown[]
): void {
  if (typeof argument === 'function') {
    argument(...args)
  } else {
    argument.forEach((fn) => fn(...args))
  }
}

export function hasSlot(this: Vue, name = 'default'): boolean {
  return (
    this.$slots[name] !== undefined || this.$scopedSlots[name] !== undefined
  )
}

export function normalizeSlot(
  this: Vue,
  name = 'default',
  scope = {},
): ScopedSlotChildren {
  const slot = this.$scopedSlots[name] || this.$slots[name]
  return isFunction(slot) ? slot(scope) : slot
}

const idGenerator = customAlphabet(alphanumeric, 8)
export const generateId = idGenerator
export const uid = idGenerator

export function getMessageFromStatus(status: Status): string {
  switch (status) {
    case 'success':
      return 'Valid'
    case 'danger':
      return 'Invalid'
    case 'warning':
      return 'Required'
    case 'undetermined':
      return 'Undetermined'
    default:
      return ''
  }
}
