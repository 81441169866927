import mixins from 'vue-typed-mixins'

import type { FormControlMessageVariant } from '../components/FormControlMessage/FormControlMessage.types'
import type { Status } from '../types'
import MessageMixin, { type MessageMixinProps } from './message.mixin'
import StateMixin, { type StateMixinProps } from './state.mixin'

export interface StateMessageMixinProps
  extends MessageMixinProps,
    StateMixinProps {}

export default mixins(StateMixin, MessageMixin).extend({
  name: 'StateMessageMixin',
  computed: {
    $_status(): Status | undefined {
      if (this.messageVariant !== undefined) return this.messageVariant
      if (this.valid === true) return 'success'
      if (this.invalid === true) return 'danger'
      if (this.required === true) return 'warning'
      return undefined
    },
    $_message_variant(): FormControlMessageVariant | undefined {
      if (this.messageVariant !== undefined) return this.messageVariant
      return this.$_status
    },
  },
})
