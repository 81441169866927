enum Token {
  TOKENS_MANAGER = 'TOKENS_MANAGER',
  HTTP_CLIENT = 'HTTP_CLIENT',
  AUTH_SERVICE = 'AUTH_SERVICE',
  PAYMENT_SERVICE = 'PAYMENT_SERVICE',
  SESSION_SERVICE = 'SESSION_SERVICE',
  SESSION_GROUP_SERVICE = 'SESSION_GROUP_SERVICE',
  WORKSPACE_SERVICE = 'WORKSPACE_SERVICE',
  SCHEDULER_SERVICE = 'SCHEDULER_SERVICE',
  CART_SERVICE = 'CART_SERVICE',
  TEMPLATE_SERVICE = 'TEMPLATE_SERVICE',
  EMAIL_SERVICE = 'EMAIL_SERVICE',
  PLANT_SERVICE = 'PLANT_SERVICE',
  PRICING_SERVICE = 'PRICING_SERVICE',
  AI_PROMPT_SERVICE = 'AI_PROMPT_SERVICE',
  ASSIGNMENT_SERVICE = 'ASSIGNMENT_SERVICE',
}

export default Token
