
import Vue, { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'
import {
  FormControlMessageVariant,
  FORM_CONTROL_MESSAGE_VARIANTS,
} from './FormControlMessage.types'

export default Vue.extend({
  name: 'FormControlMessage',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    variant: {
      type: String as PropType<FormControlMessageVariant>,
      required: true,
      validator: (val: FormControlMessageVariant) =>
        FORM_CONTROL_MESSAGE_VARIANTS.includes(val),
    },
  },
  render(h, { props, children, data }) {
    return h(
      props.tag,
      mergeData(data, {
        class: {
          [`app-form-control-message--variant--${props.variant}`]: true,
        },
        staticClass: 'app-form-control-message',
      }),
      children,
    )
  },
})
