import { Role, ModifierRole } from '@/graphql/types'

export const MODIFIER_CAR = 'CAR'
export const MODIFIER_BICYCLE = 'BICYCLE'
export const MODIFIER_COSTUMES = 'COSTUMES'

export const MODIFIER_ROLE_TO_DISPLAY_NAME: Record<ModifierRole, string> = {
  [ModifierRole.HOST]: 'Host',
  [ModifierRole.EVENT_PRODUCER]: 'Event Producer',
  [ModifierRole.PLANT]: 'Plant',
}

export const ROLE_TO_MODIFIER_ROLE: Partial<Record<Role, ModifierRole>> = {
  [Role.HOST]: ModifierRole.HOST,
  [Role.EVENT_PRODUCER]: ModifierRole.EVENT_PRODUCER,
  [Role.PLANT]: ModifierRole.PLANT,
}
