import Vue from 'vue'

export default Vue.extend({
  name: 'IconWarning',
  functional: true,
  render(h) {
    return h(
      'svg',
      {
        staticClass: 'app-alert__icon',
        attrs: { viewBox: '0 -960 960 960' },
      },
      [
        h('path', {
          attrs: {
            d: 'm40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 29-11t11-29q0-17-11-28t-29-12q-17 0-28 12t-12 28q0 17 12 29t28 11Zm-40-120h80v-200h-80v200Zm40-100Z',
          },
        }),
      ],
    )
  },
})
