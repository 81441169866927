<script>
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveDefinitionItem',
  functional: true,
  render(h, { children, data }) {
    return h(
      'div',
      mergeData(data, { staticClass: 'app-definition-list__item' }),
      children,
    )
  },
})
</script>

<style lang="scss">
@use '../../../../styles/variables';

.app-definition-list {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: {
      top: 6px;
      bottom: 6px;
    }

    dt {
      color: var(--weve-definition-list-title-color);
    }

    dd {
      margin-left: 0;
      text-align: right;
      color: var(--weve-definition-list-definition-color);
    }

    a {
      text-decoration: none;
      color: var(--weve-definition-list-definition-color);

      &:hover,
      &:focus {
        color: var(--weve-definition-list-link-hover-color);
        text-decoration: underline;
      }
    }
  }

  &.w-theme--dark {
    --weve-definition-list-title-color: #{variables.$color-neutral-7};
    --weve-definition-list-definition-color: #{variables.$color-text-light};
    --weve-definition-list-link-hover-color: #{variables.$color-accent};
  }

  &.w-theme--light {
    --weve-definition-list-title-color: #{variables.$color-neutral-5};
    --weve-definition-list-definition-color: #{variables.$color-text-dark};
    --weve-definition-list-link-hover-color: #{variables.$color-main-purple};
  }
}
</style>
