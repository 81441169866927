import { computed } from 'vue'

import type { Undefined, WorkspaceId } from '@/types'
import MutationTypes from '@/store/mutation-types'

import useStore from './useStore'

export default function useWorkspaceId<
  T extends Undefined<WorkspaceId> = WorkspaceId,
>() {
  const { store } = useStore()
  const workspaceId = computed<T>({
    get: () => store.state.workspaceId as T,
    set: (id) => {
      store.commit(MutationTypes.SET_WORKSPACE_ID, id)
    },
  })
  return {
    workspaceId,
  }
}
