
import { ValidationProvider } from 'vee-validate'
import type { WithRefs } from 'vue-typed-refs'

import { ValidationMixin } from '../../mixins'

import TextField from '../TextField/TextField.vue'

export type Refs = {
  validator: InstanceType<typeof ValidationProvider>
  input: InstanceType<typeof TextField>
}

export default (
  ValidationMixin as WithRefs<Refs, typeof ValidationMixin>
).extend({
  name: 'AppValidationTextField',
  components: {
    ValidationProvider,
    TextField,
  },
  inheritAttrs: false,
  methods: {
    /** @public */
    focus() {
      this.$refs.input.focus()
    },
    /** @public */
    validate() {
      return this.$refs.validator.validate()
    },
    /** @public */
    reset() {
      this.$refs.validator.reset()
    },
  },
})
