import type { ComponentOptions } from 'vue'
import Vue from 'vue'

import type { Nullable } from '@/types'
import { getCurrentInstance } from '@/util'

type SuccessResponse<D> = { success: true; data: D }
type ErrorResponse<E> = { success: false; error?: E }

export default function useDialogBuilder() {
  const current = getCurrentInstance()

  function mount<S, E>(
    instance: Vue,
  ): Promise<SuccessResponse<S> | ErrorResponse<E>> {
    return new Promise<SuccessResponse<S> | ErrorResponse<E>>((resolve) => {
      const div = document.createElement('div')
      document.body.appendChild(div)
      instance.$mount(div)
      const destroy = () => {
        instance.$destroy()
        instance.$el.remove()
      }
      current.proxy.$once('hook:destroyed', destroy)
      instance.$once('cancel', () => {
        destroy()
        resolve({ success: false })
      })
      instance.$once('success', (data: S) => {
        destroy()
        resolve({ success: true, data })
      })
      instance.$once('error', (error: E) => {
        destroy()
        resolve({ success: false, error })
      })
    })
  }

  function build<S = never, E = never>(
    options: ComponentOptions<Vue>,
    propsData?: Record<string, unknown>,
  ): Promise<SuccessResponse<S> | ErrorResponse<E>> {
    const DialogComponent = Vue.extend(options)
    const instance: Nullable<InstanceType<typeof DialogComponent>> =
      new DialogComponent({
        propsData,
        parent: current.proxy.$root,
      })
    return mount<S, E>(instance)
  }

  return {
    build,
    mount,
  }
}
