export const FORM_CONTROL_MESSAGE_VARIANTS = [
  'success',
  'danger',
  'warning',
  'undetermined',
  'white',
] as const

export type FormControlMessageVariant =
  (typeof FORM_CONTROL_MESSAGE_VARIANTS)[number]

export interface FormControlMessageProps {
  tag?: string
  variant: FormControlMessageVariant
}
