import { computed } from 'vue'

import type {
  SystemKey,
  GetSystemQuery,
  GetSystemQueryVariables,
} from '@/graphql/types'
import {
  useGetSystemQuery,
  useSetSystemMutation,
  useDeleteSystemMutation,
} from '@/use/graphql'

import getSystemDocument from './graphql/getSystem.graphql'

export default function useSystem(key: SystemKey) {
  const { result, loading } = useGetSystemQuery({
    key,
  })
  const { mutate: update } = useSetSystemMutation()

  const { mutate: del } = useDeleteSystemMutation({
    update: (cache, { data }) => {
      const deleted = data?.deleteSystem ?? null
      if (deleted === null) return
      cache.evict({ id: cache.identify(deleted) })
    },
  })
  const system = computed(() => result.value?.system ?? null)
  const model = computed({
    get: () => system.value?.value ?? undefined,
    set: async (value: string | undefined) => {
      if (value !== undefined) {
        await update(
          {
            input: {
              key,
              value,
            },
          },
          {
            optimisticResponse: {
              __typename: 'Mutation',
              setSystem: {
                __typename: 'System',
                key,
                value,
              },
            },
            update: (cache, { data }) => {
              const created = data?.setSystem ?? null
              if (created === null) return
              const result = cache.readQuery<
                GetSystemQuery,
                GetSystemQueryVariables
              >({
                query: getSystemDocument,
                variables: {
                  key,
                },
              })
              if (result === null) return
              cache.writeQuery<GetSystemQuery, GetSystemQueryVariables>({
                query: getSystemDocument,
                variables: {
                  key,
                },
                data: {
                  __typename: 'Query',
                  system: created,
                },
              })
            },
          },
        )

        return
      }
      await del({
        input: {
          key,
        },
      })
      return
    },
  })

  return {
    value: model,
    loading,
  }
}
