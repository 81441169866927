import type { PluginObject } from 'vue'
import type { Store } from 'vuex'

import ServicesManager from './manager'

const ServicesPlugin: PluginObject<{ store: Store<unknown> }> = {
  install(Vue, options) {
    const manager = new ServicesManager(Vue.prototype.$di)
    Vue.prototype.$services = manager
    if (options !== undefined) {
      options.store.$services = manager
    }
  },
}

export default ServicesPlugin
