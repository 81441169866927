import { Credit } from '@/graphql/types'

export const PRICE_TO_DISPLAY_NAME: Record<Credit, string> = {
  [Credit.CREDIT_0]: '0',
  [Credit.CREDIT_1]: '1',
  [Credit.CREDIT_2]: '2',
  [Credit.CREDIT_3]: '3',
  [Credit.CREDIT_4]: '4',
  [Credit.CREDIT_5]: '5',
  [Credit.CREDIT_6]: '6',
  [Credit.CREDIT_7]: '7',
  [Credit.CREDIT_8]: '8',
  [Credit.CREDIT_9]: '9',
  [Credit.CREDIT_10]: '10',
  [Credit.CREDIT_11]: '11',
  [Credit.CREDIT_12]: '12',
  [Credit.CREDIT_13]: '13',
  [Credit.CREDIT_14]: '14',
  [Credit.CREDIT_15]: '15',
}
