
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'

export default ThemeMixin.extend({
  name: 'WeveCardTitle',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'span',
    },
  },
  render(h, { props, children, data, injections }) {
    return h(
      props.tag,
      mergeData(data, {
        staticClass: 'app-card__title',
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
      }),
      children,
    )
  },
})
