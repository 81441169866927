import BaseService from '@/services/BaseService'

export default function extractErrorMessage(e: unknown): string {
  if (BaseService.isServerError(e)) {
    return e.response.data.message ?? e.message
  }
  if (e instanceof Error) {
    return e.message
  }
  return 'Something Went Wrong'
}
