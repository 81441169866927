
import type { VNode } from 'vue'
import mixins from 'vue-typed-mixins'
import VueSelect from 'vue-select'
import { createPopper } from '@popperjs/core'
import type { WithRefs } from 'vue-typed-refs'

import type { VueComponentListeners } from '@/types'

import {
  IdentifiableMixin,
  TextInputMixin,
  SelectMixin,
  ThemeMixin,
  ScrollLockMixin,
} from '../../mixins'
import { hasSlot, normalizeSlot } from '../../utils'
import WeveButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import WeveIcon from '../Icon/Icon.vue'
import WeveFormControlLabel from '../FormControlLabel/FormControlLabel.vue'
import WeveFormControlHint from '../FormControlHint/FormControlHint.vue'
import WeveStatusIndicator from '../StatusIndicator/StatusIndicator.vue'
import WeveFormControlMessage from '../FormControlMessage/FormControlMessage.vue'
import WeveSkeleton from '../Skeleton/Skeleton.vue'
import WeveLink from '../Link/Link.vue'
import WeveInlineHelp from '../InlineHelp/InlineHelp.vue'
import WeveSpinner from '../Spinner/Spinner.vue'

const SELECTED_OPTION_SELECTOR = '.vs__dropdown-option--selected'

type VueSelectRefs = {
  toggle: HTMLElement
  dropdownMenu?: HTMLElement
}

type VueSelectComponent = InstanceType<typeof VueSelect> & {
  $refs: VueSelectRefs
} & { typeAheadPointer: number }

type Refs = {
  select: VueSelectComponent
}

/**
 * @see https://github.com/sagalbot/vue-select/blob/master/src/components/Select.vue#L1134
 */
VueSelect.computed.dropdownOpen = function (this: {
  noDrop: boolean
  open: boolean
}) {
  return this.noDrop ? false : this.open
}

const Extendable = mixins(
  IdentifiableMixin,
  TextInputMixin,
  SelectMixin,
  ThemeMixin,
  ScrollLockMixin,
)

export default (Extendable as WithRefs<Refs, typeof Extendable>).extend({
  name: 'WeveSelect',
  inheritAttrs: false,
  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
      default: undefined,
    },
    dropdownClass: {
      type: String,
      default: undefined,
    },
    helpLink: {
      type: String,
      default: undefined,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    creatable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    computedPlaceholder(): string | undefined {
      const { label, placeholder } = this
      if (placeholder !== undefined) return placeholder
      if (label === undefined) return undefined
      return `Select ${label}`
    },
    listeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        open: () => {
          this.active = true
          this.$nextTick(this.lockScroll)
        },
        close: () => {
          this.active = false
          this.unlockScroll()
        },
        'search:focus': this.onSearchFocus,
      }
    },
  },
  watch: {
    loading() {
      this.$nextTick(() => {
        const { dropdownMenu } = this.$refs.select.$refs
        if (dropdownMenu !== undefined) {
          dropdownMenu.scrollTop = 9999
        }
      })
    },
  },
  // For development (always open)
  /* mounted() {
    setInterval(() => {
      this.$refs.select.open = true
    }, 500)
  }, */
  methods: {
    /** @see https://vue-select.org/guide/positioning.html#popper-js-integration */
    withPopper(
      dropdownList: HTMLElement,
      component: VueSelectComponent,
      { width }: { width: string },
    ) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width
      if (this.dropdownClass) {
        dropdownList.classList.add(this.dropdownClass)
      }
      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'vs--drop-up',
                state.placement === 'top',
              )
              dropdownList.classList.toggle(
                'vs__dropdown-menu--drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
    onSearchFocus(...args: unknown[]) {
      // @ts-expect-error TODO
      this.$listeners['search:focus']?.(...args)

      if (this.value) {
        const index = this.options.findIndex(
          (option) => option[this.optionValue] === this.value,
        )
        if (index !== -1) {
          this.$refs.select.typeAheadPointer = index
        }
      }

      this.$nextTick(() => {
        const { dropdownMenu } = this.$refs.select.$refs
        if (dropdownMenu !== undefined) {
          const selectedOption = dropdownMenu.querySelector<HTMLLIElement>(
            SELECTED_OPTION_SELECTOR,
          )
          if (selectedOption !== null) {
            dropdownMenu.scrollTop =
              selectedOption.offsetTop - dropdownMenu.offsetHeight / 2 + 20
          }
        }
      })
    },
    onCreateClick() {
      this.$emit('create')
    },
    hasSlot,
    normalizeSlot,
  },
  render(h): VNode {
    return h(
      'div',
      {
        class: {
          'app-select--disabled': this.disabled,
          'app-select--invalid': this.invalid,
          'app-select--readonly': this.readonly,
          'app-select--loading': this.loading,
          'app-select--active': this.active,
          'app-form-control': true,
          'w-theme--light': this.light,
        },
        staticClass: 'app-select',
      },
      [
        h(
          'div',
          {
            staticClass: 'app-select__top',
            class: { 'sr-only': this.hideLabel },
          },
          [
            h(
              WeveFormControlLabel,
              {
                attrs: { for: this.id },
                staticClass: 'app-select__label',
              },
              this.label,
            ),
            this.hasSlot('append-label')
              ? this.normalizeSlot('append-label')
              : undefined,
            this.help !== undefined
              ? h(
                  WeveInlineHelp,
                  {
                    props: { link: this.helpLink },
                    staticClass: 'app-select__help',
                  },
                  this.help,
                )
              : undefined,
          ],
        ),
        h('div', { staticClass: 'app-select__holder' }, [
          h('div', { staticClass: 'app-select__inner' }, [
            h(VueSelect, {
              ref: 'select',
              props: {
                ...this.$attrs,
                value: this.value,
                options: this.options,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                reduce: (option: any) => {
                  if (typeof option === 'object' && option !== null) {
                    return option[this.optionValue]
                  }
                  return option
                },
                inputId: this.id,
                label: this.optionLabel,
                getOptionLabel: this._getOptionLabel,
                placeholder: this.computedPlaceholder,
                disabled: this.disabled || this.readonly || this.processing,
                transition: '',
                searchable: this.searchable,
                clearable: this.clearable,
                loading: this.loading || this.processing,
                calculatePosition: this.appendToBody
                  ? this.withPopper
                  : undefined,
                appendToBody: this.appendToBody,
              },
              scopedSlots: {
                ...this.$scopedSlots,
                option: (option) => {
                  return h(
                    'div',
                    {
                      staticClass: 'app-select__option',
                      class: { 'w-theme--light': this.light },
                      attrs: { title: this._getOptionLabel(option) },
                    },
                    [
                      h(
                        'span',
                        undefined,
                        this.hasSlot('option')
                          ? this.normalizeSlot('option', option)
                          : this._getOptionLabel(option),
                      ),
                      this._getOptionValue(option) === this.value
                        ? h(WeveIcon, {
                            props: { name: 'check' },
                            staticClass: 'app-select__option-icon',
                          })
                        : undefined,
                    ],
                  )
                },
                'selected-option': (option) => {
                  if (this.processing)
                    return h(WeveSkeleton, {
                      props: { type: 'simple' },
                      style: {
                        width: '80px',
                        height: '8px',
                        borderRadius: '2px',
                      },
                    })
                  return h('span', { staticClass: 'd-block truncate' }, [
                    this.hasSlot('selected-option')
                      ? this.normalizeSlot('selected-option', { item: option })
                      : this._getOptionLabel(option) === 'null'
                        ? ''
                        : this._getOptionLabel(option),
                  ])
                },
                'open-indicator': () => {
                  return h(WeveIcon, {
                    props: { name: 'arrow-drop-down' },
                    staticClass: 'app-select__open-indicator',
                  })
                },
                'list-footer': ({ loading }: { loading: boolean }) => {
                  if (loading) {
                    return h(
                      'div',
                      undefined,
                      [0, 1, 2, 3, 4, 5, 6, 7].map(() =>
                        h(WeveSkeleton, { props: { type: 'select-option' } }),
                      ),
                    )
                  }

                  if (this.fetching) {
                    return h('div', { staticClass: 'h-8 pt-1.5 text-center' }, [
                      h(WeveSpinner, { props: { size: 'sm' } }),
                    ])
                  }

                  if (this.hasMore) {
                    return h(
                      'li',
                      { staticClass: 'h-8 py-2 mb-1 fz-0 text-center' },
                      [
                        h(
                          WeveLink,
                          {
                            attrs: { role: 'button', href: '#' },
                            on: {
                              click: (e: Event) => {
                                e.preventDefault()
                                this.$emit('more')
                              },
                            },
                          },
                          'Load More',
                        ),
                      ],
                    )
                  }

                  return null
                },
              },
              on: this.listeners,
            }),
            this.$_status !== undefined || this.creatable
              ? h(
                  'div',
                  {
                    staticClass: 'app-select__append-inner',
                  },
                  [
                    this.$_status === undefined
                      ? h(WeveStatusIndicator, {
                          props: { status: 'warning' },
                        })
                      : null,
                    this.creatable
                      ? h(WeveButtonIcon, {
                          staticClass: 'app-select__create',
                          props: { icon: 'plus', label: 'Create' },
                          on: {
                            mousedown: (e: MouseEvent) => {
                              e.preventDefault()
                              e.stopPropagation()
                            },
                            click: this.onCreateClick,
                          },
                        })
                      : null,
                  ],
                )
              : null,
            this.message !== undefined ||
            this.hint !== undefined ||
            this.hasSlot('hint')
              ? h('div', { staticClass: 'app-select__bottom' }, [
                  this.hasSlot('hint')
                    ? this.normalizeSlot('hint')
                    : this.hint !== undefined
                      ? h(WeveFormControlHint, undefined, [this.hint])
                      : undefined,
                  this.message !== undefined &&
                  this.$_message_variant !== undefined
                    ? h(
                        WeveFormControlMessage,
                        {
                          props: { variant: this.$_message_variant },
                          staticClass: 'app-select__message',
                        },
                        [this.message],
                      )
                    : undefined,
                ])
              : undefined,
          ]),
          this.hasSlot('append')
            ? h(
                'div',
                { staticClass: 'app-select__append' },
                this.normalizeSlot('append'),
              )
            : undefined,
        ]),
        this.hasSlot('append-outer')
          ? this.normalizeSlot('append-outer')
          : undefined,
      ],
    )
  },
})
