
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'

export default ThemeMixin.extend({
  name: 'NavigationList',
  functional: true,
  render(h, { children, data, injections }) {
    return h(
      'ul',
      mergeData(data, {
        staticClass: 'app-nav-list',
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme !== 'light',
        },
      }),
      children
        .filter((child) => child.tag !== undefined)
        .map((child) => {
          return h('li', { staticClass: 'app-nav-list__item' }, [child])
        }),
    )
  },
})
