var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('MountingPortal',{attrs:{"mount-to":"body","append":""},scopedSlots:_vm._u([{key:"manual",fn:function({ to }){return [_c('div',{ref:"root",class:[
        'weve-dropdown',
        { 'weve-dropdown--simple': _vm.simple, 'weve-dropdown--open': _vm.isOpen },
      ]},[_vm._t("activator",function(){return [_c('button',_vm._g(_vm._b({ref:"activator",class:_vm.activatorClassNames,attrs:{"type":"button","disabled":_vm.disabled}},'button',_vm.activatorAttrsComputed,false),_vm.activatorOn),[_c('span',{staticClass:"weve-dropdown__activator-text"},[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]},{"title":_vm.title,"isOpen":_vm.isOpen})],2),_c('WeveIcon',{staticClass:"weve-dropdown__activator-icon",attrs:{"name":"arrow-drop-down"}})],1)]},{"attrs":_vm.activatorAttrsComputed,"on":_vm.activatorOn}),(_vm.isOpen)?_c('portal',{attrs:{"to":to}},[(_vm.isOpen === true && _vm.simple === false)?_c('div',{staticClass:"weve-dropdown__overlay",on:{"click":_vm.hide}}):_vm._e(),(_vm.simple === true)?_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
            handler: _vm.hide,
            middleware: _vm.clickOutsideMiddleware,
            isActive: _vm.clickOutside,
          }),expression:"{\n            handler: hide,\n            middleware: clickOutsideMiddleware,\n            isActive: clickOutside,\n          }"}],ref:"content",staticClass:"weve-dropdown__menu",class:{ 'w-theme--light': _vm.light, 'w-theme--dark': _vm.dark },on:{"click":_vm.hide}},[_vm._t("default")],2):[_c('div',_vm._b({ref:"content",staticClass:"weve-dropdown__content",class:[
              _vm.contentClass,
              {
                [`weve-dropdown__content--placement--${_vm.computedOptions.placement}`]: true,
                ['weve-dropdown__content--big']: _vm.simple === false,
                'w-theme--light': _vm.light,
                'w-theme--dark': _vm.dark,
              },
            ],attrs:{"aria-labelledby":_vm.activatorId}},'div',_vm.activatorAttrs,false),[_c('button-icon',{staticClass:"weve-dropdown__close",attrs:{"icon":"close","label":"Close"},on:{"click":_vm.hide}}),_vm._t("default")],2)]],2):_vm._e()],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }