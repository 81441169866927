import type { PluginObject } from 'vue'
import {
  BvToastShortcutMethod,
  TooltipPlugin,
  ToastPlugin,
} from 'bootstrap-vue'
import { merge } from 'lodash-es'

import type { ToastOptions } from '../components/Toast/types'
import ButtonIcon from '../components/ButtonIcon/ButtonIcon.vue'
import { generateId } from '../utils'

export function toast(
  this: Vue,
  message: Parameters<BvToastShortcutMethod>[0],
  options: ToastOptions = {},
  bvOptions?: Parameters<BvToastShortcutMethod>[1],
): void {
  const id = generateId()
  const h = this.$createElement
  const resolvedOptions = merge(
    {
      id,
      toaster: 'b-toaster-bottom-right',
      autoHideDelay: 4000,
      noCloseButton: true,
      toastClass:
        options.variant !== undefined ? `--${options.variant}` : undefined,
    },
    bvOptions,
  )
  this.$bvToast.toast(
    [
      h('div', undefined, [
        h('div', { staticClass: 'toast-content' }, [message]),
        h(ButtonIcon, {
          props: { icon: 'close', label: 'Close', size: 'sm', inverse: true },
          staticClass: 'app-toast__close',
          on: {
            click: () => {
              this.$bvToast.hide(id)
            },
          },
        }),
      ]),
    ],
    resolvedOptions,
  )
}

const Plugin: PluginObject<never> = {
  install(Vue) {
    Vue.use(TooltipPlugin)
    Vue.use(ToastPlugin)

    const ToastMixin = Vue.extend({
      created() {
        this.$toast = toast
      },
    })

    Vue.mixin(ToastMixin)
  },
}

export default Plugin
