import type { Ref } from 'vue'
import { ref, watch } from 'vue'
import { debounce } from 'lodash-es'

export default function useDebouncedRef<T>(r: Ref<T>) {
  const debouncedRef = ref(r.value)

  watch(
    r,
    debounce((v) => (debouncedRef.value = v), 400),
  )

  return debouncedRef
}
