
import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

export default Vue.extend({
  name: 'WeveForm',
  functional: true,
  render(h, { data, children }) {
    return h('form', mergeData({ staticClass: 'app-form' }, data), children)
  },
})
