
import Vue, { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import { Size, SIZES } from './types'

export default Vue.extend({
  name: 'SvgIcon',
  functional: true,
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<Size>,
      default: 'default' as const,
      validator: (val: Size) => SIZES.includes(val),
    },
    title: {
      type: String,
      default: undefined,
    },
  },
  render(h, { props, data }) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    let icon = require(`@/assets/icons/${props.name}.svg`)

    if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
      icon = icon.default
    }

    return h(
      'svg',
      mergeData(data, {
        attrs: { title: props.title },
        staticClass: 'svg-icon',
        class: {
          [`svg-icon--size--${props.size}`]: props.size !== 'default',
        },
      }),
      [
        props.title !== undefined ? h('title', undefined, props.title) : null,
        h('use', {
          attrs: {
            'xlink:href': icon.url,
            'xmlns:xlink': 'http://www.w3.org/1999/xlink',
          },
        }),
      ],
    )
  },
})
