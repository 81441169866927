import { PermissionRole, UserPermission } from '@/graphql/types'

export const PERMISSION_ROLE_TO_DISPLAY_NAME: Record<PermissionRole, string> = {
  [PermissionRole.ADMIN]: 'Admin',
  [PermissionRole.EDITOR]: 'Editor',
  [PermissionRole.VIEWER]: 'Viewer',
}

export const USER_PERMISSION_TO_DISPLAY_NAME: Record<UserPermission, string> = {
  [UserPermission.ADMIN]: 'Admin',
  [UserPermission.STAFF]: 'Staff',
  [UserPermission.HOST]: 'Host',
  [UserPermission.BOOKING_USER]: 'Booking User',
}
