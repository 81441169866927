import type { MutationTree } from 'vuex'

import type {
  UserForStoreFragment,
  UserWithEmailFragment,
} from '@/graphql/types'
import { namedOperations } from '@/graphql/types'
import apolloClient from '@/apollo'

import userWithEmailDocument from './graphql/userWithEmail.graphql'
import type { State } from './state'
import MutationTypes from './mutation-types'

const mutations: MutationTree<State> = {
  [MutationTypes.SET_USER](state, user: UserForStoreFragment) {
    state.user = user
  },
  [MutationTypes.SET_EMAIL](_, { id, email }: { id: string; email: string }) {
    const user = apolloClient.cache.readFragment<UserWithEmailFragment>({
      fragment: userWithEmailDocument,
      fragmentName: namedOperations.Fragment.userWithEmail,
      id: apolloClient.cache.identify({ id, __typename: 'User' }),
    })
    if (user === null) return
    apolloClient.cache.writeFragment<UserWithEmailFragment>({
      fragment: userWithEmailDocument,
      fragmentName: namedOperations.Fragment.userWithEmail,
      id: apolloClient.cache.identify({ id, __typename: 'User' }),
      data: {
        __typename: 'User',
        id,
        email,
      },
    })
  },
  [MutationTypes.SET_AUTHENTICATING](state, authenticating: boolean) {
    state.authenticating = authenticating
  },
}

export default mutations
