import type { PluginObject } from 'vue'

import Storage from './Storage'

export const storage = new Storage()

const WebStoragePlugin: PluginObject<never> = {
  install(Vue) {
    Vue.prototype.$storage = storage
  },
}

export default WebStoragePlugin
