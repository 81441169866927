import Cookies, { CookieAttributes } from 'js-cookie'

import type { Undefined } from '@/types'
import type { Token, RefreshToken } from './auth.types'
import type { TokensManager } from './interfaces/TokensManager'
import type { Refresher } from './interfaces/Refresher'

const COOKIES_SET_OPTIONS: CookieAttributes =
  process.env.VUE_APP_ENVIRONMENT === 'development'
    ? {
        domain: '.localhost',
        path: '/',
        secure: false,
        sameSite: 'Lax',
        expires: 30,
      }
    : {
        domain: '.thegogame.com',
        path: '/',
        secure: true,
        sameSite: 'None',
        expires: 30,
      }

const COOKIES_REMOVE_OPTIONS: CookieAttributes =
  process.env.VUE_APP_ENVIRONMENT === 'development'
    ? {
        domain: '.localhost',
        path: '/',
      }
    : {
        domain: '.thegogame.com',
        path: '/',
      }

const Key = {
  TOKEN: `tgg_${process.env.VUE_APP_ENVIRONMENT}_token`,
  REFRESH_TOKEN: `tgg_${process.env.VUE_APP_ENVIRONMENT}_refresh_token`,
}

export default class TokensManagerImpl implements TokensManager {
  private _token: Undefined<Token>
  private _refreshToken: Undefined<RefreshToken>

  constructor(
    private readonly refresher: Refresher,
    options: { token?: Token; refreshToken?: RefreshToken } = {},
  ) {
    const token = options.token ?? Cookies.get(Key.TOKEN) ?? undefined
    const refreshToken =
      options.refreshToken ?? Cookies.get(Key.REFRESH_TOKEN) ?? undefined

    this._token = token
    this._refreshToken = refreshToken
  }

  get token(): Undefined<Token> {
    return this._token
  }

  set token(value: Undefined<Token>) {
    this._token = value
    if (value !== undefined) {
      Cookies.set(Key.TOKEN, value, COOKIES_SET_OPTIONS)
    } else {
      Cookies.remove(Key.TOKEN, COOKIES_REMOVE_OPTIONS)
    }
  }

  get refreshToken(): Undefined<RefreshToken> {
    return this._refreshToken
  }

  set refreshToken(value: Undefined<RefreshToken>) {
    this._refreshToken = value
    if (value !== undefined) {
      Cookies.set(Key.REFRESH_TOKEN, value, COOKIES_SET_OPTIONS)
    } else {
      Cookies.remove(Key.REFRESH_TOKEN, COOKIES_REMOVE_OPTIONS)
    }
  }

  get empty(): boolean {
    return this.token === undefined
  }

  async refresh(): Promise<Undefined<Token>> {
    if (this._refreshToken === undefined) return undefined
    const token = await this.refresher.refresh(this._refreshToken)
    this.token = token
    return token
  }

  clear(): void {
    this._token = undefined
    this._refreshToken = undefined
    Cookies.remove(Key.TOKEN, COOKIES_REMOVE_OPTIONS)
    Cookies.remove(Key.REFRESH_TOKEN, COOKIES_REMOVE_OPTIONS)
  }
}
