import type { ApolloClient } from '@apollo/client/core'

import type { SessionId } from '@/types'
import type {
  SessionStatus,
  GetSessionWithAssignmentsQuery,
  GetSessionWithAssignmentsQueryVariables,
  GetSessionWithIntegrationsQuery,
  GetSessionWithIntegrationsQueryVariables,
  SessionWithCreditsFragment,
  SessionWithStatusFragment,
} from '@/graphql/types'

import getSessionWithIntegrationsDocument from './graphql/getSessionWithIntegrations.graphql'
import getSessionWithAssignmentsDocument from './graphql/getSessionWithAssignments.graphql'
import sessionWithStatusDocument from './graphql/sessionWithStatus.graphql'
import sessionWithCreditsDocument from './graphql/sessionWithCredits.graphql'

export default class SessionCache {
  static async refetchIntegrations(
    client: ApolloClient<unknown>,
    id: SessionId,
  ): Promise<void> {
    await client.query<
      GetSessionWithIntegrationsQuery,
      GetSessionWithIntegrationsQueryVariables
    >({
      query: getSessionWithIntegrationsDocument,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    })
  }

  static async refetchAssignments(
    client: ApolloClient<unknown>,
    id: SessionId,
  ): Promise<void> {
    await client.query<
      GetSessionWithAssignmentsQuery,
      GetSessionWithAssignmentsQueryVariables
    >({
      query: getSessionWithAssignmentsDocument,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    })
  }

  static updateStatus(
    client: ApolloClient<unknown>,
    id: SessionId,
    status: SessionStatus,
  ): void {
    client.writeFragment<SessionWithStatusFragment>({
      fragment: sessionWithStatusDocument,
      id: `Session:${id}`,
      data: {
        __typename: 'Session',
        id,
        status,
      },
    })
  }

  static updateCredits(
    client: ApolloClient<unknown>,
    id: SessionId,
    credits: number,
  ): void {
    client.writeFragment<SessionWithCreditsFragment>({
      fragment: sessionWithCreditsDocument,
      id: `Session:${id}`,
      data: {
        __typename: 'Session',
        id,
        credits,
      },
    })
  }
}
