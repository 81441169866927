import { isEqual } from 'lodash-es'

export default function changed<T extends Record<string, unknown>>(
  original: T,
  changed: T,
): Array<keyof T> {
  const result: Array<keyof T> = []
  for (const key of Object.keys(original)) {
    if (isEqual(original[key], changed[key]) === false) {
      result.push(key)
    }
  }
  return result
}
