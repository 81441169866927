
import type { PropType, VNode } from 'vue'
import mixins from 'vue-typed-mixins'
import type { WithRefs } from 'vue-typed-refs'
import Multiselect from 'vue-multiselect'

import type { VueComponentListeners } from '../../types'
import { IdentifiableMixin, ThemeMixin, SelectMixin } from '../../mixins'

import WeveIcon from '../Icon/Icon.vue'
import WeveFormControlLabel from '../FormControlLabel/FormControlLabel.vue'
import WeveFormControlHint from '../FormControlHint/FormControlHint.vue'
import WeveFormControlMessage from '../FormControlMessage/FormControlMessage.vue'
import WevePill from '../Pill/Pill.vue'
import WeveBadge from '../Badge/Badge.vue'
import WeveSpinner from '../Spinner/Spinner.vue'
import WeveLink from '../Link/Link.vue'
import WeveAlert from '../Alert/Alert.vue'

import WeveMultiselectOption from './components/MultiselectOption.vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'

type Refs = {
  loader: HTMLElement
}

const Extendable = mixins(IdentifiableMixin, ThemeMixin, SelectMixin)

export default (Extendable as WithRefs<Refs, typeof Extendable>).extend({
  name: 'WeveMultiselect',
  inheritAttrs: false,
  props: {
    value: {
      type: Array as PropType<unknown[] | null>,
      default() {
        return []
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 9999,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabledOnLoading: {
      type: Boolean,
      default: false,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: undefined,
    },
    error: {
      type: String,
      default: undefined,
    },
    removeable: {
      type: [Boolean, Function] as PropType<
        boolean | ((option: unknown) => boolean)
      >,
      default: true,
    },
  },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    normalizedValue(): Record<string, unknown>[] {
      if (this.value === null) return []
      return this.value.map((value) => {
        const option = this._getOptionByValue(value)

        if (
          option !== undefined &&
          typeof option === 'object' &&
          option !== null
        ) {
          return option as Record<string, unknown>
        }

        return { [this.optionLabel]: value, [this.optionValue]: value }
      })
    },
    normalizedOptions(): unknown[] {
      const clone = this.options.slice()
      const result: Record<string, unknown>[] = []

      for (const option of clone) {
        const o: Record<string, unknown> = {}
        if (typeof option === 'object' && option !== null) {
          o[this.optionValue] = option[this.optionValue]
          o[this.optionLabel] = option[this.optionLabel]
        } else {
          o[this.optionValue] = option
          o[this.optionLabel] = option
        }

        if (this.loading && this.disabledOnLoading) {
          o.$isDisabled = true
        }

        result.push(o)
      }

      for (const value of this.normalizedValue) {
        const option = this._getOptionByValue(this._getOptionValue(value))

        if (option === undefined) {
          result.push({
            [this.optionLabel]: this._getOptionLabel(value),
            [this.optionValue]: this._getOptionValue(value),
          })
        }
      }

      result.sort((a, b) => {
        if (this.isSelected(a) && this.isSelected(b)) {
          return 0
        }
        if (this.isSelected(a)) {
          return -1
        }
        return 1
      })

      return result
    },
    listeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        input: (e: unknown[]) => {
          this.$emit('input', e.map(this._getOptionValue))
        },
        open: () => {
          this.active = true
        },
        close: () => {
          this.active = false
        },
      }
    },
    limitText(): string {
      return (this.normalizedValue.length ?? 0) > this.limit
        ? `+${(this.normalizedValue.length ?? 0) - this.limit}`
        : '0'
    },
    computedPlaceholder(): string | undefined {
      const { label, placeholder } = this
      if (placeholder !== undefined) return placeholder
      if (label === undefined) return undefined
      return `Select ${label}`
    },
  },
  watch: {
    loading(value: boolean) {
      if (value) {
        this.$nextTick(() => {
          const list = this.$refs.loader.parentElement
          if (
            list !== null &&
            list.scrollTop === list.scrollHeight - list.offsetHeight
          ) {
            list.scrollTop = list.scrollHeight
          }
        })
      }
    },
  },
  mounted() {
    // setInterval(() => {
    //   this.$refs.multiselect.isOpen = true
    // }, 1000)
  },
  methods: {
    isSelected(option: unknown): boolean {
      return (
        this.value?.find((val) => val === this._getOptionValue(option)) !==
        undefined
      )
    },
  },
  render(h): VNode {
    return h(
      'div',
      {
        class: {
          'app-multiselect--active': this.active,
          'app-multiselect--invalid': this.error !== undefined,
          'w-theme--light': this.light,
          'w-theme--dark': this.dark,
        },
        staticClass: 'app-multiselect app-form-control',
      },
      [
        h('div', { staticClass: 'app-multiselect__top' }, [
          h(
            WeveFormControlLabel,
            {
              props: { disabled: this.disabled },
              attrs: { for: this.id },
              staticClass: 'app-multiselect__label',
            },
            this.label,
          ),
        ]),
        h('div', { staticClass: 'app-multiselect__inner' }, [
          this.normalizedValue.length === 0 && this.active
            ? h(
                'span',
                { staticClass: 'app-multiselect__placeholder' },
                this.computedPlaceholder,
              )
            : undefined,
          h(Multiselect, {
            ref: 'multiselect',
            props: {
              ...this.$attrs,
              value: this.normalizedValue,
              options: this.normalizedOptions,
              id: this.id,
              multiple: true,
              label: this.optionLabel,
              disabled: this.disabled,
              trackBy: this.optionValue,
              placeholder: this.computedPlaceholder,
              showLabels: false,
              limit: this.limit,
              closeOnSelect: false,
            },
            on: this.listeners,
            scopedSlots: {
              caret: ({ toggle }) => {
                return [
                  this.loading
                    ? h(WeveSpinner, {
                        staticClass: 'app-multiselect__spinner',
                      })
                    : undefined,
                  h(
                    'button',
                    {
                      attrs: { type: 'button', 'aria-label': 'Toggle' },
                      staticClass: 'app-multiselect__caret',
                      on: {
                        click: toggle,
                      },
                    },
                    [h(WeveIcon, { props: { name: 'arrow-drop-down' } })],
                  ),
                ]
              },
              tag: ({ option, remove }) => {
                return h(
                  WevePill,
                  {
                    props: {
                      removable:
                        this.removeable === true
                          ? true
                          : this.removeable === false
                            ? false
                            : this.removeable(option),
                      disabled: this.loading,
                      wrap: true,
                    },
                    on: {
                      remove: () => {
                        remove(option)
                      },
                    },
                  },
                  this._getOptionLabel(option),
                )
              },
              afterList: () => {
                if (this.loading) {
                  return h(
                    'li',
                    { ref: 'loader', staticClass: 'h-8 py-1 fz-0 relative' },
                    [
                      h(
                        'div',
                        {
                          staticClass:
                            'absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2',
                        },
                        [
                          h(WeveSpinner, {
                            props: { size: 'sm' },
                            staticClass: '',
                          }),
                        ],
                      ),
                    ],
                  )
                }

                if (this.hasMore) {
                  return h('li', { staticClass: 'h-7 py-1 text-center' }, [
                    h(
                      WeveLink,
                      {
                        attrs: { role: 'button', href: '#' },
                        staticClass: 'text-sm',
                        on: {
                          click: (e: Event) => {
                            e.preventDefault()
                            this.$emit('more')
                          },
                        },
                      },
                      'Load More',
                    ),
                  ])
                }

                return undefined
              },
              option: ({ option }) => {
                return h(WeveMultiselectOption, {
                  props: {
                    selected: this.isSelected(option),
                    text: this._getOptionLabel(option),
                    disabled: option.$isDisabled,
                  },
                })
              },
              limit:
                this.limit !== undefined
                  ? () => {
                      return h(
                        WeveBadge,
                        { staticClass: 'app-multiselect__badge' },
                        this.limitText,
                      )
                    }
                  : undefined,
              noOptions: () => {
                return h(
                  WeveAlert,
                  { props: { variant: 'warning' } },
                  'List is empty',
                )
              },
              noResult: () => {
                if (this.loading) return h('span')
                return h(
                  WeveAlert,
                  { props: { variant: 'warning' } },
                  'Sorry, no matching options',
                )
              },
            },
          }),
        ]),
        this.hint !== undefined || this.error !== undefined
          ? h('div', { staticClass: 'app-multiselect__bottom' }, [
              h(WeveFormControlHint, undefined, this.hint),
              h(
                WeveFormControlMessage,
                { props: { variant: 'danger' } },
                this.error,
              ),
            ])
          : undefined,
      ],
    )
  },
})
