import { getCurrentInstance as originalCurrentInstance } from 'vue'

export default function getCurrentInstance(): NonNullable<
  ReturnType<typeof originalCurrentInstance>
> {
  const instance = originalCurrentInstance()
  if (instance === null) {
    throw new Error(
      '`getCurrentInstance` must be called within a `setup` function',
    )
  }
  return instance
}
