import { SessionDocument } from '@/types'
import {
  SessionType,
  PackageLevel,
  HostType,
  PdfType,
  SessionTemplateType,
  SessionStatus,
} from '@/graphql/types'
import type { Status } from '@/components/ui/types'

export const SESSION_TYPE_TO_DISPLAY_NAME: Record<SessionType, string> = {
  [SessionType.REMOTE]: 'Virtual',
  [SessionType.HYBRID]: 'Hybrid',
  [SessionType.IN_PERSON]: 'In-Person',
}

export const SESSION_TYPE_OPTIONS: { value: SessionType; label: string }[] =
  Object.values(SessionType).map((type) => ({
    value: type,
    label: SESSION_TYPE_TO_DISPLAY_NAME[type],
  }))

export const SESSION_DOCUMENT_TO_DISPLAY_NAME: Record<SessionDocument, string> =
  {
    [SessionDocument.IRL_ADMIN_INSTRUCTIONS]: 'Admin Instructions',
    [SessionDocument.IRL_LOGIN_INSTRUCTIONS]: 'Player Login Instructions',
    [SessionDocument.IRL_LOGIN_INSTRUCTIONS_NO_CODE]:
      'Player Login Instructions Without Code',
    [SessionDocument.IRL_TALKING_POINTS]: 'Talking Points',
  }

export const HOST_TYPE_TO_DISPLAY_NAME: Record<HostType, string> = {
  [HostType.ON_SCREEN]: 'On-Screen',
  [HostType.ON_SITE]: 'On-Site',
  [HostType.SELF_HOSTED]: 'Self-Hosted',
}

export const PDF_TYPE_TO_DISPLAY_NAME = {
  [PdfType.BATTLE_ROYALE]: 'Battle Royale',
  [PdfType.GAME_ADVENTURE]: 'Go Game Adventure',
  [PdfType.MUSIC_VIDEO]: 'Music Video',
  [PdfType.SECRET_AGENT]: 'Secret Agent',
  [PdfType.GAME_SHOW]: 'Game Show',
  [PdfType.SINGLE_TEAM_ADVENTURE]: 'Single Team Adventure',
  [PdfType.SPANISH_ADVENTURE]: 'Spanish Adventure',
  [PdfType.NETWORKING_BINGO]: 'Networking Bingo',
  [PdfType.VAS]: 'VAS',
}

export const PACKAGE_LEVEL_TO_DISPLAY_NAME: Record<PackageLevel, string> = {
  [PackageLevel.STANDARD]: 'Standard',
  [PackageLevel.PRO]: 'Pro',
  [PackageLevel.PREMIUM]: 'Premium',
}

export const SESSION_TEMPLATE_TYPE_TO_DISPLAY_NAME: Record<
  SessionTemplateType,
  string
> = {
  [SessionTemplateType.LEGACY]: 'Templates',
  [SessionTemplateType.MASTER]: 'Content Tool Templates',
  [SessionTemplateType.CUSTOM]: 'Custom Templates',
}

export const PACKAGE_LEVEL_OPTIONS = [
  {
    value: PackageLevel.STANDARD,
    label: PACKAGE_LEVEL_TO_DISPLAY_NAME[PackageLevel.STANDARD],
  },
  {
    value: PackageLevel.PRO,
    label: PACKAGE_LEVEL_TO_DISPLAY_NAME[PackageLevel.PRO],
  },
  {
    value: PackageLevel.PREMIUM,
    label: PACKAGE_LEVEL_TO_DISPLAY_NAME[PackageLevel.PREMIUM],
  },
]

export const SESSION_STATUS_TO_DISPLAY_NAME: Record<SessionStatus, string> = {
  [SessionStatus.MANAGERS_NEEDED]: 'Managers needed',
  [SessionStatus.HOST_NEEDED]: 'Needs hosts assigned',
  [SessionStatus.USER_DETAILS_REQUIRED]: 'More details required to run',
  [SessionStatus.EM_DETAILS_REQUIRED]: 'Ready for review',
  [SessionStatus.GAME_READY]: 'Ready to run event',
}

export const SESSION_STATUS_OPTIONS = Object.values(SessionStatus).map(
  (status) => ({
    value: status,
    label: SESSION_STATUS_TO_DISPLAY_NAME[status],
  }),
)

export const SESSION_STATUS_TO_STATUS: Record<SessionStatus, Status> = {
  [SessionStatus.MANAGERS_NEEDED]: 'warning',
  [SessionStatus.HOST_NEEDED]: 'warning',
  [SessionStatus.USER_DETAILS_REQUIRED]: 'warning',
  [SessionStatus.EM_DETAILS_REQUIRED]: 'warning',
  [SessionStatus.GAME_READY]: 'success',
}

export const VAS_PDF_TYPES = new Set([PdfType.VAS])
export const CODE_PDF_TYPES = new Set([
  PdfType.BATTLE_ROYALE,
  PdfType.GAME_ADVENTURE,
  PdfType.MUSIC_VIDEO,
  PdfType.SECRET_AGENT,
])
export const PLAY_PDF_TYPES = new Set([
  PdfType.NETWORKING_BINGO,
])
