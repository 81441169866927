import { useMutation } from '@tanstack/vue-query'

import type { UserId } from '@/types'

import useConfirm from './useConfirm'
import useAuthService from './services/useAuthService'

export default function useResetPassword(onError?: (e: unknown) => void) {
  const { confirm } = useConfirm()
  const { service } = useAuthService()
  const { mutateAsync, isLoading: loading } = useMutation(
    async (id: UserId) => {
      await service.resetPassword(id)
    },
    { onError },
  )
  async function reset(id: UserId): Promise<boolean> {
    const confirmed = await confirm(
      'Are you sure you would like to reset password? User will be logged out and will need to check email for a reset code.',
    )
    if (confirmed === false) return false
    await mutateAsync(id)
    return true
  }

  return {
    reset,
    loading,
  }
}
