import { useApolloClient } from '@vue/apollo-composable'

import { SessionId, SessionGroupId } from '@/types'
import type { SessionGroupWithSessionsFragment } from '@/graphql/types'
import { namedOperations } from '@/graphql/types'
import sessionGroupWithSessionsDocument from '@/graphql/common/fragments/sessionGroupWithSessions.graphql'

export default function useSessionGroup() {
  const { client } = useApolloClient()
  function add(id: SessionGroupId, session: SessionId) {
    const data = client.readFragment<SessionGroupWithSessionsFragment>({
      fragment: sessionGroupWithSessionsDocument,
      fragmentName: namedOperations.Fragment.sessionGroupWithSessions,
      id: `SessionGroup:${id}`,
    })
    if (data === null) return
    client.writeFragment<SessionGroupWithSessionsFragment>({
      fragment: sessionGroupWithSessionsDocument,
      fragmentName: namedOperations.Fragment.sessionGroupWithSessions,
      id: `SessionGroup:${id}`,
      data: {
        __typename: 'SessionGroup',
        id: data.id,
        sessions: [...data.sessions, { __typename: 'Session', id: session }],
      },
    })
  }
  return { add }
}
