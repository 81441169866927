import Vue from 'vue'

import type { Nullable, UserId } from '@/types'
import { getCurrentInstance } from '@/util'

import type ChangeEmailDialogType from './components/ChangeEmailDialog.vue'

export default function useEmail() {
  const vm = getCurrentInstance()

  async function change(id: UserId): Promise<{ success: boolean }> {
    return new Promise(async (resolve) => {
      const { default: options } = await import(
        './components/ChangeEmailDialog.vue?options'
      )
      const ChangeEmailDialog = Vue.extend(options)
      let instance: Nullable<InstanceType<typeof ChangeEmailDialogType>> =
        new ChangeEmailDialog({
          propsData: { user: id },
          parent: vm.proxy.$root,
        })
      const div = document.createElement('div')
      document.body.appendChild(div)
      instance.$mount(div)
      instance.show()
      const destroy = () => {
        if (instance === null) {
          return
        }
        instance.$destroy()
        instance.$el.remove()
        instance = null
        resolve({ success: false })
      }
      vm.proxy.$once('hook:destroyed', destroy)
      instance.$once('cancel', () => {
        destroy()
        resolve({ success: false })
      })
      instance.$once('success', () => {
        destroy()
        resolve({ success: true })
      })
    })
  }

  return {
    change,
  }
}
