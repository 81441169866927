import type { PluginObject, VNode } from 'vue'
import { ModalPlugin } from 'bootstrap-vue'

import type { NotifyCallback } from './notify.types'
import DefaultNotify from './components/DefaultNotify.vue'

function notify(
  this: Vue,
  message: string | VNode[],
  options: { title?: string; callback?: NotifyCallback } = {},
) {
  const div = document.createElement('div')
  document.body.append(div)
  const dn: InstanceType<typeof DefaultNotify> = new DefaultNotify({
    propsData: {
      text: typeof message === 'string' ? message : undefined,
      title: options.title,
      callback: options.callback,
    },
  })
  if (typeof message !== 'string') {
    dn.$slots.default = message
  }
  dn.$mount(div)
  return { hide: dn.ok }
}

const NotifyPlugin: PluginObject<never> = {
  install(Vue) {
    const NotifyMixin = Vue.extend({
      created() {
        this.$notify = notify
      },
    })

    Vue.use(ModalPlugin)
    Vue.mixin(NotifyMixin)
  },
}

export type Notify = typeof notify

export default NotifyPlugin
