import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import { props } from '../../mixins/message.mixin'
import FormControlMessage from '../FormControlMessage/FormControlMessage.vue'

import './StepperStep.scss'

export default ThemeMixin.extend({
  name: 'StepperStep',
  functional: true,
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    accessible: {
      type: Boolean,
      default: false,
    },
    ...props,
  },
  render(h, { props, data, slots, children, scopedSlots, injections }) {
    const { prepend } = slots()
    const prependSlot =
      prepend ?? scopedSlots.prepend !== undefined
        ? scopedSlots.prepend({})
        : undefined

    return h(
      'li',
      mergeData(data, {
        attrs: { 'aria-current': props.active ? 'true' : undefined },
        staticClass: 'weve-stepper-step',
        class: {
          'weve-stepper-step--active': props.active,
          'weve-stepper-step--accessible': props.accessible,
          'weve-stepper-step--with-prepend': prependSlot !== undefined,
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme === 'dark',
        },
      }),
      [
        h(
          'div',
          {
            staticClass: 'weve-stepper-step__inner',
          },
          [
            prependSlot !== undefined
              ? h(
                  'span',
                  {
                    staticClass: 'weve-stepper-step__prepend',
                  },

                  prependSlot,
                )
              : null,
            children,
          ],
        ),
        props.message !== undefined
          ? h('div', { staticClass: 'weve-stepper-step__bottom' }, [
              h(
                FormControlMessage,
                { props: { variant: props.messageVariant } },
                props.message,
              ),
            ])
          : null,
      ],
    )
  },
})
