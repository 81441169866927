
import type { PropType } from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import type { Theme } from '../../types'
import { ThemeMixin } from '../../mixins'
import type { SpinnerSize } from './Spinner.types'
import { SPINNER_SIZES } from './Spinner.types'

export default ThemeMixin.extend({
  name: 'WeveSpinner',
  functional: true,
  props: {
    size: {
      type: String as PropType<SpinnerSize>,
      default: 'default' as const,
      validator: (val: SpinnerSize) => SPINNER_SIZES.includes(val),
    },
    theme: {
      type: String as PropType<Theme>,
      validator: (val: Theme) => val === 'light' || val === 'dark',
    },
  },
  render(h, { props, data, injections }) {
    const theme = props.theme ?? injections.theme
    return h(
      'span',
      mergeData(data, {
        attrs: { role: 'status' },
        class: {
          [`app-spinner--size--${props.size}`]: true,
          'w-theme--light': theme === 'light',
          'w-theme--dark': theme !== 'light',
        },
        staticClass: 'app-spinner',
      }),
      [h('span', { staticClass: 'sr-only' }, 'Loading')],
    )
  },
})
