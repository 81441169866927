/* global google */
import type { MaybeRef } from 'vue'
import { ref, computed, unref } from 'vue'
import axios from 'axios'
import { watchDebounced } from '@vueuse/core'

import type { Nullable } from '@/types'
import { LocationInput } from '@/graphql/types'
import { useGeocoder } from '@/modules/location'

export default function useGeoLocation(
  enabled: MaybeRef<boolean> = true,
  delay = 1000,
) {
  const location = ref<Nullable<LocationInput>>(null)
  const { geocoder } = useGeocoder()

  const enabled$ = computed(() => {
    if (unref(enabled) === false) return false
    if (geocoder.value === null) return false
    return true
  })

  watchDebounced(
    enabled$,
    async (value) => {
      if (value === false) return
      if (geocoder.value === null) return

      const { data } = await axios.post<{
        location: { lat: number; lng: number }
      }>(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`,
      )
      const { lat, lng } = data.location
      const latLng = new google.maps.LatLng(lat, lng)
      const response = await geocoder.value.geocode({
        location: latLng,
      })
      const entry =
        response.results.find((result) => result.types.includes('locality')) ??
        null
      if (entry === null) return
      location.value = {
        name: entry.address_components[0].long_name,
        address: entry.formatted_address,
        placeId: entry.place_id,
        point: {
          type: 'Point',
          coordinates: [
            entry.geometry.location.lng(),
            entry.geometry.location.lat(),
          ],
        },
      }
    },
    { debounce: delay, immediate: true },
  )

  return { location }
}
