
import type { VNode } from 'vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'DisabledWrapper',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    active: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Boolean,
      default: false,
    },
  },
  render(h): VNode {
    if (this.active) {
      return h(
        this.tag,
        {
          attrs: { tabindex: '0' },
          staticClass: 'AppDisabledWrapper',
          class: {
            'AppDisabledWrapper--rounded': this.rounded,
            'AppDisabledWrapper--offset': this.offset,
          },
        },
        this.$slots.default,
      )
    } else {
      if (this.$slots.default === undefined) {
        return h()
      }
      return this.$slots.default[0]
    }
  },
})
