import type { SessionDocument, SessionId } from '@/types'
import { PackageLevel } from '@/graphql/types'

import Request from '../Request'
import BaseService from '../BaseService'
import {
  SessionFile,
  CreateSessionDto,
  CreateCode,
  PriceCode,
} from './SessionService.types'
import type {
  SyncResult,
  SessionPriceInput,
  SessionQuote,
  ToGroupResult,
} from './SessionService.types'
import { UpdateSlotsCode } from './SessionService.types'
import {
  NotEnoughHostsException,
  NotEnoughCreditsAndHostsException,
  NotEnoughCreditsException,
  UnknownPriceZoneException,
  SubscribersOnlyException,
} from './SessionService.exceptions'

export interface CreateSessionResponse {
  id: SessionId
}

class SessionService extends BaseService {
  price(input: SessionPriceInput): Request<SessionQuote> {
    const ac = new AbortController()
    return new Request(
      this.client
        .post<SessionQuote>('/cart/session/calculate', input, {
          signal: ac.signal,
        })
        .then(({ data }) => data)
        .catch((e) => {
          if (
            BaseService.isServerErrorWithMetadata<{
              code: PriceCode
              missing: number
            }>(e)
          ) {
            if (
              e.response.data.metadata.code === PriceCode.UNKNOWN_PRICE_ZONE
            ) {
              throw new UnknownPriceZoneException()
            }
          }
          throw e
        }),
      ac,
    )
  }

  async create(payload: CreateSessionDto): Promise<CreateSessionResponse> {
    const params = {
      workspaceId: payload.workspaceId,
      experienceId: payload.experienceId,
      format: payload.format,
      hostType: payload.hostType,
      timezone: payload.timezone,
      attendees: payload.attendees,
      duration: payload.duration,
      date: payload.date ?? undefined,
      location: payload.location ?? undefined,
      packageLevel: payload.packageLevel ?? undefined,
      customizations:
        payload.customizations !== null
          ? [...payload.customizations]
          : undefined,
      group: payload.group ?? undefined,
    }
    try {
      const { data } = await this.client.post<CreateSessionResponse>(
        '/session',
        params,
      )
      return data
    } catch (e) {
      if (
        BaseService.isServerErrorWithMetadata<{
          code: CreateCode
          missing: number
        }>(e)
      ) {
        if (e.response.data.metadata.code === CreateCode.NOT_ENOUGH_CREDITS) {
          throw new NotEnoughCreditsException(e.response.data.metadata.missing)
        }
        if (e.response.data.metadata.code === CreateCode.SUBSCRIBERS_ONLY) {
          throw new SubscribersOnlyException()
        }
      }
      throw e
    }
  }

  async upgrade(id: string, upgrade: PackageLevel): Promise<void> {
    try {
      await this.client.post(`/session/${id}/upgrade`, {
        upgrade,
      })
    } catch (e) {
      if (
        BaseService.isServerErrorWithMetadata<{
          code: CreateCode
          missing: number
        }>(e)
      ) {
        if (e.response.data.metadata.code === CreateCode.NOT_ENOUGH_CREDITS) {
          throw new NotEnoughCreditsException(e.response.data.metadata.missing)
        }
      }
      throw e
    }
  }

  async upgradeForce(id: string, upgrade: PackageLevel): Promise<void> {
    await this.client.post(`/session/${id}/upgrade/force`, {
      upgrade,
    })
  }

  async updateSlots(session: SessionId, quantity: number): Promise<void> {
    try {
      await this.client.put(`/scheduler/session/${session}/slots`, {
        quantity,
      })
    } catch (e: unknown) {
      if (
        BaseService.isServerErrorWithMetadata<{
          code: UpdateSlotsCode.NOT_ENOUGH_CREDITS
          missing: number
        }>(e) &&
        e.response.data.metadata.code === UpdateSlotsCode.NOT_ENOUGH_CREDITS
      ) {
        throw new NotEnoughCreditsException(e.response.data.metadata.missing)
      }

      if (
        BaseService.isServerErrorWithMetadata<{
          code: UpdateSlotsCode.NOT_ENOUGH_HOSTS
          missing: number
          fee: number
        }>(e) &&
        e.response.data.metadata.code === UpdateSlotsCode.NOT_ENOUGH_HOSTS
      ) {
        throw new NotEnoughHostsException(
          e.response.data.metadata.missing,
          e.response.data.metadata.fee,
        )
      }

      if (
        BaseService.isServerErrorWithMetadata<{
          code: UpdateSlotsCode.NOT_ENOUGH_HOSTS_AND_CREDITS
          credits: number
          hosts: number
          fee: number
        }>(e) &&
        e.response.data.metadata.code ===
          UpdateSlotsCode.NOT_ENOUGH_HOSTS_AND_CREDITS
      ) {
        throw new NotEnoughCreditsAndHostsException(
          new NotEnoughCreditsException(e.response.data.metadata.credits),
          new NotEnoughHostsException(
            e.response.data.metadata.hosts,
            e.response.data.metadata.fee,
          ),
        )
      }
      throw e
    }
  }

  async updateSlotsForce(session: SessionId, quantity: number): Promise<void> {
    await this.client.put(`/scheduler/session/${session}/slots/force`, {
      quantity,
    })
  }

  async sync(id: string): Promise<SyncResult> {
    const { data } = await this.client.post<SyncResult>(
      `session/${id}/synchronize`,
    )
    return data
  }

  async link(id: string, gameId: string): Promise<SyncResult[]> {
    const { data } = await this.client.post<SyncResult[]>(
      `session/${id}/link/breadcrumb`,
      {
        gameId,
      },
    )
    return data
  }

  async file(id: SessionId, type: SessionFile): Promise<Blob> {
    const { data } = await this.client.get(`/session/${id}/report/${type}`, {
      responseType: 'blob',
    })
    return data
  }

  async document(id: SessionId, type: SessionDocument): Promise<Blob> {
    const { data } = await this.client.get<Blob>(`/session/${id}/document`, {
      params: { type },
      responseType: 'blob',
    })
    return data
  }

  async lawnSchedule(id: SessionId): Promise<Blob> {
    const { data } = await this.client.get<Blob>(
      `/session/${id}/lawn-schedule`,
      {
        responseType: 'blob',
      },
    )
    return data
  }

  async toGroup(id: SessionId): Promise<ToGroupResult> {
    const { data } = await this.client.post<ToGroupResult>(
      '/session-group/session',
      { id },
    )
    return data
  }
}

export default SessionService
