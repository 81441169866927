
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'

export default ThemeMixin.extend({
  name: 'CheckboxSkeleton',
  functional: true,
  render(h, { data, injections }) {
    return h(
      'div',
      mergeData(data, {
        staticClass: 'app-checkbox-skeleton app-form-element-skeleton',
        class: {
          'w-theme--light': injections.theme === 'light',
          'w-theme--dark': injections.theme === 'dark',
        },
      }),
      [
        h('div', {
          staticClass: 'app-checkbox-skeleton__imitator skeleton-element',
        }),
        h('div', {
          staticClass: 'app-checkbox-skeleton__text skeleton-element',
        }),
      ],
    )
  },
})
