export const VARIANTS = ['google', 'outlook', 'ical'] as const

export type Variant = (typeof VARIANTS)[number]

export interface Props {
  variant: Variant
  title?: string
  location?: string
  start?: Date | null
  end?: Date | null
  details?: string
}
