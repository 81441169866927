
import type { PropType } from 'vue'
import type { RawLocation } from 'vue-router'
import { mergeData } from 'vue-functional-data-merge'

import { ThemeMixin } from '../../mixins'
import WeveIcon from '../Icon/Icon.vue'
import Spinner from '../Spinner/Spinner.vue'
import type { ButtonIconColor, ButtonIconVariant } from './ButtonIcon.types'
import { BUTTON_ICON_VARIANTS, BUTTON_ICON_COLORS } from './ButtonIcon.types'

export default ThemeMixin.extend({
  name: 'WeveButtonIcon',
  functional: true,
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    variant: {
      type: String as PropType<ButtonIconVariant>,
      default: 'default' as const,
      validator: (val: ButtonIconVariant) => BUTTON_ICON_VARIANTS.has(val),
    },
    color: {
      type: String as PropType<ButtonIconColor>,
      default: 'default' as const,
      validator: (val: ButtonIconColor) => BUTTON_ICON_COLORS.has(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: undefined,
    },
  },
  render(h, { props, data, children, injections }) {
    const tag = props.to !== undefined ? 'router-link' : 'button'

    return h(
      tag,
      mergeData(data, {
        props:
          tag === 'router-link'
            ? {
                to: props.to,
              }
            : undefined,
        attrs: {
          type: tag === 'button' ? 'button' : undefined,
          'aria-label': props.label,
          disabled: props.disabled || props.loading,
        },
        staticClass: 'weve-button-icon',
        class: {
          [`weve-button-icon--variant--${props.variant}`]: true,
          [`weve-button-icon--color--${props.color}`]:
            props.color !== 'default',
          'weve-button-icon--disabled': props.disabled,
          'weve-button-icon--loading': props.loading,
          'weve-button-icon--active': props.active,
          'w-theme--light': props.inverse
            ? injections.theme === 'dark'
            : injections.theme === 'light',
          'w-theme--dark': props.inverse
            ? injections.theme === 'light'
            : injections.theme === 'dark',
        },
      }),
      [
        props.loading
          ? h(Spinner)
          : h(WeveIcon, { props: { name: props.icon } }),
        children,
      ],
    )
  },
})
