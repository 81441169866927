import Vue from 'vue'
import { mergeData } from 'vue-functional-data-merge'

import FormControlLabel from '../FormControlLabel/FormControlLabel.vue'

import './Fieldset.scss'

export default Vue.extend({
  name: 'WeveFieldset',
  functional: true,
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  render(h, { props, data, children }) {
    return h('fieldset', mergeData(data, { staticClass: 'weve-fieldset' }), [
      h(
        FormControlLabel,
        { props: { tag: 'legend' }, staticClass: 'weve-fieldset__label' },
        props.label,
      ),
      children,
    ])
  },
})
