import type { Store } from 'vuex'

import type { WorkspaceId } from '@/types'
import type { Role, PermissionRole } from '@/graphql/types'
import store from '@/store'
import GetterTypes from '@/store/getter-types'

import type { Accessor } from './types'

export default class AccessorImpl implements Accessor {
  private readonly store: Store<unknown>

  constructor() {
    this.store = store
  }

  hasAccess(workspaceId: WorkspaceId, role: PermissionRole): boolean {
    return this.store.getters[GetterTypes.HAS_ACCESS](workspaceId, role)
  }

  hasAppropriateRole(role: Role): boolean {
    return this.store.getters[GetterTypes.HAS_APPROPRIATE_ROLE](role)
  }
}
