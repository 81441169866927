import Vue, { PropType } from 'vue'

import { generateId } from '../utils'

export interface Props {
  id: string
  label: string | undefined
  placeholder: string | undefined
}

const IdentifiableMixin = Vue.extend({
  props: {
    id: {
      type: String,
      default(): string {
        return generateId()
      },
    },
    label: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    placeholder: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },
})

export default IdentifiableMixin
