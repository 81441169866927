import { ExperienceType, ExperienceCategory } from '@/graphql/types'

export const EXPERIENCE_TYPE_TO_DISPLAY_NAME: Record<ExperienceType, string> = {
  [ExperienceType.COMBO]: 'Combo',
  [ExperienceType.MULTI_FORMAT]: 'Multi Format',
}

export const EXPERIENCE_CATEGORY_TO_DISPLAY_NAME: Record<
  ExperienceCategory,
  string
> = {
  [ExperienceCategory.ADVENTURE]: 'Scavenger Hunts, etc.',
  [ExperienceCategory.GAME_SHOW]: 'Game Shows',
  [ExperienceCategory.DEI]: 'Culture Celebrations',
  [ExperienceCategory.PROFESSIONAL_DEVELOPMENT]: 'Professional Development',
  [ExperienceCategory.THEATRICAL]: 'Creative',
  [ExperienceCategory.ESCAPE_ROOM]: 'Escape Rooms',
  [ExperienceCategory.INTERNATIONAL]: 'International',
  [ExperienceCategory.SPECIAL_OCCASION]: 'Special Occasions',
  [ExperienceCategory.KIT_BASED]: 'Kit-Based',
  [ExperienceCategory.NONE]: 'None',
}

export enum ExperienceTitle {
  CLASSIC_ADVENTURE = 'Classic Adventure',
  LAWN_GAMES = 'Lawn Games',
  SECRET_AGENT = 'Secret Agent',
  BATTLE_ROYALE = 'Battle Royale',
  MUSIC_VIDEO = 'Music Video Game',
  MOVIE_GAME = 'Movie Game',
  LIP_DUB = 'Lip Dub',
  GAME_SHOW = 'Original Game Show',
  BLACKOUT = 'BLACKOUT',
}
