import { shallowRef, watch } from 'vue'

import type { Nullable } from '@/types'
import { useGoogleMaps } from '@/use'

const places = shallowRef<Nullable<google.maps.places.PlacesService>>(null)

export default function usePlaces() {
  const { loaded } = useGoogleMaps()
  watch(
    loaded,
    async (v) => {
      if (v === false) return
      places.value = new google.maps.places.PlacesService(
        document.createElement('div'),
      )
    },
    { immediate: true },
  )
  function get(): Promise<google.maps.places.PlacesService> {
    return new Promise((resolve) => {
      if (places.value !== null) {
        resolve(places.value)
        return
      }
      const unwatch = watch(places, (v) => {
        if (v === null) return
        unwatch()
        resolve(v)
      })
    })
  }
  return { places, get }
}
