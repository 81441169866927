import axios from 'axios'
import { ref, computed } from 'vue'
import { ApolloError } from '@apollo/client'

import { extractErrorMessage } from '@/util'

export default function useServerError() {
  const serverError = ref('')

  const hasServerError = computed(() => serverError.value !== '')

  function handleServerError(e: unknown) {
    resetServerError()
    if (e instanceof axios.Cancel) {
      return
    }
    serverError.value = extractErrorMessage(e)
  }

  function handleAplloError(e: unknown) {
    resetServerError()
    if (e instanceof ApolloError) {
      let first = true
      for (const error of e.graphQLErrors) {
        serverError.value += `${first ? '' : '\n'}${error.message}`
        if (first) {
          first = false
        }
      }
    } else {
      throw e
    }
  }

  function resetServerError() {
    serverError.value = ''
  }

  return {
    serverError,
    hasServerError,
    handleServerError,
    handleAplloError,
    resetServerError,
  }
}
