import type { Ref } from 'vue'
import { computed } from 'vue'

import { isFalsy } from '@/util'

export default function useValidationMessage(
  value: Ref<unknown>,
  rules: Ref<{ required?: boolean }>,
  options: { number?: boolean } = {},
) {
  const message = computed(() => {
    if (
      (isFalsy(value.value) ||
        (options.number === true && value.value === 0)) &&
      rules.value.required === true
    ) {
      return 'Required'
    }
    return undefined
  })
  return {
    message,
  }
}
