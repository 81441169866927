
import type { PropType, VNode } from 'vue'
import type { WithEvents } from 'vue-typed-emit'

import { SlotsMixin } from '../../mixins'
import WeveButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import WeveSelect from '../Select/Select.vue'

import type { Pagination, PaginationEvents } from './Pagination.types'
import { DEFAULT_PAGINATION_OPTIONS } from './Pagination.constants'

export default (
  SlotsMixin as WithEvents<PaginationEvents, typeof SlotsMixin>
).extend({
  name: 'WevePagination',
  components: {
    WeveButtonIcon,
    WeveSelect,
  },
  props: {
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      default: () => DEFAULT_PAGINATION_OPTIONS,
    },
  },
  render(h): VNode {
    return h('div', { staticClass: 'pagination' }, [
      this.hasSlot('prepend') ? this.normalizeSlot('prepend') : undefined,
      h(WeveSelect, {
        props: {
          value: this.limit,
          options: this.options,
          label: 'Limit',
          hideLabel: true,
          placeholder: 'Limit',
          appendToBody: true,
        },
        staticClass: 'pagination__limit',

        on: {
          input: (value: string) => this.$emit('update:limit', parseInt(value)),
        },
      }),
      h(WeveButtonIcon, {
        props: {
          icon: 'chevron-left',
          label: 'Go to previous page',
          disabled: this.pagination.hasPreviousPage === false,
        },
        on: { click: () => this.$emit('prev') },
      }),
      h(WeveButtonIcon, {
        props: {
          icon: 'chevron-right',
          label: 'Go to next page',
          disabled: this.pagination.hasNextPage === false,
        },
        on: { click: () => this.$emit('next') },
      }),
    ])
  },
})
